import * as S from './SegmentWrapper.styles';

const SegmentWrapper = ({
  children,
  title,
  icon,
}: {
  children?: any;
  title: string;
  icon: string;
}) => {
  const getIcon = () => {
    switch (icon) {
      case 'user':
        return <S.UserIcon />;
      case 'password':
        return <S.LockIcon />;
      case 'data':
        return <S.BriefcaseIcon />;
      case 'documents':
        return <S.DocumentIcon />;
      case 'notifications':
        return <S.LabelIcon />;
      default:
        break;
    }
  };

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        {getIcon()}
        <S.HeaderText>{title}</S.HeaderText>
      </S.HeaderWrapper>
      <S.BodyWrapper>{children}</S.BodyWrapper>
    </S.Wrapper>
  );
};

export default SegmentWrapper;

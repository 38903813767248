import { useEffect, useState } from 'react';
import { OFFER_TYPE } from '../../redux/offer/types';
import { ICategory } from '../../redux/utils/types';
import CategoryButton from '../Categories/CategoryButton/CategoryButton';
import SubcategoryPanel from '../Categories/SubcategoryPanel/SubcategoryPanel';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import * as S from './SlideComponent.styles';
import * as api from '../../api/common-api';

interface ISlideComponent {
  open: boolean;
  offerType: OFFER_TYPE;
  onSearch: () => void;
}

const SlideComponent = (props: ISlideComponent) => {
  const { open, offerType, onSearch } = props;

  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  const [categories, setCategories] = useState<ICategory[]>([]);

  const fetchCategories = async () => {
    const fetchedCategories = (await api.getCategories()) || [];
    setCategories(fetchedCategories);
  };
  const areCategoriesAvailable = categories && categories.length > 0;
  const bazaarCategories =
    areCategoriesAvailable &&
    categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.BAZAAR);
  const announementsCategories =
    categories && categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.ANNOUNCEMENT);

  const usedCategoriesType =
    offerType === OFFER_TYPE.ANNOUNCEMENT ? announementsCategories : bazaarCategories;

  const isUsedCategoriesType = usedCategoriesType && usedCategoriesType.length > 0;

  const handleSearchCategory = (val: string[]) => {
    const lastArrayElement = val && val[val.length - 1];
    if (!lastArrayElement) {
      return;
    }
    history.push(`/search?offerType=${offerType}&categoryId=${lastArrayElement}`);
  };

  const handleCategorySelect = (id: string) => {
    const pickedCategory =
      isUsedCategoriesType && usedCategoriesType.find((x) => x.id === id)?.categories;
    if (pickedCategory && pickedCategory.length < 1) {
      onSearch();
      return handleSearchCategory([id]);
    }
    if (selectedCategory.includes(id)) {
      return setSelectedCategory([]);
    }
    setSelectedCategory([id]);
  };

  useEffect(() => {
    setSelectedCategory([]);
    fetchCategories();
  }, [open]);
  const isDesktop = useMediaQuery('(min-width: 1600px)');
  const maxHeight = isDesktop ? '150px' : '120px';

  return (
    <S.Wrapper>
      <S.InnerWrapper isHovered={open} isHigher={offerType === OFFER_TYPE.BAZAAR}>
        <S.CategoriesWrapper isHovered={open} isHigher={offerType === OFFER_TYPE.BAZAAR}>
          {isUsedCategoriesType &&
            usedCategoriesType.map((category: ICategory) => {
              const { id, label, categories = [] } = category;
              const isActive = selectedCategory.includes(id);

              return (
                <div key={id} style={{ maxHeight: maxHeight, height: '100%', overflow: 'hidden' }}>
                  <CategoryButton
                    {...category}
                    isActive={isActive}
                    onClick={() => handleCategorySelect(id)}
                  />
                  <div>
                    {isActive && (
                      <SubcategoryPanel
                        handleSelectAll={() => {
                          handleSearchCategory([id]);
                          setSelectedCategory([]);
                          onSearch();
                        }}
                        selected={[]}
                        handleSelect={(subId: string) => {
                          handleSearchCategory([id, subId]);
                          setSelectedCategory([]);
                          onSearch();
                        }}
                        title={label}
                        subCategories={categories}
                        onClose={() => setSelectedCategory([])}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </S.CategoriesWrapper>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default SlideComponent;

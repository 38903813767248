import tick from '../../../assets/purple_tick.svg';
import * as S from './Benefits.styles';

export interface IBenefit {
  title: string;
  description: string;
}

interface IBenefits {
  benefits: IBenefit[];
}

const Benefits = ({ benefits }: IBenefits) => {
  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>Korzyści</S.Title>
      </S.TitleWrapper>
      {benefits &&
        benefits.length > 0 &&
        benefits.map((b: IBenefit, index: number) => (
          <S.BenefitWrapper key={index}>
            <S.TickWrapper>
              <S.Tick src={tick} />
            </S.TickWrapper>
            <S.ContentWrapper>
              <S.BenefitTitleWrapper>
                <S.BenefitTitle>{b.title}</S.BenefitTitle>
              </S.BenefitTitleWrapper>
              <S.BenefitDescriptionWrapper>
                <S.BenefitDescription>{b.description}</S.BenefitDescription>
              </S.BenefitDescriptionWrapper>
            </S.ContentWrapper>
          </S.BenefitWrapper>
        ))}
    </S.Wrapper>
  );
};

export default Benefits;

import { useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordError, loadingData } from '../../../redux/auth/selectors';
import { deleteAccount, setChangePasswordErrorMessage } from '../../../redux/auth/actions';
import { LOADING_FIELD } from '../../../redux/auth/types';
import delay from 'lodash/delay';
import SubmitButton from '../../LoginPage/SubmitButton/SubmitButton';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './DeleteAccount.styles';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  const { verificationCode } = params;

  useEffect(() => {
    if (!verificationCode) {
      history.push('/');
    }
  }, [verificationCode]);

  const { isLoading, loadingField } = useSelector(loadingData);

  const handleDeleteAccount = (event: FormEvent) => {
    event.preventDefault();
    dispatch(deleteAccount({ verificationCode }));
  };

  const errorMessage = useSelector(changePasswordError);

  const clearErrorMessage = () => dispatch(setChangePasswordErrorMessage(''));
  useEffect(() => {
    if (errorMessage) {
      delay(clearErrorMessage, 8000);
    }
  }, [errorMessage]);

  const isSubmitting = loadingField === LOADING_FIELD.PASSWORD_CHANGE && isLoading;

  return (
    <S.ExternalWrapper>
      <S.FormWrapper id="password-form" onSubmit={handleDeleteAccount}>
        <div>
          <SubmitButton type="submit" text={'Usuń konto'} isLoading={isSubmitting} />
        </div>
      </S.FormWrapper>
    </S.ExternalWrapper>
  );
};

export default DeleteAccount;

import { IOffer } from '../../../redux/offer/types';
import Logo from '../../../assets/malutcyLogo.svg';
import formatNumber from '../../../utils/formatNumber';
import { Button } from 'antd';
import * as S from '../../UserDashboard/OfferItem/OfferItem.styles';

const AdminOfferItem = ({
  offer: { id, title, price, offererPhone, offererEmail, count },
  isFirst,
  image,
}: {
  isFirst: boolean;
  offer: IOffer;
  image: any;
}) => {
  const formattedPrice = formatNumber(price);
  //TODO : handle confirmReport and removeReport ;))))
  const isDisabled = true;

  return (
    <S.Wrapper isFirst={isFirst}>
      {image ? (
        <S.Image src={image.href} alt={`${title} image`} />
      ) : (
        <S.MockImage src={Logo} alt={`no image provided`} />
      )}
      <S.BodyWrapper>
        <S.DataWrapper>
          <S.Title to={`/offer/${id}`}>{title}</S.Title>
          <S.PriceWrapper>
            <S.DataRow>{count}</S.DataRow>
          </S.PriceWrapper>
          <S.PriceWrapper>
            <S.DataRow>{formattedPrice} zł</S.DataRow>
          </S.PriceWrapper>
          <S.DisplaysWrapper>
            {offererPhone && (
              <S.TextSection>
                <S.MobileIcon />
                <S.GrayDataRow href={`tel:${offererPhone}`}>tel: {offererPhone}</S.GrayDataRow>
              </S.TextSection>
            )}
            <S.TextSection>
              <S.EnvelopeIcon />
              <S.GrayDataRow href={`mailto:${offererEmail}`}>e-mail: {offererEmail}</S.GrayDataRow>
            </S.TextSection>
          </S.DisplaysWrapper>
          <S.DisplaysWrapper>
            <Button
              type="primary"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: 96,
                height: 26,
                borderRadius: 8,
                fontSize: 14,
                marginBottom: 6,
              }}
            >
              Zaakceptuj
            </Button>
            <Button
              type="default"
              danger
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: 96,
                height: 26,
                borderRadius: 8,
                fontSize: 14,
              }}
            >
              Usuń
            </Button>
          </S.DisplaysWrapper>
        </S.DataWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default AdminOfferItem;

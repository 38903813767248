import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MapPin from '../../../assets/mapPin.svg';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled(Link)`
  text-decoration: none;
  width: 100%;
  height: 141px;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  padding-right: 31px;
  box-sizing: border-box;
  font-family: Poppins;
  background-color: #fff;
`;

export const Image = styled.img`
  width: 251px;
  min-height: 141px;
  object-fit: cover;
`;

export const DataWrapper = styled.section`
  padding: 10px 19px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Category = styled.div`
  font-family: Poppins;
  font-size: 10px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const LocalizationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  width: 1px;
  height: 12px;
  background-color: #bdbdbd;
  margin: auto 15px;
`;

export const LocationIcon = styled.img.attrs({ alt: 'location', src: MapPin })`
  min-width: 10px;
  height: 10px;
  opacity: 0.6;
  margin-right: 4px;
`;

export const City = styled.div`
  font-family: Poppins;
  font-size: 10px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const Title = styled.h6`
  margin: 0;
  margin-bottom: 14px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: #373f41;
`;

export const Price = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 13px;
  line-height: 123%;
  letter-spacing: 0.1px;
  color: rgba(75, 65, 196, 1);
`;

export const Date = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const StyledIcon = styled.img`
  width: 53px;
  height: 70px;
`;

export const StarWrapper = styled.button`
  width: 53px;
  height: 141px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border: none;
  background-color: transparent;
  :focus {
    outline: none;
  }
`;

// export const StarIcon = styled.img.attrs({ alt: 'Star', src: Star })`
//   min-width: 33px;
//   height: 33px;
//   margin-bottom: 7px;
//   opacity: 0.6;
// `;

const starCSS = `
  width: 33px;
  min-width: 33px;
  height: 33px;
  margin-bottom: 7px;
`;

export const StarIcon = styled(AiOutlineStar)`
  ${starCSS}
  color: ${themes.default.graphemeGrey};
`;

export const FullStarIcon = styled(AiFillStar)`
  ${starCSS}
  color: ${themes.default.star}
`;

export const StarLabel = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
  white-space: nowrap;
`;

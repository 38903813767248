// import SmallFeature from '../SmallFeature/SmallFeature';
import * as S from './Features.styles';

const Features = () => (
  <S.Wrapper>
    <S.InnerWrapper>
      <S.MainFeatureWrapper>
        <S.SubTitle>Platforma dla Ciebie i Twoich dzieci</S.SubTitle>
        <S.Title>Dlaczego warto korzystać z Malutcy.pl?</S.Title>
        <S.Description>
          Malutcy.pl jest najnowszym darmowym serwisem ogłoszeń lokalnych i ogólnopolskich. Dodanie
          każdego ogłoszenia w naszym serwisie jest bezpłatne, niezależnie od kategorii. Serwis daje
          użytkownikom możliwość znalezienia organizowanych w okolicy zajęć dodatkowych dla dzieci,
          a także sprzedaży oraz kupna przedmiotów dla dzieci i rodziców. W serwisie Malutcy.pl
          możesz samodzielnie zamieścić ogłoszenie dotyczące organizowanych przez Ciebie zajęć dla
          dzieci.
        </S.Description>
        {/* <S.StyledLink to="/">
          <S.LinkText>Czytaj więcej</S.LinkText>
          <S.Arrow />
        </S.StyledLink> */}
      </S.MainFeatureWrapper>
      {/* <S.SmallFeaturesWrapper> */}
      {/* <SmallFeature
          icon="alarm"
          title="Twój czas"
          description="Zapisz swoje ulubione wyszukiwania ogłoszeń, a my powiadomimy Cię jeżeli w serwisie pojawi się nowe ogłoszenie spełniające Twoje kryteria wyszukiwań. Wiemy jak cenny jest Twój czas, dopilnujemy sprawy za Ciebie."
        /> */}
      {/* <SmallFeature
          icon="check"
          title="Za darmo"
          description="Dodanie ogłoszenia w serwisie jest całkowicie darmowe, bez względu na kategorię, której dotyczy ogłoszenie."
        /> */}
      {/* <SmallFeature
          icon="fast"
          title="Polski kapitał"
          description="Korzystając z naszego serwisu wspierasz polską gospodarkę, Kapitał naszego serwisu jest w 100% polski."
        />
        <SmallFeature
          icon="dataBase"
          title="Obszar działania"
          description="Łączymy sprzedającego oraz klienta bez względu na odległość między nimi.."
        /> */}
      {/* </S.SmallFeaturesWrapper> */}
    </S.InnerWrapper>
  </S.Wrapper>
);

export default Features;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { userData } from '../../../redux/auth/selectors';
import { toast } from 'react-toastify';
import * as S from './ContactForm.styles';
import * as api from '../../../api/common-api';

const ContactForm = ({ offererId }: { offererId: string }) => {
  const history = useHistory();
  const params: any = useParams();
  const [maybeThread, setMaybeThread] = useState(undefined);
  const { id: offerId } = params;

  const { email: loggedInUsersEmail, id: senderId } = useSelector(userData);

  const fetchIsThreadCreated = async () => {
    const isCreated = await api.getIsThreadCreated({ offerId, offererId });
    setMaybeThread(isCreated);
  };

  useEffect(() => {
    if (offererId && offerId) {
      fetchIsThreadCreated();
    }
  }, []);

  const [email, setEmail] = useState(loggedInUsersEmail);
  const [phone, setPhone] = useState('');

  const buttonLabel = maybeThread ? 'Przenieś do chatu' : 'Wyślij wiadomość';
  const initialMessage = maybeThread ? 'Rozmowa z ogłoszeniodawcą została już rozpoczęta...' : '';

  const [message, setMessage] = useState(initialMessage);

  const submit = async (event: any) => {
    event.preventDefault();
    if (maybeThread) {
      const { id: threadId } = maybeThread;
      history.push(`/user-dashboard/messages/${threadId}`);
    } else {
      await api
        .sendMessage({
          email,
          phone,
          body: message,
          offerId,
          recipientId: offererId,
          senderId,
        })
        .then(
          () => {
            toast.success('Wiadomość wysłana');
          },
          () => {
            toast.warn('Wystąpił błąd');
          }
        );
      setMessage('');
      fetchIsThreadCreated();
    }
  };

  const isSendMessageDisabled = !maybeThread && (!email || !message);
  const limit = 500;
  const availableChars = limit - message.length;

  return (
    <S.MainWrapper>
      <S.Wrapper id={'contact'} onSubmit={submit}>
        <S.TopText>Skontaktuj się z&nbsp;ogloszeniodawcą</S.TopText>
        <S.InputsPanel>
          <S.Input
            required={!maybeThread}
            disabled={maybeThread}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            placeholder={'Email'}
          />
          <S.Input
            value={phone}
            disabled={maybeThread}
            onChange={(e: any) => setPhone(e.target.value)}
            placeholder={'Telefon (opcjonalne)'}
          />
        </S.InputsPanel>
        <S.Column>
          <S.Textarea
            disabled={maybeThread}
            required={!maybeThread}
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            placeholder={'Treść wiadomości'}
            maxLength={limit}
          />
          <S.Counter>Pozostało znaków: {availableChars}</S.Counter>
          <S.Button type={'submit'} disabled={isSendMessageDisabled}>
            {buttonLabel}
          </S.Button>
        </S.Column>
      </S.Wrapper>
    </S.MainWrapper>
  );
};

export default ContactForm;

import { ICategory } from '../../../redux/utils/types';
import * as S from './Subcategory.style';

export default function Subcategory({
  category: { label },
  onClick,
  isSelected,
}: {
  category: ICategory;
  onClick: () => void;
  isSelected: boolean;
}) {
  return (
    <S.Wrapper type={'button'} onClick={onClick}>
      <S.Arrow />
      <S.Label isSelected={isSelected}>{label}</S.Label>
    </S.Wrapper>
  );
}

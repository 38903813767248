import { Link } from 'react-router-dom';
import * as S from './Header.styles';

const Header = ({
  text,
  isSelected = false,
  link,
}: {
  text: string;
  isSelected?: boolean;
  link: string;
}) => (
  <Link style={S.LinkStyles} to={link}>
    <S.Wrapper isSelected={isSelected}>{text}</S.Wrapper>
  </Link>
);

export default Header;

import Header from '../Header/Header';
import * as S from './ContactPageWrapper.styles';

const ContactPageWrapper = ({
  title,
  children,
  image,
}: {
  title: string;
  image?: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <S.ExternalWrapper>
    <Header title={title} image={image} />
    <S.Wrapper>
      {/* <LinksPanel /> */}
      <S.MainWrapper>{children}</S.MainWrapper>
    </S.Wrapper>
  </S.ExternalWrapper>
);

export default ContactPageWrapper;

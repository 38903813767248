import styled from 'styled-components';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const LastPageWrapper = styled.button`
  display: flex;
  border: none;
  align-items: center;
  background: transparent;
  :focus {
    outline: none;
  }
`;

export const NextPageWrapper = styled.button`
  display: flex;
  border: none;
  align-items: center;
  background: transparent;
  :focus {
    outline: none;
  }
`;

export const PageText = styled.div<{ active: boolean }>`
  font-family: Poppins;
  font-size: 13px;
  line-height: 123%;
  letter-spacing: 0.1px;
  color: #bdbdbd;
  margin: 0 4px;
  ${(props) =>
    props.active &&
    `
    color: #373F41;
  `}
`;

export const NextPageIcon = styled(IoIosArrowForward)<{ props: { active: boolean } }>`
  width: 14px;
  height: 14px;
  color: #bdbdbd;
  ${({ props: { active } }) =>
    active &&
    `
    color: #373F41;
  `}
`;
export const LastPageIcon = styled(IoIosArrowBack)<{ props: { active: boolean } }>`
  width: 14px;
  height: 14px;
  color: #bdbdbd;
  ${({ props: { active } }) =>
    active &&
    `
    color: #373F41;
  `}
`;

export const PagesWrapper = styled.div`
  display: flex;
  margin: 0 4px;
  justify-content: center;
  /* flex-wrap: wrap; */
`;

export const Page = styled.div<{ active?: boolean }>`
  width: 27px;
  height: 27px;
  margin: 4px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.active ? 'rgba(75,65,196,1)' : '#fff')};
  ${(props) => !props.active && 'box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);'};
`;

export const PageNumber = styled.div<{ active: boolean }>`
  font-family: Poppins;
  font-size: 13px;
  line-height: 123%;
  letter-spacing: 0.1px;
  color: ${(props) => (props.active ? '#fff' : 'rgba(75,65,196,1)')};
`;

import * as A from './actionTypes';
import * as api from '../../api/common-api';
import {
  ArchiveThread,
  InvoiceAddressPayload,
  LOADING_FIELD,
  LogLastSeen,
  SubscriptionPayload,
  User,
  UserUtilsPayload,
  MESSAGE_CATEGORY,
  IContactMessage,
  IUsersPayload,
  IUpdateUsersStatuses,
} from './types';
import { toast } from 'react-toastify';
import { history } from '../../App';
import { AppState } from '../store';
import { IUpdateCategoryParameterOptionsProps, NewCategoryParameter } from '../../api/common-api';

export const setUserData = (data: User) => ({
  type: A.SET_USER_DATA,
  data,
});

export const setMessagesQuery = (query: string) => ({
  type: A.SET_MESSAGES_QUERY,
  query,
});

export const setMessagesCategory = (data: MESSAGE_CATEGORY) => ({
  type: A.SET_MESSAGES_CATEGORY,
  data,
});

export const setSignInErrorMessage = (message: string) => ({
  type: A.SET_SIGN_IN_ERROR_MESSAGE,
  data: message,
});

export const setIsLoadingData = () => ({
  type: A.SET_LOADING_DATA,
});

export const setIsLoadingDataSuccess = () => ({
  type: A.SET_LOADING_DATA_SUCCESS,
});

export function register(data: { email: string; password: string }) {
  return function (dispatch: any) {
    dispatch(setSignInErrorMessage(''));
    dispatch({ type: A.REGISTER_USER_START });
    return api.register(data).then(
      (response: any) => {
        const verificationCode = response;
        history.push(`/email-confirmation/${verificationCode}`);
        dispatch({ type: A.REGISTER_USER_SUCCESS, data: response });
      },
      (error: any) => {
        if (error.response.status === 401) {
          dispatch({ type: A.REGISTER_USER_FAILED });
          dispatch(setSignInErrorMessage(error.response.data));
        }
        console.log('An error occurred.', error);
      }
    );
  };
}

export function loginLocally(data: { email: string; password: string }) {
  return function (dispatch: any, getState: () => AppState) {
    // const {
    //   utils: { pageToNavigateAfterLogin },
    // } = getState();
    dispatch({ type: A.LOGIN_USER_START });
    return api.loginLocally(data).then(
      (response: any) => {
        history.push('/'); //pageToNavigateAfterLogin);
        dispatch(setSignInErrorMessage(''));
        const {
          user: { name, nickname },
        } = response;
        toast.success(`Witaj ${nickname ? nickname : name}!`);
        dispatch({ type: A.LOGIN_USER_SUCCESS, data: response });
      },
      (error: any) => {
        if (error.response.status === 401) {
          dispatch(setSignInErrorMessage(error.response.data));
        }
        dispatch({ type: A.LOGIN_USER_FAILED });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function loginWithGoogle(data: any) {
  return function (dispatch: any) {
    return api.loginWithGoogle(data).then(
      (response: any) => {
        history.push('/');
        const {
          user: { name, nickname },
        } = response;
        toast.success(`Witaj ponownie ${nickname ? nickname : name}!`);
        dispatch({ type: A.LOGIN_USER_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function loginWithFacebook(data: any) {
  return function (dispatch: any) {
    return api.loginWithGoogle(data).then(
      (response: any) => {
        const {
          user: { name, nickname },
        } = response;
        history.push('/');
        toast.success(`Witaj ponownie ${nickname ? nickname : name}!`);
        dispatch({ type: A.LOGIN_USER_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function logout() {
  return async function (dispatch: any, getState: any) {
    const {
      auth: {
        user: { name, nickname },
      },
    }: AppState = getState();
    return api.logout().then(
      (response: any) => {
        history.push('/');
        toast.success(`Użytkownik ${nickname ? nickname : name} został poprawnie wylogowany`);
        dispatch({ type: A.LOGOUT_USER_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function setIsLoading(isLoading: boolean, loadingField: LOADING_FIELD) {
  return {
    type: A.SET_IS_LOADING,
    data: { isLoading, loadingField },
  };
}

export function updateUserContactData(
  data: {
    nickname: string;
    localisation: string;
    phone: string;
    email?: string;
    lat: number;
    lon: number;
  },
  avatar?: any
) {
  const { lat: newLat, lon: newLon } = data;
  return async function (dispatch: any, getState: any) {
    dispatch(setIsLoading(true, LOADING_FIELD.CONTACT_DATA));
    const {
      auth: {
        user: { lat = 0, lon = 0 },
      },
    }: AppState = getState();

    let tempLat = newLat !== 0 ? newLat : lat ? lat : 0;
    let tempLon = newLon !== 0 ? newLon : lon ? lon : 0;

    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data, lat: tempLat, lon: tempLon }));
    if (avatar) {
      formData.append('files', avatar);
    }

    return api.updateUserContactData(formData).then(
      (response: any) => {
        toast.success('Dane podstawowe zostały zmienione');
        dispatch({ type: A.USER_DATA_UPDATE_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch(setIsLoading(false, LOADING_FIELD.CONTACT_DATA));
        toast.warn('Wystąpił błąd podczas zapisu');
        console.log('An error occurred during contact data update. ', error);
      }
    );
  };
}

export function editUsersSubscriptions(data: SubscriptionPayload) {
  return async function (dispatch: any) {
    dispatch(setIsLoading(true, LOADING_FIELD.NOTIFICATIONS));
    return api.editUsersSubscriptions(data).then(
      (response: any) => {
        toast.success('Preferencje subskrypcji zostały zapisane');
        dispatch({ type: A.USER_DATA_UPDATE_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch(setIsLoading(false, LOADING_FIELD.NOTIFICATIONS));
        toast.warn('Wystąpił błąd podczas zapisu');
        console.log('An error occurred during contact data update. ', error);
      }
    );
  };
}

export function editInvoiceAddress(data: InvoiceAddressPayload) {
  return async function (dispatch: any) {
    dispatch(setIsLoading(true, LOADING_FIELD.INVOICE_DATA));
    return api.editInvoiceAddress(data).then(
      (response: any) => {
        toast.success('Dane fakturowania zostały zapisane');
        dispatch({ type: A.USER_DATA_UPDATE_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch(setIsLoading(false, LOADING_FIELD.INVOICE_DATA));
        toast.warn('Wystąpił błąd podczas zapisu');
        console.log('An error occurred during contact data update. ', error);
      }
    );
  };
}

// TODO: remove password when mailchimp
export const setResetedEmail = (email: string, password: string) => ({
  type: A.RESET_PASSWORD_SUCCESS,
  email,
  password,
});

export function resetPassword(email: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.RESET_PASSWORD_START });
    return api.resetPassword({ email }).then(
      (response: any) => {
        history.push(`/reset-password-confirmation`);
        dispatch({ type: A.RESET_PASSWORD_SUCCESS });
      },
      (error: any) => {
        console.log('An error occurred during contact data update. ', error.body);
        dispatch({ type: A.RESET_PASSWORD_FAILED });
      }
    );
  };
}

export const setChangePasswordErrorMessage = (message: string) => ({
  type: A.SET_PASSWORD_ERROR_MESSAGE,
  message,
});

export const initPasswordChange = () => {
  return async function (dispatch: any) {
    // dispatch(setIsLoadingData());
    return api.initPasswordChange().then(
      () => {
        toast.success('Wysłaliśmy maila ze zmianą hasła');
        // dispatch(setIsLoadingDataSuccess());
      },
      () => {
        toast.warn('Wystąpił błąd');
        // dispatch(setIsLoadingDataSuccess());
      }
    );
  };
};

export function changePassword(data: {
  password: string;
  newPassword: string;
  newPassword2: string;
  verificationCode: string;
}) {
  return async function (dispatch: any) {
    dispatch(setIsLoadingData());
    return api.changePassword(data).then(
      () => {
        toast.success('Hasło zostało zapisane');
        dispatch(setIsLoadingDataSuccess());
        history.push('/user-dashboard/settings');
      },
      (error: any) => {
        toast.warn('Wystapił błąd podczas zapisu hasła');
        dispatch(setIsLoadingDataSuccess());
        if (error.response.status === 401) {
          return dispatch(setChangePasswordErrorMessage(error.response.data));
        }
        console.log('An error occurred during contact data update. ', error.response);
        history.push('/user-dashboard/settings');
      }
    );
  };
}

export function changeResetedPassword(data: {
  verificationCode: string;
  password: string;
  repeatedPassword: string;
}) {
  return async function (dispatch: any) {
    dispatch(setIsLoadingData());
    dispatch(setChangePasswordErrorMessage(''));
    return api.changeResetedPassword(data).then(
      () => {
        toast.success('Hasło zostało zmienione!');
        history.push('/login');
        dispatch(setIsLoadingDataSuccess());
      },
      (error: any) => {
        toast.warn('Wystapił błąd podczas zmiany hasła');
        dispatch(setIsLoadingDataSuccess());
        if (error.response.status === 401) {
          return dispatch(setChangePasswordErrorMessage(error.response.data));
        }
      }
    );
  };
}

export const archiveThread = (payload: ArchiveThread) => {
  return function (dispatch: any) {
    dispatch({ type: A.ARCHIVE_THREAD });
    api.archiveThread(payload).then(
      (response: any) => dispatch({ type: A.ARCHIVE_THREAD_SUCCESS, data: payload }),
      (error: any) => {
        dispatch({ type: A.ARCHIVE_THREAD_FAIL });
        console.log('Error has occured', error);
      }
    );
  };
};

export const removeThreads = (payload: { threadIds: string[] }) => {
  return function (dispatch: any) {
    dispatch({ type: A.REMOVE_THREAD });
    api.removeThreads(payload).then(
      (response: any) => {
        toast.success(`Usunięto ${response} ${response > 1 ? 'wątki' : 'wątek'}`);
        dispatch({ type: A.REMOVE_THREAD_SUCCESS, data: { ...payload } });
      },
      (error: any) => {
        dispatch({ type: A.REMOVE_THREAD_FAIL });
        console.log('Error has occured', error);
      }
    );
  };
};

export const logLastSeen = (payload: LogLastSeen) => {
  return function () {
    api.logLastSeen(payload).then(
      (response: any) => console.log(response),
      (error: any) => console.log('Error has occurred', error)
    );
  };
};

export const editUserUtils = (payload: UserUtilsPayload) => {
  return function (dispatch: any) {
    dispatch({ type: A.USER_UTILS });
    api.editUserUtils(payload).then(
      (response: any) => dispatch({ type: A.USER_UTILS_SUCCESS, data: response }),
      (error: any) => {
        dispatch({ type: A.USER_UTILS_FAIL });
        console.log('Error has occured', error);
      }
    );
  };
};

export const addUsersFile = (payload: FormData) => {
  return function (dispatch: any) {
    dispatch(setIsLoading(true, LOADING_FIELD.CV_FILE));
    api.addUsersFile(payload).then(
      (response: any) => {
        toast.success('Plik został zapisany poprawnie');
        dispatch({ type: A.ADD_USER_FILE_SUCCESS, data: response });
      },
      (error: any) => {
        toast.warn('Nastąpił bład podczas zapisu pliku.');
        dispatch(setIsLoading(false, LOADING_FIELD.CV_FILE));
        console.log('Error has occured', error);
      }
    );
  };
};

export const getDashboardCount = () => {
  return function (dispatch: any) {
    api.getDashboardCount().then(
      (response: any) => dispatch({ type: A.GET_DASHBOARD_COUNT_SUCCESS, data: response }),
      (error: any) => {
        console.log('Error has occured', error);
      }
    );
  };
};

export const getUsersFiles = () => {
  return function (dispatch: any) {
    api.getUsersFiles().then(
      (response: any) => dispatch({ type: A.GET_USERS_FILES_SUCCESS, data: response }),
      (error: any) => {
        console.log('Error has occured', error);
      }
    );
  };
};

export const deleteUsersFile = (fileId: string) => {
  return function (dispatch: any) {
    api.deleteUsersFile(fileId).then(
      (response: any) => dispatch({ type: A.DELETE_USERS_FILE_SUCCESS, data: response }),
      (error: any) => {
        console.log('Error has occured', error);
      }
    );
  };
};

// export const sendContactMessage = (payload: IContactMessage) => {
//   return function (dispatch: any) {
//     dispatch(setIsLoading(true, LOADING_FIELD.CONTACT_FORM));
//     api.sendContactMessage(payload).then(
//       (response: any) => {
//         dispatch(setIsLoading(false, LOADING_FIELD.CONTACT_FORM));
//         toast.success('Wiadomość została wysłana');
//       },
//       (error: any) => {
//         dispatch(setIsLoading(false, LOADING_FIELD.CONTACT_FORM));
//         toast.warn('Nie udało się przesłać wiadomości');
//         console.log('Error has occured', error);
//       }
//     );
//   };
// };

export const selectAccountType = (isCompany: boolean) => {
  return function (dispatch: any) {
    dispatch(setIsLoading(true, LOADING_FIELD.ACCOUNT_TYPE));
    api.selectAccountType(isCompany).then(
      (response: any) => {
        dispatch({ type: A.SELECT_ACCOUNT_TYPE, data: response });
        toast.success('Wybrany typ konta został zapisany');
      },
      (error: any) => {
        dispatch(setIsLoading(false, LOADING_FIELD.ACCOUNT_TYPE));
        toast.warn('Nastąpił błąd podczas zapisu typu konta');
        console.log('Nastąpił błąd podczas zapisu typu konta', error);
      }
    );
  };
};

export const getTransactions = () => {
  return function (dispatch: any) {
    api.getTransactions().then(
      (response: any) => {
        dispatch({ type: A.GET_TRANSACTIONS_SUCCESS, data: response });
      },
      (error: any) => {
        console.log('Nastąpił błąd podczas zapisu typu konta', error);
      }
    );
  };
};

export const getUsers = (params?: IUsersPayload) => {
  return function (dispatch: any) {
    api.getUsers(params).then(
      (response: any) => {
        dispatch({ type: A.GET_USERS_SUCCESS, data: response });
      },
      (error: any) => {
        console.log('Nastąpił błąd podczas pobierania użytkowników', error);
      }
    );
  };
};

export const updateUsersStatuses = (params: IUpdateUsersStatuses) => {
  return function (dispatch: any) {
    api.updateUsersStatuses(params).then(
      (response: any) => {
        toast.success('Sukces!');
        dispatch({ type: A.UPDATE_USERS_SUCCESS, data: params });
      },
      (error: any) => {
        console.log('Nastąpił błąd podczas zapisu danych', error);
      }
    );
  };
};

export function addCategory(data: { name: string; parentId?: string }, file: any) {
  return async function (dispatch: any) {
    dispatch({ type: A.ADD_NEW_CATEGORY });
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    formData.append('files', file);
    return api.addCategory(formData).then(
      () => {
        dispatch({ type: A.ADD_NEW_CATEGORY_SUCCESS });
        toast.success('Kategoria została dodane poprawnie');
      },
      () => {
        dispatch({ type: A.ADD_NEW_CATEGORY_FAIL });
        toast.warn('Nastąpił błąd przy dodawaniu kategorii');
      }
    );
  };
}

export const addCategoryParameter = (parameter: NewCategoryParameter) => {
  return async function () {
    return api.addCategoryParameter(parameter).then(
      () => {
        toast.success('Parametr został dodany poprawnie');
      },
      () => {
        toast.warn('Nastąpił błąd przy dodawaniu parametru');
      }
    );
  };
};

export const updateCategoryParameterOptions = (parameter: IUpdateCategoryParameterOptionsProps) => {
  return api.updateCategoryParameterOptions(parameter).then(
    () => {
      toast.success('Parametr został zaktualizowany');
    },
    () => {
      toast.warn('Nastąpił błąd');
    }
  );
};

export const deleteCategoryParameter = (id: string) => {
  return api.deleteCategoryParameter(id).then(
    () => {
      toast.success('Parametr został usunięty poprawnie');
    },
    () => {
      toast.warn('Nastąpił błąd przy usuwaniu parametru');
    }
  );
};

export const updateCategoryParameter = (data: api.IUpdateCategoryParameterProps) => {
  return api.updateCategoryParameter(data).then(
    () => {
      toast.success('Parametr został zaktualizowany');
    },
    () => {
      toast.warn('Wystąpił błąd');
    }
  );
};

export const deleteCategory = (id: string) => {
  return api.deleteCategory(id).then(
    (res: any) => {
      res.status === 401
        ? toast.warn('Nastąpił błąd przy usuwaniu kategorii')
        : toast.success('Kategoria została usunięta');
      return res;
    },
    (res: any) => {
      toast.warn('Nastąpił błąd przy usuwaniu kategorii');
      return res;
    }
  );
};

interface IUpdateCategoryName {
  id: string;
  label: string;
}

export const updateCategoryName = (props: IUpdateCategoryName) => {
  return api.updateCategoryName(props).then(
    () => {
      toast.success('Nazwa zmieniona');
    },
    () => {
      toast.warn('Wystąpił błąd');
    }
  );
};

export const initDeleteAccount = () => {
  return async function (dispatch: any) {
    dispatch(setIsLoadingData());
    return api.initDeleteAccount().then(
      () => {
        toast.success('Wysłaliśmy email potwierdzający');
        dispatch(setIsLoadingDataSuccess());
      },
      () => {
        toast.warn('Wystąpił błąd');
        dispatch(setIsLoadingDataSuccess());
      }
    );
  };
};

export function deleteAccount(data: { verificationCode: string }) {
  return async function (dispatch: any) {
    dispatch(setIsLoadingData());
    return api.deleteAccount(data).then(
      () => {
        toast.success('Konto zostało usunięte');
        dispatch(setIsLoadingDataSuccess());
        dispatch({ type: A.LOGOUT_USER_SUCCESS });
        history.push('/');
      },
      (error: any) => {
        toast.warn('Wystapił błąd');
        dispatch(setIsLoadingDataSuccess());
        if (error.response.status === 401) {
          return dispatch(setChangePasswordErrorMessage(error.response.data));
        }
        console.log('An error occurred during contact data update. ', error.response);
        history.push('/');
      }
    );
  };
}

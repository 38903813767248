import styled, { CSSProperties } from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = (isSelected: boolean, isHover: boolean): CSSProperties => ({
  height: '52px',
  backgroundColor: isSelected ? 'rgba(75,65,196,1)' : 'transparent',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  padding: '11px 28px',
  marginBottom: '10px',
  boxSizing: 'border-box',
  cursor: 'pointer',
  textDecoration: 'none',
  width: '100%',
  transition: '250ms',
});

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  border-radius: 100%;
`;

export const Icon = styled.img`
  width: 12px;
  height: 12px;
  color: ${themes.default.white};
`;

export const Text = styled.div`
  font-family: Poppins;
  font-size: 16px;
  line-height: 387%;
  margin: 0 auto 0 0;
  color: ${themes.default.white};
`;

export const CounterWrapper = styled.div<{ isHover: boolean }>`
  width: 30px;
  height: 20px;
  border-radius: 10px;
  margin: 0 0 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6fcf97;
  transition: 250ms;
  @media (min-width: 1024px) {
    background-color: ${({ isHover }) => (isHover ? '#6fcf97' : '#6fcf97')};
  }
`;

export const Counter = styled.div`
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.32px;
  color: white;
`;

import { memo } from 'react';
import FAQSegment from '../FAQSegment/FAQSegment';
// import FeedbackBar from '../FeedbackBar/FeedbackBar';
import { categories, articles } from '../faqData';
import { useHistory, useParams } from 'react-router-dom';
import FAQPageWrapper from '../FAQPageWrapper/FAQPageWrapper';
import FAQContact from '../FAQContact/FAQContact';
import * as S from './FAQItemPage.styles';

export default memo(function FAQItemPage() {
  const { faqItemID }: { faqItemID?: string } = useParams();
  const history = useHistory();

  const article = articles.find(({ id }) => id === faqItemID);

  if (!article) {
    history.push('/');
    return <></>;
  }
  const { body, title, categoryId } = article;
  const category = categories.find(({ id }) => id === categoryId);

  return (
    <FAQPageWrapper>
      <S.InnerWrapper>
        {category && (
          <S.Aside>
            <FAQSegment {...category} />
          </S.Aside>
        )}
        <S.BodyWrapper>
          <S.ArticleWrapper>
            <S.Title>{title}</S.Title>
            <S.ArticleText>{body}</S.ArticleText>
          </S.ArticleWrapper>
          {/* <FeedbackBar /> */}
          <FAQContact />
        </S.BodyWrapper>
      </S.InnerWrapper>
    </FAQPageWrapper>
  );
});

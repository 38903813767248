import styled from 'styled-components';
import { IoMdPerson, IoMdMenu } from 'react-icons/io';
import { MdClose, MdMenu } from 'react-icons/md';
import Logo from '../../assets/malutcyplLogo.svg';
import { themes } from '../../assets/CSS/colorThemes';
import U from '../../assets/CSS/commonStyles';
import { Link } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';

export const ExternalWrapper = styled.header<{ isOpen: boolean }>`
  width: 100%;
  box-shadow: ${({ isOpen }) => (isOpen ? 'none' : '1px 1px 12px rgba(0, 0, 0, 0.1)')};
  background-color: ${themes.default.white};
  position: fixed;
  z-index: 21;
  height: 74px;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  ${U.standardFlex}
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 16px 18px;
  max-width: 1480px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 98%;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
`;

export const User = styled(MdClose)`
  width: 30px;
  color: ${themes.default.main};
  @media (min-width: 880px) {
    display: none;
  }
`;

export const MenuIcon = styled(IoMdMenu)`
  width: 30px;
  color: ${themes.default.main};
  @media (min-width: 880px) {
    display: none;
  }
`;

export const TopLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
`;

export const LogoWrapper = styled(Link)`
  display: flex;
`;

export const BBLogo = styled.img.attrs({
  alt: 'Malutcy',
  src: Logo,
  lang: 'en',
})`
  cursor: pointer;
  min-width: 82px;
`;

export const LogoLabel = styled.label`
  font-family: Poppins;
  ${U.standardFont14}
  margin-left: 24px;
  color: ${themes.default.main};

  @media (max-width: 880px) {
    visibility: hidden;
    display: none;
  }
`;

export const OptionsWrapper = styled.div`
  ${U.standardFlex}
  flex-wrap: nowrap;
`;

export const Person = styled(IoMdPerson)`
  color: ${themes.default.darkerGrey};
  width: 19px;
`;

export const PersonLabel = styled.label`
  margin: 0 30px 0 8px;
  font-family: Mulish;
  ${U.standardFont14}
  color: ${themes.default.darkerGrey};

  @media(max-width: 800px) {
    visibility: hidden;
    width: 24px;
    margin: 0;
  }
`;

export const AddOfferButton = styled.button`
  width: 249px;
  height: 42px;
  font-family: Poppins;
  ${U.standardFont14}
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  border: none;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 4px ${themes.default.main};
  }
  :focus {
    outline: none;
  }
`;

const UserMenuIconStyles = `
  color: ${themes.default.main};
  min-width: 30px;
  height: 30px;
`;

export const CloseUserMenuIcon = styled(MdClose)`
  ${UserMenuIconStyles}
`;

export const OpenUserMenuIcon = styled(MdMenu)`
  ${UserMenuIconStyles}
`;

export const PlainButton = styled.button`
  ${U.plainButton}
  @media(min-width: 880px) {
    display: none;
  }
`;

export const DropdownsWrapper = styled.div`
  display: flex;
  padding-left: 50px;
  gap: 46px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DropdownName = styled.span<{ isHovered: boolean }>`
  font-family: Poppins;
  font-weight: 600;
  color: ${({ isHovered }) => (isHovered ? 'rgba(75, 65, 196, 1)' : 'rgba(17, 36, 77, 1)')};
`;

export const DropdownArrow = styled(FiChevronDown)<{ isHovered: boolean }>`
  width: 38px;
  height: 28px;
  color: ${({ isHovered }) => (isHovered ? 'rgba(75, 65, 196, 1)' : 'rgba(17, 36, 77, 1)')};
  transform: ${({ isHovered }) => (isHovered ? 'rotate(0)' : 'rotate(-90deg)')};
  transition: 400ms;
`;

export const DrawerWrapper = styled.div`
  position: 'relative';
  height: 200px;
  padding: 48px;
  overflow: 'hidden';
`;

import { useState, useRef, useEffect } from 'react';
import { Message, Thread } from '../../../redux/auth/types';
import { userData } from '../../../redux/auth/selectors';
import ChatMessage from '../ChatMessage/ChatMessage';
import moment from 'moment';
import Logo from '../../../assets/malutcyLogo.svg';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getThreadById } from '../../../api/threads';
import { useSelector } from 'react-redux';
import { archiveThread } from '../../../api/common-api';
import { FormatPrice } from '../../../utils/formatPrice';
import { toast } from 'react-toastify';
import * as S from './ChatBox.styles';
import * as api from '../../../api/common-api';

const EMPTY_RECIPIENT = { nickname: '', name: '', avatar: '' };

interface Props {
  threadId: string;
}

const ChatBox = ({ threadId }: Props) => {
  const history = useHistory();
  const inputRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [threadDetails, setThreadDetails] = useState<Thread | undefined>(undefined);

  const [message, setMessage] = useState('');
  const isMobile = useMediaQuery('(min-width: 450px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { id, email } = useSelector(userData);

  const registerVisit = () => console.log('Visit registered'); // dispatch(logLastSeen({ threadId, lastSeen: new Date() }));

  const fetchThread = async () => {
    setIsLoading(true);
    const thread = await getThreadById(threadId);
    setThreadDetails(thread);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchThread();
  }, [threadId]);

  useEffect(() => {
    if (inputRef && inputRef.current && !isDesktop) {
      window.scroll(0, inputRef.current.offsetTop - 600);
    }
    registerVisit();
    return registerVisit();
  }, []);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const isDisabled = !message || message.length < 1 || message.length > 500 || isLoading;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    scrollToBottom();
  }, [threadDetails?.messages]);

  if (!threadDetails) {
    return null;
  }

  const {
    offer: { id: offerId, images, title, price },
    sender,
    recipient,
    senderId,
    recipientId,
    messages,
    archive = [],
  } = threadDetails;

  const isArchived = archive.length > 0;

  // const formattedPrice = formatNumber(price);
  const formattedPrice = FormatPrice(price);

  const { nickname: recipientNickname, name: recipientName, avatar: recipientAvatar } =
    recipient || EMPTY_RECIPIENT;
  const { nickname: senderNickname, name: senderName, avatar: senderAvatar } =
    sender || EMPTY_RECIPIENT;

  const senderDisplayName = senderNickname ? senderNickname : senderName;
  const recipientDisplayName = recipientNickname ? recipientNickname : recipientName;

  const handleSendMessage = async () => {
    setMessage('');
    setIsLoading(true);
    await api
      .sendMessage({
        body: message,
        threadId,
        email,
        offerId,
        recipientId,
        senderId,
      })
      .then(
        () => {
          toast.success('Wiadomość wysłana');
        },
        () => {
          toast.warn('Wystąpił błąd');
        }
      );

    await fetchThread();
    setIsLoading(false);
    // setThreadDetails((prev: any) => ({
    //   ...prev,
    //   messages: [...prev.messages, newMessage],
    // }));
  };

  const sendOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleArchive = async () => {
    await archiveThread({ threadId, isArchive: !isArchived });
    history.push('/user-dashboard/messages/');
  };

  const offerMainImage = images && images.find(({ order }) => order === 0);

  const textToDisplay = 'Napisz wiadomość';

  const limit = 500;
  const availableChars = limit - message.length;

  return (
    <S.Wrapper>
      <S.TopWrapper>
        <S.PlainButton onClick={() => history.push('/user-dashboard/messages')}>
          <S.ArrowLeft />
          <S.ButtonText>Wróć</S.ButtonText>
        </S.PlainButton>
        <S.ItemWrapper to={`/offer/${offerId}`}>
          <S.ItemImage src={offerMainImage ? offerMainImage.href : Logo} alt={title + ' image'} />
          <S.DataWrapper>
            <S.ItemTitle>{title}</S.ItemTitle>
            <S.CalledPrice>{formattedPrice} zł</S.CalledPrice>
          </S.DataWrapper>
        </S.ItemWrapper>
        <S.PlainButton onClick={handleArchive}>
          <S.ArchiveIcon />
          <S.ButtonText>{isArchived ? 'Przywróć z kosza' : 'Przenieś do kosza'}</S.ButtonText>
        </S.PlainButton>
      </S.TopWrapper>
      <S.BodyWrapper>
        <div
          style={{
            marginTop: '80px',
          }}
        >
          {messages.map(({ body, created_at, authorId, id: messageId }: Message) => {
            const isOwnMessage = id === authorId;
            const isSender = authorId === senderId;
            return (
              <ChatMessage
                id={authorId}
                key={messageId}
                avatar={isSender ? senderAvatar : recipientAvatar}
                date={moment(created_at).format('HH:mm DD.MM.YYYY')}
                isHost={isOwnMessage}
                message={body}
                name={isSender ? senderDisplayName : recipientDisplayName}
              />
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </S.BodyWrapper>
      <S.BottomWrapper ref={inputRef}>
        {/* <S.ButtonsWrapper>
          <S.YesFinishButton onClick={() => handleSendInstantMessage('Tak, zgadzam się.')}>
            <S.FinishButtonText>Tak, zgadzam się</S.FinishButtonText>
          </S.YesFinishButton>
          <S.NoFinishButton onClick={() => handleSendInstantMessage('Niestety muszę odmówić.')}>
            <S.FinishButtonText>Niestety muszę odmówić</S.FinishButtonText>
          </S.NoFinishButton>
        </S.ButtonsWrapper> */}
        <S.InputWrapper>
          <S.Input
            rows={3}
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            placeholder={isMobile ? textToDisplay : 'Napisz...'}
            onKeyPress={sendOnEnter}
            maxLength={limit}
          />
          <S.PlainButton disabled={isDisabled} onClick={handleSendMessage}>
            <S.SendText isActive={!isDisabled}>Wyślij</S.SendText>
          </S.PlainButton>
        </S.InputWrapper>
        <S.Counter>Pozostało znaków: {availableChars}</S.Counter>
      </S.BottomWrapper>
    </S.Wrapper>
  );
};

export default ChatBox;

import { useEffect, useState } from 'react';
import Option from '../Option/Option';
import { MESSAGE_CATEGORY, Thread } from '../../../redux/auth/types';
import { useParams } from 'react-router';
import Disclaimer from '../Disclaimer/Disclaimer';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { getThreads } from '../../../api/threads';
import MessageOverview from '../MessageOverview/MessageOverview';
import Spinner from '../../Spinner/Spinner';
import ChatBox from '../ChatBox/ChatBox';
import { useDispatch } from 'react-redux';
import { removeThreads } from '../../../redux/auth/actions';
import * as S from './MessagesPage.styles';

const TITLE = {
  [MESSAGE_CATEGORY.TRASH]: 'Archiwalne',
  [MESSAGE_CATEGORY.ACTIVE]: 'Aktywne',
};

const MessagesPageWrapper = () => {
  const dispatch = useDispatch();
  const [threadsToDisplay, setThreadsToDisplay] = useState<Thread[]>([]);
  const [activeThreads, setActiveThreads] = useState<Thread[]>([]);
  const [trashThreads, setTrashThreads] = useState<Thread[]>([]);
  const [selectedToRemove, setSelectedToRemove] = useState<string[]>([]);
  const [messageCategory, setMessageCategory] = useState<MESSAGE_CATEGORY>(MESSAGE_CATEGORY.ACTIVE);
  const [query, setQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { threadId }: { threadId: string } = useParams();

  const isMobile = useMediaQuery('(min-width: 600px');
  const isTablet = useMediaQuery('(min-width: 370px');

  const fetchThreads = async () => {
    setIsLoading(true);
    const {
      threads: { active, trash },
    } = await getThreads();
    setActiveThreads(active);
    setTrashThreads(trash);
    setThreadsToDisplay(active);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchThreads();
  }, [threadId]);

  const isTrash = messageCategory === MESSAGE_CATEGORY.TRASH;
  // const areSelectedAll = trashThreads.length === selectedToRemove.length;

  const handleSelectCategory = (category: MESSAGE_CATEGORY) => setMessageCategory(category);
  const handleSelect = (id: string) => {
    if (selectedToRemove.includes(id)) {
      setSelectedToRemove(selectedToRemove.filter((currentId) => currentId !== id));
    } else {
      setSelectedToRemove([...selectedToRemove, id]);
    }
  };

  // const handleSelectAll = () => {
  //   if (areSelectedAll) {
  //     return setSelectedToRemove([]);
  //   } else {
  //     setSelectedToRemove(trashThreads.map(({ id }) => id));
  //   }
  // };

  // const handleRemove = () => {
  //   dispatch(removeThreads({ threadIds: selectedToRemove }));
  //   setTrashThreads(trashThreads.filter(({ id }) => !selectedToRemove.includes(id)));
  // };
  const handleRemoveOne = (id: string) => {
    dispatch(removeThreads({ threadIds: [id] }));
    setTrashThreads(trashThreads.filter((x) => x.id !== id));
  };

  useEffect(() => {
    setThreadsToDisplay(isTrash ? trashThreads : activeThreads);
  }, [query, trashThreads, activeThreads, isTrash]);

  const ShowThreadsList = () => {
    return (
      <>
        <S.RemoveRow isActive={isTrash}>
          <S.InfoText>
            Wątki umieszczone w koszu zostaną automatycznie usunięte po 30 dniach od dodania.
          </S.InfoText>
          {/* <S.SelectAll onClick={handleSelectAll}>
            {areSelectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
          </S.SelectAll>
          <S.RemoveButton onClick={handleRemove} disabled={selectedToRemove.length === 0}>
            Usuń zaznaczone
          </S.RemoveButton> */}
        </S.RemoveRow>
        <S.ResultsWrapper>
          {threadsToDisplay.map((thread: Thread, index) => {
            const { id } = thread;
            const isSelected = selectedToRemove.includes(id);
            return (
              <MessageOverview
                isTrash={isTrash}
                handleSelect={() => handleSelect(id)}
                isSelected={isSelected}
                isFirst={index === 0}
                thread={thread}
                key={`overview_${id}`}
                handleRemoveOne={() => handleRemoveOne(id)}
              />
            );
          })}
        </S.ResultsWrapper>
      </>
    );
  };

  const isSelectedThread = !!threadId;
  const selectedThread = threadsToDisplay.find(({ id }) => id === threadId);

  return (
    <S.Wrapper>
      <Disclaimer />
      <S.Header>Wiadomości</S.Header>
      {!isMobile && <S.SubHeader>{TITLE[messageCategory]}</S.SubHeader>}
      <S.NavRow>
        <S.OptionsWrapper>
          <Option
            onClick={() => handleSelectCategory(MESSAGE_CATEGORY.ACTIVE)}
            isActive={messageCategory === MESSAGE_CATEGORY.ACTIVE}
            text="Aktywne"
            count={activeThreads.length}
            icon={<S.Incoming />}
          />
          <Option
            onClick={() => handleSelectCategory(MESSAGE_CATEGORY.TRASH)}
            isActive={messageCategory === MESSAGE_CATEGORY.TRASH}
            text="Kosz"
            icon={<S.Archived />}
            count={trashThreads.length}
          />
        </S.OptionsWrapper>
        <S.SearchWrapper>
          {isTablet && <S.Label>Wyszukaj</S.Label>}
          <S.InputWrapper>
            <S.SearchIcon />
            <S.InputField value={query} onChange={(e: any) => setQuery(e.target.value)} />
          </S.InputWrapper>
        </S.SearchWrapper>
        {/* <SearchField query={query} setQuery={setQuery} /> */}
      </S.NavRow>
      {isLoading ? (
        <Spinner />
      ) : isSelectedThread && selectedThread ? (
        <ChatBox threadId={selectedThread.id} />
      ) : (
        <ShowThreadsList />
      )}
    </S.Wrapper>
  );
};

export default MessagesPageWrapper;

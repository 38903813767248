import styled from 'styled-components';

export const TableContainer = styled.div``;

export const DynamicParameterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 400px;
`;

export const ParameterButton = styled.button`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: rgba(75, 65, 196, 1);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
  transition: 400ms;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const ParameterButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
`;

export const DeleteButton = styled(ParameterButton)`
  color: rgba(75, 65, 196, 1);
  border: 1px solid rgba(75, 65, 196, 1);
  background-color: white;
`;

export const GapContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ParameterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OptionsTitleContainer = styled.div``;

export const OptionsTitle = styled.h3`
  margin: 0;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
`;

import styled from 'styled-components';
import { BsArrowRightShort } from 'react-icons/bs';
import { themes } from '../../../assets/CSS/colorThemes';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  background-color: #fef9eb;
  padding: 46px 0;
  @media (min-width: 768px) {
    padding: 84px 0;
  }
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1101px;
  width: 100%;
  flex-direction: row-reverse;
  padding: 0 18px;
  box-sizing: border-box;
`;

export const SmallFeaturesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 507px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 536px) {
    justify-content: center;
  }
`;

export const MainFeatureWrapper = styled.div`
  /* max-width: 452px; */
  max-width: 700px;
  margin: 0 auto auto;
  @media (max-width: 958px) {
    margin: 0 auto 46px auto;
  }
`;

export const SubTitle = styled.h4`
  margin-bottom: 16px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 125%;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(75, 65, 196, 1);
`;

export const Title = styled.h3`
  margin: 13px 0 30px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 115%;
  color: rgba(17, 36, 77, 1);
`;

export const Description = styled.div`
  font-family: Poppins;
  font-size: 16px;
  line-height: 150%;
  color: rgba(17, 36, 77, 1);
  margin-bottom: 30px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const LinkText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${themes.default.main};
`;

export const Arrow = styled(BsArrowRightShort)`
  width: 24px;
  height: 24px;
  color: ${themes.default.main};
`;

import { useEffect, useState } from 'react';
import type { SelectProps } from 'antd';
import { Select, Button } from 'antd';
import { toast } from 'react-toastify';
import * as api from '../../../../api/common-api';
import * as S from './TagSelect.style';

const TagSelect = ({ offerId }: { offerId: string }) => {
  const options: SelectProps['options'] = [];
  const defaultValues: string[] = [];
  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState<string[]>(defaultValues);

  const setSelectedOfferTags = async (offerTags: any[]) => {
    offerTags.map((o: any) => defaultValues.push(o.tagId));
    setSelectValue(defaultValues);
  };

  const fetchTagOptions = async () => {
    setIsLoading(true);
    const fetchedTags = await api.getAllTags();
    fetchedTags.map((t: any) => options.push({ label: t.label, value: t.id }));
    setTagOptions(options);
    setIsLoading(false);
  };

  const fetchOfferTags = async () => {
    if (!offerId) {
      return;
    }
    setIsLoading(true);
    const fetchedOfferTags = await api.getOfferTags({ offerId });
    await setSelectedOfferTags(fetchedOfferTags);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOfferTags();
    fetchTagOptions();
  }, [offerId]);

  const handleOnSelect = (id: string) => {
    setSelectValue([...selectValue, id]);
  };

  const handleOnDeselect = (id: string) => {
    const filteredSelectValues = selectValue.filter((value: string) => value != id);
    setSelectValue(filteredSelectValues);
  };

  const handleSaveChanges = async () => {
    await api.handleOfferTags({ offerId, selectValue });
    await fetchOfferTags();
    toast.success('Zapisano zmiany.');
  };

  return (
    <S.Wrapper>
      <S.RowWrapper>
        <Select
          mode="multiple"
          placeholder="Dodaj tagi"
          style={{ width: '100%' }}
          optionFilterProp="label"
          value={selectValue}
          options={tagOptions}
          loading={isLoading}
          filterOption={true}
          onSelect={(id: string) => handleOnSelect(id)}
          onDeselect={(id: string) => handleOnDeselect(id)}
        />
      </S.RowWrapper>
      <S.RowWrapper style={{ alignSelf: 'center' }}>
        <Button onClick={handleSaveChanges}>Zapisz zmiany</Button>
      </S.RowWrapper>
    </S.Wrapper>
  );
};

export default TagSelect;

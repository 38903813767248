import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* min-height: calc(100vh - 75px); */
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  background-color: rgba(233, 245, 245, 1);
  flex: 1;
  @media (min-height: 410px) {
    padding-top: 0;
  }
`;

export const RightImagesContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 0;
  right: 0;
`;

export const RightImage = styled.img`
  position: relative;
  top: 44px;
  height: 100%;
`;

export const BackgroundImage = styled.img`
  max-height: 394px;
  height: 100%;
  object-fit: contain;
  position: relative;
  opacity: 0.4;
  @media (min-width: 872px) {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  max-width: 554px;
  padding: 32px;
  box-sizing: border-box;
  z-index: 1;
  margin: 0 auto auto;
  @media (min-width: 768px) {
    margin: auto;
  }
`;

export const Header = styled.h2`
  margin: 0 0 30px 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 27px;
  line-height: 148%;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${themes.default.graphemeGrey};
`;

export const Information = styled.div`
  font-family: Poppins;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 30px;
`;

export const BottomMessage = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 107%;
  text-align: center;
  color: ${themes.default.graphemeGrey};
  margin-top: 30px;
`;

export const BlueLink = styled(Link)`
  color: ${themes.default.main};
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
`;

export const BodyWrapper = styled.div`
  max-width: 375px;
  margin: 0 auto;
`;

export const ButtonLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  border-radius: 4px;
  border: none;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.4px;
  margin-bottom: 20px;
  text-decoration: none;
  font-size: 16px;
  line-height: 120%;
  padding: 16px;
  box-sizing: border-box;
  @media (min-width: 400px) {
    height: 60px;
    font-size: 19px;
    line-height: 211%;
  }
  :visited {
    color: ${themes.default.white};
  }
  :focus {
    outline: none;
  }
`;

export const MailIcon = styled(FiMail)`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  @media (min-width: 400px) {
    width: 30px;
    height: 30px;
  }
`;

export const LeftImgContainer = styled.div`
  position: absolute;
  top: -40px;
  z-index: 0;
  left: 140px;
`;

export const AbsoluteImg = styled.img``;

import * as S from './CustomCheckbox.styles';

const CustomCheckbox = ({
  value,
  width,
  setValue,
  required = false,
  margin,
}: {
  value?: boolean;
  width?: number;
  required?: boolean;
  setValue: () => void;
  margin?: number;
}) => (
  <S.ButtonFree
    required={required}
    type={'checkbox'}
    onClick={() => setValue()}
    width={width}
    checked={value}
    isSelected={value ? value : false}
    margin={margin}
  />
);

export default CustomCheckbox;

import styled from 'styled-components';
import List from '../../../assets/list.svg';
import Grid from '../../../assets/grid.svg';

export const SortOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    justify-content: center;
    width: 100%;
  }
`;

export const Label = styled.label`
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
  color: #bdbdbd;
  margin-right: 8px;
  margin-bottom: 7px;
`;

export const SortByButton = styled.button<{ selected: boolean }>`
  box-sizing: border-box;
  font-family: Poppins;
  border: none;
  background-color: transparent;
  :focus {
    outline: none;
    background: transparent;
  }
  padding: 5px;
  padding-bottom: 13px;
  border-bottom: 3px slid transparent;
  color: #373f41;
  ${(props) =>
    props.selected &&
    `
    padding-bottom: 10px;
    border-bottom: 3px solid rgba(75, 65, 196, 1);
    color: rgba(75, 65, 196, 1);
  `}
`;

export const DisplayListButton = styled.button<{ isSelected: boolean }>`
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 14px;
  margin-left: 18px;
  :focus {
    outline: none;
  }
  border-bottom: 3px solid transparent;
  ${(props) =>
    props.isSelected &&
    `
    border-bottom: 3px solid rgba(75, 65, 196, 1);
    color: rgba(75, 65, 196, 1);
  `}
`;

export const DisplayGridButton = styled(DisplayListButton)`
  margin-right: 0;
  margin-left: 0;
`;

export const DisplayList = styled.img.attrs({ src: List, alt: 'Display List' })`
  width: 24px;
  height: 24px;

  cursor: pointer;
  align-self: center;
`;

export const DisplayGrid = styled.img.attrs({ src: Grid, alt: 'Display Grid' })`
  width: 20px;
  height: 20px;
  cursor: pointer;
  align-self: center;
`;

import { IBenefit, IFaq } from '../../components/AddOffer/PageOne/PageOne';

export interface ITempOffer {
  categoryIds: string[];
  title: string;
  description: string;
  price: number;
  giveaway: boolean;
  offererName: string;
  offererEmail: string;
  offererPhone: string;
  offererLocalisation: string;
  offerType: OFFER_TYPE;
  isBrandNew: boolean;
  commitmentForm: string;
  faqs: IFaq[];
  benefits: IBenefit[];
  ageFrom: number;
  ageTo: number;
  subscriptionOptions: any[];
  dynamicOptions?: any[];
  images: any[];
  city: string;
  street: string;
  zipCode: string;
  streetNumber: string;
  location: { name: string; lat: number; lon: number };
}

export enum OFFER_TYPE {
  BAZAAR = 'BAZAAR',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export enum COMMITMENT_FORM {
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const SpacerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
`;

export const Line = styled.hr`
  border: 1px solid ${themes.default.greyBackgroundColor};
  width: 43%;
`;

export const SpacerText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.3px;
  color: ${themes.default.magentaBlack};
`;

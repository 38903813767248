import styled from 'styled-components';
import U from '../../../assets/CSS/commonStyles';
import { themes } from '../../../assets/CSS/colorThemes';

export const ExternalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  background-color: rgba(233, 245, 245, 1);
  flex: 1;
  @media (min-height: 410px) {
    padding-top: 0;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 584px;
  width: 100%;
`;

export const inputLocalStyles = `
max-width: 275px;
width: 100%;
border-radius: 4px;
@media(max-width: 800px) {
  max-width: 100%;
}
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface MessageProps {
  isWarning?: boolean;
}

export const Message = styled.button<MessageProps>`
  ${U.plainButton}
  font-family: Poppins;
  ${U.standardFont14}
  margin-left: 16px;
  color: ${(props) => (props.isWarning ? themes.default.negative : themes.default.positive)};
`;

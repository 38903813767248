import { useHistory, useParams } from 'react-router-dom';
import UserOffersHeader from './Header/UserOffersHeader';
import { useEffect, useState } from 'react';
import { instance } from '../../api/common-api';
import OfferThumbnail from '../LandingPage/OfferThumbnail/OfferThumbnail';
import { OFFER_STATUS } from '../../redux/offer/types';
import Spinner from '../Spinner/Spinner';
import { Select, InputNumber, Button, Drawer, Collapse, Radio, Space } from 'antd';
import useMediaQuery from '../../hooks/useMediaQuery';
import { toast } from 'react-toastify';
import filterImage from '../../assets/User/Filter.svg';
import sadEmoji from '../../assets/User/SadEmoji.svg';
import { USER_STATUS } from '../../redux/auth/types';
import OffersWrapper from '../LandingPage/OffersWrapper/OffersWrapper';
import Pagination from '../OfferListComponent/Pagination/Pagination';
import logo from '../../assets/malutcyLogo.svg';
import ProductModal from '../Modals/ProductModal/ProductModal';
import * as S from './UsersOffers.styles';

interface IOption {
  value: string;
  label: string;
}

interface IUserData {
  name?: string;
  nickname?: string;
  avatar?: string;
  localisation?: string;
  isOnline?: boolean;
  status?: string;
}

const sortOptions: IOption[] = [
  {
    label: 'Od najnowszych',
    value: 'desc',
  },
  {
    label: 'Od najstarszych',
    value: 'asc',
  },
];

const offerTypeOptions: IOption[] = [
  {
    label: 'Wszystkie',
    value: '',
  },
  {
    label: 'Przedmioty',
    value: 'BAZAAR',
  },
  {
    label: 'Zajęcia',
    value: 'ANNOUNCEMENT',
  },
];

const UsersOffers = () => {
  const params: { id: string } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [userData, setUserData] = useState<IUserData>({});

  const [selectedOfferType, setSelectedOffertype] = useState(offerTypeOptions[0].value);
  const [selectedOrder, setSelectedOrder] = useState(sortOptions[0].value);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [useFilter, setUseFilters] = useState(false);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<any>();

  const [page, setPage] = useState<number>(1);
  const [usePage, setUsePage] = useState(false);
  const [count, setCount] = useState(0);

  const [idToSetWatched, setIdToSetWatched] = useState('');

  const handleIdToSetWatched = (id: string) => {
    if (!id) {
      return null;
    }
    setIdToSetWatched(id);
  };

  const handleOpenModal = (id: string) => {
    if (!id) {
      return null;
    }
    setModalProps(data?.find((x) => x.id === id));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalProps(undefined);
    setIsModalOpen(false);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
    setUsePage(true);
  };

  const history = useHistory();
  const { id } = params;

  useEffect(() => {
    if (!id) {
      history.push('/');
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await instance.post(`/user/offers`, {
        id,
        order: selectedOrder,
        offerType: selectedOfferType,
        minPrice,
        maxPrice,
        page: page - 1,
      });
      const {
        data: { offers, userData, count },
      } = res;
      setCount(count);
      setData(offers);
      setUserData(userData);
    } catch (error) {
      setUseFilters(false);
      toast.warn('Nie znaleziono użytkownika');
      history.push('/');
      console.log({ error });
    }
    setUseFilters(false);
    setUsePage(false);
    setIsLoading(false);
  };

  const resetFilterData = () => {
    setMinPrice(null);
    setMaxPrice(null);
    setSelectedOffertype(offerTypeOptions[0].value);
    setSelectedOrder(sortOptions[0].value);
    setUseFilters(true);
  };

  const resetFilters = () => {
    resetFilterData();
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (useFilter) {
      fetchData();
    }
  }, [useFilter]);

  useEffect(() => {
    if (usePage) {
      fetchData();
    }
  }, [usePage]);

  const scrollToResults = () => {
    const element = document.getElementById('results');
    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 80,
      });
    }
  };

  useEffect(() => {
    scrollToResults();
  }, [data]);

  const { status } = userData;
  const isAccountSuspended = status === USER_STATUS.DISABLED;

  const isWide = useMediaQuery('(min-width: 1134px)');
  const isDesktop = useMediaQuery('(min-width: 853px)');
  const isTablet = useMediaQuery('(min-width: 800px)');
  const isMobile = useMediaQuery('(min-width: 572px)');

  const mockItems = () => {
    const itemsInRow = isWide ? 4 : isDesktop ? 3 : isMobile ? 2 : 1;
    const rowsLength = data.length;
    if (rowsLength === 12) {
      return [];
    }
    const itemsInLastRow = rowsLength % itemsInRow;
    let mockItemsQuantity = 0;
    if (itemsInLastRow === 0) {
      return [];
    } else {
      mockItemsQuantity = itemsInRow - itemsInLastRow;
    }
    const items = [];
    for (let index = 0; index < mockItemsQuantity; index++) {
      items.push(<S.MockOffer key={index} aria-hidden={true} />);
    }
    return items;
  };

  const renderOffers = () => {
    if (isAccountSuspended) {
      return (
        <OffersWrapper
          offers={data}
          backgroundColor={'#fff'}
          header={'Sprawdź te oferty'}
          redirectURL={'/search?offerType=BAZAAR'}
        />
      );
    } else {
      return (
        <>
          {data.map(
            ({
              id,
              title,
              description,
              price,
              giveaway,
              href,
              city,
              label,
              commitmentForm,
              created_at,
              subscriptionOptions,
              isWatched,
              geo_lat,
              geo_lng,
              offererEmail,
            }) => (
              <OfferThumbnail
                id={id}
                title={title}
                description={description}
                price={price}
                giveaway={giveaway}
                images={[{ order: 0, href: href || logo }]}
                created_at={created_at}
                offererName={''}
                offererEmail={offererEmail}
                offererPhone={''}
                offererLocalization={''}
                promoteOptionId={''}
                categories={[{ id: '', label }]}
                user={undefined}
                watched={[]}
                watchedCount={0}
                offerLat={0}
                offerLon={0}
                isAdminAccepted={false}
                status={OFFER_STATUS.ACTIVE}
                faqs={[]}
                benefits={[]}
                subscriptionOptions={subscriptionOptions}
                geo_lat={geo_lat}
                geo_lng={geo_lng}
                city={city}
                commitmentForm={commitmentForm}
                isWatched={isWatched}
                handleOpenModal={(id: string) => handleOpenModal(id)}
                handleSetWatched={idToSetWatched}
              />
            )
          )}
          {mockItems()}
        </>
      );
    }
  };

  const renderFilters = () => {
    if (isAccountSuspended) {
      return (
        <S.AccountSuspendedText>
          <S.SadFace src={sadEmoji} /> Niestety to konto jest zawieszone i nie ma żadnych ogłoszeń.
        </S.AccountSuspendedText>
      );
    } else {
      return (
        <>
          <S.InfoContainer>
            <S.Info id="results">Wszystkie ogłoszenia</S.Info>
            <S.CountInfo>
              Znaleziono <S.Count>{count}</S.Count> ogłoszeń
            </S.CountInfo>
          </S.InfoContainer>
          {!isTablet && (
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 48,
                fontFamily: 'Poppins',
                color: '#11244D',
                border: '1px solid#BDBDBD',
                borderRadius: '8px',
                fontSize: '16px',
              }}
              onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
            >
              <S.FilterImage src={filterImage} />
              Filtrowanie i sortowanie
            </Button>
          )}
          {isTablet && (
            <S.FiltersContainer>
              <S.LeftSide>
                <InputNumber
                  placeholder="Cena od"
                  value={minPrice}
                  onChange={(e: any) => setMinPrice(e)}
                  max={maxPrice || undefined}
                  min={0}
                />
                <InputNumber
                  placeholder="Cena do"
                  value={maxPrice}
                  onChange={(e: any) => setMaxPrice(e)}
                  min={minPrice || undefined}
                  max={9999}
                />
                <Select
                  placeholder="Typ"
                  options={offerTypeOptions}
                  defaultValue={offerTypeOptions[0]}
                  style={{ minWidth: 140 }}
                  onChange={(e: any) => setSelectedOffertype(e)}
                />
                <Button
                  style={{ background: 'rgba(75,65,196,1)', color: 'white' }}
                  onClick={() => {
                    fetchData();
                  }}
                >
                  Szukaj
                </Button>
                <Button onClick={() => resetFilters()}>Resetuj filtry</Button>
              </S.LeftSide>
              <S.RightSide>
                <Select
                  placeholder="Sortuj"
                  options={sortOptions}
                  defaultValue={sortOptions[0]}
                  style={{ minWidth: 140 }}
                  onChange={(e: any) => setSelectedOrder(e)}
                />
              </S.RightSide>
            </S.FiltersContainer>
          )}
        </>
      );
    }
  };

  return (
    <S.Wrapper>
      <UserOffersHeader {...userData} isLoading={isLoading} />
      <S.InnerWrapper>
        {renderFilters()}
        {isLoading ? (
          <S.OffersWrapper>
            <S.SpinnerContainer>
              <Spinner />
            </S.SpinnerContainer>
          </S.OffersWrapper>
        ) : (
          <S.OffersWrapper>{renderOffers()}</S.OffersWrapper>
        )}
        <div style={{ marginBottom: '32px' }}>
          {count && count > 0 && (
            <Pagination limit={12} page={page} resultsCount={count} setPage={handleChangePage} />
          )}
        </div>
      </S.InnerWrapper>
      <Drawer
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        width="100VW"
        height="100VH"
        placement="top"
        headerStyle={{
          background: '#E9F5F5',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontWeight: '600',
          color: '#11244D',
        }}
        title="Filtrowanie i sortowanie"
      >
        <Collapse
          bordered={false}
          expandIconPosition="end"
          defaultActiveKey={['1', '2', '3']}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            background: 'white',
            borderBottom: '1px solid #E5E5E5',
            fontSize: '16px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            borderRadius: 0,
          }}
          items={[
            {
              key: '1',
              label: 'Sortuj',
              children: (
                <Radio.Group value={selectedOrder}>
                  {sortOptions.map((o) => (
                    <Radio
                      value={o.value}
                      onClick={() => setSelectedOrder(o.value)}
                      style={{ fontSize: '16px' }}
                    >
                      {o.label}
                    </Radio>
                  ))}
                </Radio.Group>
              ),
            },
            {
              key: '2',
              label: 'Typ',
              children: (
                <Radio.Group value={selectedOfferType}>
                  {offerTypeOptions.map((o) => (
                    <Radio
                      value={o.value}
                      onClick={() => setSelectedOffertype(o.value)}
                      style={{ fontSize: '16px' }}
                    >
                      {o.label}
                    </Radio>
                  ))}
                </Radio.Group>
              ),
            },
            {
              key: '3',
              label: 'Cena',
              children: (
                <Space direction="horizontal">
                  <InputNumber
                    placeholder="Cena od"
                    value={minPrice}
                    onChange={(e: any) => setMinPrice(e)}
                    max={maxPrice || undefined}
                    min={0}
                  />
                  <InputNumber
                    placeholder="Cena do"
                    value={maxPrice}
                    onChange={(e: any) => setMaxPrice(e)}
                    min={minPrice || undefined}
                    max={9999}
                  />
                </Space>
              ),
            },
          ]}
        />
        <Space direction="vertical" style={{ margin: '0 auto' }}>
          <Button
            style={{
              background: '#4B41C4',
              color: 'white',
              fontFamily: 'Poppins',
              fontSize: 16,
              fontWeight: '600',
              height: 40,
              borderRadius: 8,
              width: '100%',
              maxWidth: 334,
              minWidth: 300,
            }}
            onClick={() => {
              fetchData();
              setIsFilterModalOpen(false);
            }}
          >
            Szukaj
          </Button>
          <Button
            style={{
              background: 'white',
              color: '#4B41C4',
              fontFamily: 'Poppins',
              fontSize: 16,
              fontWeight: '600',
              height: 40,
              borderRadius: 8,
              width: '100%',
              maxWidth: 334,
              minWidth: 300,
            }}
            onClick={() => {
              resetFilters();
              setIsFilterModalOpen(false);
            }}
          >
            Resetuj filtry
          </Button>
        </Space>
      </Drawer>
      {isModalOpen && (
        <ProductModal
          {...modalProps}
          images={[{ order: 0, href: modalProps?.href || logo }]}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
        />
      )}
    </S.Wrapper>
  );
};

export default UsersOffers;

import { useEffect, useState } from 'react';
import InputWithCounter from '../InputWithCounter/InputWithCounter';
import PageWrapper from '../PageWrapper/PageWrapper';
import PriceInput from '../PriceInput/PriceInput';
import { history } from '../../../App';
import { getCategoryParameters } from '../../../api/common-api';
import { COMMITMENT_FORM, OFFER_TYPE } from '../../../redux/offer/types';
import BenefitRow from './BenefitRow';
import FaqRow from './FaqRow';
import { v4 as uuidv4 } from 'uuid';
import IntervalRow from './IntervalRow';
import { Button, Cascader, Select, TreeSelect } from 'antd';
import { ICategory } from '../../../redux/utils/types';
import type { DefaultOptionType } from 'antd/es/cascader';
import useMediaQuery from '../../../hooks/useMediaQuery';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import Stars1 from '../../../assets/OfferPage/stars-1.svg';
import { emptyTempOffer, useOfferContext } from '../../../context/offer/offerContext';
import { getTreeDataPath } from '../../../utils/getTreeDataPath';
import { DownCircleTwoTone } from '@ant-design/icons';
import * as S from './PageOne.styles';
import * as api from '../../../api/common-api';
export interface IOption {
  value: string;
  label: string;
}

export const offerTypesOptions: IOption[] = [
  {
    value: 'BAZAAR',
    label: 'Oferuję przedmiot na sprzedaż',
  },
  {
    value: 'ANNOUNCEMENT',
    label: 'Oferuję uczestnictwo w zajęciach',
  },
];

export const selectSubscriptionOptions: IOption[] = [
  {
    value: 'WEEKLY',
    label: 'Tydzień',
  },
  {
    value: 'MONTHLY',
    label: 'Miesiąc',
  },
  {
    value: 'ANNUALY',
    label: 'Rok',
  },
];

export interface IBenefit {
  id: string;
  icon?: string;
  title: string;
  description: string;
}

export interface IFaq {
  id: string;
  question: string;
  answer: string;
}

export interface IInterval {
  id: string;
  commitmentForm: any;
  price: number;
}

enum CATEGORY {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  BAZAAR = 'BAZAAR',
}

const PageOne = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const { tempOffer, setTempOffer } = useOfferContext();
  const [categories, setCategories] = useState<ICategory[]>([]);

  const fetchCategories = async () => {
    const fetchedCategories = (await api.getCategories()) || [];
    setCategories(fetchedCategories);
  };

  useEffect(() => {
    const locationState = history.location.state;
    fetchCategories();
    if (locationState && locationState === '/create/step-two') {
      return;
    } else {
      clearForm();
      setTempOffer(emptyTempOffer);
    }
  }, []);

  const areCategoriesAvailable = categories && categories.length > 0;
  const bazaarCategories =
    areCategoriesAvailable &&
    categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.BAZAAR);
  const announementsCategories =
    categories && categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.ANNOUNCEMENT);

  const {
    title,
    categoryIds = [],
    description,
    price,
    giveaway,
    offerType: storedOfferType,
    dynamicOptions: storedDynamicOptions,
    benefits: storedBenefits,
    faqs: storedFaqs,
    ageFrom: storedAgeFrom,
    ageTo: storedAgeTo,
    commitmentForm: storedCommitmentForm,
    subscriptionOptions: storedSubscriptionOptions,
  } = tempOffer;

  const storedOfferTypeValue = offerTypesOptions.find((x: IOption) => x.value === storedOfferType);

  const getProperOfferType = () => {
    const locationState = history.location.state;
    if (locationState && locationState === '/create/step-two') {
      return storedOfferTypeValue;
    } else {
      return offerTypesOptions[0];
    }
  };

  const [newTitle, setTitle] = useState(title);
  const [newCategoryIds, setNewCategoryIds] = useState<any[]>(categoryIds);
  const [newDescription, setDescription] = useState(description);
  const [newPrice, setPrice] = useState(price > 0 ? `${price}` : '');
  const [newGiveaway, setGiveaway] = useState(giveaway);
  const [availableParameters, setAvailableParameters] = useState<any[]>([]);
  const [offerType, setOfferType] = useState<any>(getProperOfferType());
  const [isBrandNew, _setIsBrandNew] = useState(false);
  const [ageFrom, setAgeFrom] = useState<any>(storedAgeFrom);
  const [ageTo, setAgeTo] = useState<any>(storedAgeTo);
  const [faqToAdd, setFaqToAdd] = useState<IFaq[]>(storedFaqs);
  const [benefitToAdd, setBenefitToAdd] = useState<IBenefit[]>(storedBenefits);
  const [intervalToAdd, setIntervalToAdd] = useState<IInterval[]>(storedSubscriptionOptions);
  const [dynamicOptions, setDynamicOptions] = useState<any[]>([]);
  const [isSinglePrice, setIsSinglePrice] = useState(price > 0 || giveaway || false);
  const [isSubscription, setIsSubscription] = useState(
    storedCommitmentForm === COMMITMENT_FORM.SUBSCRIPTION || false
  );

  const usedCategoriesType =
    (offerType.value === CATEGORY.BAZAAR || offerType === CATEGORY.BAZAAR
      ? bazaarCategories
      : announementsCategories) || [];

  const handleAddNewFaq = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      question: '',
      answer: '',
    };
    const updatedRecords = [...faqToAdd, newRecord];
    setFaqToAdd(updatedRecords);
  };

  const handleAddNewBenefit = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      title: '',
      description: '',
    };
    const updatedRecords = [...benefitToAdd, newRecord];
    setBenefitToAdd(updatedRecords);
  };

  const handleAddNewInterval = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      commitmentForm: {
        label: '',
        value: '',
      },
      price: 0,
    };
    const updatedRecords = [...intervalToAdd, newRecord];
    setIntervalToAdd(updatedRecords);
  };

  const properOfferType = typeof offerType === 'object' ? offerType.value : offerType;

  const handleNext = (e: any) => {
    e.preventDefault();
    const data = {
      title: newTitle,
      categoryIds: newCategoryIds,
      description: newDescription,
      price: +newPrice,
      giveaway: newGiveaway,
      offerType: properOfferType,
      isBrandNew,
      commitmentForm: isSubscription
        ? COMMITMENT_FORM.SUBSCRIPTION
        : COMMITMENT_FORM.ONE_TIME_PAYMENT,
      faqs: faqToAdd,
      benefits: benefitToAdd,
      ageFrom,
      ageTo,
      subscriptionOptions: intervalToAdd,
      dynamicOptions,
    };
    setTempOffer({ ...tempOffer, ...data });
    history.push('/create/step-two');
  };
  useEffect(() => {
    const getParams = async () => {
      if (newCategoryIds.length < 1) {
        return;
      }
      const childCategoryId = newCategoryIds[newCategoryIds.length - 1];
      const params = await getCategoryParameters(childCategoryId);
      const defaultParamsValues: any[] = [];
      params
        .filter((p: any) => p.defaultValue || p.type === 'yesNo')
        .map(({ id, type, label, defaultValue, isCheckedByDefault, options }: any) => {
          const properDefaultValue = type === 'yesNo' ? isCheckedByDefault : defaultValue;
          const selectDefaultValue = options.find((x: any) => x.label === defaultValue)?.id || '';
          defaultParamsValues.push({
            id,
            type,
            value: type === 'select' ? selectDefaultValue : properDefaultValue,
            label,
          });
        });
      setDynamicOptions(storedDynamicOptions ? storedDynamicOptions : defaultParamsValues);
      setAvailableParameters(params);
    };
    getParams();
  }, [newCategoryIds]);

  const RenderParams = () => {
    return availableParameters.map((param, index, type) => {
      const getDynamicOptionValue = () =>
        dynamicOptions.find((x: any) => x.id === param.id)?.value || '';
      if (param.type === 'yesNo') {
        return (
          <S.DynamicOptionsWrapper key={index}>
            <S.StyledCheckboxInput
              type="checkbox"
              onClick={() => handleToggleCheckbox(param.id, param.label)}
              checked={getDynamicOptionValue()}
            />
            <S.DynamicOptionlabel isSelect={false}>{param.label}</S.DynamicOptionlabel>
          </S.DynamicOptionsWrapper>
        );
      } else if (param.type === 'text') {
        return (
          <S.DynamicTextWrapper key={index}>
            <S.DynamicOptionlabel isSelect>{param.label}</S.DynamicOptionlabel>
            <S.DynamicTextContainer>
              <input
                style={{
                  display: 'flex',
                  border: '1px solid rgba(226, 232, 240, 1)',
                  borderRadius: '4px',
                  padding: '14px 20px',
                  flex: '1',
                }}
                defaultChecked={false}
                key={param.id}
                required={param.isRequired}
                value={getDynamicOptionValue() || ''}
                onChange={(e: any) =>
                  handleDynamicSelectOptionsData({
                    e,
                    id: param.id,
                    type: param.type,
                    value: e.target.value,
                    label: param.label,
                  })
                }
              />
            </S.DynamicTextContainer>
          </S.DynamicTextWrapper>
        );
      } else if (param.type === 'select') {
        const valueToSearch = getDynamicOptionValue();
        const getDefaultSelectValue = () =>
          param.options.find((x: any) => x.id === valueToSearch)?.id || '';

        const optionValue = getDefaultSelectValue();
        return (
          <S.DynamicTextWrapper key={index}>
            <S.DynamicOptionlabel isSelect>{param.label}</S.DynamicOptionlabel>
            <S.DynamicInputContainer>
              <select
                style={{
                  display: 'flex',
                  border: '1px solid rgba(226, 232, 240, 1)',
                  borderRadius: '4px',
                  padding: '14px 20px',
                  flex: '1',
                }}
                key={param.id}
                required={param.isRequired}
                onChange={(e: any) =>
                  handleDynamicSelectOptionsData({
                    e,
                    id: param.id,
                    type: param.type,
                    value: e.target.value,
                    label: param.label,
                  })
                }
              >
                {!optionValue && <option selected>Wybierz</option>}
                {param.options
                  // .sort(
                  //   (a: any, b: any) =>
                  //     a.label.toLowerCase().charCodeAt(0) - b.label.toLowerCase().charCodeAt(0)
                  // )
                  .map((option: any) => (
                    <option key={option.id} value={option.id} selected={option.id === optionValue}>
                      {option.label}
                    </option>
                  ))}
              </select>
            </S.DynamicInputContainer>
          </S.DynamicTextWrapper>
        );
      }
    });
  };

  interface changeOptionValue {
    e: any;
    id: string;
    type: string;
    value: string;
    label: string;
  }

  const handleDynamicSelectOptionsData = (props: changeOptionValue) => {
    const { e } = props;
    e.preventDefault();
    handleChangeSelectOrText(props);
  };

  const handleChangeSelectOrText = (props: changeOptionValue) => {
    const { e, id, type, value, label } = props;
    e.preventDefault();
    const options = [...dynamicOptions];
    let alreadyIncludedOption = false;
    options.map((i: any) => {
      if (i.id === id) {
        i.value = e.target.value;
        alreadyIncludedOption = true;
        return;
      }
    });
    if (!alreadyIncludedOption) {
      options.push({
        id,
        type,
        value,
        label,
      });
    }
    setDynamicOptions(options);
  };

  const handleToggleCheckbox = (id: string, label: string) => {
    const options = [...dynamicOptions];
    let alreadyIncludedOption = false;
    options.map((i: any) => {
      if (i.id === id) {
        i.value = !i.value;
        alreadyIncludedOption = true;
        return;
      }
    });
    if (!alreadyIncludedOption) {
      options.push({
        id,
        type: 'yesNo',
        value: dynamicOptions.find((x: any) => x.id === id).value,
        label,
      });
    }
    setDynamicOptions(options);
  };

  // const isBazaar = offerType === OFFER_TYPE.BAZAAR || offerType.value === OFFER_TYPE.BAZAAR;

  const isAnnouncement =
    offerType === OFFER_TYPE.ANNOUNCEMENT || offerType.value === OFFER_TYPE.ANNOUNCEMENT;

  const handleDeleteBenefitRecord = (id: string) => {
    const filteredRecords = benefitToAdd.filter((b: any) => b.id !== id);
    setBenefitToAdd(filteredRecords);
  };

  const handleDeleteFaqRecord = (id: string) => {
    const filteredRecords = faqToAdd.filter((f: any) => f.id !== id);
    setFaqToAdd(filteredRecords);
  };

  const handleDeleteIntervalRecord = (id: string) => {
    const filteredRecords = intervalToAdd.filter((i: any) => i.id !== id);
    setIntervalToAdd(filteredRecords);
  };

  const renderFaqs = () =>
    faqToAdd.map((f: IFaq, index: number) => (
      <FaqRow
        key={index}
        {...f}
        index={index}
        onDelete={() => handleDeleteFaqRecord(f.id)}
        onChange={(e: any) => handleFaqData(e.target.name, e.target.value, f.id)}
      />
    ));

  const renderBenefits = () =>
    benefitToAdd.map((b: IBenefit, index: number) => (
      <BenefitRow
        key={index}
        {...b}
        index={index}
        onDelete={() => handleDeleteBenefitRecord(b.id)}
        onChange={(e: any) => handleBenefitsData(e.target.name, e.target.value, b.id)}
      />
    ));

  const priceValidator = (value: any) => {
    const regex = /^[1-9][0-9]*[.]{0,1}[0-9]{0,2}$/g;
    const isValid = regex.test(value) || value === 0.01;
    if (isValid) {
      return value;
    }
  };

  const renderIntervals = () =>
    intervalToAdd.map((i: IInterval, index: number) => (
      <IntervalRow
        key={index}
        options={subOptions}
        {...i}
        index={index}
        onDelete={() => handleDeleteIntervalRecord(i.id)}
        onOptionSelected={(option, itemId) => {
          const intervals = [...intervalToAdd];
          intervals.map((interval: IInterval) => {
            if (interval.id === itemId) {
              interval.commitmentForm = option;
            }
          });
          setIntervalToAdd(intervals);
        }}
        onPriceUpdate={(price) => {
          const intervals = [...intervalToAdd];
          intervals.map((interval: IInterval) => {
            if (interval.id === i.id) {
              interval.price = priceValidator(price);
            }
          });
          setIntervalToAdd(intervals);
        }}
      />
    ));

  const handleBenefitsData = (name: string, value: any, rowId: string) => {
    setBenefitToAdd(
      [...benefitToAdd].map((f: any) => {
        if (f.id === rowId) {
          f[name] = value;
        }
        return f;
      })
    );
  };

  const handleFaqData = (name: string, value: any, rowId: string) =>
    setFaqToAdd(
      [...faqToAdd].map((f: any) => {
        if (f.id === rowId) {
          f[name] = value;
        }
        return f;
      })
    );

  const isButtonDisabled =
    !offerType || newCategoryIds?.length === 0 || offerType === OFFER_TYPE.ANNOUNCEMENT
      ? isSinglePrice === false && isSubscription === false
      : false;

  const showSinglePrice = () => {
    if (offerType === OFFER_TYPE.BAZAAR || offerType.value === OFFER_TYPE.BAZAAR) {
      return (
        <PriceInput
          isFree={newGiveaway}
          value={newPrice}
          setPrice={setPrice}
          setGiveaway={setGiveaway}
        />
      );
    }
  };

  const showSingleAnnouncementPrice = () => {
    if (
      (offerType === OFFER_TYPE.ANNOUNCEMENT || offerType.value === OFFER_TYPE.ANNOUNCEMENT) &&
      isSinglePrice
    ) {
      return (
        <PriceInput
          isFree={newGiveaway}
          value={newPrice}
          setPrice={setPrice}
          setGiveaway={setGiveaway}
        />
      );
    }
  };

  const showSubscriptionPrice = () => {
    if (offerType === OFFER_TYPE.ANNOUNCEMENT || offerType.value === OFFER_TYPE.ANNOUNCEMENT) {
      if (isSubscription) {
        return (
          <>
            <S.SecondaryTitleWithButton>
              {intervalToAdd && intervalToAdd.length <= 2 && (
                <>
                  <S.SecondaryTitle>Typ karnetu</S.SecondaryTitle>
                  <S.AddButton onClick={(e: any) => handleAddNewInterval(e)}>Add</S.AddButton>
                </>
              )}
            </S.SecondaryTitleWithButton>
            <S.Row>
              <S.RowsWrapper>{renderIntervals()}</S.RowsWrapper>
            </S.Row>
          </>
        );
      }
    }
  };

  const pickedOptions = intervalToAdd.map((i: IInterval) => i.commitmentForm);

  const subOptions = selectSubscriptionOptions.filter((s: any) =>
    pickedOptions ? !pickedOptions.includes(s.value) : selectSubscriptionOptions
  );

  const onChange = (value: string[]) => {
    setNewCategoryIds(value);
  };

  const onMobileChange = (value: any) => {
    const values = getTreeDataPath(usedCategoriesType, value);
    setNewCategoryIds(values);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const clearForm = () => {
    setTitle('');
    setDescription('');
    setPrice('');
    setGiveaway(false);
    setAgeFrom('');
    setAgeTo('');
    setBenefitToAdd([]);
    setFaqToAdd([]);
    setNewCategoryIds([]);
    setDynamicOptions([]);
    setAvailableParameters([]);
    setIntervalToAdd([]);
    setIsSubscription(false);
    setIsSinglePrice(false);
  };

  const renderPickCategoryComponent = () => {
    if (isDesktop) {
      return (
        <Cascader
          style={{
            width: '100%',
          }}
          options={usedCategoriesType}
          onChange={(val: any) => onChange(val)}
          placeholder="Wybierz kategorie"
          showSearch={{ filter }}
          fieldNames={{ value: 'id', children: 'categories', label: 'label' }}
          expandTrigger={isDesktop ? 'hover' : 'click'}
          changeOnSelect
          aria-required
          value={newCategoryIds}
          allowClear={false}
        />
      );
    } else {
      return (
        <TreeSelect
          // showSearch search doesn't work properly when we're setting expandedKeys. Either pick search or clear option
          style={{ width: '100%' }}
          value={newCategoryIds[newCategoryIds.length - 1] || []}
          defaultValue={[]}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder="Wybierz kategorie"
          allowClear
          onClear={() => setNewCategoryIds([])}
          // filterTreeNode={(search, item: any) => {
          //   return item?.label?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
          // }}
          onChange={(val: any) => onMobileChange(val)}
          treeData={usedCategoriesType || []}
          fieldNames={{ value: 'id', children: 'categories' }}
          onTreeExpand={(value) => setNewCategoryIds(value)} //allow set newCategoryIds, so expanded keys updates
          treeExpandedKeys={newCategoryIds} //allow clear expanded values when clearing function called
          // treeIcon={}
          clearIcon={<S.ClearIcon />}
          switcherIcon={
            <DownCircleTwoTone
              rev={undefined}
              style={{
                fontSize: '16px',
              }}
              twoToneColor={'rgba(75,65,196,1)'}
            />
          }
        />
      );
    }
  };

  return (
    <S.ExternalWrapper>
      <S.StarsContainer1>
        <S.StyledImage src={Stars1} />
      </S.StarsContainer1>
      <PageWrapper isActiveStepOne={true} onSubmit={handleNext}>
        <S.Title>Dodaj ogłoszenie</S.Title>
        <S.AntSelectContainer>
          <S.AntStyledLabel>Typ oferty</S.AntStyledLabel>
          <Select
            aria-required
            options={offerTypesOptions}
            placeholder="Typ oferty"
            value={offerType}
            onChange={(e) => {
              setOfferType(e);
              clearForm();
            }}
          />
        </S.AntSelectContainer>
        <InputWithCounter required type="title" handleInputChange={setTitle} value={newTitle} />
        <S.CascaderContainer>
          <S.CreatorLabel>
            Wybierz kategorię <S.Red>*</S.Red>
          </S.CreatorLabel>
          {renderPickCategoryComponent()}
        </S.CascaderContainer>
        <InputWithCounter
          required
          type="description"
          handleInputChange={setDescription}
          value={newDescription}
        />
        <S.SeparatorWrapper>
          {isAnnouncement && (
            <S.PricesContainer>
              <S.CheckboxContainer>
                <CustomCheckbox
                  value={isSinglePrice}
                  setValue={() => {
                    setIsSinglePrice(!isSinglePrice);
                    setPrice('');
                    setGiveaway(false);
                  }}
                />
                <S.StyledLabel
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsSinglePrice(!isSinglePrice);
                    setPrice('');
                    setGiveaway(false);
                  }}
                >
                  Cena jednorazowa
                </S.StyledLabel>
              </S.CheckboxContainer>
              {showSingleAnnouncementPrice()}
              <S.CheckboxContainer>
                <CustomCheckbox
                  value={isSubscription}
                  setValue={() => setIsSubscription(!isSubscription)}
                />
                <S.StyledLabel
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsSubscription(!isSubscription)}
                >
                  Karnet
                </S.StyledLabel>
              </S.CheckboxContainer>
              {showSubscriptionPrice()}
            </S.PricesContainer>
          )}
          {showSinglePrice()}
        </S.SeparatorWrapper>
        <S.SecondaryTitle>Parametry</S.SecondaryTitle>
        <S.InputsWrapper>
          <S.ParamsWrapper>
            {isAnnouncement && (
              <>
                <S.Row style={{ gap: '8px' }}>
                  <S.AntInputContainer>
                    <S.AntStyledLabel>Wiek od</S.AntStyledLabel>
                    <S.StyledInput
                      placeholder="Wiek od"
                      value={ageFrom}
                      onChange={(e: any) => setAgeFrom(e.target.value)}
                      type="number"
                      min={0}
                      max={100}
                    />
                  </S.AntInputContainer>
                  <S.AntInputContainer>
                    <S.AntStyledLabel>Wiek do</S.AntStyledLabel>
                    <S.StyledInput
                      placeholder="Wiek do"
                      value={ageTo}
                      onChange={(e: any) => setAgeTo(e.target.value)}
                      type="number"
                      min={0}
                      max={100}
                    />
                  </S.AntInputContainer>
                </S.Row>
              </>
            )}
            {RenderParams()}
          </S.ParamsWrapper>
          {isAnnouncement && (
            <S.BottomContainer>
              <S.SecondaryTitleWithButton>
                <S.SecondaryTitle>
                  Dodaj korzyści wynikające z uczestnictwa w Twoich zajęciach
                </S.SecondaryTitle>
                <S.AddButton onClick={(e: any) => handleAddNewBenefit(e)}>Add</S.AddButton>
              </S.SecondaryTitleWithButton>
              <S.Row>
                <S.RowsWrapper>{renderBenefits()}</S.RowsWrapper>
              </S.Row>
              <S.SecondaryTitleWithButton>
                <S.SecondaryTitle>Dodaj najczęściej zadawane pytania</S.SecondaryTitle>
                <S.AddButton onClick={(e: any) => handleAddNewFaq(e)}>Add</S.AddButton>
              </S.SecondaryTitleWithButton>
              <S.Row>
                <S.RowsWrapper>{renderFaqs()}</S.RowsWrapper>
              </S.Row>
            </S.BottomContainer>
          )}
        </S.InputsWrapper>
        <S.ButtonWrapper>
          <Button
            onClick={(e: any) => handleNext(e)}
            disabled={isButtonDisabled}
            type="primary"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: '100%',
              height: 52,
              borderRadius: 8,
              fontSize: 14,
              marginBottom: 6,
            }}
          >
            Dalej
          </Button>
        </S.ButtonWrapper>
      </PageWrapper>
    </S.ExternalWrapper>
  );
};

export default PageOne;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useIsUserAdmin } from '../redux/auth/selectors';

export interface IProtectedProps extends RouteComponentProps {
  path?: string;
  component?: any;
  exact?: boolean;
}

function ProtectedRoute({ path, component: Component, exact = true }: IProtectedProps) {
  const isAdmin = useSelector(useIsUserAdmin);
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    if (!isAdmin && pathname === path) {
      history.push('/login');
    }
  }, [isAdmin, history, path, pathname]);

  return (
    <Route
      exact={exact}
      sensitive={false}
      path={path}
      render={(props) => (isAdmin ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}

export default withRouter(ProtectedRoute);

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { FiMail } from 'react-icons/fi';

export const Wrapper = styled.button`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  color: ${themes.default.white};
  background-color: ${(props) =>
    props.disabled ? themes.default.lightGraphemeGrey : themes.default.main};
  border-radius: 8px;
  border: none;
  font-family: Poppins;
  font-weight: 600;
  font-size: 19px;
  line-height: 211%;
  text-align: center;
  letter-spacing: -0.4px;
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${themes.default.lightGraphemeGrey};
  }
  margin-bottom: 20px;
  &:hover&:not([disabled]) {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const MailIcon = styled(FiMail)`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const ExternalWrapper = styled.form`
  max-width: 726px;
  width: 100%;
  background: ${themes.default.white};
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  padding: 34px 65px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 980px) {
    padding: 34px 15px;
  }
`;

export const Wrapper = styled.div``;

export const Header = styled.h2`
  margin-bottom: 24px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 21px;
  line-height: 190%;
  letter-spacing: -0.4px;
  color: #363a40;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 980px) {
    flex-wrap: wrap;
  }
`;

export const Spacer = styled.div`
  height: auto;
  width: 34px;
  @media (max-width: 980px) {
    width: 0px;
  }
`;

export const StyledTextarea = styled.textarea`
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  height: 126px;
  padding: 13px 21px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: ${themes.default.graphemeGrey};
  overflow: auto;
  resize: none;
  width: 100%;
  :placeholder {
    color: ${themes.default.bdGrey};
  }
  :focus {
    outline: none;
  }
  margin-bottom: 9px;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  display: flex;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.4px;
  padding: 4px 0;
  max-width: 120px;
  width: 100%;
  border-radius: 4px;
  border: none;
  justify-content: center;
  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  color: white;
  background-color: rgba(75, 65, 196, 1);
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const AcceptButton = styled(Button)`
  border: 1px solid black;
  background-color: white;
  color: black;
  &:hover {
    box-shadow: 0 0 4px black;
  }
`;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

interface WrapperProps {
  isSelected: boolean;
}

export const LinkStyles = { textDecoration: 'none', margin: '0 4px' };

export const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  width: 100px;
  font-size: 17px;
  @media (min-width: 376px) {
    width: 147px;
    font-size: 19px;
  }
  font-family: Poppins;
  font-weight: 600;
  line-height: 211%;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${({ isSelected }) => (isSelected ? themes.default.graphemeGrey : '#BDBDBD')};
  border-bottom: 3px solid
    ${({ isSelected }) => (isSelected ? `${themes.default.main}` : 'transparent')};
`;

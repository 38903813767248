import { createContext, useContext, useState } from 'react';
import { ITempOffer, OFFER_TYPE } from './types';

export const emptyTempOffer: ITempOffer = {
  categoryIds: [],
  title: '',
  description: '',
  price: 0,
  giveaway: false,
  offererName: '',
  offererEmail: '',
  offererPhone: '',
  offererLocalisation: '',
  offerType: OFFER_TYPE.BAZAAR,
  isBrandNew: false,
  commitmentForm: '',
  faqs: [],
  benefits: [],
  ageFrom: 0,
  ageTo: 0,
  subscriptionOptions: [],
  images: [],
  city: '',
  street: '',
  zipCode: '',
  streetNumber: '',
  location: { name: '', lat: 0, lon: 0 },
};

export const OfferContext = createContext<any>(emptyTempOffer);

interface IOfferProvider {
  children: React.ReactNode;
}

const OfferProvider = ({ children }: IOfferProvider) => {
  const [tempOffer, setTempOffer] = useState(emptyTempOffer);

  const value = {
    tempOffer,
    setTempOffer,
  };

  return <OfferContext.Provider value={value}>{children}</OfferContext.Provider>;
};

const useOfferContext = () => {
  const context = useContext(OfferContext);
  if (context === undefined) {
    throw new Error('Must be within an OfferProvider');
  }
  return context;
};

export { OfferProvider, useOfferContext };

import { memo, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, useLocation, Redirect, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import Navbar from './components/Navbar/Navbar';
import RegisterPage from './components/LoginPage/RegisterPage';
import LoginPage from './components/LoginPage/LoginPage';
import ContactPage from './components/ContactComponents/ContactPage/ContactPage';
import PageOne from './components/AddOffer/PageOne/PageOne';
import PageTwo from './components/AddOffer/PageTwo/PageTwo';
import FAQPage from './components/FAQComponents/FAQPage/FAQPage';
import FAQItemPage from './components/FAQComponents/FAQItemPage/FAQItemPage';
import UserDashboardRouter from './UserDashboardRouter';
import AdminDashboardRouter from './AdminDashboardRouter';
import PasswordReminderPage from './components/LoginPage/PasswordReminderPage/PasswordReminderPage';
import ScrollToTop from './utils/ScrollToTop';
import Confirmation from './components/LoginPage/Confirmation/Confirmation';
import ConfirmCode from './components/LoginPage/Confirmation/ConfirmCode';
import OfferPage from './components/OfferComponents/OfferPage/OfferPage';
import NavbarMobile from './components/NavbarMobile/NavbarMobile';
import NewUserModal from './components/Modals/NewUserModal/NewUserModal';
import RegulationsPage from './components/ContactComponents/RegulationsPage/RegulationsPage';
import TermsPage from './components/ContactComponents/TermsPage/TermsPage';
import ProtectedRoute from './utils/ProtectedRoute';
import useMediaQuery from './hooks/useMediaQuery';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import OfferListPage from './components/OfferListComponent/OfferListPage/OffersListPage';
import { StripeProvider, Elements } from 'react-stripe-elements';
import OfferEdit from './components/OfferComponents/OfferEdit/OfferEdit';
import ResetPasswordPage from './components/LoginPage/ResetPasswordPage';
import ResetPasswordConfirmation from './components/LoginPage/Confirmation/ResetPasswordConfirmation';
import ChangePassword from './components/UserSettings/ChangePassword/ChangePassword';
import DeleteAccount from './components/UserSettings/DeleteAccount/DeleteAccount';
import { GoogleOAuthProvider } from '@react-oauth/google';
import usePageTracking from './hooks/useTrackingPage';
import { OfferProvider } from './context/offer/offerContext';
import UsersOffers from './components/UsersOffers/UsersOffers';
import Cookies from './components/Cookies/Cookies';
import { ConfigProvider } from 'antd';
import * as S from './App.styles';

export const history = createBrowserHistory();

const App = () => {
  usePageTracking();
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
  const historyHook = useLocation();
  const location = historyHook.pathname;
  const isCreatingPageOrEditing = location.includes('create') || location.includes('edit');
  const isDesktop = useMediaQuery('(min-width: 881px)');
  const [isMobileMenuOpened, setIsMobilMenuOpened] = useState(false);

  const providerStyles = {
    fontFamily: 'Poppins',
    colorPrimary: 'rgba(75,65,196,1)',
    colorPrimaryHover: 'rgba(75,65,196,1)',
  };

  return (
    <GoogleOAuthProvider clientId="7434743585-4q1hlgbi07qkocr4dbmq6ojs0rpvi1o4.apps.googleusercontent.com">
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <OfferProvider>
          <Elements>
            <Router history={history}>
              <ConfigProvider
                theme={{
                  components: {
                    Select: providerStyles,
                    Input: providerStyles,
                    List: providerStyles,
                    Button: providerStyles,
                    Radio: providerStyles,
                    InputNumber: providerStyles,
                  },
                }}
              >
                <ToastContainer />
                <ScrollToTop />
                {isDesktop ? (
                  <Navbar />
                ) : (
                  <NavbarMobile
                    isMenuOpen={isMobileMenuOpened}
                    setIsMenuOpened={setIsMobilMenuOpened}
                  />
                )}
                <NewUserModal />
                <S.Wrapper>
                  <Switch>
                    <Route path="/" exact component={LandingPage} />
                    <Route path="/register" exact component={RegisterPage} />
                    <Route path="/login" exact component={LoginPage} />
                    <Route path="/login-google" exact component={LoginPage} />
                    <Route path="/reset-password" exact component={PasswordReminderPage} />
                    <Route
                      path="/reset-password-confirmation"
                      exact
                      component={ResetPasswordConfirmation}
                    />
                    <Route
                      path="/reset-password/:verificationCode"
                      exact
                      component={ResetPasswordPage}
                    />
                    <Route
                      path="/change-password/:verificationCode"
                      exact
                      component={ChangePassword}
                    />
                    <Route
                      path="/delete-account/:verificationCode"
                      exact
                      component={DeleteAccount}
                    />
                    <Route path="/help" exact component={LandingPage} />
                    <Route path="/faq" exact component={FAQPage} />
                    <Route path="/faq/:faqItemID" exact component={FAQItemPage} />
                    <Route path="/contact" exact component={ContactPage} />
                    <Route path="/regulations" exact component={RegulationsPage} />
                    <Route path="/privacy-policy" exact component={TermsPage} />
                    <Route path="/search" exact component={OfferListPage} />
                    <ProtectedRoute path="/create/step-one" exact component={PageOne} />
                    <ProtectedRoute path="/create/step-two" exact component={PageTwo} />
                    <Route path="/offer/:id" exact component={OfferPage} />
                    <ProtectedRoute path="/offer/:id/edit" exact component={OfferEdit} />
                    <Route path="/user/:id" exact component={UsersOffers} />
                    <Route
                      path="/user-dashboard"
                      render={() => (
                        <UserDashboardRouter
                          isMenuOpen={isMobileMenuOpened}
                          setIsMenuOpen={setIsMobilMenuOpened}
                        />
                      )}
                    />
                    <Route
                      path="/admin-dashboard"
                      render={() => (
                        <AdminDashboardRouter
                          isMenuOpen={isMobileMenuOpened}
                          setIsMenuOpen={setIsMobilMenuOpened}
                        />
                      )}
                    />
                    <Route
                      path="/email-confirmation/:verificationCode"
                      exact
                      component={Confirmation}
                    />
                    <Route
                      path="/validate-account/:verificationCode"
                      exact
                      component={ConfirmCode}
                    />
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                  {!isCreatingPageOrEditing && <Footer />}
                  <Cookies />
                </S.Wrapper>
              </ConfigProvider>
            </Router>
          </Elements>
        </OfferProvider>
      </StripeProvider>
    </GoogleOAuthProvider>
  );
};

export default memo(App);

import { useState } from 'react';
import * as S from './OfferContact.style';

export default function OfferContact(data: { phoneNumber: string; isOnline: boolean }) {
  const { phoneNumber, isOnline } = data;

  const [isPhoneNumberShown, setIsPhoneNumberShown] = useState(false);
  const hidePhone = () => {
    if (!phoneNumber) {
      return '';
    }
    const visible = phoneNumber.slice(0, 2);
    const hidden = phoneNumber.slice(3).replaceAll(/\d/g, 'x');
    return visible + hidden;
  };

  const renderClickablePhoneNumber = () => {
    if (isPhoneNumberShown) {
      return (
        <S.StyledAnchor href={`tel:${phoneNumber}`}>
          <S.PhoneNumber>{isPhoneNumberShown ? phoneNumber : hidePhone()}</S.PhoneNumber>
        </S.StyledAnchor>
      );
    }
    return <S.PhoneNumber>{isPhoneNumberShown ? phoneNumber : hidePhone()}</S.PhoneNumber>;
  };

  return (
    <S.Wrapper>
      {phoneNumber && (
        <S.Panel>
          <S.Icon />
          {renderClickablePhoneNumber()}
          {!isPhoneNumberShown && (
            <S.Button onClick={() => setIsPhoneNumberShown(true)}>pokaż</S.Button>
          )}
        </S.Panel>
      )}
      <S.Panel>
        <S.OnlineDot isOnline={isOnline} />
        <S.OnlineStatus isOnline={isOnline}>Jest {isOnline ? 'Online' : 'Offline'}</S.OnlineStatus>
      </S.Panel>
    </S.Wrapper>
  );
}

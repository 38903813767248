import ImageOne from '../../../assets/NewSection/image-one.svg';
import ImageTwo from '../../../assets/NewSection/image-two.svg';
import { useHistory } from 'react-router';
import * as S from './NewSection.styles';

const NewSection = () => {
  const history = useHistory();
  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.HeaderWrapper>
          <S.Header>Co znajdziesz na Malutcy.pl?</S.Header>
        </S.HeaderWrapper>
        <S.ContentWrapper>
          <S.SectionWrapper>
            <S.ImageWrapper>
              <S.StyledImage src={ImageOne} />
            </S.ImageWrapper>
            <S.SectionHeaderWrapper>
              <S.SectionHeader>Zajęcia dla dzieci w Twojej miejscowości</S.SectionHeader>
            </S.SectionHeaderWrapper>
            <S.SectionDescriptionWrapper>
              {/* <S.SectionDescription>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis
              </S.SectionDescription> */}
            </S.SectionDescriptionWrapper>
            <S.ButtonWrapper>
              <S.StyledButton onClick={() => history.push('/search?offerType=ANNOUNCEMENT')}>
                Najpopularniejsze zajęcia
              </S.StyledButton>
            </S.ButtonWrapper>
          </S.SectionWrapper>
          <S.SectionWrapper>
            <S.ImageWrapper>
              <S.StyledImage src={ImageTwo} />
            </S.ImageWrapper>
            <S.SectionHeaderWrapper>
              <S.SectionHeader>Bazarek, na którym kupisz niezbędne przedmioty</S.SectionHeader>
            </S.SectionHeaderWrapper>
            <S.SectionDescriptionWrapper>
              {/* <S.SectionDescription>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis
              </S.SectionDescription> */}
            </S.SectionDescriptionWrapper>
            <S.ButtonWrapper>
              <S.StyledButton onClick={() => history.push('/search?offerType=BAZAAR')}>
                Sprawdź bazarek
              </S.StyledButton>
            </S.ButtonWrapper>
          </S.SectionWrapper>
        </S.ContentWrapper>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default NewSection;

import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './Pagination.styles';

export default function Pagination({
  page,
  limit,
  resultsCount,
  setPage,
}: {
  page: number;
  limit: number;
  resultsCount: number;
  setPage: (value: number) => void;
}) {
  const maxPages = Math.ceil(resultsCount / limit);
  let pageButtons = [];
  const isNextActive = page + 1 <= maxPages;
  const isPreviousActive = page > 1;
  const isTablet = useMediaQuery('(min-width: 450px)');
  const isSmall = useMediaQuery('(min-width: 300px)');

  for (let index = 1; index < maxPages + 1; index++) {
    pageButtons.push(
      <S.Page onClick={() => setPage(index)} key={`page_${index}`} active={index === page}>
        <S.PageNumber active={index === page}>{index}</S.PageNumber>
      </S.Page>
    );
  }

  if (isSmall) {
    const neighborsCount = 2;
    const totalLimit = neighborsCount * 2 + 1;
    const sideLimit = neighborsCount + 1;

    const left = page - neighborsCount;
    const right = page + neighborsCount;

    if (maxPages < totalLimit) {
      pageButtons = pageButtons.slice(0, maxPages);
    } else if (page <= sideLimit) {
      pageButtons = pageButtons.slice(0, totalLimit);
    } else if (page > maxPages - sideLimit) {
      pageButtons = pageButtons.slice(maxPages - totalLimit);
    } else {
      pageButtons = pageButtons.slice(left, right);
      pageButtons.unshift(<S.Page key={'left_ellipsis'}>...</S.Page>);
      pageButtons.push(<S.Page key={'right_ellipsis'}>...</S.Page>);
      pageButtons.unshift(
        <S.Page onClick={() => setPage(1)} key={`page_${1}`} active={1 === page}>
          <S.PageNumber active={1 === page}>{1}</S.PageNumber>
        </S.Page>
      );

      pageButtons.push(
        <S.Page
          onClick={() => setPage(maxPages)}
          key={`page_${maxPages}`}
          active={maxPages === page}
        >
          <S.PageNumber active={maxPages === page}>{maxPages}</S.PageNumber>
        </S.Page>
      );
    }
  }

  return (
    <S.PaginationWrapper>
      <S.LastPageWrapper disabled={!isPreviousActive} onClick={() => setPage(page - 1)}>
        <S.LastPageIcon props={{ active: isPreviousActive }} />
        {isTablet && <S.PageText active={isPreviousActive}>Poprzednia</S.PageText>}
      </S.LastPageWrapper>
      <S.PagesWrapper>{pageButtons}</S.PagesWrapper>
      <S.NextPageWrapper disabled={!isNextActive} onClick={() => setPage(page + 1)}>
        {isTablet && <S.PageText active={isNextActive}>Następna</S.PageText>}
        <S.NextPageIcon props={{ active: isNextActive }} />
      </S.NextPageWrapper>
    </S.PaginationWrapper>
  );
}

import MenuItem from '../UserDashboard/MenuItem/MenuItem';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonProps, adminButtons } from '../UserDashboard/Menu/Buttons';
import { useRef } from 'react';
import { logout } from '../../redux/auth/actions';
import * as S from '../UserDashboard/MobileMenu/MobileMenu.styles';

const MobileMenu = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}) => {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = history;

  const handleItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (!isMenuOpen) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <S.Wrapper ref={ref}>
      <S.ButtonsWrapper>
        {adminButtons.map((button: ButtonProps) => {
          const { text, href } = button;
          return (
            <MenuItem
              key={text}
              {...button}
              isActive={pathname === href}
              handleClick={handleItemClick}
              count={0}
            />
          );
        })}
        <S.LogoutButton onClick={handleLogout}>Wyloguj</S.LogoutButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default MobileMenu;

import { memo } from 'react';
import * as S from './SearchResult.styles';

const SearchResult = ({
  icon,
  alt,
  title,
  category,
  link,
}: {
  icon: any;
  alt?: string;
  title: string;
  category: string;
  link: string;
}) => (
  <S.Wrapper to={link}>
    <S.Icon src={icon} alt={alt} />
    <S.DescriptionWrapper>
      <S.Title>{title}</S.Title>
      <S.BreadCrumb>{category}</S.BreadCrumb>
    </S.DescriptionWrapper>
  </S.Wrapper>
);

export default memo(SearchResult);

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 18px;
  max-width: 1112px;
  margin: 0 auto;
  @media (min-width: 1112px) {
    width: 100%;
  }
`;

export const OffersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
  margin-bottom: 64px;
  flex: 1;
  align-items: center;
  min-height: 400px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 12px;
`;

export const RightSide = styled.div``;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

export const Info = styled.h3`
  margin: 0;
  color: #11244d;
  text-align: left;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 135%;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CountInfo = styled.div`
  display: flex;
  color: #11244d;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const Count = styled(CountInfo)`
  font-weight: 600;
  padding: 0 4px;
`;

export const FilterImage = styled.img`
  margin-right: 12px;
  max-height: 20px;
`;

export const AccountSuspendedText = styled.div`
  display: flex;
  align-items: center;
  color: #11244d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
`;

export const SadFace = styled.img`
  margin-right: 12px;
`;

export const MockOffer = styled.div`
  width: 100%;
  max-width: 251px;
  height: 170px;
`;

import { useHistory } from 'react-router';
import FAQPageWrapper from '../FAQPageWrapper/FAQPageWrapper';
import SearchResultsWrapper from '../SearchResultsWrapper/SearchResultsWrapper';
import { IFaqCategory, categories, popular } from '../faqData';
import useMediaQuery from '../../../hooks/useMediaQuery';
import FAQContact from '../FAQContact/FAQContact';
import * as S from './FAQSearchPage.styles';

export default function FAQSearchPage() {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const searchParams = new URLSearchParams(search);
  const queryParam = searchParams.get('query');
  const query = queryParam ? queryParam.toLowerCase() : '';
  const isDesktop = useMediaQuery('(min-width: 900px)');

  let results: IFaqCategory[] = [];
  let resultsCount = 0;

  for (let index = 0; index < categories.length; index++) {
    const category = categories[index];
    const { articles } = category;
    const foundArticles = articles.filter(
      ({ title, body }) => title.toLowerCase().includes(query) || body.toLowerCase().includes(query)
    );
    const foundCount = foundArticles.length;
    resultsCount = resultsCount + foundCount;
    if (foundCount > 0) {
      results = [...results, { ...category, articles: foundArticles }];
    }
  }

  return (
    <FAQPageWrapper>
      <S.ExternalWrapper>
        <S.Wrapper>
          <SearchResultsWrapper
            resultsCount={resultsCount}
            query={queryParam ? queryParam : ''}
            results={results}
          />
          {isDesktop && (
            <S.SideWrapper>
              <S.PopularHeader>Najpopularniejsze</S.PopularHeader>
              {popular.map(({ id, title }) => (
                <S.PopularItem key={`popular_${id}`} to={`/faq/${id}`}>
                  {title}
                </S.PopularItem>
              ))}
            </S.SideWrapper>
          )}
        </S.Wrapper>
        <FAQContact />
      </S.ExternalWrapper>
    </FAQPageWrapper>
  );
}

import styled from 'styled-components';
import { TiWarningOutline } from 'react-icons/ti';

export const Wrapper = styled.div`
  max-width: 893px;
  box-sizing: border-box;
  width: 100%;
`;

export const Header = styled.div`
  margin: 0 0 8px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #110f11;
`;

export const DeleteAccountButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DeleteAccoutButton = styled.button`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.4000000059604645px;
  text-align: center;
  color: #b70000;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #b70000;
  max-width: 180px;
  width: 100%;
  padding: 2px 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4px #b70000;
  }
`;

export const WarningWrapper = styled.div`
  display: flex;
  margin: 10px 0 16px 0;
`;

export const WarningContainer = styled.div`
  display: flex;
  background-color: rgb(254 251 224);
  border-radius: 16px;
  padding: 6px 12px;
  border: 1px solid rgb(247 158 19);
`;
export const StyledIcon = styled(TiWarningOutline)`
  margin-right: 6px;
  color: rgb(247 158 19);
`;

export const WarningText = styled.span``;

export const ChangePasswordButtonContainer = styled.div`
  display: flex;
  max-width: 274px;
`;

import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getCitiesOptions } from '../../api/settings';
import { Select } from 'antd';
import useMediaQuery from '../../hooks/useMediaQuery';
import * as S from './SearchFields.styles';

interface ISearchFields {
  onSetSelectedOfferType?: string;
}
interface IOption {
  value: string;
  label: string;
}

const SearchFields = ({ onSetSelectedOfferType }: ISearchFields) => {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const params = new URLSearchParams(search);
  const queryParam = params.get('query');
  const localizationParam = params.get('localization');
  const offerTypeParam = params.get('offerType');
  const [query, setQuery] = useState(queryParam ? queryParam : '');
  const [selectedCity, setSelectedCity] = useState(localizationParam ? localizationParam : null);
  const [citiesOptions, setCitiesOptions] = useState<IOption[]>([]);
  const [focusedInput, setFocusedInput] = useState(false);

  useEffect(() => {
    setQuery('');
  }, [offerTypeParam]);

  useEffect(() => {
    const getCities = async () => {
      const { cities } = await getCitiesOptions();
      setCitiesOptions(cities);
    };
    getCities();
  }, []);

  const handleSearchOffers = (e: FormEvent) => {
    e.preventDefault();
    if (onSetSelectedOfferType) {
      params.set('offerType', onSetSelectedOfferType);
    }
    if (query) {
      params.set('query', query);
    } else {
      params.delete('query');
    }
    if (selectedCity) {
      params.set('location', selectedCity.toLocaleLowerCase());
    } else {
      params.delete('location');
    }
    history.push(`/search?${params.toString()}`);
  };

  useEffect(() => {
    if (queryParam) {
      setQuery(query);
    }
  }, []);

  const handleClearQuery = () => {
    params.delete('query');
    setQuery('');
    history.push(`/search?${params.toString()}`);
  };

  const handleClearLocalization = () => {
    params.delete('location');
    setSelectedCity(null);
    history.push(`/search?${params.toString()}`);
  };

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <S.Wrapper onSubmit={handleSearchOffers}>
      <S.QueryInputWrapper isFocused={focusedInput}>
        <S.QueryInput
          bordered={false}
          placeholder={'Szukaj'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          prefix={<S.SearchIcon isFocused={focusedInput} />}
          onFocus={() => setFocusedInput(true)}
          onBlur={() => setFocusedInput(false)}
        />
        {queryParam && <S.ClearIcon onClick={() => handleClearQuery()} isFocused={focusedInput} />}
      </S.QueryInputWrapper>
      <S.SelectorsSpan>
        <Select
          options={citiesOptions}
          placeholder="Lokalizacja"
          value={selectedCity}
          onSelect={(e: any) => {
            setSelectedCity(e);
          }}
          style={{
            width: isDesktop ? 190 : '100%',
            height: 50,
          }}
          allowClear
          onClear={() => handleClearLocalization()}
        />
      </S.SelectorsSpan>
      <S.ButtonWrapper>
        <S.StyledButton
          onClick={(e: any) => handleSearchOffers(e)}
          type="primary"
          style={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            minWidth: 200,
            height: 50,
          }}
        >
          Wyszukaj
        </S.StyledButton>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default SearchFields;

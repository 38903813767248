import styled from 'styled-components';
import U from '../../../assets/CSS/commonStyles';

export const Wrapper = styled.div`
  flex-grow: 1;
  padding: 20px 18px;
  background-color: #11244d;
  ${U.columnFlex}
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 335px;
  min-width: 284px;
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  height: fit-content;
  /* position: sticky; */
  top: 108px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const LogoutButton = styled.button`
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  box-sizing: border-box;
  font-weight: 600;
  transition: 250ms;
  background-color: rgba(75, 65, 196, 1);
  :hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

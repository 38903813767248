import { useHistory } from 'react-router-dom';
import * as S from './OfferCreator.style';

const OfferCreator = (data: {
  title: string;
  direction: string;
  name: string;
  avatar: string;
  id: string;
  city: string;
}) => {
  const history = useHistory();
  const { direction, name, avatar, id, city } = data;
  const AvatarStyle = avatar ? <S.Avatar src={avatar} /> : <S.EmptyAvatar />;

  const handleNavigate = () => history.push(`/user/${id}`);
  return (
    <S.Wrapper>
      <S.SupportContainer>
        <S.AvatarContainer>{AvatarStyle}</S.AvatarContainer>
      </S.SupportContainer>
      <S.UserContainer>
        <S.Name onClick={() => handleNavigate()}>{name}</S.Name>
        <S.Offers onClick={() => handleNavigate()}>Wszystkie ogłoszenia</S.Offers>
        <S.LocationWrapper>
          <S.LocationIcon />
          <S.City>{city}</S.City>
        </S.LocationWrapper>
      </S.UserContainer>
    </S.Wrapper>
  );
};

export default OfferCreator;

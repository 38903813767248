import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './MenuItem.styles';

const MenuItem = ({
  icon,
  text,
  href,
  count = 0,
  isActive,
  handleClick,
}: {
  icon: any;
  text: string;
  href: string;
  count?: number;
  isActive: boolean;
  handleClick?: () => void;
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Link
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      to={href}
      style={S.Wrapper(isActive, isHover)}
      onClick={handleClick}
    >
      <S.IconWrapper>
        <S.Icon src={icon} alt={`${text} icon`} />
      </S.IconWrapper>
      <S.Text>{text}</S.Text>
      {count > 0 && (
        <S.CounterWrapper isHover={isHover}>
          <S.Counter>{count}</S.Counter>
        </S.CounterWrapper>
      )}
    </Link>
  );
};

export default MenuItem;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  margin: 0 auto 53px auto;
  max-width: 721px;
  height: 100px;
  padding: 0 18px;
  box-sizing: border-box;
`;

const Text = `
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  text-align: center;
  color: ${themes.default.graphemeGrey};
`;

export const Message = styled.div`
  ${Text}
  margin-bottom: 18px;
`;

export const ContactLink = styled(Link)`
  ${Text}
  display: block;
  color: ${themes.default.main};
  text-decoration: none;
`;

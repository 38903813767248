import { useHistory } from 'react-router-dom';
import * as S from './Details.styles';

interface IDetails {
  isBazaar?: boolean;
  offerType: string;
  advertiser: string;
  when?: string;
  forWhom?: string;
  price: any;
  address: string;
  state?: string;
  dynamicOptions?: any[];
  id?: string;
}

const Details = ({
  isBazaar,
  offerType,
  advertiser,
  forWhom,
  price,
  address,
  state,
  dynamicOptions,
  id,
}: IDetails) => {
  const history = useHistory();
  const navigateToUsersOffers = () => {
    if (!id) {
      return;
    }
    history.push(`/user/${id}`);
  };
  return (
    <S.DetailsContainer>
      <S.Detail>
        <S.DetailLabel>Typ:</S.DetailLabel>
        <S.DetailValue>{offerType}</S.DetailValue>
      </S.Detail>
      <S.Detail>
        <S.DetailLabel>Kto:</S.DetailLabel>
        <S.DetailValue style={{ cursor: 'pointer' }} onClick={() => navigateToUsersOffers()}>
          {advertiser}
        </S.DetailValue>
      </S.Detail>
      {/* {isBazaar && (
        <S.Detail>
          <S.DetailLabel>Stan:</S.DetailLabel>
          <S.DetailValue>{state}</S.DetailValue>
        </S.Detail>
      )} */}
      {/* <S.Detail>
        <S.DetailLabel>Kiedy:</S.DetailLabel>
        <S.DetailValue>{when}</S.DetailValue>
      </S.Detail> */}
      {!isBazaar && forWhom !== '' && (
        <S.Detail>
          <S.DetailLabel>Dla kogo:</S.DetailLabel>
          <S.DetailValue>{forWhom}</S.DetailValue>
        </S.Detail>
      )}
      <S.Detail>
        <S.DetailLabel>Cena:</S.DetailLabel>
        <S.ActionTextWrapper>
          <S.DetailValue>{price}</S.DetailValue>
          {/* <S.ActionText>Szczegółowy cennik</S.ActionText> */}
        </S.ActionTextWrapper>
      </S.Detail>
      {address !== '' && (
        <S.Detail>
          <S.DetailLabel>Miejsce:</S.DetailLabel>
          <S.ActionTextWrapper>
            <S.DetailValue>{address}</S.DetailValue>
            {/* <S.ActionText>Mapa dojazdu</S.ActionText> */}
          </S.ActionTextWrapper>
        </S.Detail>
      )}
      {dynamicOptions &&
        dynamicOptions.length > 0 &&
        dynamicOptions.map((d: any, index: number) => {
          const properValue =
            d.value === 'true' ? (
              <S.SyledTick />
            ) : d.value === 'false' ? (
              <S.StyledClose />
            ) : (
              d.value
            );
          return (
            <S.Detail key={index}>
              <S.DetailLabel>{d.label}:</S.DetailLabel>
              <S.ActionTextWrapper>
                <S.DetailValue>{properValue}</S.DetailValue>
                {/* <S.ActionText>Mapa dojazdu</S.ActionText> */}
              </S.ActionTextWrapper>
            </S.Detail>
          );
        })}
    </S.DetailsContainer>
  );
};

export default Details;

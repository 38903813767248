import OfferCreator from '../OfferCreator/OfferCreator';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import * as S from './OfferMap.style';

const containerStyle = {
  width: '100%',
  height: '100%',
};
const OfferMap = ({
  city,
  name,
  direction,
  zoom = 8,
  coordinates = [0, 0],
  avatar = '',
  userId = '',
}: {
  city: string;
  name: string;
  direction: string;
  coordinates: [number, number];

  zoom?: number;
  radius?: number;
  avatar: string;
  userId?: string;
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA2ptNi3RAAGKmWZ9ES21kt153CCVPx_oA',
    libraries: ['places'],
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const center = {
    lat: coordinates[0],
    lng: coordinates[1],
  };

  return (
    <S.Wrapper isInModal={false}>
      <div
        style={{
          height: '200px',
        }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          options={{
            zoom,
          }}
        >
          <Marker position={center}></Marker>
        </GoogleMap>
      </div>
      <S.CreatorContainer>
        <OfferCreator
          id={userId ? userId : ''}
          title={''}
          name={name}
          direction={direction}
          avatar={avatar || ''}
          city={city || ''}
        />
      </S.CreatorContainer>
    </S.Wrapper>
  );
};

export default OfferMap;

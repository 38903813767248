import { COMMITMENT_FORM } from "../redux/offer/types";
import { FormatPrice } from './formatPrice'

interface IFinalPrice {
  subscriptionOptions: any[]
  formattedPrice: string;
  commitmentForm: COMMITMENT_FORM | undefined
}
export const GetFinalPrice = (props: IFinalPrice) => {
  const { subscriptionOptions, formattedPrice, commitmentForm } = props

  const subPrices =
    subscriptionOptions.length > 0 && subscriptionOptions.map((x) => FormatPrice(x.price));

  const allPrices = !subPrices
    ? [Number(formattedPrice)]
    : formattedPrice === '0' && subPrices
      ? [...subPrices]
      : subPrices && formattedPrice !== '0'
        ? [...subPrices, Number(formattedPrice)]
        : [];

  const theLowestPrice = Math.min(...allPrices);

  const getPrice = () => {
    if (commitmentForm === COMMITMENT_FORM.SUBSCRIPTION) {
      const onlyOnePrice = allPrices.length === 1
      const shouldBeForFree = allPrices[0] === 0
      if (onlyOnePrice && shouldBeForFree) {
        return 'Za darmo'
      } else if (onlyOnePrice) {
        return `${theLowestPrice} zł`
      } else {
        return `od ${theLowestPrice} zł`
      }
    } else if (commitmentForm === COMMITMENT_FORM.ONE_TIME_PAYMENT) {
      const isForFree = formattedPrice === '0'
      if (isForFree) {
        return 'Za darmo'
      } else {
        return `${formattedPrice} zł`;
      }
    }
  }

  return getPrice()
}


import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import { themes } from '../../../assets/CSS/colorThemes';
import { MdClose } from 'react-icons/md';

export const ExternalWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  padding: 0 18px;
  box-sizing: border-box;
  /* min-height: 252px; */
  z-index: 1;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1016px;
  background-color: white;

  box-sizing: border-box;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  padding: 24px 16px;
  @media (min-width: 400px) {
    padding: 24px;
  }
  @media (min-width: 820px) {
    padding: 24px 92px;
  }
  border-radius: 25px;
  overflow: auto;
`;

export const Arrow = styled(IoIosArrowForward)`
  width: 16px;
  height: 16px;
  margin-right: 14px;
  color: ${themes.default.main};
  margin-top: 2px;
`;

export const InfoWrapper = styled.div`
  max-width: 921px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const CategoryLabel = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: ${themes.default.fontColorBlack};
`;

export const AllAuctions = styled.div`
  font-family: Mulish;
  font-weight: bold;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: 0.3px;
  margin-left: 10px;
  color: ${themes.default.main};
  cursor: pointer;
`;

export const SubCategoriesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CloseIcon = styled(MdClose)`
  width: 32px;
  height: 32px;
  color: ${themes.default.graphemeGrey};
`;

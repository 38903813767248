import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { RiMailDownloadLine, RiMailSendLine, RiMailLockLine } from 'react-icons/ri';
import { GrSearch } from 'react-icons/gr';

export const Wrapper = styled.div`
  max-width: 893px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  padding: 0;
  margin: 0 0 8px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #110f11;
`;

export const SubHeader = styled.h2`
  padding: 0;
  margin: 0 0 28px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  color: #110f11;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  padding: 0 0 12px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  @media (min-width: 881px) {
    width: fit-content;
  }
  @media (min-width: 1007px) {
    margin: 0;
  }

  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.bdGrey};
  }
`;

export const NavRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 8px;
  flex-direction: column;
  width: 100%;
  @media (min-width: 881px) {
    flex-direction: row;
  }
`;

export const Incoming = styled(RiMailDownloadLine)`
  width: 24px;
  height: 24px;
  color: ${themes.default.black};
`;

export const Sent = styled(RiMailSendLine)`
  width: 24px;
  height: 24px;
  color: ${themes.default.black};
`;

export const Archived = styled(RiMailLockLine)`
  width: 24px;
  height: 24px;
  color: ${themes.default.black};
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 370px) {
    width: 308px;
  }
`;

export const Label = styled.label`
  font-family: Poppins;
  font-size: 16px;
  line-height: 112%;
  letter-spacing: 0.1px;
  color: #828282;
  margin-right: 9px;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 225px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  align-items: center;
`;

export const SearchIcon = styled(GrSearch)`
  color: #bdbdbd;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin: auto 10px auto 15px;
  opacity: 0.55;
`;

export const InputField = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #110f11;
  :placeholder {
    color: #828282;
  }
  :focus {
    outline: none;
  }
`;





export const ResultsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 28px;
  overflow: auto;
  /* @media (min-width: 1024px) {
    max-height: calc(100vh - 300px);
  } */
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.extraLightGrey};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.bdGrey};
  }
`;

export const RemoveRow = styled.div<{ isActive: boolean }>`
  width: 100%;
  display: flex;
  transition: 250ms;
  margin: 0 0 16px;
  margin: ${({ isActive }) => (isActive ? '0 0 16px' : '0')};
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  height: ${({ isActive }) => (isActive ? '114px' : '0')};
  @media (min-width: 350px) {
    height: ${({ isActive }) => (isActive ? '78px' : '0')};
  }
  @media (min-width: 800px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @media (min-width: 916px) {
    justify-content: flex-end;
    flex-wrap: nowrap;
    height: ${({ isActive }) => (isActive ? '34px' : '0')};
  }
`;

export const InfoText = styled.p`
  width: 100%;
  text-align: center;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  color: #373f41;
  margin: auto 0;
  padding: 0 8px;
  box-sizing: border-box;
  color: red;
`;

export const SelectAll = styled.button`
  min-width: 142px;
  background-color: ${themes.default.main};
  border: none;
  :disabled {
    filter: saturate(0) opacity(0.5);
  }
  transition: 250ms;
  color: ${themes.default.white};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  margin: 0 8px 0 0;
  white-space: nowrap;
`;

export const RemoveButton = styled(SelectAll)`
  background-color: ${themes.default.negative};
`;

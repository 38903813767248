import styled from 'styled-components';
import { TiTickOutline } from 'react-icons/ti';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 40px 0 30px 0;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  padding-bottom: 24px;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Detail = styled.div`
  display: flex;
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`;
export const DetailLabel = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  color: rgba(130, 130, 130, 1);
  padding-right: 26px;
  min-width: 74px;
`;

export const DetailValue = styled.span`
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  color: rgba(17, 36, 77, 1);
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionText = styled.span`
  color: rgba(75, 65, 196, 1);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`;

export const ActionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SyledTick = styled(TiTickOutline)`
  color: rgba(75, 65, 196, 1);
  height: 24px;
  width: 24px;
`;

export const StyledClose = styled(AiOutlineCloseCircle)`
  color: red;
  height: 24px;
  width: 24px;
`;

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const USER_DATA_UPDATE_SUCCESS = 'USER_DATA_UPDATE_SUCCESS';
export const SET_SIGN_IN_ERROR_MESSAGE = 'SET_SIGN_IN_ERROR_MESSAGE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SET_LOADING_DATA = 'SET_LOADING_DATA';
export const SET_LOADING_DATA_SUCCESS = 'SET_LOADING_DATA_SUCCESS';
export const SET_PASSWORD_ERROR_MESSAGE = 'SET_PASSWORD_ERROR_MESSAGE';
export const SET_VERIFIED_USER = 'SET_VERIFIED_USER';
export const TOGGLE_WATCHED_OFFER_START = 'TOGGLE_WATCHED_OFFER_START';
export const TOGGLE_WATCHED_OFFER_SUCCESS = 'TOGGLE_WATCHED_OFFER_SUCCESS';
export const TOGGLE_WATCHED_OFFER_FAILURE = 'TOGGLE_WATCHED_OFFER_FAILURE';
// export const SEND_FIRST_MESSAGE_SUCCESS = 'SEND_FIRST_MESSAGE_SUCCESS';
export const ARCHIVE_THREAD = 'ARCHIVE_THREAD';
export const ARCHIVE_THREAD_SUCCESS = 'ARCHIVE_THREAD_SUCCESS';
export const ARCHIVE_THREAD_FAIL = 'ARCHIVE_THREAD_FAIL';
export const REMOVE_THREAD = 'REMOVE_THREAD';
export const REMOVE_THREAD_SUCCESS = 'REMOVE_THREAD_SUCCESS';
export const REMOVE_THREAD_FAIL = 'REMOVE_THREAD_FAIL';
export const USER_UTILS = 'USER_UTILS';
export const USER_UTILS_SUCCESS = 'USER_UTILS_SUCCESS';
export const USER_UTILS_FAIL = 'USER_UTILS_FAIL';
export const SET_MESSAGES_QUERY = 'SET_MESSAGES_QUERY';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_MESSAGES_CATEGORY = 'SET_MESSAGES_CATEGORY';
export const ADD_USER_FILE_SUCCESS = 'ADD_USER_FILE_SUCCESS';
export const GET_USERS_FILES_SUCCESS = 'GET_USERS_FILES_SUCCESS';
export const DELETE_USERS_FILE_SUCCESS = 'DELETE_USERS_FILE_SUCCESS';
export const GET_DASHBOARD_COUNT_SUCCESS = 'GET_DASHBOARD_COUNT_SUCCESS';
export const SELECT_ACCOUNT_TYPE = 'SELECT_ACCOUNT_TYPE';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY';
export const ADD_NEW_CATEGORY_SUCCESS = 'ADD_NEW_CATEGORY_SUCCESS';
export const ADD_NEW_CATEGORY_FAIL = 'ADD_NEW_CATEGORY_FAIL';

import { useEffect, useState } from 'react';
import OfferRow from '../OfferRow/OfferRow';
import SearchFields from '../../SearchFields/SearchFields';
import DisplayOptions from '../DisplayOptions/DisplayOptions';
import Pagination from '../Pagination/Pagination';
import OfferThumbnail from '../../LandingPage/OfferThumbnail/OfferThumbnail';
import { useDispatch } from 'react-redux';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useHistory } from 'react-router';
import { IWatchSearchParams, OFFER_TYPE } from '../../../redux/offer/types';
import imgLeft1 from '../../../assets/SearchSection/left1.svg';
import imgLeft2 from '../../../assets/SearchSection/left2.svg';
import imgRight1 from '../../../assets/SearchSection/right1.svg';
import imgRight2 from '../../../assets/SearchSection/right2.svg';
import CategoriesContainer from '../../Categories/CategoriesContainer/CategoriesContainer/CategoriesContainer';
import Spinner from '../../Spinner/Spinner';
import { getFilters, getSubcategory } from '../../../api/common-api';
import Filter from './Filter';
import { Input } from 'antd';
import { toast } from 'react-toastify';
import * as api from '../../../api/common-api';
import * as S from './OfferListPage.styles';
import ProductModal from '../../Modals/ProductModal/ProductModal';

const OfferListPage = () => {
  const history = useHistory();

  const {
    location: { search },
  } = history;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const searchParams = new URLSearchParams(search);
  const searchPage = searchParams.get('page');
  const searchQuery = searchParams.get('query');
  const searchCategoryId = searchParams.get('categoryId');
  const searchOfferType = searchParams.get('offerType');
  const location = searchParams.get('location');
  const searchOrder = searchParams.get('order');

  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const limit = 12;
  const [page, setPage] = useState<number>(searchPage === null ? 1 : +searchPage);

  const [count, setCount] = useState(0);
  const [rows, setRows] = useState<any[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<any>();

  const handleOpenModal = (id: string) => {
    if (!id) {
      return null;
    }
    setModalProps(rows?.find((x) => x.id === id));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalProps(undefined);
    setIsModalOpen(false);
  };

  const [idToSetWatched, setIdToSetWatched] = useState('');

  const handleIdToSetWatched = (id: string) => {
    if (!id) {
      return null;
    }
    setIdToSetWatched(id);
  };

  useEffect(() => {
    searchParams.delete('offerType');
    if (searchOfferType) {
      searchParams.set('offerType', searchOfferType);
      setSelectedOfferType(searchOfferType);
    }
  }, [searchOfferType]);

  useEffect(() => {
    searchParams.delete('categoryId');
    if (searchCategoryId) {
      searchParams.set('categoryId', searchCategoryId);
      setCategory([searchCategoryId]);
    }
  }, [searchCategoryId]);

  const getSubcategoryData = async (searchCategoryId: string) => {
    if (!searchCategoryId) {
      return;
    }
    const splittedSearchCategoryId = searchCategoryId.split(',');
    const properCategory = splittedSearchCategoryId.pop();
    const { subCategories, backToCategory } = await getSubcategory(properCategory || '');
    setBackToCategory(backToCategory);
    setSubcategories(subCategories);
  };

  const getFiltersData = async (searchCategoryId: string) => {
    if (!searchCategoryId) {
      return;
    }
    const splittedSearchCategoryId = searchCategoryId.split(',');
    const properCategory = splittedSearchCategoryId.pop();
    const filtersData = await getFilters(properCategory || '');
    setFilters(filtersData);
  };

  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [backToCategory, setBackToCategory] = useState<any>();
  const [filters, setFilters] = useState<any[]>([]);

  const [displayGrid, setDisplayGrid] = useState(false);
  const [selectedCategory, setCategory] = useState<any>(searchCategoryId);
  const [priceMin, setPriceMin] = useState();
  const [priceMax, setPriceMax] = useState();
  const [sortByDate, setSortByDate] = useState(searchOrder === 'price' || !searchOrder);
  const [selectedOfferType, setSelectedOfferType] = useState(
    searchOfferType || OFFER_TYPE.ANNOUNCEMENT
  );

  useEffect(() => {
    if (selectedCategory) {
      const pickedId =
        typeof selectedCategory === 'string'
          ? selectedCategory
          : selectedCategory[selectedCategory.length - 1];
      getSubcategoryData(pickedId);
      getFiltersData(pickedId);
    }
  }, [selectedCategory]);

  const handleChangeSelectedOfferType = (offerType: OFFER_TYPE) => {
    setCategory('');
    setSelectedOfferType(offerType);
    setSubcategories([]);
    setFilters([]);
    setBackToCategory(undefined);
    searchParams.delete('categoryId');
    searchParams.delete('query');
    searchParams.set('offerType', offerType);
    history.push(`/search?${searchParams.toString()}`);
  };

  const isWide = useMediaQuery('(min-width: 1134px)');
  const isDesktop = useMediaQuery('(min-width: 853px)');
  const isTablet = useMediaQuery('(min-width: 800px)');
  const isMobile = useMediaQuery('(min-width: 572px)');

  const mockItems = () => {
    const itemsInRow = isWide ? 4 : isDesktop ? 3 : isMobile ? 2 : 1;
    const rowsLength = rows.length;
    if (rowsLength === 12) {
      return [];
    }
    const itemsInLastRow = rowsLength % itemsInRow;
    let mockItemsQuantity = 0;
    if (itemsInLastRow === 0) {
      return [];
    } else {
      mockItemsQuantity = itemsInRow - itemsInLastRow;
    }
    const items = [];
    for (let index = 0; index < mockItemsQuantity; index++) {
      items.push(<S.MockOffer key={index} aria-hidden={true} />);
    }
    return items;
  };

  const scrollToResults = () => {
    const element = document.getElementById('results');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToResults();
  }, [rows]);

  const handleChangeCategory = (value: string) => {
    if (!value) {
      searchParams.set('categoryId', `${value}`);
    } else {
      searchParams.delete('categoryId');
    }
    setCategory(value);
    history.push(`/search?${searchParams.toString()}`);
  };

  const handleChangePage = (value: number) => {
    if (value > 1) {
      searchParams.set('page', `${value}`);
    } else {
      searchParams.delete('page');
    }
    setPage(value);
    history.push(`/search?${searchParams.toString()}`);
  };

  const handleChangeOrder = (value: boolean) => {
    if (!value) {
      searchParams.set('order', 'price');
    } else {
      searchParams.delete('order');
    }
    setSortByDate(value);
    history.push(`/search?${searchParams.toString()}`);
  };

  useEffect(() => {
    handleChangePage(1);
  }, [searchQuery, searchCategoryId, searchOfferType, location]);

  const handleSearchCategory = (val: string[]) => {
    const lastArrayElement = val && val[val.length - 1];
    if (!lastArrayElement) {
      setCategory('');
      setSubcategories([]);
      setBackToCategory(undefined);
      searchParams.delete('categoryId');
      return history.push(`/search?${searchParams.toString()}`);
    }
    if (val) {
      searchParams.set('categoryId', lastArrayElement);
    } else {
      searchParams.delete('categoryId');
    }
    setCategory(val);
    history.push(`/search?${searchParams.toString()}`);
  };

  const searchPayload: IWatchSearchParams = {
    query: searchQuery ? searchQuery : undefined,
    categoryId: searchCategoryId ? searchCategoryId : selectedCategory || undefined,
    offerType: searchOfferType ? searchOfferType : selectedOfferType,
    location: location ? location : undefined,
  };

  const getOffers = async () => {
    setIsLoading(true);
    const res = await api
      .searchOffers({
        ...searchPayload,
        order: searchOrder === 'price' ? 'price' : 'created_at',
        limit,
        page: page - 1,
        priceMax,
        priceMin,
        filters: generateFilters(),
      })
      .catch(() => {
        toast.warn('Nie znaleziono ofert');
        setIsLoading(false);
      });
    if (!res) {
      return null;
    }
    const { rows, count } = res;
    setRows(rows);
    setCount(count);
    setIsLoading(false);
  };

  useEffect(() => {
    getOffers();
  }, [search, page, searchOfferType, searchCategoryId]);

  useEffect(() => {
    if (!isTablet) {
      setDisplayGrid(true);
    }
  }, [isTablet]);

  const searchQueryText = searchQuery ? (
    <S.QueryTextContainer>
      Wyszukiwana fraza: <S.QueryText>{searchQuery}</S.QueryText>
    </S.QueryTextContainer>
  ) : (
    ''
  );

  const generateFilters = () => {
    let filter: any = {};
    if (searchOptions.length > 0) {
      filter.dynamicOptions = searchOptions;
    }
    return filter;
  };

  const searchWithFilters = async () => {
    getOffers();
  };

  const renderFilters = () => {
    const filter = filters.map((f, index) => (
      <S.SingleInputContainer>
        <Filter {...f} filterData={searchOptions} setFilterData={setSearchOptions} key={index} />
      </S.SingleInputContainer>
    ));
    return filter;
  };

  return (
    <S.ExternalWrapper>
      <S.HeaderWrapper>
        <>
          <S.LeftOneImageContainer>
            <S.AbsoluteImage src={imgLeft1} />
          </S.LeftOneImageContainer>
          <S.LeftTwoImageContainer>
            <S.AbsoluteImage src={imgLeft2} />
          </S.LeftTwoImageContainer>
          <S.RightOneImageContainer>
            <S.AbsoluteImage src={imgRight1} />
          </S.RightOneImageContainer>
          <S.RightTwoImageContainer>
            <S.AbsoluteImage src={imgRight2} />
          </S.RightTwoImageContainer>
        </>
        <S.SearchHeaderWrapper>
          <S.SearchHeader>{searchQueryText}</S.SearchHeader>
        </S.SearchHeaderWrapper>
        <S.PurpleContainer />
        <S.WrapperContainer>
          <S.CategorySearchButtonsContainer>
            <S.CategoryButton
              isSelected={selectedOfferType === OFFER_TYPE.ANNOUNCEMENT}
              onClick={() => {
                handleChangeSelectedOfferType(OFFER_TYPE.ANNOUNCEMENT);
              }}
            >
              Zajęcia
            </S.CategoryButton>
            <S.CategoryButton
              isSelected={selectedOfferType === OFFER_TYPE.BAZAAR}
              onClick={() => handleChangeSelectedOfferType(OFFER_TYPE.BAZAAR)}
            >
              Bazarek
            </S.CategoryButton>
          </S.CategorySearchButtonsContainer>
          <S.SearchWrapper>
            <SearchFields onSetSelectedOfferType={selectedOfferType} />
            <div
              style={{
                fontSize: '12px',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              Brakuje na liście Twojego miasta? Dodaj ogłoszenie i pomóż rozwijać społeczność
              Malutcy.pl
            </div>
          </S.SearchWrapper>
        </S.WrapperContainer>
      </S.HeaderWrapper>
      <S.WaveContainer />
      <S.CategoriesWrapper style={{ paddingTop: '40px' }}>
        <CategoriesContainer
          setCategoryIds={handleSearchCategory}
          selected={[]}
          marginBottom="40px"
          selectedType={selectedOfferType || OFFER_TYPE.BAZAAR}
          onChangeType={handleChangeSelectedOfferType}
          selectedCategories={selectedCategory}
        />
      </S.CategoriesWrapper>
      <S.Wrapper id="results">
        <S.SubcategoriesWrapper>
          <S.SingleInputContainer>
            <S.LabelContainer>
              <S.StyledLabel>Cena od</S.StyledLabel>
            </S.LabelContainer>
            <Input
              placeholder={'Cena od'}
              min="0"
              step="1"
              type="number"
              onChange={(e: any) => setPriceMin(e.target.value)}
              allowClear
            />
          </S.SingleInputContainer>
          <S.SingleInputContainer>
            <S.LabelContainer>
              <S.StyledLabel>Cena do</S.StyledLabel>
            </S.LabelContainer>
            <Input
              placeholder={'Cena do'}
              min="0"
              step="1"
              type="number"
              onChange={(e: any) => setPriceMax(e.target.value)}
              allowClear
            />
          </S.SingleInputContainer>
          {renderFilters()}
        </S.SubcategoriesWrapper>
        <S.SearchButtonContainer>
          <S.SearchButton onClick={() => searchWithFilters()}>Szukaj</S.SearchButton>
        </S.SearchButtonContainer>
        {backToCategory && (
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <S.BackToSubcategory
              onClick={() => handleSearchCategory([backToCategory.id])}
              isSelected
            >
              <S.ArrowBack /> Powrót do {backToCategory.label}
            </S.BackToSubcategory>
          </div>
        )}
        <S.SubcategoriesWrapper>
          {subcategories.map((sc: any) => (
            <S.SubcategoryContainer
              onClick={() => handleSearchCategory([sc.id])}
              isSelected={selectedCategory.includes(sc.id)}
            >
              {sc.label}
            </S.SubcategoryContainer>
          ))}
        </S.SubcategoriesWrapper>
        <S.ResultsHeaderWrapper>
          <S.BreadCrumbWrapper>
            <S.BreadCrumbHead onClick={() => handleChangeCategory('')}>
              Strona główna
            </S.BreadCrumbHead>
          </S.BreadCrumbWrapper>
          <DisplayOptions
            displayGrid={displayGrid}
            setDisplayGrid={setDisplayGrid}
            sortByDate={sortByDate}
            setSortByDate={handleChangeOrder}
          />
        </S.ResultsHeaderWrapper>
        <S.ResultsCount>
          Znaleziono <strong>{count}</strong> ogłoszeń
        </S.ResultsCount>
        {isLoading ? (
          <S.SpinnerContainer>
            <Spinner />
          </S.SpinnerContainer>
        ) : (
          <>
            <S.ResultsWrapper>
              {rows.map((offer) =>
                displayGrid ? (
                  <OfferThumbnail
                    key={offer.id}
                    {...offer}
                    handleOpenModal={(id: string) => handleOpenModal(id)}
                    handleSetWatched={idToSetWatched}
                  />
                ) : (
                  <OfferRow key={offer.id} {...offer} />
                )
              )}
              {displayGrid && mockItems()}
            </S.ResultsWrapper>
            <Pagination limit={limit} page={page} resultsCount={count} setPage={handleChangePage} />
          </>
        )}
      </S.Wrapper>
      <div
        style={{
          width: '100%',
          height: '40px',
          position: 'absolute',
          right: '0',
          bottom: '-20px',
          backgroundColor: '#fafafa',
        }}
      />
      {isModalOpen && (
        <ProductModal
          {...modalProps}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
        />
      )}
    </S.ExternalWrapper>
  );
};

export default OfferListPage;

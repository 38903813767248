import styled from 'styled-components';
import SVG from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  width: 165px;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  gap: 16px;
`;

export const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 20px;
`;

export const Icon = styled.img.attrs({ src: SVG.phone, alt: 'phone' })`
  width: 14px;
  height: 20px;
`;

export const PhoneNumber = styled.div`
  color: ${themes.default.fontColorBlack};
  font-size: 16px;
  margin: 0 10px;
  vertical-align: text-top;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  background-color: ${themes.default.lavender};
  color: ${themes.default.white};
  box-sizing: border-box;
  width: 47px;
  height: 19px;
  font-size: 9px;
  text-transform: uppercase;
  border: 1px solid ${themes.default.lavender};
`;

export const OnlineDot = styled.div<{ isOnline: boolean }>`
  height: 12px;
  width: 12px;
  background-color: ${({ isOnline }) =>
    isOnline ? themes.default.positive : themes.default.negative};
  border-radius: 100%;
  align-self: center;
  margin-right: 8px;
`;

export const OnlineStatus = styled.div<{ isOnline: boolean }>`
  color: ${({ isOnline }) => (isOnline ? 'rgba(111, 207, 151, 1)' : themes.default.negative)};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
`;

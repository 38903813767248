import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 256px;
  height: 50px;
  background: ${themes.default.white};
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 57px;
  padding: 15px 21px;
  @media (max-width: 800px) {
    margin-bottom: 19px;
  }
`;

export const Currency = styled.div`
  font-family: Nunito;
  font-size: 16px;
  line-height: 150%;
  color: ${themes.default.graphemeGrey};
`;

export const PriceInput = styled.input`
  height: 100%;
  width: 80%;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: ${themes.default.fontColorBlack};
  :focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    color: ${themes.default.bdGrey};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  display: block;
  font-family: Nunito;
  font-size: 16px;
  line-height: 150%;
  color: ${themes.default.graphemeGrey};
`;

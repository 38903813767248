import rightImage from '../../../assets/User/RightImage.svg';
import leftImage from '../../../assets/User/LeftImage.svg';
import mapPin from '../../../assets/User/MapPin.svg';
import { USER_STATUS } from '../../../redux/auth/types';
import * as S from './UsersOffersHeader.styles';

interface IUserOffersHeader {
  avatar?: string;
  name?: string;
  localisation?: string;
  nickname?: string;
  isOnline?: boolean;
  isLoading?: boolean;
  status?: string;
}

const UserOffersHeader = (props: IUserOffersHeader) => {
  const {
    avatar = '',
    name = '',
    localisation = '',
    nickname = '',
    isOnline = false,
    isLoading = true,
    status,
  } = props;
  const isOnlineText = `Jest ${isOnline ? 'Online' : 'Offline'}`;
  const isAccountSuspended = status === USER_STATUS.DISABLED;

  const renderLastLogin = () => {
    if (isAccountSuspended) {
      return (
        <S.LastLogin>
          <S.SuspendedAccount>Konto zawieszone</S.SuspendedAccount>
        </S.LastLogin>
      );
    } else if (!isLoading) {
      return (
        <S.LastLogin>
          <S.OnlineDot isOnline={isOnline} />
          <S.OnlineStatus isOnline={isOnline}>{isOnlineText}</S.OnlineStatus>
        </S.LastLogin>
      );
    }
    return <></>;
  };

  return (
    <S.ExternalWrapper>
      <S.LeftImageContainer>
        <S.AbsoluteImage src={rightImage} />
      </S.LeftImageContainer>
      <S.Wrapper>
        <S.AvatarContainer>{avatar && <S.Avatar src={avatar} />}</S.AvatarContainer>
        <S.Container>
          <S.HeaderContainer>
            <S.Header>{nickname ? nickname : name}</S.Header>
            {localisation && (
              <S.LocationContainer>
                <S.LocationIcon src={mapPin} />
                <S.Location>{localisation}</S.Location>
              </S.LocationContainer>
            )}
          </S.HeaderContainer>
          {renderLastLogin()}
        </S.Container>
      </S.Wrapper>
      <S.WaveContainer />
      <S.RightImageContainer>
        <S.AbsoluteImage src={leftImage} />
      </S.RightImageContainer>
    </S.ExternalWrapper>
  );
};

export default UserOffersHeader;

import { memo, useEffect, useState } from 'react';
import FederatedButtons from './FederatedButtons/FederatedButtons';
import SubmitButton from './SubmitButton/SubmitButton';
import Header from './Header/Header';
import PageWrapper from './PageWrapper/PageWrapper';
import { register, setSignInErrorMessage } from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { signInError, useLoading } from '../../redux/auth/selectors';
import leftImage from '../../assets/Login/leftImg.svg';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';
import * as S from './RegisterPage.styles';

const RegisterPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRuleAccepted, setIsRuleAccepted] = useState(false);

  const handleRegister = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(register({ email, password }));
  };
  const errorMessage = useSelector(signInError);

  useEffect(() => {
    if (errorMessage) {
      dispatch(setSignInErrorMessage(''));
    }
  }, [password, email]);

  const isDisabled = !isRuleAccepted || email === '' || password === '';

  const isLoading = useSelector(useLoading);
  return (
    <PageWrapper>
      <S.LeftImgContainer>
        <S.AbsoluteImg src={leftImage} />
      </S.LeftImgContainer>
      <S.Wrapper onSubmit={handleRegister}>
        <S.OptionsWrapper>
          <Header link={'/login'} text={'Zaloguj się'} />
          <Header link={'/register'} text={'Rejestracja'} isSelected />
        </S.OptionsWrapper>
        <FederatedButtons />
        <Input
          required
          type="email"
          placeholder="login"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <Input.Password
          min={8}
          required
          type="password"
          placeholder="hasło"
          value={password}
          iconRender={(isVisible) =>
            isVisible ? (
              <EyeTwoTone rev={undefined} twoToneColor="rgba(75,65,196,1)" />
            ) : (
              <EyeInvisibleOutlined rev={undefined} />
            )
          }
          onChange={(e: any) => setPassword(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <S.DisclaimerWarning>
          Klikając przycisk zarejestruj się akceptuję
          <S.Blue to={'/regulations'}>Regulamin</S.Blue>
        </S.DisclaimerWarning>
        <S.CheckboxWrapper>
          <S.CheckboxBox
            type="button"
            isActive={isRuleAccepted}
            onClick={() => setIsRuleAccepted(!isRuleAccepted)}
          >
            {isRuleAccepted && <S.CheckIcon />}
          </S.CheckboxBox>
          <S.CheckboxText>
            Przyjmuję do wiadomości, że Malutcy.pl wykorzystuje moje dane osobowe zgodnie z Polityką
            prywatności oraz Polityką dotyczącą plików cookie i podobnych technologii. Malutcy.pl
            wykorzystują zautomatyzowane systemy i partnerów do analizowania, w jaki sposób
            korzystam z usług w celu zapewnienia odpowiedniej funkcjonalności produktu, treści,
            dostosowanych i opartych na zainteresowaniach reklam, jak również ochrony przed spamem,
            złośliwym oprogramowaniem i nieuprawnionym korzystaniem z&nbsp;naszych usług.
          </S.CheckboxText>
        </S.CheckboxWrapper>
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        <SubmitButton
          type="submit"
          disabled={isDisabled}
          text={'Zarejestruj się'}
          isLoading={isLoading}
        />
        <S.Disclaimer>
          Masz już swoje konto? <S.Blue to={'/login'}>Zaloguj się</S.Blue>
        </S.Disclaimer>
      </S.Wrapper>
    </PageWrapper>
  );
};

export default memo(RegisterPage);

import styled from 'styled-components';
import { FiArchive } from 'react-icons/fi';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { themes } from '../../../assets/CSS/colorThemes';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  background-color: ${themes.default.white};
  border: 1px solid ${themes.default.greyBackgroundColor};
  padding-bottom: 58px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* @media (min-width: 1024px) {
    max-height: calc(100vh - 260px);
  } */
`;

export const TopWrapper = styled.div`
  position: sticky;
  top: 75px;
  @media (min-width: 1024px) {
    position: initial;
  }
  min-height: 50px;
  background-color: ${themes.default.lightGrey};
  border-bottom: 1px solid ${themes.default.greyBackgroundColor};
  border-radius: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 33px;
  justify-content: space-between;
  @media (max-width: 800px) {
    padding: 0 21px;
  }
`;

export const BodyWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding: 16px 16px 0;
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.extraLightGrey};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.bdGrey};
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const ItemWrapper = styled(Link)`
  text-decoration: none;
  max-width: 645px;
  flex-direction: column;
  padding: 16px 16px 16px 0;
  @media (min-width: 550px) {
    flex-direction: row;
    height: 80px;
  }
  display: flex;
  border: 2px solid ${themes.default.lavender};
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
`;

export const ItemImage = styled.img`
  width: 105px;
  height: 58px;
  margin-right: 21px;
  object-fit: contain;
`;

export const DataWrapper = styled.div``;

export const ItemTitle = styled.h2`
  padding: 0;
  font-family: 'Mark Pro';
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1px;
  margin: 8px 0 6px;
  @media (min-width: 550px) {
    margin: 0 0 6px;
  }
  color: ${themes.default.reddishGrey};
`;

export const CalledPrice = styled.div`
  font-family: 'Mark Pro';
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: ${themes.default.reddishGrey};
`;

export const PlainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  :focus {
    outline: none;
  }
`;

export const ArchiveIcon = styled(FiArchive)`
  width: 21px;
  height: 21px;
  color: ${themes.default.main};
  margin-right: 2px;
`;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
  width: 21px;
  height: 21px;
  color: ${themes.default.main};
  margin-right: 2px;
`;

export const ButtonText = styled.div`
  font-family: 'Mark Pro';
  font-size: 13px;
  line-height: 138%;
  letter-spacing: 0.1px;
  color: ${themes.default.main};
`;

export const ButtonsWrapper = styled.div`
  @media (min-width: 520px) {
    display: flex;
  }
  margin: 15px 0;
`;

export const YesFinishButton = styled.button`
  background-color: transparent;
  box-sizing: border-box;
  border: 1.5px solid ${themes.default.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 19px;
  margin: 0 auto 12px;
  width: 100%;
  @media (min-width: 520px) {
    width: fit-content;
    margin: 0 13px 0 0;
  }
`;

export const FinishButtonText = styled.div`
  font-family: 'Mark Pro';
  font-weight: 450;
  font-size: 14px;
  line-height: 143%;
  color: ${themes.default.main};
`;

export const NoFinishButton = styled(YesFinishButton)`
  margin: 0 auto;
  @media (min-width: 520px) {
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  background-color: ${themes.default.extraLightGrey};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 18px;
  box-sizing: border-box;
  width: 100%;
`;

export const Input = styled.textarea`
  width: 100%;
  border: none;
  /* resize: none; */
  background: transparent;
  font-family: 'Mark Pro';
  font-weight: 450;
  font-size: 14px;
  line-height: 143%;
  color: #110f11;
  :placeholder {
    color: #999;
  }
  height: 100%;
  @media (min-width: 520px) {
    height: auto;
  }
  :focus {
    outline: none;
  }
`;

export const SendText = styled.div<{ isActive: boolean }>`
  font-family: 'Mark Pro';
  font-weight: bold;
  font-size: 16px;
  line-height: 125%;
  color: ${themes.default.main};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
`;

export const Message = styled.div`
  background: ${themes.default.lightGrey};
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 645px;
  height: auto;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  color: ${themes.default.magentaBlack};
  box-sizing: border-box;
  background-color: #dfe8fc;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 17px;
  }
`;

export const Li = styled.li`
  word-spacing: 4px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  color: ${themes.default.magentaBlack};
`;

export const A = styled.a`
  color: ${themes.default.magentaBlack};
  :visited {
    color: ${themes.default.magentaBlack};
  }
`;

export const Counter = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 164%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;
  color: ${themes.default.fontColorBlack};
  align-self: flex-end;
`;
import styled from 'styled-components';

interface IWrapperProps {
  isHovered: boolean;
  isHigher: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const InnerWrapper = styled.div<IWrapperProps>`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: ${({ isHovered }) => (isHovered ? '0' : '100%')};
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  height: ${({ isHovered, isHigher }) =>
    isHigher && isHovered ? '402px' : isHovered ? '256px' : '0'};
  transition: 0.5s ease;
  top: -1px;
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
  @media (min-width: 1600px) {
    height: ${({ isHovered, isHigher }) =>
      isHigher && isHovered ? '466px' : isHovered ? '308px' : '0'};
  }
`;

export const CategoriesWrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 1146px;
  /* gap: 8px; */
  gap: 5%;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 8px;
  @media (min-width: 1600px) {
    gap: 2%;
  }
`;

import styled from 'styled-components';
import { FiUser } from 'react-icons/fi';
import { Input } from 'antd';

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 30px;
  @media (max-width: 880px) {
    flex-wrap: wrap;
    padding-bottom: 24px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 179px;
  @media (max-width: 880px) {
    margin-bottom: 30px;
  }
`;

export const Avatar = styled.img`
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto 13px auto;
  object-fit: cover;
  object-position: center;
`;

export const PlainAvatar = styled(FiUser)`
  width: 120px;
  height: 120px;
  padding: 28px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto 13px auto;
  color: #bdbdbd;
`;

export const ChangePhoto = styled.button`
  border: none;
  background: transparent;
  :focus {
    outline: none;
  }
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  text-align: center;
  color: #373f41;
  margin: 0 auto;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 274px;
`;

export const StyledLabel = styled.label`
  color: #11244d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const RedStar = styled.span`
  color: red;
`;

export const StyledInput = styled(Input)`
  padding: 14px 20px;
`;

import * as S from './FacebookShare.styles';

// TODO Move to .env
// const URL_BASE = `https://bidbuy-fe.onrender.com`;

const FacebookShare = () => {
  // const openWindow = () => {
  //   if (window !== null && id) {
  //     window.open(
  //       `https://www.facebook.com/sharer/sharer.php?u=${`${currentUrl}`}`,
  //       '',
  //       'width=600,height=400'
  //     );
  //   }
  // };

  // const currentUrl = 'https://malutcy.pl/offer/3ab7d6bf-e53d-4acf-b775-2572d7172e4b';
  const currentUrl = window.location.href;

  return (
    // <S.Button onClick={shareFunc}>
    //   <S.FBIcon />
    // </S.Button>
    <S.ShareDiv
      className="fb-share-button"
      data-href={currentUrl}
      data-layout="button"
      data-size="small"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}%2F&amp;src=sdkpreparse`}
        className="fb-xfbml-parse-ignore"
      >
        <S.FBIcon />
      </a>
    </S.ShareDiv>
  );
};

export default FacebookShare;

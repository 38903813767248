import { useParams } from 'react-router';
import * as S from '../FacebookShare/FacebookShare.styles';

const MailShare = () => {
  const { id }: { id?: string } = useParams();
  const currentUrl = window.location.href;

  return (
    <a href={`mailto:?subject=Zobacz%20ofertę%20na%20Malutcy.pl&body=${currentUrl}`}>
      <S.MailIcon />
    </a>
  );
};

export default MailShare;

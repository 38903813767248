import { Select } from 'antd';
import { IInterval, IOption, selectSubscriptionOptions } from './PageOne';
import * as S from './PageOne.styles';

interface IIntervalRow extends IInterval {
  onDelete: (index: number) => void;
  onPriceUpdate: (price: number) => void;
  onOptionSelected: (option: IOption, itemid: string) => void;
  index: number;
  options: IOption[];
  readOnly?: boolean;
}

const IntervalRow = (props: IIntervalRow) => {
  const {
    id,
    commitmentForm,
    price,
    onDelete,
    index,
    onPriceUpdate,
    onOptionSelected,
    options,
    readOnly = false,
  } = props;

  const priceValidator = (event: any, setter: (val: number) => void) => {
    const value = event.target.value;
    const regex = /^[0-9]*[.]{0,1}[0-9]{0,2}$/g;
    const isValid = (regex.test(value) || 0) && Number(value) < 99999;
    if (isValid) {
      setter(value);
    }
  };
  const selectedOption = selectSubscriptionOptions.find((x) => x === commitmentForm);

  return (
    <S.RowContainer>
      <S.InputContainer>
        <S.RowLabel>Opcja</S.RowLabel>
        <Select
          title="commitmentForm"
          options={options}
          placeholder="Opcja"
          onChange={(e: any) => onOptionSelected(e, id)}
          value={selectedOption}
          style={{ minWidth: '160px', width: '100%' }}
        />
      </S.InputContainer>
      <S.InputContainer className="noArrowsNumberInput">
        <S.RowLabel>Cena</S.RowLabel>
        <S.RowInput
          type="number"
          name="price"
          value={price}
          defaultValue={price}
          onChange={(e: any) => priceValidator(e, onPriceUpdate)}
          max={999999}
          required
          min={0}
          step="0.01"
          readOnly={readOnly}
        />
      </S.InputContainer>
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.RowContainer>
  );
};

export default IntervalRow;

import styled from 'styled-components';
import U from '../../../assets/CSS/commonStyles';
import { themes } from '../../../assets/CSS/colorThemes';
import { Input } from 'antd';

export const ExternalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  background-color: rgba(233, 245, 245, 1);
  flex: 1;
  @media (min-height: 410px) {
    padding-top: 0;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 584px;
  width: 100%;
  gap: 24px;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface MessageProps {
  isWarning?: boolean;
}

export const Message = styled.button<MessageProps>`
  ${U.plainButton}
  font-family: Poppins;
  ${U.standardFont14}
  margin-left: 16px;
  color: ${(props) => (props.isWarning ? themes.default.negative : themes.default.positive)};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 274px;
`;

export const StyledLabel = styled.label`
  color: #11244d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const RedStar = styled.span`
  color: red;
`;

export const StyledInput = styled(Input)`
  padding: 14px 20px;
`;

import { useEffect, useState } from 'react';
import { IOffer, OFFER_TYPE } from '../../redux/offer/types';
import SearchSection from '../LandingSearch/SearchSection';
import Features from './Features/Features';
import OffersWrapper from './OffersWrapper/OffersWrapper';
import NewSection from './NewSection/NewSection';
import CategoriesContainer from '../Categories/CategoriesContainer/CategoriesContainer/CategoriesContainer';
import useMediaQuery from '../../hooks/useMediaQuery';
import * as api from '../../api/common-api';
import * as S from './LandingPage.styles';

const LandingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bazaarOffers, setBazaarOffers] = useState<IOffer[]>([]);
  const [announcementOffers, setAnnouncementOffers] = useState<IOffer[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(OFFER_TYPE.ANNOUNCEMENT);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { bazaarResults, announcementResults } = await api.getOffers();
      setBazaarOffers(bazaarResults);
      setAnnouncementOffers(announcementResults);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const isDesktop = useMediaQuery('(min-width: 880px)');

  return (
    <S.Wrapper>
      <SearchSection selectedType={selectedCategory} onChangeType={setSelectedCategory} />
      <div style={{ position: 'relative', top: '-20px' }}>
        <S.WaveContainer isRotated={false} />
        <S.CategoriesWrapper>
          <CategoriesContainer
            selected={[]}
            selectedType={selectedCategory}
            onChangeType={setSelectedCategory}
            showCategories={!isDesktop}
          />
        </S.CategoriesWrapper>
      </div>
      <OffersWrapper
        offers={announcementOffers}
        backgroundColor={'#fff'}
        header={'Najpopularniejsze zajęcia'}
        redirectURL={'/search?offerType=ANNOUNCEMENT'}
        isLoading={isLoading}
      />
      <OffersWrapper
        offers={bazaarOffers}
        backgroundColor={'#fff'}
        header={'Najnowsze na bazarku'}
        redirectURL={'/search?offerType=BAZAAR'}
        isLoading={isLoading}
        isDoubleRowed
      />
      <NewSection />
      <Features />
      <S.WaveContainer isRotated />
      <S.Disclaimer>
        Malutcy.pl to serwis z darmowymi ogłoszeniami. Znajdziesz tu zajęcia dla dzieci zgromadzone
        w wielu kategoriach, m.in. Klub Malucha, Zajęcia edukacyjne, Sportowe, Artystyczne,
        Taneczne, Integracja sensoryczna, Korepetycje. Przedmioty możesz kupować i sprzedawać na
        Bazarku, umieszczając je w jednej z wielu kategorii: Ciąża i macierzyństwo, Akcesoria
        dziecięce do karmienia, Kosmetyki i artykuły higieniczne dla dzieci, Ubrania dla dzieci,
        Zabawki, Sport i rekreacja, Książki i edukacja, Artykuły szkolne i plastyczne i wiele
        innych. Szybko znajdziesz tu ciekawe ogłoszenia i łatwo skontaktujesz się z ogłoszeniodawcą.
        Kupuj i sprzedawaj po sąsiedzku na Malutcy.pl.
      </S.Disclaimer>
    </S.Wrapper>
  );
};

export default LandingPage;

import Cookies from 'universal-cookie';

const cookieName = 'malutcy-cookie';

const cookieAge = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
}; ///one year

export const acceptCookie = () => {
  const cookies = new Cookies();
  cookies.set(cookieName, 'accepted', {
    expires: cookieAge(),
    path: '/',
  });
};

export const checkIfCookieIsAccepted = () => {
  const cookies = new Cookies();
  const cookieValue = cookies.get(cookieName);
  return cookieValue === 'accepted';
};

import { useRef, useState, ChangeEvent, MouseEvent } from 'react';
import _get from 'lodash/get';
import Miniature from './Miniature';
import { toast } from 'react-toastify';
import { useOfferContext } from '../../../context/offer/offerContext';
import * as S from './PhotosUpload.styles';

const resizeFile = (file: File) =>
  new Promise<File>((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const maxWidth = 1200;
      const maxHeight = 900;

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx!.fillStyle = 'white';
      ctx!.fillRect(0, 0, canvas.width, canvas.height);
      ctx!.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (blob) {
          resolve(new File([blob], file.name, { type: 'image/jpeg' }));
        } else {
          reject(new Error('Failed to resize the image'));
        }
      }, 'image/jpeg');
    };

    img.onerror = reject;
  });

const maxFileSize = 5000000;

const PhotosUpload = ({
  photos = [],
  setPhotos,
  required = false,
  isEdit = false,
  existingPhotoCount = 0,
  existingPhotos = [],
  handleSelectPhoto,
  selectedPhotos = [],
}: {
  photos: { order: number; file: any }[];
  setPhotos: (photos: any[]) => void;
  required?: boolean;
  isEdit?: boolean;
  existingPhotoCount?: number;
  existingPhotos?: any;
  handleSelectPhoto?: (id: string) => void;
  selectedPhotos?: any[];
}) => {
  const { tempOffer, setTempOffer } = useOfferContext();
  const inputRef = useRef(null);
  const singleInputRef = useRef(null);
  const [isWarning, setIsWarning] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.click();
    }
  };

  const maxAdditionFiles = isEdit ? 10 - existingPhotoCount : 10;

  const handleAddFiles = async (files: FileList) => {
    const resizedFilesArray = await Promise.all(Array.from(files).map(resizeFile));

    const filesList: { order: number; file: any }[] = resizedFilesArray
      .slice(0, maxAdditionFiles)
      .map((currentFile, currentIndex) => {
        if (currentFile.size <= maxFileSize) {
          return { order: currentIndex, file: currentFile };
        } else {
          setIsWarning(true);
          return null;
        }
      })
      .filter(Boolean) as { order: number; file: any }[];

    const reordered = [...filesList, ...photos].map(({ file }, index: number) => ({
      file,
      order: isEdit ? index + existingPhotoCount : index,
    }));

    const isSizeValid = reordered.every((file) => file.file.size <= maxFileSize);
    if (!isSizeValid) {
      return toast.success('Załączony plik jest za duży. Maksymalny rozmiar pliku to 5MB');
    }

    setPhotos(reordered);
    setTempOffer({ ...tempOffer, images: reordered });
  };

  const handleAddFileByIndex = async (files: File[]) => {
    const file = files[0];

    if (!file) return;

    if (file.size <= maxFileSize) {
      const resizedFile = await resizeFile(file);

      const photosBefore = photos.slice(0, selectedIndex);
      const photosAfter = photos.slice(selectedIndex + 1);

      setPhotos([...photosBefore, { file: resizedFile, order: selectedIndex }, ...photosAfter]);
      setTempOffer({
        ...tempOffer,
        images: [...photosBefore, { file: resizedFile, order: selectedIndex }, ...photosAfter],
      });
    } else {
      toast.error('Zdjęcie przekracza maksymalny rozmiar 5MB');
    }
  };

  const handleSetSelectedPhoto = (e: MouseEvent, index: number) => {
    if (photos.length === 0) {
      return handleOnClick(e);
    }
    setSelectedIndex(index);
    if (singleInputRef.current) {
      // @ts-ignore
      singleInputRef.current.click();
    }
  };

  const handleRemove = (e: MouseEvent, index: number) => {
    e.preventDefault();
    const reducer = (acc: { order: number; file: any }[], value: { order: number; file: any }) => {
      const { order } = value;
      if (order === index) {
        return acc;
      }
      if (order > index) {
        return [...acc, { ...value, order: order - 1 }];
      }
      return [...acc, value];
    };
    const newPhotos = photos.reduce(reducer, []);
    setPhotos(newPhotos);
    setTempOffer({ ...tempOffer, images: newPhotos });
  };

  const displayMiniatures = () => {
    let miniatures: JSX.Element[] = [];
    const existingPhotosWithoutFirstPhoto = existingPhotos.slice(1);
    existingPhotosWithoutFirstPhoto.map((a: any, index: number) =>
      miniatures.push(
        <Miniature
          photo={{ file: {}, name: '' }}
          onClick={() => {}}
          key={`photo_${index}`}
          remove={(e: MouseEvent) => {
            e.preventDefault();
            handleSelectPhoto && handleSelectPhoto(a.id);
          }}
          src={a.href}
          isSelected={selectedPhotos.includes(a.id)}
        />
      )
    );
    for (let index = existingPhotos.length > 0 ? 0 : 1; index < maxAdditionFiles; index++) {
      const photo = _get(photos, `[${index}]`, undefined);
      if (photo) {
        miniatures.push(
          <Miniature
            photo={photo}
            remove={(e: MouseEvent) => handleRemove(e, index)}
            key={`photo_${index}`}
            onClick={(e) => handleSetSelectedPhoto(e, index)}
          />
        );
      } else {
        miniatures.push(
          <S.MiniPhoto
            type={'button'}
            onClick={(e) => handleSetSelectedPhoto(e, index)}
            key={`mockPhoto_${index}`}
          >
            <S.CameraIcon />
          </S.MiniPhoto>
        );
      }
    }
    return miniatures;
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const areFilesValid = Array.from(e.dataTransfer.files).every((file: any) => {
        const fileSizeKiloBytes = file.size / 1024;
        return fileSizeKiloBytes < maxFileSize;
      });

      if (!areFilesValid) {
        console.log('Załączony plik jest za duży. Maksymalny rozmiar pliku to 5MB');
        return toast.success('Załączony plik jest za duży. Maksymalny rozmiar pliku to 5MB');
      }

      handleAddFiles(e.dataTransfer.files);
    }
  };

  const isMainPhotoSelected = existingPhotos[0] && selectedPhotos.includes(existingPhotos[0].id);
  return (
    <S.Wrapper isEdit={isEdit}>
      {!isEdit && <S.Title>Zdjęcia</S.Title>}
      <S.Input
        required={required}
        ref={inputRef}
        onChange={(e: ChangeEvent<{ files: any }>) => handleAddFiles(e.target.files)}
        type="file"
        multiple
        accept="image/*"
      />
      <S.Input
        ref={singleInputRef}
        onChange={(e: ChangeEvent<{ files: any }>) => handleAddFileByIndex(e.target.files)}
        type="file"
        accept="image/*"
      />
      <S.BodyWrapper>
        <S.MainPhoto
          type={'button'}
          onClick={(e) =>
            existingPhotoCount > 0 ? null : photos.length === 0 && handleSetSelectedPhoto(e, 0)
          }
          onDragOver={(e: React.DragEvent) => e.preventDefault()}
          onDrop={onDrop}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {existingPhotoCount > 0 ? (
            <>
              <S.RemoveWrapper
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  handleSelectPhoto && handleSelectPhoto(existingPhotos[0].id);
                }}
                hover={isHover}
                isExistingPhoto={existingPhotos[0]}
              >
                <S.RemoveIcon />
              </S.RemoveWrapper>
              {!existingPhotos[0] && (
                <S.ChangeWrapper onClick={() => {}} hover={isHover}>
                  <S.ChangeIcon />
                </S.ChangeWrapper>
              )}
              <S.Thumbnail src={existingPhotos[0].href} alt="Main photo" />
              {isMainPhotoSelected && (
                <S.TrashContainer
                  onClick={() => handleSelectPhoto && handleSelectPhoto(existingPhotos[0].id)}
                >
                  <S.TrashIcon />
                </S.TrashContainer>
              )}
            </>
          ) : existingPhotoCount === 0 && photos[0] ? (
            <>
              <S.RemoveWrapper onClick={(e: MouseEvent) => handleRemove(e, 0)} hover={isHover}>
                <S.RemoveIcon />
              </S.RemoveWrapper>
              <S.ChangeWrapper
                onClick={(e: MouseEvent) => handleSetSelectedPhoto(e, 0)}
                hover={isHover}
              >
                <S.ChangeIcon />
              </S.ChangeWrapper>

              <S.Thumbnail src={URL.createObjectURL(photos[0].file)} alt="Main photo" />
            </>
          ) : (
            <S.SimpleButton>
              <S.CameraIcon />
              <S.Label>Dodaj zdjęcie</S.Label>
            </S.SimpleButton>
          )}
        </S.MainPhoto>
        <S.MiniaturesWrapper>{displayMiniatures()}</S.MiniaturesWrapper>
      </S.BodyWrapper>
      <S.Instructions isHighlighted={isWarning}>
        Możesz dodać do 10 zdjęć o wymiarach minimum 800x600 pikseli i wadze pliku nie większej niż
        5MB, w formacie JPG, PNG lub GIF. Pierwsza fotografia będzie wyświetlana jako zdjęcie
        główne.
      </S.Instructions>
    </S.Wrapper>
  );
};

export default PhotosUpload;

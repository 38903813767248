import { useState } from 'react';
import OfferContact from '../OfferContact/OfferContact';
import { useSelector } from 'react-redux';
import FacebookShare from '../../Partials/FacebookShare/FacebookShare';
import WhatsAppShare from '../../Partials/WhatsAppShare/WhatsAppShare';
import MailShare from '../../Partials/MailShare/MailShare';
import { userData } from '../../../redux/auth/selectors';
import formatNumber from '../../../utils/formatNumber';
import { USER_ROLE } from '../../../redux/auth/types';
import { FormatPrice } from '../../../utils/formatPrice';
import HeartIcon from '../../../assets/heartIcon.svg';
import HeartIconFull from '../../../assets/heartIconFull.svg';
import MailIcon from '../../../assets/mailIcon.svg';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as api from '../../../api/common-api';
import * as S from './OfferInfoPanel.style';
import Spinner from '../../Spinner/Spinner';

const OfferInfoPanel = ({
  isInModal = false,
  isWatched = false,
  props,
  handleIdSetWatched,
  handleCloseModal,
}: {
  isInModal?: boolean;
  isWatched?: boolean;
  props?: any;
  handleIdSetWatched?: any;
  handleCloseModal?: () => void;
}) => {
  const {
    id,
    price,
    offererPhone,
    user,
    threads = [],
    commitmentForm,
    subscriptionOptions = [],
    isOnline,
  } = props;
  const isThread = threads.length > 0;
  const threadId = isThread ? threads[0].id : '';
  const displayPhone = user ? user.phone : offererPhone;

  const { id: loggedInUserId, role } = useSelector(userData);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isOfferWatched, setIsOfferWatched] = useState(isWatched || false);

  const handleDelete = async () => {
    if (id) {
      await api.deleteOffer([id]);
      history.push('/');
    }
  };

  const isAdmin = role === USER_ROLE.ADMIN;

  const isOwnOffer = loggedInUserId === user?.id;

  const handleObserve = async () => {
    if (!id || isLoading) {
      return null;
    }
    setIsLoading(true);
    await api
      .toggleWatchedOffer(id)
      .then(({ isAdded }) => {
        toast.success(`Oferta ${isAdded ? 'dodana do' : 'usunięta z'} obserwowanych`);
        setIsOfferWatched(isAdded ? true : false);
        handleIdSetWatched(id);
      })
      .catch((e) => {
        console.log({ e });
        toast.info('Zaloguj się by dodać do obserwowanych');
        setIsLoading(false);
        history.push('/login');
      });
    setIsLoading(false);
  };

  const handleAccept = async () => {
    await api
      .acceptOffers([id])
      .then(() => toast.info('Oferta zaakceptowana'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    handleClose();
  };

  const mainButton = () => {
    if (isAdmin) {
      return (
        <S.StyledLink onClick={handleAccept} to={'/admin-dashboard/offers'}>
          <S.CheckIcon />
          Zaakceptuj
        </S.StyledLink>
      );
    }
    if (isOwnOffer) {
      return (
        <S.StyledLink onClick={handleClose} to={`/offer/${id}/edit`}>
          Edytuj ogłoszenie
        </S.StyledLink>
      );
    }
    return (
      <S.StyledHashButton onClick={handleClose} to={chatOrLogin} smooth>
        <S.StyledIcon src={MailIcon} /> {buttonLabel}
      </S.StyledHashButton>
    );
  };

  const secondaryButton = () => {
    if (isAdmin) {
      return (
        <S.RemoveButton onClick={handleDelete}>
          <S.Trash /> Usuń ogłoszenie
        </S.RemoveButton>
      );
    } else if (!isOwnOffer && !isAdmin) {
      return (
        <S.TemporaryButton isInverted={true} onClick={handleObserve}>
          {isLoading ? (
            <>
              <Spinner height={30} width={30} />
            </>
          ) : isOfferWatched ? (
            <>
              <S.StyledIcon src={HeartIconFull} /> Nie obserwuj
            </>
          ) : (
            <>
              <S.StyledIcon src={HeartIcon} /> Obserwuj
            </>
          )}
        </S.TemporaryButton>
      );
    }
    return;
  };

  const handleClose = () => isInModal && handleCloseModal && handleCloseModal();
  const newPrice = FormatPrice(price);
  const formattedPrice = formatNumber(newPrice);
  const buttonLabel = isThread ? 'Zobacz Chat' : 'Napisz Wiadomość';
  const chatLink = isThread
    ? `/messages/${threadId}`
    : isInModal
    ? `/offer/${id}#contact`
    : '#contact';
  const chatOrLogin = loggedInUserId ? chatLink : '/login';

  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  return (
    <S.Wrapper isInModal={isInModal}>
      <S.PriceWrapper>
        <S.PricePanel>{finalPrice}</S.PricePanel>
      </S.PriceWrapper>
      {mainButton()}
      {secondaryButton()}
      <S.ContactWrapper>
        <OfferContact phoneNumber={displayPhone} isOnline={isOnline} />
      </S.ContactWrapper>
      <S.ShareSpace>
        <S.SharePanel>
          Udostępnij
          <FacebookShare />
          <WhatsAppShare />
          <MailShare />
        </S.SharePanel>
      </S.ShareSpace>
    </S.Wrapper>
  );
};

export default OfferInfoPanel;

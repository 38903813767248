import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './DisplayOptions.styles';

const DisplayOptions = ({
  displayGrid,
  setDisplayGrid,
  sortByDate,
  setSortByDate,
}: {
  setDisplayGrid: (value: boolean) => void;
  displayGrid: boolean;
  sortByDate: boolean;
  setSortByDate: (value: boolean) => void;
}) => {
  const isTablet = useMediaQuery('(min-width: 800px');
  return (
    <S.SortOptionsWrapper>
      <S.Label>Sortuj:</S.Label>
      <S.SortByButton selected={sortByDate} onClick={() => setSortByDate(true)}>
        Najnowsze
      </S.SortByButton>
      <S.SortByButton selected={!sortByDate} onClick={() => setSortByDate(false)}>
        Najtańsze
      </S.SortByButton>
      {isTablet && (
        <>
          <S.DisplayListButton isSelected={!displayGrid} onClick={() => setDisplayGrid(false)}>
            <S.DisplayList />
          </S.DisplayListButton>
          <S.DisplayGridButton isSelected={displayGrid} onClick={() => setDisplayGrid(true)}>
            <S.DisplayGrid />
          </S.DisplayGridButton>
        </>
      )}
    </S.SortOptionsWrapper>
  );
};

export default DisplayOptions;

import { memo } from 'react';
import { Switch } from 'react-router-dom';
import AdminProtectedRoute from './utils/AdminProtectedRoute';
import Menu from './components/AdminDashboard/Menu';
import MobileMenu from './components/AdminDashboard/MobileMenu';
import NotAcceptedOffersPage from './components/AdminDashboard/NotAcceptedOffersPage/NotAcceptedOffersPage';
import OfferReportsPage from './components/AdminDashboard/OfferReportsPage/OfferReportsPage';
import UsersPage from './components/AdminDashboard/UsersPage/UsersPage';
import useMediaQuery from './hooks/useMediaQuery';
import SystemPage from './components/AdminDashboard/SystemPage/SystemPage';
import TagsPage from './components/AdminDashboard/TagsPage/TagsPage';
import * as S from './App.styles';

export default memo(
  ({
    isMenuOpen,
    setIsMenuOpen,
  }: {
    isMenuOpen: boolean;
    setIsMenuOpen: (value: boolean) => void;
  }) => {
    const isDesktop = useMediaQuery('(min-width: 880px)');
    return (
      <S.InnerWrapper>
        {isDesktop ? (
          <Menu />
        ) : (
          <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
        <S.BodyWrapper>
          <S.ExternalWrapper>
            <Switch>
              <AdminProtectedRoute
                path="/admin-dashboard/offers"
                exact
                component={NotAcceptedOffersPage}
              />
              <AdminProtectedRoute
                path="/admin-dashboard/reports"
                exact
                component={OfferReportsPage}
              />
              <AdminProtectedRoute path="/admin-dashboard/system" exact component={SystemPage} />
              <AdminProtectedRoute path="/admin-dashboard/users" exact component={UsersPage} />
              <AdminProtectedRoute path="/admin-dashboard/tags" exact component={TagsPage} />
            </Switch>
          </S.ExternalWrapper>
          <div
            style={{
              width: '100%',
              height: '40px',
              position: 'absolute',
              right: '0',
              bottom: '-20px',
              backgroundColor: '#fafafa',
            }}
          />
        </S.BodyWrapper>
      </S.InnerWrapper>
    );
  }
);

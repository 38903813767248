import { memo } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { updateUsersStatuses } from '../../../redux/auth/actions';
import { User, USER_ROLE, USER_STATUS } from '../../../redux/auth/types';
import { Select } from 'antd';
import * as S from './UserRow.styles';

const roles = [
  {
    value: USER_ROLE.ADMIN,
    label: 'Administrator',
  },
  {
    value: USER_ROLE.COMPANY,
    label: 'Firma',
  },
  {
    value: USER_ROLE.USER,
    label: 'Użytkownik',
  },
];

const statuses = [
  {
    value: USER_STATUS.ACTIVE,
    label: 'Aktywny',
  },
  {
    value: USER_STATUS.DISABLED,
    label: 'Zablokowany',
  },
  {
    value: USER_STATUS.NEW_USER,
    label: 'Nowy',
  },
  {
    value: USER_STATUS.NOT_VERIFIED,
    label: 'Nie zweryfikowany',
  },
];

export default memo(function UserRow({
  user: { id, email, name, surname, status, role, nickname },
  isFirst = false,
}: {
  user: User;
  isFirst?: boolean;
}) {
  const isDesktop = useMediaQuery('(min-width: 1024px');

  const dispatch = useDispatch();

  const handleUpdateRole = (value: USER_ROLE) =>
    dispatch(updateUsersStatuses({ userIds: [id], role: value }));

  const handleUpdateStatus = (value: USER_STATUS) =>
    dispatch(updateUsersStatuses({ userIds: [id], status: value }));

  const selectedStatus = statuses.find(({ value }) => value === status);
  const selectedRole = roles.find(({ value }) => value === role);

  return (
    <S.Wrapper isFirst={isFirst}>
      {isDesktop ? (
        <>
          <S.UserMail>{email}</S.UserMail>
          <S.UserName>{nickname}</S.UserName>
          <S.UserName>
            {name} {surname}
          </S.UserName>
        </>
      ) : (
        <S.Row>
          <S.UserMail>{email}</S.UserMail>
          <S.UserName>{nickname}</S.UserName>
          <S.UserName>
            {name} {surname}
          </S.UserName>
        </S.Row>
      )}
      <S.ActionsContainer>
        <Select
          options={statuses}
          placeholder="Status"
          value={selectedStatus ? selectedStatus.label : ''}
          onSelect={(e: any) => {
            handleUpdateStatus(e);
          }}
          popupMatchSelectWidth={false}
        />
        <Select
          options={roles}
          placeholder="Rola"
          value={selectedRole ? selectedRole.label : ''}
          onSelect={(e: any) => {
            handleUpdateRole(e);
          }}
          popupMatchSelectWidth={false}
        />
      </S.ActionsContainer>
    </S.Wrapper>
  );
});

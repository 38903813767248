import styled from 'styled-components';
import { MdCheck, MdClose } from 'react-icons/md';
import { themes } from '../../../assets/CSS/colorThemes';

export const CheckBox = styled.div<{ selected: boolean; isRed: boolean }>`
  width: 28px;
  min-width: 28px;
  height: 28px;
  background-color: ${({ selected, isRed }) =>
    selected ? (isRed ? themes.default.negative : themes.default.positive) : themes.default.white};
  transition: 300ms;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 22px auto 4px;
  box-sizing: border-box;
  cursor: pointer;
`;

const commonIcon = `
  width: 100%;
  height: 100%;
  color: ${themes.default.white};
`;

export const CheckIcon = styled(MdCheck)`
  ${commonIcon}
`;

export const RemoveIcon = styled(MdClose)`
  ${commonIcon}
`;

import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OFFER_TYPE } from '../../../../redux/offer/types';
import { addCategory } from '../../../../redux/auth/actions';
import { Button, Input, Select } from 'antd';
import { TreeSelect } from 'antd';
import Spinner from '../../../Spinner/Spinner';
import { loadingData } from '../../../../redux/auth/selectors';
import * as S from './CategoryTable.styles';

interface INewCategoryProps {
  allCategories: any[];
  allCatsWithChildren: any[];
  closeModal: () => void;
}

const categoryOfferTypeOptions: any[] = [
  {
    label: 'Bazarek',
    value: OFFER_TYPE.BAZAAR,
  },
  {
    label: 'Ogłoszenie',
    value: OFFER_TYPE.ANNOUNCEMENT,
  },
];

const NewCategoryForm = (props: INewCategoryProps) => {
  const { allCategories = [], allCatsWithChildren = [], closeModal } = props;

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [newCategoryName, setNewCategoryName] = useState('');
  const [selectedParent, setSelectedParent] = useState<any>(0);
  const [categoryOfferType, setCategoryOfferType] = useState(OFFER_TYPE.BAZAAR);
  const [file, setFile] = useState<File | any>();
  const { isLoading } = useSelector(loadingData);

  const selectedParentVal = allCategories.find(({ value }) => value === selectedParent) || {};

  const handleSaveCategory = () => {
    const data = {
      name: newCategoryName,
      parentId: selectedParentVal.value,
      categoryOfferType: categoryOfferType,
    };
    dispatch(addCategory(data, file));
    closeModal();
  };

  const buttonText = isLoading ? <Spinner /> : 'Dodaj';

  const isButtonDisabled = !newCategoryName || !categoryOfferType;

  return (
    <S.DynamicParameterWrapper>
      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={selectedParentVal}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="Wybierz aby dodać subkategorie"
        allowClear
        filterTreeNode={(search, item: any) => {
          return item?.label?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
        onChange={(val: any) => setSelectedParent(val)}
        treeData={allCatsWithChildren || []}
        fieldNames={{ value: 'id', children: 'categories' }}
      />
      <Input
        value={newCategoryName}
        onChange={(e: any) => setNewCategoryName(e.target.value)}
        addonBefore="Nazwa"
      />
      <Select
        options={categoryOfferTypeOptions}
        placeholder="Wybierz typ"
        onChange={(e: any) => setCategoryOfferType(e)}
        defaultValue={categoryOfferTypeOptions[0]}
      />
      <Input
        onChange={(e: any) => setFile(e.target.files[0])}
        type="file"
        accept="image/*"
        ref={inputRef}
      />
      <S.GapContainer>
        <Button onClick={() => handleSaveCategory()} disabled={isButtonDisabled}>
          {buttonText}
        </Button>
      </S.GapContainer>
    </S.DynamicParameterWrapper>
  );
};

export default NewCategoryForm;

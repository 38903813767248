import { useEffect, useState } from 'react';
import CustomCheckbox from '../../Partials/CustomCheckbox/CustomCheckbox';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { IOffer } from '../../../redux/offer/types';
import AdminOfferItem from './AdminOfferItem';
import AdminOfferItemMobile from './AdminOfferItemMobile';
import { toast } from 'react-toastify';
import * as S from '../../UserDashboard/OffersPage/OffersPage.styles';
import * as api from '../../../api/common-api';

const NotAcceptedOffersPage = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [notAcceptedOffers, setNotAcceptedOffers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isSideMenu = useMediaQuery('(min-width: 881px)');
  const isTablet = useMediaQuery('(min-width: 750px)');
  const isWideItem = isDesktop || (!isSideMenu && isTablet);

  const handleSelect = (id: string) => {
    if (selectedIds.includes(id)) {
      const filtered = selectedIds.filter((currentId) => currentId !== id);
      setSelectedIds(filtered);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const areSelectedAll = notAcceptedOffers.length === selectedIds.length;
  const handleSelectAll = () => {
    if (areSelectedAll) {
      return setSelectedIds([]);
    }
    const all = notAcceptedOffers.map(({ id }) => id);
    setSelectedIds(all);
  };

  const fetchNotAcceptedOffers = async () => {
    setIsLoading(true);
    const fetchedOffers = await api.getNotAcceptedOffers();
    setNotAcceptedOffers(fetchedOffers);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNotAcceptedOffers();
  }, []);

  const handleAccept = async () => {
    setIsLoading(true);
    await api
      .acceptOffers(selectedIds)
      .then(() => toast.info('Oferty zaakceptowane'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    await fetchNotAcceptedOffers();
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await api
      .deleteOffer(selectedIds)
      .then(() => toast.info('Oferty usunięte'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    await fetchNotAcceptedOffers();
    setIsLoading(false);
  };

  const notSelectedIds = selectedIds.length === 0;
  const isDisabled = notSelectedIds || isLoading;
  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.Header>Ogłoszenia niezaakceptowane</S.Header>
        <S.RemoveRow>
          {isTablet ? (
            <S.SelectAll onClick={handleSelectAll}>
              {areSelectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
            </S.SelectAll>
          ) : (
            <CustomCheckbox isSelected={areSelectedAll} handleSelect={handleSelectAll} />
          )}
          <S.AcceptAll onClick={handleAccept} disabled={isDisabled}>
            {isTablet ? 'Zaakceptuj zaznaczone' : <S.Check />}
          </S.AcceptAll>
          <S.RemoveButton onClick={handleDelete} disabled={isDisabled}>
            {isTablet ? 'Usuń zaznaczone' : <S.Trash />}
          </S.RemoveButton>
        </S.RemoveRow>

        {isWideItem && (
          <S.TableHeaderRow>
            <S.TableTitle>Tytuł</S.TableTitle>
            <S.TableDate>Data</S.TableDate>
            <S.TablePrice>Cena</S.TablePrice>
            <S.TableDisplays>Wyświetlanie</S.TableDisplays>
            <S.TableAdminFields>Akcje</S.TableAdminFields>
          </S.TableHeaderRow>
        )}
        {notAcceptedOffers.map((offer: IOffer, index: number) => {
          const { id } = offer;
          const isSelected = selectedIds.includes(id);
          return (
            <S.ResultRow key={id}>
              <CustomCheckbox isSelected={isSelected} handleSelect={() => handleSelect(id)} />
              {isWideItem && (
                <AdminOfferItem
                  isFirst={index === 1}
                  offer={offer}
                  onChange={fetchNotAcceptedOffers}
                />
              )}
              {!isWideItem && (
                <AdminOfferItemMobile
                  isFirst={index === 1}
                  offer={offer}
                  onChange={fetchNotAcceptedOffers}
                />
              )}
            </S.ResultRow>
          );
        })}
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default NotAcceptedOffersPage;

import styled from 'styled-components';
import SVG from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { MdCheck } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';

interface WrapperProps {
  isInModal: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  background-color: ${themes.default.white};
  max-width: 286px;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  padding: 28px 24px;
  margin-bottom: 34px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  @media (max-width: 1130px) {
    max-width: 100%;
  }
  ${(props) =>
    props.isInModal &&
    `
  box-shadow: none;
  margin-bottom: 16px;
  `}
`;

export const PricePanel = styled.div`
  font-size: 20px;
  color: ${themes.default.main};
  font-weight: 600;
`;

const commonButtonStyles = `
width: 100%;
max-width: 234px;
text-decoration: none;
height: 54px;
border: 2px solid ${themes.default.main};
box-sizing: border-box;
margin: 0px auto 16px auto;
font-weight: 500;
font-size: 15px;
padding: 12px 12px;
display: flex;
justify-content: center;
display: flex;
align-items: center;
text-align: center;
background-color: ${themes.default.main};
color: ${themes.default.white};
`;

export const StyledHashButton = styled(HashLink)`
  ${commonButtonStyles}
  border-radius:8px;
  &:hover {
    box-shadow: 0 0 4px ${themes.default.main};
  }
`;

export const StyledLink = styled(Link)`
  ${commonButtonStyles}
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  padding: 0 4px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const TemporaryButton = styled.button<{ isInverted: boolean }>`
  ${commonButtonStyles}
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
  ${(props: { isInverted: boolean }) =>
    props.isInverted
      ? `background-color: ${themes.default.white}; color: ${themes.default.main};`
      : `background-color: ${themes.default.main}; color:${themes.default.white};`}
`;

export const RemoveButton = styled.button`
  ${commonButtonStyles}
  background-color: ${themes.default.negative}; 
  border-color: ${themes.default.negative}; 
  color:${themes.default.white};
  border-radius: 8px;
  :disabled {
    filter: saturate(.4);
    cursor: default;
  }
`;

export const ShareSpace = styled.div`
  height: 41px;
  /* border-top: solid 1px ${themes.default.bdGrey}; */
  display: flex;
`;

export const SharePanel = styled.div`
  display: flex;
  color: rgba(130, 130, 130, 1);
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  gap: 6px;
`;

export const FBIcon = styled.img.attrs({ src: SVG.fbGreyIcon, alt: 'facebook' })`
  height: 20px;
  width: 20px;
  padding: 3px 7px;
`;

export const MailIcon = styled.img.attrs({ src: SVG.mailGreyIcon, alt: 'mail' })`
  height: 27px;
  width: 27px;
`;

export const ContactWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 16px;
`;

// const starCSS = `
//   width: 22px;
//   height: 22px;
//   padding-right: 8px;
//   color: ${themes.default.main};
// `;

export const CheckIcon = styled(MdCheck)`
  padding-right: 14px;
  width: 16px;
  height: 16px;
  color: ${themes.default.white};
`;

export const Trash = styled(IoMdTrash)`
  padding-right: 14px;
  width: 16px;
  height: 16px;
  color: ${themes.default.white};
`;

export const PriceWrapper = styled.div`
  display: flex;
  max-width: 342px;
  min-height: 24px;
  margin: 0 auto;
  padding-bottom: 16px;
  align-items: center;
`;

export const PriceForContainer = styled.span`
  color: rgba(130, 130, 130, 1);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
`;

export const StyledIcon = styled.img`
  min-width: 16px;
  width: 16px;
  height: 14px;
  margin-right: 12px;
`;

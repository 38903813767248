import { useState, KeyboardEvent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';
import ContactPageWrapper from '../../ContactComponents/ContactPageWrapper/ContactPageWrapper';
import * as S from './FAQSearch.styles';

const FAQSearch = () => {
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('query');

  const [localQuery, setLocalQuery] = useState<string>(query ? query : '');
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const handleSearch = () => history.push(`/faq?query=${localQuery}`);

  const handleOnKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <ContactPageWrapper title="FAQ">
      <S.MainWrapper>
        <S.SearchWrapper>
          <S.SearchIcon />
          <S.SearchInput
            placeholder="Wyszukaj"
            value={localQuery}
            onChange={(e: ChangeEvent<{ value: string }>) => setLocalQuery(e.target.value)}
            onKeyPress={handleOnKeyPress}
          />
        </S.SearchWrapper>
      </S.MainWrapper>
    </ContactPageWrapper>
  );
};

export default FAQSearch;

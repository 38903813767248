import { IOffer } from '../../../redux/offer/types';
import moment from 'moment';
import useMediaQuery from '../../../hooks/useMediaQuery';
import getCategory from '../../../utils/getCategory';
import Logo from '../../../assets/malutcyLogo.svg';
import { FormatPrice } from '../../../utils/formatPrice';
import { toast } from 'react-toastify';
import * as S from './WatchedOfferItem.styles';
import * as api from '../../../api/common-api';

const WatchedOfferItem = ({
  offer: { id, title, price, created_at, images, expiresAt, categories },
  isLast,
  handleRemove,
}: {
  offer: IOffer;
  isLast: boolean;
  handleRemove: (id: string) => void;
}) => {
  const createdDate = moment(created_at).format('DD.MM');
  const endDate = expiresAt && moment(expiresAt).format('DD.MM');
  const activeTimeLength = moment(created_at).from(moment(new Date()));
  const thumbnail = images[0] ? images[0].href : '';

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { label } = getCategory(categories);
  const finalPrice = FormatPrice(price);

  return (
    <S.Wrapper isLast={isLast}>
      <S.Body>
        {thumbnail ? (
          <S.Image src={thumbnail ? thumbnail : Logo} alt={`${title} photo`} />
        ) : (
          <S.MockImage />
        )}
        <S.DataWrapper>
          <S.TitleWrapper>
            <S.Title to={`/offer/${id}`}>{title}</S.Title>
            <S.GrayDataRow>{label}</S.GrayDataRow>
          </S.TitleWrapper>
          <S.DateWrapper>
            <S.HourCounter>{activeTimeLength}</S.HourCounter>
            <S.GrayDataRow>
              od {createdDate} {endDate && `do ${endDate}`}
            </S.GrayDataRow>
          </S.DateWrapper>
          <S.PriceWrapper>
            <S.PriceRow>{price ? `${finalPrice} zł` : 'darmowe'}</S.PriceRow>
            {isDesktop && (
              <S.DeleteWrapper>
                <S.PlainButton onClick={() => handleRemove(id)}>
                  <S.TrashIcon />
                  <S.ButtonText>Usuń z obserwowanych</S.ButtonText>
                </S.PlainButton>
              </S.DeleteWrapper>
            )}
          </S.PriceWrapper>
        </S.DataWrapper>
      </S.Body>
      {!isDesktop && (
        <S.DeleteWrapper>
          <S.PlainButton onClick={() => handleRemove(id)}>
            <S.TrashIcon />
            <S.ButtonText>Usuń z obserwowanych</S.ButtonText>
          </S.PlainButton>
        </S.DeleteWrapper>
      )}
    </S.Wrapper>
  );
};

export default WatchedOfferItem;

import MenuItem from '../MenuItem/MenuItem';
import { useHistory } from 'react-router-dom';
import { ButtonProps, buttons } from './Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useDashboardCount } from '../../../redux/auth/selectors';
import { logout } from '../../../redux/auth/actions';
import * as S from './Menu.styles';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const { messagesCount, watchedCount, offersCount } = useSelector(useDashboardCount);
  const count: { [key: string]: number } = {
    Wiadomości: messagesCount,
    Obserwowane: watchedCount,
    Płatności: 0,
    Ustawienia: 0,
    Ogłoszenia: offersCount,
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <S.Wrapper>
        <S.ButtonsWrapper>
          {buttons.map((button: ButtonProps) => {
            const { text, href } = button;
            return (
              <MenuItem key={text} {...button} isActive={pathname === href} count={count[text]} />
            );
          })}
          <S.LogoutButton onClick={handleLogout}>Wyloguj</S.LogoutButton>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </div>
  );
};

export default Menu;

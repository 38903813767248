import FAQSegment from '../FAQSegment/FAQSegment';
import { categories, IFaqCategory } from '../faqData';
import * as S from './SegmentsWrapper.styles';

const SegmentsWrapper = () => (
  <S.Wrapper>
    {categories.map((category: IFaqCategory) => (
      <FAQSegment key={category.id} {...category} />
    ))}
  </S.Wrapper>
);

export default SegmentsWrapper;

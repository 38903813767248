import { Thread } from '../../../redux/auth/types';
import offerAvatar from '../../../assets/offerAvatar.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { userData } from '../../../redux/auth/selectors';
import * as S from './MessageOverview.styles';

const MessageOverview = ({
  thread: { offer, sender, recipient, message, senderId, isRead, id: threadId },
  isFirst,
  isTrash = false,
  isSelected = false,
  handleSelect = () => {},
  handleRemoveOne,
}: {
  thread: Thread;
  isFirst: boolean;
  isTrash?: boolean;
  isSelected?: boolean;
  handleSelect?: () => void;
  handleRemoveOne?: () => void;
}) => {
  moment.locale('pl');
  const { id } = useSelector(userData);
  const { nickname, name, avatar } =
    senderId === id
      ? recipient
      : sender || { nickname: 'Niezalogowany użytkownik', name: '', surname: '', avatar: '' };
  const { created_at, body } = message; //[messages.length - 1];
  const { title } = offer;

  // const remove = async (e: FormEvent) => {
  //   e.preventDefault();
  //   handleRemoveOne && handleRemoveOne();
  //   history.push('/user-dashboard/messages/');
  // };
  return (
    <S.ExternalWrapper>
      {/* <S.CheckboxWrapper isActive={isTrash}>
        <CustomCheckbox isRed isSelected={isSelected} handleSelect={handleSelect} />
      </S.CheckboxWrapper> */}
      <S.Wrapper props={{ isFirst }} role={'article'} to={`/user-dashboard/messages/${threadId}`}>
        <S.UserWrapper>
          <S.Avatar src={avatar ? avatar : offerAvatar} alt="Users photo" />
          <S.UserDescriptionWrapper>
            <S.UserName>{nickname ? nickname : `${name}`}</S.UserName>
            <S.AddedAt>{moment(created_at).fromNow(true)}</S.AddedAt>
          </S.UserDescriptionWrapper>
        </S.UserWrapper>
        <S.BodyWrapper>
          <S.Row>
            {/* <S.Bullet isActive={!isRead} /> */}
            <S.Title>{title}</S.Title>
          </S.Row>
          <S.Message>{body}</S.Message>
        </S.BodyWrapper>
        {/* {isTrash && <S.RemoveButton onClick={remove}>Usuń całkowicie</S.RemoveButton>} */}
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default MessageOverview;

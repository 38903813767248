import { IBenefit } from './PageOne';
import * as S from './PageOne.styles';

interface IBenefitRow extends IBenefit {
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
  index: number;
  readOnly?: boolean;
}

const BenefitRow = (props: IBenefitRow) => {
  const { id, title, description, onDelete, index, onChange, readOnly = false } = props;

  return (
    <S.RowContainer>
      <S.InputContainer>
        <S.RowLabel>Tytuł</S.RowLabel>
        <S.RowInput
          name="title"
          value={title}
          defaultValue={title}
          onChange={(e: any) => onChange(e, id)}
          type="text"
          required
          title="Uzupełnij to pole"
          readOnly={readOnly}
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.RowLabel>Opis</S.RowLabel>
        <S.RowInput
          name="description"
          value={description}
          defaultValue={description}
          onChange={(e: any) => onChange(e, id)}
          type="text"
          required
          readOnly={readOnly}
        />
      </S.InputContainer>
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.RowContainer>
  );
};

export default BenefitRow;

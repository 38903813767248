import { FormEvent, memo, useEffect, useState } from 'react';
import { IOffer } from '../../../redux/offer/types';
import moment from 'moment';
import { history } from '../../../App';
import { useSelector } from 'react-redux';
import { userData } from '../../../redux/auth/selectors';
import 'moment/locale/pl';
import logo from '../../../assets/malutcyLogo.svg';
import formatNumber from '../../../utils/formatNumber';
import { FormatPrice } from '../../../utils/formatPrice';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import HeartIconFull from '../../../assets/heartIconFull.svg';
import HeartIcon from '../../../assets/heartIcon.svg';
import * as S from './OfferThumbnail.styles';
import * as api from '../../../api/common-api';
import { toast } from 'react-toastify';

interface IExtendedOfferThumbnail extends IOffer {
  handleOpenModal?: (id: string) => void;
  handleSetWatched?: string;
}

const OfferThumbnail = ({
  categories,
  title,
  description,
  price,
  images = [],
  created_at,
  id,
  commitmentForm,
  subscriptionOptions = [],
  offererEmail,
  city,
  isWatched,
  handleOpenModal,
  handleSetWatched,
}: IExtendedOfferThumbnail) => {
  const { email } = useSelector(userData);
  const isOwnOffer = offererEmail === email;
  moment.locale('pl');
  const date = moment(created_at).fromNow(true);
  const handleRedirect = (id: string) => {
    history.push(`/offer/${id}`);
  };

  const openPreview = () => handleOpenModal && handleOpenModal(id);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOfferWatched, setIsOfferWatched] = useState(isWatched || false);

  const mainPhoto = images && images.find(({ order }) => order === 0);
  const { label } = categories && categories.length > 0 ? categories[0] : { label: ' ' };

  const handleObserve = async (e: FormEvent, id: string) => {
    e.preventDefault();
    if (!id || isLoading) {
      return null;
    }
    setIsLoading(true);
    await api
      .toggleWatchedOffer(id)
      .then(({ isAdded }) => {
        toast.success(`Oferta ${isAdded ? 'dodana do' : 'usunięta z'} obserwowanych`);
        setIsOfferWatched(isAdded ? true : false);
      })
      .catch((e) => {
        console.log({ e });
        toast.info('Zaloguj się by dodać do obserwowanych');
        setIsLoading(false);
        history.push('/login');
      });
    setIsLoading(false);
  };

  const formattedPrice = formatNumber(FormatPrice(price));
  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  useEffect(() => {
    if (handleSetWatched === id) {
      setIsOfferWatched(!isOfferWatched);
    }
  }, [handleSetWatched]);

  return (
    <S.Wrapper>
      <S.ImageWrapper
        onMouseEnter={() => setIsPreviewOpen(true)}
        onMouseLeave={() => setIsPreviewOpen(false)}
      >
        {mainPhoto ? <S.Image src={mainPhoto.href} /> : <S.MockImage src={logo} />}
        {!isOwnOffer && (
          <S.StarWrapper onClick={(e: any) => handleObserve(e, id)}>
            {isOfferWatched ? (
              <S.StyledIcon src={HeartIconFull} />
            ) : (
              <S.StyledIcon src={HeartIcon} />
            )}
          </S.StarWrapper>
        )}
        <S.PreviewWrapper isOpen={isPreviewOpen}>
          <S.PreviewButton onClick={openPreview}>Podgląd</S.PreviewButton>
        </S.PreviewWrapper>
      </S.ImageWrapper>
      <S.DataWrapper onClick={() => handleRedirect(id)}>
        <S.Row>
          <S.Category>{label}</S.Category>
          <S.LocalizationWrapper>
            <S.LocationIcon />
            <S.City>{city}</S.City>
          </S.LocalizationWrapper>
        </S.Row>
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
        </S.TitleContainer>
        <S.DescriptionContainer>
          <S.Description>{description}</S.Description>
        </S.DescriptionContainer>
        <S.PriceAndDateContainer>
          <S.Price>{finalPrice}</S.Price>
          <S.Date>{date} temu</S.Date>
        </S.PriceAndDateContainer>
      </S.DataWrapper>
    </S.Wrapper>
  );
};

export default memo(OfferThumbnail);

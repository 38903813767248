import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import { themes } from '../../../assets/CSS/colorThemes';
import U from '../../../assets/CSS/commonStyles';
import Picture from '../../../assets/Panel/Group.svg';

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  background-color: #e9f5f5;
  position: relative;
  flex-direction: column;
  height: 329px;
  width: 100%;
  margin: 0 auto 24px;
  z-index: 1;
  justify-content: space-between;
  @media (min-width: 800px) {
    flex-direction: row;
    height: 144px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;

export const TextWrapper = styled.div`
  margin: 0 auto;
  z-index: 2;
  max-width: 250px;
  padding-top: 52px;
  @media (min-width: 800px) {
    margin-left: 90px;
    max-width: none;
    padding-top: 0;
  }
`;

export const Header = styled.h1`
  margin: 0 0 12px 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: ${themes.default.main};
  text-align: center;
  @media (min-width: 800px) {
    text-align: left;
  }
`;

export const CustomText = styled.div`
  font-family: Poppins;
  font-size: 16px;
  line-height: 137%;
  color: #373f41;
  text-align: center;
  @media (min-width: 800px) {
    text-align: left;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  position: relative;
  height: 188px;
  width: 100%;
  @media (min-width: 800px) {
    max-width: 262px;
  }
`;

export const Art = styled.img.attrs({ src: Picture, alt: 'user dashboard art' })`
  height: 100%;
  position: absolute;
  bottom: -4px;
  width: 100%;
  object-fit: contain;
  object-position: center;
  @media (min-width: 800px) {
    width: 208px;
    margin: 0;
  }
`;

export const PlainButton = styled.button`
  ${U.plainButton}
  z-index: 1;
  position: absolute;
  padding: 8px;
  top: 0;
  right: 4px;
  margin: 14px 14px auto -28px;
  text-align: center;
  @media (min-width: 800px) {
    margin: 14px 12px auto 0;
  }
`;

export const CloseIcon = styled(GrClose)`
  color: #4b41c4;
  width: 16px;
  height: 16px;
`;

export const AbsoluteImage = styled.img``;

export const LeftOneImageContainer = styled.div`
  position: absolute;
  z-index: 0;
  transform: rotate(24deg);
  top: -22px;
  left: -80px;
`;

export const LeftTwoImageContainer = styled.div`
  position: absolute;
  /* top: 0; */
  left: 0;
  z-index: 0;
  top: -40%;
  @media (min-width: 768px) {
    top: -95%;
  }
`;

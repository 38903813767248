import styled from 'styled-components';

export const Wrapper = styled.div``;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1107px;
  width: 100%;
  margin: 0 auto;
  padding: 43px 0 0;
  justify-content: space-between;
`;

export const BodyWrapper = styled.div`
  max-width: 774px;
`;

export const ArticleWrapper = styled.article`
  max-width: 721px;
  margin-bottom: 50px;
  @media (max-width: 800px) {
    margin-top: 70px;
  }
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 109%;
  color: #373f41;
  margin-bottom: 32px;
`;

export const ArticleText = styled.div`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  color: #373f41;
  white-space: pre-line;
`;

export const Aside = styled.aside`
  max-width: 270px;
  margin-bottom: 50px;
`;

import { IoMdCheckmark } from 'react-icons/io';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from '../../assets/CSS/colorThemes';
import U from '../../assets/CSS/commonStyles';

export const Wrapper = styled.form`
  max-width: 508px;
  width: 100%;
  position: relative;
  background: ${themes.default.white};
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  padding: 34px 65px;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 2;
  @media (max-width: 800px) {
    margin: 0 auto;
    padding: 34px 15px;
  }
`;

export const Disclaimer = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 107%;
  text-align: center;
  color: ${themes.default.magentaBlack};
`;

export const Disclaimer2 = styled(Disclaimer)`
  margin-top: 20px;
`;

export const Blue = styled(Link)`
  color: ${themes.default.main};
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin: 0 4px;
`;

export const DisclaimerWarning = styled.div`
  font-family: Poppins;
  font-size: 13px;
  line-height: 115%;
  text-align: center;
  color: ${themes.default.magentaBlack};
  margin-bottom: 28px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-bottom: 23px;
`;

export const CheckboxBox = styled.button<{ isActive: boolean }>`
  :focus {
    outline: none;
  }
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 28px;
  height: 28px;
  margin-right: 10px;
  cursor: pointer;
  ${U.centeredFlex}
  padding: 0;
  background-color: ${(props) => (props.isActive ? themes.default.positive : 'transparent')};
`;

export const CheckIcon = styled(IoMdCheckmark)`
  height: 30px;
  width: 30px;
  color: ${themes.default.white};
`;

export const CheckboxText = styled.div`
  font-family: Poppins;
  font-size: 10px;
  line-height: 130%;
  color: #828282;
  text-align: justify;
`;

export const OptionsWrapper = styled.div`
  ${U.standardFlex}
  justify-content: space-evenly;
  margin-bottom: 33px;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  ${U.standardFont13}
  font-family: Poppins;
  width: 100%;
  text-align: center;
  margin: 16px 0;
  height: 21px;
  color: ${themes.default.negative};
`;

export const Header = styled.h6`
  font-family: Poppins;
  font-weight: 600;
  font-size: 19px;
  line-height: 211%;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${themes.default.graphemeGrey};
  margin: 0 auto;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  font-family: Nunito;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${themes.default.graphemeGrey};
  height: 104px;
`;

export const AbsoluteImg = styled.img``;

export const LeftImgContainer = styled.div`
  position: absolute;
  top: calc(50% - 50px);
  z-index: 0;
  left: 140px;
`;

export const ExternalWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 48px;
  background-color: rgba(233, 245, 245, 1);
  flex: 1;
  @media (min-height: 410px) {
    padding-top: 0;
  }
`;

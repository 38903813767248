import { IOffer, OFFER_STATUS } from '../../../redux/offer/types';
import formatNumber from '../../../utils/formatNumber';
import Logo from '../../../assets/malutcyLogo.svg';
import moment from 'moment';
import { FormatPrice } from '../../../utils/formatPrice';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import { Button, Input, Modal, Radio, Space } from 'antd';
import { finishReasonOptions } from '../OfferItem/OfferItem';
import { toast } from 'react-toastify';
import * as api from '../../../api/common-api';
import * as S from './OfferItemMobile.style';

const OfferItemMobile = ({
  offer,
  isFirst,
  refreshOffers,
}: {
  offer: IOffer;
  isFirst: boolean;
  refreshOffers: () => void;
}) => {
  const {
    title,
    created_at,
    price,
    offererPhone,
    offererEmail,
    expiresAt,
    status,
    id,
    isAdminAccepted,
    subscriptionOptions = [],
    commitmentForm,
    viewCounter,
    watchedCount,
  } = offer;
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [closingReason, setClosingReason] = useState(finishReasonOptions[0].value);
  const [otherClosingReason, setOtherClosingReason] = useState('');
  const dateFrom = moment(created_at).format('DD.MM');
  const dateTo = moment(expiresAt).format('DD.MM');
  const newPrice = FormatPrice(price);
  const formattedPrice = formatNumber(newPrice);
  const image = offer?.images?.find(({ order }) => order === 0);
  const isActive = status === OFFER_STATUS.ACTIVE;
  const isAwaiting = !isAdminAccepted;
  const isArchived = status === OFFER_STATUS.ARCHIVED;
  const otherReasonValue = finishReasonOptions[3].value;
  const closingReasonText =
    closingReason != otherReasonValue
      ? finishReasonOptions[closingReason].label
      : otherClosingReason;
  const modalTitle = isActive
    ? 'Czy na pewno chcesz zakończyć ogłoszenie?'
    : isArchived
    ? 'Czy chcesz przywrócić ogłoszenie z archiwum?'
    : 'Czy na pewno chcesz przenieść ogłoszenie do archiwum?';

  const handleTerminate = async () => {
    if (isActive) {
      await api.terminateOffer(id, closingReasonText);
    } else {
      await api.archiveOffer({ isArchive: !isArchived, offerId: id });
    }
    setIsModalOpen(false);
    refreshOffers();
  };

  const handleRenew = async () => {
    await api.renewOffer(id);
    setIsModalOpen(false);
    refreshOffers();
  };

  const handleEdit = (id: string) => {
    history.push(`/offer/${id}/edit`);
  };

  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  const handleDeleteOffer = async () => {
    await api
      .userDeleteOffer(id)
      .then(() => toast.info('Oferta usunięta'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    setIsDeleteModalOpen(false);
    refreshOffers();
  };

  return (
    <S.Wrapper isFirst={isFirst}>
      <S.MobileBody>
        <S.MobileColumn>
          {image ? (
            <S.Image src={image.href} alt={`${title} image`} />
          ) : (
            <S.Image src={Logo} alt={`${title} image`} />
          )}
          {!isActive && !isAwaiting && !isArchived && (
            <Button
              onClick={(e: any) => !isActive && handleRenew()}
              type="primary"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: 96,
                height: 26,
                borderRadius: 8,
              }}
            >
              Wznów
            </Button>
          )}
        </S.MobileColumn>
        <S.DataWrapper>
          <S.Title to={`/offer/${id}`}>{title}</S.Title>
          <S.DateWrapper>
            <S.DataRow>od {dateFrom}</S.DataRow>
            <S.DataRow>do {dateTo}</S.DataRow>
          </S.DateWrapper>
          <S.PriceWrapper>
            <S.DataRow>{finalPrice}</S.DataRow>
          </S.PriceWrapper>
          <S.DisplaysWrapper>
            <S.Section>
              <S.MobileIcon />
              <S.GrayDataRow>
                <S.Label>tel:</S.Label> <S.Value>{offererPhone}</S.Value>
              </S.GrayDataRow>
            </S.Section>
            <S.Section>
              <S.EnvelopeIcon />
              <S.GrayDataRow>
                <S.Label>e-mail:</S.Label> <S.Value>{offererEmail}</S.Value>
              </S.GrayDataRow>
            </S.Section>
          </S.DisplaysWrapper>
        </S.DataWrapper>
      </S.MobileBody>
      <S.WatchersWrapper>
        <S.DisplayedContainer>
          <S.Section>
            <S.StarIcon />
            <S.WatchersCounter>{watchedCount}</S.WatchersCounter>
          </S.Section>
          <S.Section>
            <S.EyeIcon />
            <S.WatchersCounter>{viewCounter}</S.WatchersCounter>
          </S.Section>
        </S.DisplayedContainer>
        <S.Section>
          <S.PlainButton>
            <S.EditIcon />
            <S.ButtonText onClick={() => handleEdit(id)}>Edycja</S.ButtonText>
          </S.PlainButton>
          <S.TerminateButton onClick={() => setIsModalOpen(true)}>
            <S.FinishIcon />
            <S.ButtonText>
              {isActive ? 'Zakończ' : isArchived ? 'Przywróć' : 'Archiwizuj'}
            </S.ButtonText>
          </S.TerminateButton>
          {isArchived && (
            <S.PlainButton onClick={() => setIsDeleteModalOpen(true)}>
              <S.DeleteButtonText>Usuń</S.DeleteButtonText>
            </S.PlainButton>
          )}
        </S.Section>
      </S.WatchersWrapper>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        centered
        okText={'Potwierdź'}
        cancelText={'Anuluj'}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => (isArchived ? handleRenew() : handleTerminate())}
      >
        {isActive && (
          <Radio.Group value={closingReason} style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              {finishReasonOptions.map(({ value, label }) => (
                <S.RadioWrapper>
                  <Radio
                    style={{ minWidth: 116 }}
                    key={value}
                    value={value}
                    onClick={() => setClosingReason(value)}
                  >
                    {label}
                  </Radio>
                  {closingReason === otherReasonValue && value === 3 && (
                    <Input
                      style={{ marginLeft: 10 }}
                      maxLength={500}
                      onChange={(e: any) => setOtherClosingReason(e.target.value)}
                    />
                  )}
                </S.RadioWrapper>
              ))}
            </Space>
          </Radio.Group>
        )}
      </Modal>
      <Modal
        title={'Na pewno usunąć ofertę?'}
        open={isDeleteModalOpen}
        centered
        okText={'Usuń'}
        cancelText={'Anuluj'}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={() => handleDeleteOffer()}
      />
    </S.Wrapper>
  );
};

export default OfferItemMobile;

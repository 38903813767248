import { Thread } from '../auth/types';
import { ICategory } from '../utils/types';

export interface IImage {
  id?: string;
  order: number;
  href: string;
  isDeleted?: boolean;
}

export enum OFFER_STATUS {
  ACTIVE = 'ACTIVE',
  AWAITING = 'AWAITING',
  TERMINATED = 'TERMINATED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
  REMOVED = 'REMOVED',
}

export enum OFFER_TYPE {
  BAZAAR = 'BAZAAR',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export enum COMMITMENT_FORM {
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface IOffer {
  id: string;
  title: string;
  description: string;
  price: number;
  giveaway: boolean;
  images: IImage[];
  offererName: string;
  offererEmail: string;
  offererPhone: string;
  offererLocalization: string;
  promoteOptionId: string;
  categories: ICategory[];
  user: any;
  userId?: string;
  date?: any;
  src?: string;
  created_at?: string;
  expiresAt?: string;
  watched: any[];
  watchedCount: number;
  threads?: Thread[];
  offerLat: number;
  offerLon: number;
  isAdminAccepted: boolean;
  status: OFFER_STATUS;
  offerType?: OFFER_TYPE;
  ageFrom?: number;
  ageTo?: number;
  priceListSrc?: string;
  city?: string;
  zipCode?: string;
  street?: string;
  streetNumber?: string;
  isBrandNew?: boolean;
  commitmentForm?: COMMITMENT_FORM;
  faqs: any[];
  benefits: any[];
  subscriptionOptions: any[];
  dynamicOptions?: any[];
  viewCounter?: number;
  geo_lat: number;
  geo_lng: number;
  isWatched?: boolean;
  count?: string;
}

export const emptyOffer: IOffer = {
  id: '',
  title: '',
  description: '',
  price: 0,
  giveaway: false,
  offererName: '',
  offererEmail: '',
  offererPhone: '',
  offererLocalization: '',
  images: [],
  promoteOptionId: '',
  user: {},
  watched: [],
  watchedCount: 0,
  offerLat: 0,
  offerLon: 0,
  isAdminAccepted: false,
  status: OFFER_STATUS.AWAITING,
  categories: [],
  faqs: [],
  benefits: [],
  subscriptionOptions: [],
  viewCounter: 0,
  geo_lat: 0,
  geo_lng: 0,
};

export interface WatchedOffer {
  offer: IOffer;
  createdAt: Date;
  id: string;
}

export interface IWatchSearchParams {
  query?: string;
  categoryId?: any;
  userId?: string;
  budgetMin?: string;
  budgetMax?: string;
  city?: string;
  distance?: number;
  offerType: string;
  location?: string;
}

export interface IWatchedSearch extends IWatchSearchParams {
  id: string;
  category?: ICategory;
  lastSeen: string;
  user?: { name: string; nickname: string };
  createdAt?: string;
  updatedAt?: string;
  count?: number;
}

export interface ISearchParams extends IWatchSearchParams {
  page: number;
  limit: number;
  filters?: any;
  order: 'created_at' | 'price';
  priceMin?: any;
  priceMax?: any;
}

import { useEffect, useState } from 'react';
import { getAdminCategories } from '../../../api/common-api';
import CategoryTable from './CategoryTable/CategoryTable';
import * as S from './SystemPage.styles';

const SystemPage = () => {
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [allCategoriesWithChildren, setAllCategoriesWithChildren] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchCategories = async () => {
    const cats = await getAdminCategories();
    const { categories, getAllCategories, tableData } = cats;
    setAllCategoriesWithChildren(categories);
    setTableData(tableData);
    const catsOptions = getAllCategories.map((cat: any) => ({
      value: cat.id,
      label: cat.label,
    }));
    setAllCategories(catsOptions);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <S.Wrapper>
      <S.TableContainer>
        <CategoryTable
          data={tableData || []}
          allCategories={allCategories}
          allCatsWithChildren={allCategoriesWithChildren}
          fetchData={() => fetchCategories()}
        />
      </S.TableContainer>
    </S.Wrapper>
  );
};

export default SystemPage;

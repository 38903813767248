import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { MdLocalOffer, MdSearch } from 'react-icons/md';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: 893px;
  justify-content: space-between;
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
  width: 100%;
  @media (min-width: 881px) {
    width: fit-content;
  }
  @media (min-width: 1007px) {
    margin: 0;
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  padding: 0;
  margin: 0 0 8px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #110f11;
`;

export const SubHeader = styled.h2`
  padding: 0;
  margin: 0 0 28px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  color: #110f11;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  padding: 0 0 12px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  @media (min-width: 881px) {
    width: fit-content;
  }
  @media (min-width: 1007px) {
    margin: 0;
  }

  ::-webkit-scrollbar {
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.bdGrey};
  }
`;

export const TableHeaderRow = styled.div`
  display: flex;
  margin: 0 22px 12px 152px;
  box-sizing: border-box;
`;

export const TableTitle = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #bdbdbd;
  flex-grow: 3;
  flex-basis: 50px;
`;

export const TableDate = styled(TableTitle)`
  flex-grow: 2;
  flex-basis: 50px;
  text-align: left;
`;

export const TablePrice = styled(TableDate)`
  text-align: right;
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const commonIcon = `
  color: #000;
  width: 24px;
  height: 24px;
`;

export const OffersIcon = styled(MdLocalOffer)`
  ${commonIcon}
`;

export const SearchIcon = styled(MdSearch)`
  ${commonIcon}
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  /* color: rgb(7, 0, 92); */
  color: rgba(75, 65, 196, 1);
  padding: 20px 0;
  width: 100%;
  margin-bottom: 26px;
  font-weight: 700;
  ol {
    list-style: decimal;
    padding: 0 0 0 2rem;
    ol {
      list-style: lower-alpha;
      padding: 0 0 0 2rem;
      ol {
        list-style: lower-roman;
        padding: 0 0 0 2rem;
      }
      ul {
        padding: 0 0 0 2rem;
      }
    }
  }
  li {
    font-family: Poppins;
    margin: 16px 0 16px;
    line-height: 120%;
    font-size: 16px;
  }
`;

export const HeaderWrapper = styled.div`
  margin: 0 0 32px;
`;

export const Header = styled.h1`
  font-family: Poppins;
  margin: 24px 0;
  line-height: 120%;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  font-weight: 700;
`;

export const Paragraph = styled.p`
  font-family: Poppins;
  margin: 16px 0 16px;
  line-height: 160%;
  font-size: 16px;
  font-weight: 400;
  color: #373f41;
  strong {
    text-transform: uppercase;
  }
`;

export const Centered = styled(Paragraph)`
  text-align: center;
`;

export const Link = styled.a`
  color: rgba(75, 65, 196, 1);
  font-weight: 700;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const LabelContainer = styled.div``;

export const StyledLabel = styled.label``;

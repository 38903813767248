import styled from 'styled-components';
import U from '../../../assets/CSS/commonStyles';

export const Wrapper = styled.div`
  flex-grow: 1;
  padding: 20px 18px;
  background-color: #11244d;
  ${U.columnFlex}
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 394px;
  height: 92vh;
  position: fixed;
  z-index: 2;
  min-width: 212px;
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 250px;
  background-color: #11244d;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const redirectButtonStyles = `
  max-width: 250px;
  height: 52px;
  margin: 0 0 19px auto;
  border-radius: 15px;
  background-color: white;
  color: rgba(75,65,196,1);
  @media(min-width: 880px) {
    display: none;
}`;

export const LogoutButton = styled.button`
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  border-radius: 12px;
  padding: 8px;
  width: 100%;
  /* max-width: 200px; */
  margin: 0 auto;
  box-sizing: border-box;
  font-weight: 600;
  transition: 250ms;
  background-color: rgba(75, 65, 196, 1);
`;

import { IFaqCategory, IArticle } from '../faqData';
import * as S from './FAQSegment.styles';

const FAQSegment = ({ title, icon, articles }: IFaqCategory) => {
  return (
    <S.Wrapper>
      {icon && <S.Icon src={icon} alt={`${title} icon`} />}
      <S.Header>{title}</S.Header>
      {articles.map(({ id, title }: IArticle) => (
        <S.CustomLink to={`/faq/${id}`} key={`article_${id}`}>
          {title}
        </S.CustomLink>
      ))}
    </S.Wrapper>
  );
};

export default FAQSegment;

import ContactForm from '../ContactForm/ContactForm';
import ContactPageWrapper from '../ContactPageWrapper/ContactPageWrapper';
import ContactImage from '../../../assets/Contact.svg';
import * as S from './ContactPage.styles';

const ContactPage = () => (
  <ContactPageWrapper image={ContactImage} title="Kontakt">
    <>
      <S.Description>
        Twoje pytania i komentarze są dla nas ważne. Jeśli potrzebujesz pomocy, nasz zespół obsługi
        klienta jest dostępny, aby odpowiedzieć na każde Twoje pytanie!
      </S.Description>
      <S.FAQLink to="/faq">Odwiedź nasz dział FAQ</S.FAQLink>
      <S.DataWrapper>
        <S.DataColumn>
          <S.PhoneWrapper>
            <S.PhoneIcon />
            <S.DataTextWrapper>
              {/* <S.LinkedDataText href="tel:731 614 614">731 614 614</S.LinkedDataText> */}
              <S.DataText>
                <S.GreyOut>pn-pt 9:00 - 17:00</S.GreyOut>
              </S.DataText>
            </S.DataTextWrapper>
          </S.PhoneWrapper>
          <S.EmailWrapper>
            <S.MailIcon />
            <S.DataTextWrapper>
              <S.LinkedDataText href="mailto:kontakt@Malutcy.pl">kontakt@Malutcy.pl</S.LinkedDataText>
              <S.FormLink>Formularz kontaktowy</S.FormLink>
            </S.DataTextWrapper>
          </S.EmailWrapper>
        </S.DataColumn>
        <S.AddressWrapper>
          <S.MapPinIcon />
          <S.DataTextWrapper>
            <S.DataText>
              <S.CustomB>Malutcy.pl</S.CustomB>
            </S.DataText>
            {/* <S.DataText>ul. świętego Wawrzyńca</S.DataText>
            <S.DataText>62-200 Gniezno</S.DataText>
            <S.DataText>NIP: 7841111111</S.DataText> */}
          </S.DataTextWrapper>
        </S.AddressWrapper>
      </S.DataWrapper>
      <ContactForm />
    </>
  </ContactPageWrapper>
);

export default ContactPage;

import * as S from './FeaturesList.styles';

const FeaturesList = () => (
  <S.Wrapper>
    <S.Header>Malutcy.pl to:</S.Header>
    <S.Row>
      <S.CheckIcon />
      <S.Text>Zajęcia dla Twojego dziecka</S.Text>
    </S.Row>
    <S.Row>
      <S.CheckIcon />
      <S.Text>Bazarek z niezbędnymi przedmiotami</S.Text>
    </S.Row>
    <S.Row>
      <S.CheckIcon />
      <S.Text>Darmowe ogłoszenia</S.Text>
    </S.Row>
  </S.Wrapper>
);

export default FeaturesList;

import styled from 'styled-components';

export const Wrapper = styled.div`
  color: rgba(75, 65, 196, 1);
  padding: 20px 0;
  width: 100%;
  margin-bottom: 26px;
  font-weight: 700;
  ol {
    list-style: disc;
    padding: 0 0 0 2rem;
    ol {
      list-style: circle;
      padding: 0 0 0 2rem;
      ol {
        list-style: lower-roman;
        padding: 0 0 0 2rem;
      }
      ul {
        padding: 0 0 0 2rem;
      }
    }
  }
  li {
    font-family: Poppins;
    margin: 16px 0 16px;
    line-height: 120%;
    font-size: 16px;
  }
`;

export const HeaderWrapper = styled.div`
  margin: 0 0 32px;
`;

export const H1 = styled.h1`
  font-family: Poppins;
  margin: 24px 0;
  line-height: 120%;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
`;

export const H2 = styled.h2`
  font-family: Poppins;
  margin: 0 0 12px;
  line-height: 120%;
  font-size: 22px;
  font-weight: 500;
`;

export const H3 = styled.h3`
  font-family: Poppins;
  margin: 0 0 12px;
  line-height: 120%;
  font-size: 20px;
  font-weight: 500;
`;

export const H4 = styled.h4`
  font-family: Poppins;
  margin: 0 0 12px;
  line-height: 120%;
  font-size: 18px;
  font-weight: 500;
`;

export const H5 = styled.h5`
  font-family: Poppins;
  margin: 0 0 12px;
  line-height: 120%;
  font-size: 16px;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  font-family: Poppins;
  margin: 16px 0 16px;
  line-height: 160%;
  font-size: 16px;
  font-weight: 400;
  color: #373f41;
  strong {
    text-transform: uppercase;
  }
`;

export const Centered = styled(Paragraph)`
  text-align: center;
`;

export const Link = styled.a`
  color: rgba(75, 65, 196, 1);
  font-weight: 700;
`;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { GoCheck } from 'react-icons/go';

interface ButtonFreeI {
  width?: number;
  isSelected: boolean;
  margin?: number;
}

export const ButtonFree = styled.input<ButtonFreeI>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isSelected ? themes.default.main : themes.default.white)};
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  :focus {
    outline: none;
  }
  margin-right: ${(props: ButtonFreeI) => (props.margin ? props.margin : 16)}px;
  min-width: ${(props: ButtonFreeI) => (props.width ? props.width : 28)}px;
  width: ${(props: ButtonFreeI) => (props.width ? props.width : 28)}px;
  height: ${(props: ButtonFreeI) => (props.width ? props.width : 28)}px;
`;

export const CheckIcon = styled(GoCheck)`
  color: ${themes.default.white};
  width: 26px;
  height: 26px;
`;

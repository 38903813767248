import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 74px;
  width: 100%;
  min-height: calc(100vh - 74px);
`;

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const BodyWrapper = styled.div`
  flex-grow: 4;
  display: flex;
  /* min-height: calc(100vh - ); */
  min-height: calc(100vh - 74px);
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding: 30px 16px;
  background-color: #fafafa;
  @media (min-width: 880px) {
    padding: 20px;
  }
`;

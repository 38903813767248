import styled from 'styled-components';

export const Wrapper = styled.div<{ isCentered: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: rgb(74, 82, 83, 0.7);
  display: flex;
  box-sizing: border-box;
  ${({ isCentered }) => (isCentered ? 'align-items: center; justify-content: center;' : '')};
`;
export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const InnerWrapper = styled.section<{ isResearchModal: boolean }>`
  display: flex;
  border-radius: 4px;
  position: relative;
  background: #fff;
  padding: ${({ isResearchModal }) => (isResearchModal ? '0' : '30px')};
  flex-direction: column;
  margin: 0 12px;
  /* max-height: 70vh; */
`;

export const CloseWrapper = styled.div`
  right: 30px;
  top: 22px;
  width: 44px;
  height: 44px;
  border-radius: 14px;
  background: #f8fafb;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    background: #e0e0e0;
  }
`;

// export const Close = styled.img.attrs({ src: close })``;

export const TitleWrapper = styled.div`
  padding-bottom: 32px;
`;

export const Title = styled.span`
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0a1629;
`;

import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import logo from '../../../assets/logo.svg';
import { themes } from '../../../assets/CSS/colorThemes';
import { Link } from 'react-router-dom';

export const Wrapper = styled.article<{ isLast: boolean }>`
  width: 100%;
  min-height: 98px;
  box-sizing: border-box;
  background: ${themes.default.white};
  border: 1px solid ${themes.default.main10};
  ${(props) => !props.isLast && `border-bottom: none;`}
  padding: 14px 8px;
  @media (min-width: 375px) {
    padding: 14px 22px;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
`;

export const TitleWrapper = styled.div`
  flex-grow: 3;
  flex-basis: 50px;
`;

export const Title = styled(Link)`
  text-decoration: none;
  margin: 0;
  padding: 0 8px 0 0;
  display: block;
  box-sizing: border-box;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: ${themes.default.graphemeGrey};
  width: 100%;
  @media (max-width: 978px) {
    margin-bottom: 3px;
  }
`;

export const GrayDataRow = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 164%;
  letter-spacing: 0.1px;
  color: #828282;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  word-break: break-all;
  @media (min-width: 400px) {
    word-break: normal;
  }
  margin: 0 0 16px;
  @media (min-width: 879px) {
    margin: 0;
  }
`;

export const HourCounter = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: ${themes.default.graphemeGrey};
  margin: 0 0 4px;
  @media (min-width: 879px) {
    margin: 0;
  }
`;

export const DataRow = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: ${themes.default.graphemeGrey};
`;

export const PriceRow = styled.div`
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  line-height: 114%;
  text-align: right;
  letter-spacing: 0.1px;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 5px;
  @media (max-width: 978px) {
    text-align: left;
    margin-bottom: 11px;
  }
`;

export const TrashIcon = styled(FaRegTrashAlt)`
  width: 13px;
  min-width: 13px;
  height: 13px;
  margin: auto 4px auto 0;
  color: ${themes.default.main};
`;

export const ButtonText = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${themes.default.main};
`;

export const DeleteWrapper = styled.div`
  min-height: 26px;
  box-sizing: border-box;
  background-color: ${themes.default.lightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  min-width: 160px;
  margin: 0;
  @media (min-width: 998px) {
    margin: 16px 0 0;
  }
  @media (min-width: 1023px) {
    margin: 0;
  }
`;

export const DataWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 8px;
  align-self: center;
  @media (max-width: 978px) {
    display: block;
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  width: 105px;
  min-width: 105px;
  height: 58px;
  object-fit: cover;
  margin-right: 8px;
  @media (min-width: 375px) {
    margin-right: 24px;
  }
  @media (max-width: 978px) {
    margin-bottom: 17px;
  }
`;

export const MockImage = styled(Image).attrs({ src: logo, alt: 'offerer has not provided photo' })`
  object-fit: contain;
`;

export const DateWrapper = styled.div`
  flex-grow: 2;
  flex-basis: 50px;
  @media (max-width: 978px) {
    margin-bottom: 16px;
  }
`;

export const PriceWrapper = styled.div`
  padding-top: 7px;
  flex-grow: 2;
  flex-basis: 50px;
  @media (max-width: 978px) {
    padding-top: 0;
    margin: 0 0 28px;
  }
`;

export const MobileBody = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileColumnRight = styled(MobileColumn)`
  width: 168px;
`;

export const PlainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  :focus {
    outline: none;
  }
`;

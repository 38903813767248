import { memo, useEffect } from 'react';
import Menu from './components/UserDashboard/Menu/Menu';
import { Switch } from 'react-router-dom';
import SettingsPage from './components/UserSettings/SettingsPage/SettingsPage';
import MobileMenu from './components/UserDashboard/MobileMenu/MobileMenu';
import OffersPage from './components/UserDashboard/OffersPage/OffersPage';
import WatchedOffersPage from './components/UserDashboard/WatchedOffersPage/WatchedOffersPage';
import MessagesPage from './components/UserDashboard/MessagesPage/MessagesPage';
import ProtectedRoute from './utils/ProtectedRoute';
import { useDispatch } from 'react-redux';
import { getDashboardCount } from './redux/auth/actions';
import useMediaQuery from './hooks/useMediaQuery';
import * as S from './App.styles';

export default memo(
  ({
    isMenuOpen,
    setIsMenuOpen,
  }: {
    isMenuOpen: boolean;
    setIsMenuOpen: (value: boolean) => void;
  }) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getDashboardCount());
    }, []);
    const isDesktop = useMediaQuery('(min-width: 880px)');
    return (
      <S.InnerWrapper>
        {isDesktop ? (
          <Menu />
        ) : (
          <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
        <S.BodyWrapper>
          <S.ExternalWrapper>
            <Switch>
              <ProtectedRoute path="/user-dashboard/offers" exact component={OffersPage} />
              <ProtectedRoute path="/user-dashboard/observed" exact component={WatchedOffersPage} />
              <ProtectedRoute path="/user-dashboard/messages" exact component={MessagesPage} />
              <ProtectedRoute
                path="/user-dashboard/messages/:threadId"
                exact
                component={MessagesPage}
              />
              {/* <ProtectedRoute path="/user-dashboard/payments" exact component={PaymentsPage} /> */}
              <ProtectedRoute path="/user-dashboard/settings" exact component={SettingsPage} />
            </Switch>
          </S.ExternalWrapper>
        </S.BodyWrapper>
      </S.InnerWrapper>
    );
  }
);

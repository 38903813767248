import Spinner from '../../Spinner/Spinner';
import * as S from './SubmitButton.styles';

const SubmitButton = ({
  text,
  icon = '',
  disabled = false,
  onClick,
  type = 'button',
  form,
  isLoading = false,
}: {
  text: string;
  icon?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  form?: string;
  isLoading?: boolean;
}) => {
  const renderLoading = () => (
    <S.SpinnerWrapper>
      <Spinner />
    </S.SpinnerWrapper>
  );
  return (
    <S.Wrapper form={form} onClick={onClick} disabled={disabled} type={type}>
      {icon === 'mail' && <S.MailIcon />}
      {isLoading ? renderLoading() : text}
    </S.Wrapper>
  );
};

export default SubmitButton;

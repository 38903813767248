import { useRef } from 'react';
// import ContinueWithFacebookButton from './ContinueWithFacebookButton';
import ContinueWithGoogleButton from './ContinueWithGoogleButton';
import * as S from './FederatedButtons.styles';
import { useContainerDimensions } from '../../../utils/getDimensions';

const FederatedButons = () => {
  const elementRef = useRef(null);
  const { width } = useContainerDimensions(elementRef);

  return (
    <div>
      {/* <ContinueWithFacebookButton /> */}
      <ContinueWithGoogleButton action="login" width={width} />
      <S.SpacerWrapper ref={elementRef}>
        <S.Line />
        <S.SpacerText>Lub</S.SpacerText>
        <S.Line />
      </S.SpacerWrapper>
    </div>
  );
};

export default FederatedButons;

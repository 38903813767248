import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  position: absolute;
  opacity: 0.4;
  background: #11244d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
`;

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 999;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 12px;
  justify-content: center;
  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 32px;
    padding: 32px;
  }
`;

export const CookieWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CookieImage = styled.img``;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 860px;
`;

export const Header = styled.h3`
  margin: 0;
  color: #11244d;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
`;

export const Text = styled.span`
  color: #11244d;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  white-space: break-spaces;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 300px;
`;

export const AcceptButton = styled.button`
  display: flex;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  color: white;
  border: none;
  padding: 8px;
  transition: 300ms;
  background-color: #4b41c4;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  gap: 8px;
  &:hover {
    box-shadow: 0 0 12px #4b41c4;
  }
`;

export const Emoji = styled.img``;

export const Anchor = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: #11244d;
`;

import { useEffect, useState } from 'react';
import CustomCheckbox from '../../Partials/CustomCheckbox/CustomCheckbox';
import useMediaQuery from '../../../hooks/useMediaQuery';
import AdminOfferItem from './AdminOfferItem';
import AdminOfferItemMobile from './AdminOfferItemMobile';
import * as api from '../../../api/common-api';
import * as S from '../../UserDashboard/OffersPage/OffersPage.styles';

const OfferReportsPage = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [reportedOffers, setReportedOffers] = useState<any[]>([]);

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isSideMenu = useMediaQuery('(min-width: 881px)');
  const isTablet = useMediaQuery('(min-width: 750px)');
  const isWideItem = isDesktop || (!isSideMenu && isTablet);

  const handleSelect = (id: string) => {
    if (selectedIds.includes(id)) {
      const filtered = selectedIds.filter((currentId) => currentId !== id);
      setSelectedIds(filtered);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const offerIds = reportedOffers.map(({ id }) => id);

  const areSelectedAll = offerIds === selectedIds;
  const handleSelectAll = () => {
    if (areSelectedAll) {
      return setSelectedIds([]);
    }
    setSelectedIds(offerIds);
  };

  const handleFetchReportedOffers = async () => {
    const fetchedReportedOffers = await api.getReportedOffers();
    setReportedOffers(fetchedReportedOffers);
  };

  const handleInvalidReport = async () => {
    await api.markReportsAsRead(selectedIds);
  };

  useEffect(() => {
    handleFetchReportedOffers();
  }, []);
  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.Header>Raporty ogłoszeń</S.Header>
        <S.RemoveRow>
          {isTablet ? (
            <S.SelectAll onClick={handleSelectAll}>
              {areSelectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
            </S.SelectAll>
          ) : (
            <CustomCheckbox isSelected={areSelectedAll} handleSelect={handleSelectAll} />
          )}
          <S.AcceptAll onClick={() => handleInvalidReport()} disabled={selectedIds.length === 0}>
            {isTablet ? 'Odrzuć zgłoszena' : <S.Discard />}
          </S.AcceptAll>
          <S.RemoveButton disabled={true}>{isTablet ? 'Usuń oferty' : <S.Trash />}</S.RemoveButton>
        </S.RemoveRow>

        {isWideItem && (
          <S.TableHeaderRow>
            <S.TableTitle>Tytuł</S.TableTitle>
            <S.TableDate>Zgłoszenia</S.TableDate>
            <S.TablePrice>Cena</S.TablePrice>
            <S.TableDisplays>Wyświetlanie</S.TableDisplays>
            <S.TableAdminFields>Akcje</S.TableAdminFields>
          </S.TableHeaderRow>
        )}
        {reportedOffers.map((offer, index: number) => {
          const { id, image } = offer;
          const isSelected = selectedIds.includes(id);
          return (
            <S.ResultRow key={id}>
              {isDesktop && (
                <CustomCheckbox isSelected={isSelected} handleSelect={() => handleSelect(id)} />
              )}
              {isWideItem && <AdminOfferItem isFirst={index === 1} offer={offer} image={image} />}
              {!isWideItem && (
                <AdminOfferItemMobile isFirst={index === 1} offer={offer} image={image} />
              )}
            </S.ResultRow>
          );
        })}
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default OfferReportsPage;

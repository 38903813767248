import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { IoIosArrowBack } from 'react-icons/io';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
`;

export const ExternalWrapper = styled.div`
  position: relative;
  padding: 16px 0 58px;
  width: 100%;
  background-color: #e9f5f5;
`;

export const BackButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  @media (max-width: 529px) {
    margin: 0 auto;
  }
`;

export const BackButtonText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${themes.default.bdGrey};
`;

export const BackIcon = styled(IoIosArrowBack)`
  margin-right: 12px;
  height: 18px;
  width: 18px;
  color: ${themes.default.bdGrey};
`;

export const StarsContainer1 = styled.div`
  position: absolute;
  right: 0;
  top: 80px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const bdGrey = '#bdbdbd';
const darkerGrey = '#4f4f4f';
const darkGraphemeGrey = '#363A40';
const extraLightGrey = '#F4F4F4';
const floridaBlue = 'rgba(75, 65, 196, 1)';
const floridaBlue10 = '#E5EBF8';
const fontColorBlack = '#444B4D';
const golden = '#FFD700';
const graphemeGrey = '#373F41';
const green = '#ABD681';
const greyBackgroundColor = '#E5E5E5';
const lavender = '#9D74E0';
const lightGraphemeGrey = '#E2E8F0';
const lightGrey = '#FAFAFA';
const luminescentGray = '#F2F2F2';
const magentaBlack = '#110F11';
const mediumGrey = '#606267';
const midnightBlack = '#000';
// const mintGreen = '#6fcf97';
const mintGreen = 'rgba(75,65,196,1)';
const navyBlue = '#3A5998';
const red = 'red';
const reddishGrey = '#373f41';
const titaniumWhite = '#fff';

export const themes = {
  default: {
    bdGrey,
    black: midnightBlack,
    darkerGrey,
    darkGraphemeGrey,
    extraLightGrey,
    fontColorBlack,
    graphemeGrey,
    green,
    greyBackgroundColor,
    lavender,
    lightGraphemeGrey,
    lightGrey,
    luminescentGray,
    magentaBlack,
    main: floridaBlue,
    main10: floridaBlue10,
    mainDark: navyBlue,
    mediumGrey,
    negative: red,
    positive: mintGreen,
    reddishGrey,
    star: golden,
    white: titaniumWhite,
  },
};

import styled from 'styled-components';
import { IoIosSearch } from 'react-icons/io';
import { themes } from '../../../assets/CSS/colorThemes';
import FaqSvg from '../../../assets/faqPicture.svg';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #2b65ea;
  height: 271px;
  display: flex;
`;

export const MainWrapper = styled.div`
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 916px;
  padding: 0 18px;
  @media (min-width: 600px) {
    padding: 0 32px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 37px 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 34px;
  line-height: 118%;
  text-align: center;
  letter-spacing: -0.4px;
  color: ${themes.default.white};
`;

export const SearchWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  background: ${themes.default.white};
  box-shadow: 0px 10px 20px rgba(9, 1, 9, 0.0984484);
  display: flex;
  padding: 0 33px;
  box-sizing: border-box;
  z-index: 100;
  @media (min-width: 768px) {
    top: -92px;
  }
`;

export const SearchIcon = styled(IoIosSearch)`
  min-width: 30px;
  height: 30px;
  margin: auto;
  margin-right: 20px;
  color: #808a88;
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 96%;
  margin: auto;
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.1px;
  border: none;
  :focus {
    outline: none;
  }
  :placeholder {
    color: ${themes.default.bdGrey};
  }
`;

export const Picture = styled.img.attrs({ src: FaqSvg, alt: 'faq image' })`
  position: absolute;
  height: 100%;
  width: auto;
  top: 0;
  right: 0;
  z-index: 0;
`;

import { useState, memo } from 'react';
import { ICategory } from '../../../redux/utils/types';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './CategoryButton.style';

export interface CategoryProps extends ICategory {
  isActive: boolean;
  onClick: () => void;
}

const CategoryButton = ({ label, isActive, icon, onClick }: CategoryProps) => {
  const [isHover, setIsHover] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const isButtonActive = isDesktop ? isActive || isHover : isActive;
  return (
    <S.Wrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      isActive={isButtonActive}
      onClick={onClick}
    >
      <S.InnerContainer isActive={isButtonActive}>
        {icon && <S.Icon isActive={isButtonActive} src={icon} alt={`${label} icon`} />}
        <S.Label isActive={isButtonActive}>{label}</S.Label>
      </S.InnerContainer>
    </S.Wrapper>
  );
};

export default memo(CategoryButton);

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled.div<{ isActive: boolean }>`
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  width: ${({ isActive }) => (isActive ? '55px' : '0')};
  overflow: hidden;
`;

export const Wrapper = styled(Link)<{ props: { isFirst: boolean } }>`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 100px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-top: ${({ props: { isFirst } }) => (isFirst ? `1px solid #e5e5e5` : 'none')};
  box-sizing: border-box;
  display: flex;
  padding: 18px;
  @media (min-width: 881px) {
    padding: 18px 28px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: 175px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 130px;
  flex-grow: 2;
  max-width: 262px;
  margin: auto 8px auto 0;
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const Avatar = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  margin-right: 15px;
  object-fit: cover;
`;

export const UserDescriptionWrapper = styled.div``;

export const BodyWrapper = styled.div`
  flex-grow: 3;
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const UserName = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #110f11;
  margin-bottom: 2px;
`;

export const AddedAt = styled.div`
  font-family: Poppins;
  font-size: 13px;
  line-height: 154%;
  color: #717171;
`;

export const Title = styled.h2`
  padding: 0;
  margin: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #373f41;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;

export const Bullet = styled.div<{ isActive: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.isActive ? '#F35431' : '#fff')};
  border-radius: 100%;
  margin-right: 7px;
`;

export const Message = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 143%;
  color: #403f40;
  padding-left: 17px;
  overflow: hidden;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RemoveButton = styled.button`
  padding: 8px 16px;
  color: ${themes.default.negative};
  background-color: ${themes.default.greyBackgroundColor};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  border: none;
  height: fit-content;
  margin: auto 0;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const ResultRow = styled.div`
  display: flex;
  align-items: center;
`;

export const EmptyDiv = styled.div`
  min-width: 54px;
  width: 54px;
`;

import styled from 'styled-components';
import draw from '../../assets/SearchSection/draw.svg';

export const Wrapper = styled.div`
  display: flex;
  min-height: 440px;
  background-color: rgba(17, 36, 77, 1);
  padding: 42px 12px 52px;
  position: relative;
  @media (min-width: 370px) {
    padding: 0 0 52px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 2;
  align-items: center;
  @media (min-width: 370px) {
    padding: 56px 18px 0;
  }
  box-sizing: border-box;
  @media (min-width: 768px) {
    justify-content: center;
    padding: 0 18px;
  }
`;

export const Header = styled.h1`
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
  letter-spacing: -0.4px;
  color: white;
  padding-bottom: 16px;
  max-width: 754px;
  text-align: center;
  margin: 0 auto;
  @media (min-width: 800px) {
    font-size: 30px;
  }
`;

export const SubHeader = styled.h2`
  padding-bottom: 18px;
  font-family: Poppins;
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  line-height: 187%;
  color: white;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding-bottom: 50px;
  }
`;

export const SearchWrapper = styled.div`
  /* position: absolute;
  bottom: -70px; */
  width: 100%;
  max-width: 90%;
  padding: 24px;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  @media (min-width: 720px) {
    max-width: 920px;
  }
`;

export const CountersWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 496px) {
    justify-content: center;
  }
`;

export const CounterWrapper = styled.div`
  @media (max-width: 496px) {
    flex-basis: 140px;
    max-width: 150px;
    flex-grow: 1;
  }
`;

export const Spacer = styled.div`
  height: 61px;
  width: 1px;
  border-right: 1px solid #e5e5e5;
  margin: 0 18px 0 18px;
  @media (min-width: 400px) {
    margin: 0 30px 0 30px;
  }
`;

export const Counter = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #2b65ea;
  margin-bottom: 2px;
`;

export const CounterDescription = styled.div`
  font-family: Poppins;
  line-height: 187%;
  color: #828282;
  font-size: 14px;
  @media (min-width: 350px) {
    font-size: 16px;
  }
`;

export const LeftCounterDescription = styled(CounterDescription)`
  text-align: right;
  @media (min-width: 497px) {
    text-align: left;
  }
`;

export const LeftCounter = styled(Counter)`
  text-align: right;
  @media (min-width: 497px) {
    text-align: left;
  }
`;

export const BackgroundContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  z-index: -1;
`;

export const Background = styled.img``;

export const AbsoluteImage = styled.img<{
  isSmall?: boolean;
}>`
  width: ${({ isSmall }) => (isSmall ? '200px' : '100%')};
`;

export const LeftOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const LeftTwoImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const LeftPersonContainer = styled.div<{
  isFixed?: boolean;
}>`
  position: absolute;
  bottom: -120px;
  left: ${({ isFixed }) => (isFixed ? '0' : '70px')};
`;

export const RightOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const RightTwoImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const RightPersonContainer = styled.div<{
  isFixed?: boolean;
}>`
  position: absolute;
  bottom: -120px;
  right: ${({ isFixed }) => (isFixed ? '0' : '70px')};
`;

export const UnderlinedText = styled.span`
  background-image: url(${draw});
  background-position: 0 1em;
  background-repeat: no-repeat;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    position: absolute;
    bottom: -70px;
  }
`;

export const CategorySearchButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  position: relative;
  justify-content: center;
  /* bottom: -70px; */
`;

export const CategoryButton = styled.button<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? 'white' : 'rgba(221, 223, 228, 1)')};
  color: rgba(17, 36, 77, 1);
  border-radius: 16px 16px 0 0;
  max-width: 124px;
  width: 100%;
  border: none;
  font-family: Poppins;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  padding: 8px 32px;
  cursor: pointer;
  transition: 300ms;
`;

export const HeaderWrapper = styled.div``;

import { useEffect, useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import { useSelector } from 'react-redux';
import PhotosUpload from '../PhotosUpload/PhotosUpload';
import { userData } from '../../../redux/auth/selectors';
import YourData from '../YourData/YourData';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { OFFER_TYPE } from '../../../redux/offer/types';
import Stars1 from '../../../assets/OfferPage/stars-1.svg';
import { emptyTempOffer, useOfferContext } from '../../../context/offer/offerContext';
import { Button } from 'antd';
import * as S from './PageTwo.styles';
import * as api from '../../../api/common-api';

const PageTwo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { tempOffer, setTempOffer } = useOfferContext();
  const history = useHistory();

  const {
    title,
    categoryIds,
    description,
    price,
    giveaway,
    offererName,
    offererEmail,
    offererPhone,
    offererLocalisation,
    offerType,
    isBrandNew,
    commitmentForm,
    faqs,
    benefits,
    ageFrom,
    ageTo,
    subscriptionOptions,
    dynamicOptions,
    images,
    city,
    street,
    zipCode,
    streetNumber,
    location,
  } = tempOffer;

  const { nickname, name, email, phone, localisation, lat, lon } = useSelector(userData);
  useEffect(() => {
    if (tempOffer.title.length === 0) {
      history.push('/create/step-one');
    }
  }, []);

  const [photos, setPhotos] = useState<{ order: number; file: any }[]>(images);

  const YourDataBundle = {
    name: offererName || nickname || name,
    email: offererEmail || email,
    phone: offererPhone || phone || '',
    street,
    streetNumber,
    zipCode,
    city,
    localisation,
    location: {
      name: offererLocalisation || localisation || '',
      lat: lat ? lat : 0,
      lon: lon ? lon : 0,
    },
  };

  const handleRedirect = (e: any) => {
    e.preventDefault();
    history.push('/create/step-one', '/create/step-two');
  };

  const handleAddOffer = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const defaultMonthDuration = moment(new Date()).add(30, 'days').toISOString();
    const filesMap = photos.map(({ order, file }) => ({ order, fileName: file.name }));
    const data = {
      title,
      categoryIds,
      description,
      price: price * 100,
      giveaway,
      offererName: nickname || name,
      offererEmail: email,
      offererPhone: phone,
      offererLocalisation: location.name,
      offerLat: 0,
      offerLon: 0,
      filesMap,
      expiresAt: defaultMonthDuration,
      hasAutoRenew: false,
      offerType,
      isBrandNew,
      commitmentForm,
      faqs,
      benefits,
      ageFrom,
      ageTo,
      city,
      zipCode,
      street,
      streetNumber,
      subscriptionOptions,
      dynamicOptions,
      geo_lat: +location.lat,
      geo_lng: +location.lon,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    photos.forEach(({ file }: any) => {
      formData.append(file.webkitRelativePath, file);
    });

    const { id } = await api.addOffer(formData);
    setTempOffer(emptyTempOffer);
    setIsLoading(false);
    history.push(`/offer/${id}`);
  };

  return (
    <S.ExternalWrapper>
      <S.StarsContainer1>
        <S.StyledImage src={Stars1} />
      </S.StarsContainer1>
      <PageWrapper
        onSubmit={handleAddOffer}
        isActiveStepOne={false}
        isCompletedStepOne={true}
        isActiveStepTwo={true}
      >
        <PhotosUpload photos={photos} setPhotos={setPhotos} />
        <YourData
          dataBundle={YourDataBundle}
          isBazaar={offerType === OFFER_TYPE.BAZAAR ? true : false}
        />
        <S.ButtonsWrapper>
          <Button
            onClick={(e: any) => handleAddOffer(e)}
            type="primary"
            disabled={!city}
            loading={isLoading}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: 372,
              height: 52,
              borderRadius: 8,
              fontSize: 14,
              marginBottom: 6,
            }}
          >
            Dodaj ogłoszenie
          </Button>
          <S.BackButton onClick={(e: any) => handleRedirect(e)}>
            <S.BackIcon />
            <S.BackButtonText>Cofnij</S.BackButtonText>
          </S.BackButton>
        </S.ButtonsWrapper>
      </PageWrapper>
    </S.ExternalWrapper>
  );
};

export default PageTwo;

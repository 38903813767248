import { IOffer } from '../../../redux/offer/types';
import formatNumber from '../../../utils/formatNumber';
import Logo from '../../../assets/malutcyLogo.svg';
import { Button } from 'antd';
import * as S from '../../UserDashboard/OfferItemMobile/OfferItemMobile.style';

const OfferItemMobile = ({
  offer: { id, title, price, offererPhone, offererEmail, count },
  isFirst,
  image,
}: {
  offer: IOffer;
  isFirst: boolean;
  image: any;
}) => {
  const formattedPrice = formatNumber(price);
  //TODO : handle confirmReport and removeReport ;))))
  const isDisabled = true;

  return (
    <S.Wrapper isFirst={isFirst}>
      <S.MobileBody>
        <S.MobileColumn>
          {image ? (
            <S.Image src={image.href} alt={`${title} image`} />
          ) : (
            <S.Image src={Logo} alt={`${title} image`} />
          )}
          <Button
            type="primary"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: 96,
              height: 26,
              borderRadius: 8,
              fontSize: 14,
              marginBottom: 6,
            }}
          >
            Zaakceptuj
          </Button>
          <Button
            type="default"
            danger
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: 96,
              height: 26,
              borderRadius: 8,
              fontSize: 14,
            }}
          >
            Usuń
          </Button>
        </S.MobileColumn>
        <S.DataWrapper>
          <S.Title to={`/offer/${id}`}>{title}</S.Title>
          <S.DateWrapper>
            <S.DataRow>Zgłoszenia: {count}</S.DataRow>
          </S.DateWrapper>
          <S.PriceWrapper>
            <S.DataRow>{formattedPrice} zł</S.DataRow>
          </S.PriceWrapper>
          <S.DisplaysWrapper>
            <S.Section>
              <S.MobileIcon />
              <S.Label>tel:</S.Label> <S.Value>{offererPhone}</S.Value>
            </S.Section>
            <S.Section>
              <S.EnvelopeIcon />
              <S.Label>e-mail:</S.Label> <S.Value>{offererEmail}</S.Value>
            </S.Section>
          </S.DisplaysWrapper>
        </S.DataWrapper>
      </S.MobileBody>
    </S.Wrapper>
  );
};
export default OfferItemMobile;

import { useRef, useState } from 'react';
import SegmentWrapper from '../SegmentWrapper/SegmentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserContactData } from '../../../redux/auth/actions';
import { userData, loadingData } from '../../../redux/auth/selectors';
import { Button } from 'antd';
import * as S from './ContactData.styles';

const ContactData = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { isLoading } = useSelector(loadingData);
  const { avatar, email, nickname, localisation, phone, isPasswordSet } = useSelector(userData);

  const [tempNickname, setTempNickname] = useState(nickname ? nickname : '');
  const [selectedCity, setSelectedCity] = useState({
    name: localisation ? localisation : '',
    lat: 0,
    lon: 0,
  });
  const [tempEmail, setTempEmail] = useState(email ? email : '');
  const [tempPhone, setTempPhone] = useState(phone ? phone : '');
  const [avatarFile, setAvatarFile] = useState<any>();
  const [rawHints] = useState<any[]>([]);

  const handlePhoneChange = (e: any) => {
    // console.log({e})
    e.target.setCustomValidity('');
    setTempPhone(e.target.value);
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    const data: {
      nickname: string;
      localisation: string;
      phone: string;
      email?: string;
      lat: number;
      lon: number;
    } = {
      nickname: tempNickname,
      localisation: selectedCity.name,
      lat: selectedCity.lat,
      lon: selectedCity.lon,
      phone: tempPhone,
    };
    if (isPasswordSet) {
      data.email = tempEmail;
    }
    dispatch(updateUserContactData(data, avatarFile));
  };

  const handleClickFileInput = (e: any) => {
    e.preventDefault();
    if (fileInputRef.current) {
      // @ts-ignore
      return fileInputRef.current.click();
    }
  };

  const handleSetLocalization = (city: string) => {
    const cityName = city.split(',')[0];
    const { lat, lon } = rawHints.find(({ display_name }) => display_name === city) || {
      lat: 0,
      lon: 0,
    };
    setSelectedCity({ name: cityName, lat, lon });
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    setAvatarFile(e.dataTransfer.files[0]);
  };

  const onDragOver = (e: any) => e.preventDefault();

  return (
    <SegmentWrapper title="Dane kontaktowe" icon="user">
      <form onSubmit={(e: any) => handleSave(e)}>
        <S.BodyWrapper>
          <input
            onChange={(e: any) => setAvatarFile(e.target.files[0])}
            ref={fileInputRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/png, image/jpeg"
          />
          <S.UserWrapper>
            {avatar || avatarFile ? (
              <S.Avatar
                src={avatarFile ? URL.createObjectURL(avatarFile) : avatar}
                alt={`users avatar`}
                onClick={(e: any) => handleClickFileInput(e)}
                onDragOver={onDragOver}
                onDrop={onDrop}
              />
            ) : (
              <S.PlainAvatar
                onClick={handleClickFileInput}
                onDragOver={onDragOver}
                onDrop={onDrop}
              />
            )}
            <S.ChangePhoto onClick={(e: any) => handleClickFileInput(e)}>
              Zmień zdjęcie
            </S.ChangePhoto>
          </S.UserWrapper>
          <S.FormWrapper>
            <S.InputContainer>
              <S.StyledLabel>
                Nazwa wyświetlana <S.RedStar>*</S.RedStar>
              </S.StyledLabel>
              <S.StyledInput
                placeholder="Nazwa wyświetlana"
                value={tempNickname}
                onChange={(e: any) => setTempNickname(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.StyledLabel>Lokalizacja</S.StyledLabel>
              <S.StyledInput
                placeholder="Lokalizacja"
                value={selectedCity.name}
                onChange={(e: any) => handleSetLocalization(e.target.value)}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.StyledLabel>
                Email <S.RedStar>*</S.RedStar>
              </S.StyledLabel>
              <S.StyledInput
                placeholder="Email"
                value={tempEmail}
                onChange={(e: any) => setTempEmail(e.target.value)}
                type="email"
                readOnly
                disabled
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.StyledLabel>Numer telefonu</S.StyledLabel>
              <S.StyledInput
                placeholder="Numer telefonu"
                value={tempPhone}
                onChange={(e: any) => handlePhoneChange(e)}
                pattern="^[1-9]\d{8,8}$"
                type="tel"
                min={9}
              />
            </S.InputContainer>
          </S.FormWrapper>
        </S.BodyWrapper>
        <Button
          onClick={(e: any) => handleSave(e)}
          type="primary"
          loading={isLoading}
          style={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            maxWidth: 274,
            width: '100%',
            height: 42,
            borderRadius: 8,
          }}
        >
          Zapisz zmiany
        </Button>
      </form>
    </SegmentWrapper>
  );
};

export default ContactData;

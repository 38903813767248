import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as api from '../../../api/common-api';

export default () => {
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const { verificationCode } = params;

  useEffect(() => {
    if (!verificationCode) {
      return history.push('/');
    }

    const verify = async () => {
      const res = await api.verifyUser(verificationCode).catch(() => {
        toast.warn('Kod werfikacyjny jest nieprawidłowy lub został już wykorzystany.');
        history.push('/');
      });

      if (!res) return history.push('/login');

      dispatch({ type: 'SET_VERIFIED_USER', user: res });
      toast.success('Konto zostało poprawnie aktywowane');
      history.push('/');
    };
    verify();
  }, [verificationCode]);

  return null;
};

import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import CSS from '../../../assets/CSS/commonStyles';

export const Wrapper = styled.div`
  background-color: rgb(74, 82, 83, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

export const InnerWrapper = styled.div`
  position: relative;
  max-width: 1247px;
  width: 100%;
  max-height: 775px;
  margin: 18px;
  background-color: ${themes.default.white};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  max-height: 80vh;
  @media (min-width: 634px) {
    padding: 42px 56px;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  overflow: auto;
`;

export const Button = styled.button`
  ${CSS.plainButton}
  margin: 0 0 34px auto;
`;

export const ButtonMobile = styled.button`
  ${CSS.plainButton}
  margin: 0 0 0 auto;
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  @media (min-width: 634px) {
    top: 42px;
    right: 56px;
  }
`;

export const CloseIcon = styled(MdClose)`
  width: 32px;
  height: 32px;
  color: ${themes.default.graphemeGrey};
`;

export const RightPanel = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: column;
  @media (min-width: 556px) {
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    flex-direction: column;
    min-width: 252px;
    margin: 0 0 0 16px;
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 825px;
  width: 100%;
  height: fit-content;
  @media (max-width: 1230px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

import { IOffer } from '../../../redux/offer/types';
import moment from 'moment';
import Logo from '../../../assets/malutcyLogo.svg';
import formatNumber from '../../../utils/formatNumber';
import { FormatPrice } from '../../../utils/formatPrice';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import { toast } from 'react-toastify';
import { useState } from 'react';
import * as S from '../../UserDashboard/OfferItem/OfferItem.styles';
import * as api from '../../../api/common-api';
import { Button } from 'antd';

const AdminOfferItem = ({
  offer: {
    id,
    title,
    created_at,
    price,
    offererPhone,
    offererEmail,
    images = [],
    expiresAt,
    subscriptionOptions,
    commitmentForm,
  },
  isFirst,
  onChange,
}: {
  isFirst: boolean;
  offer: IOffer;
  onChange: () => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dateFrom = moment(created_at).format('DD.MM');
  const dateTo = moment(expiresAt).format('DD.MM');
  const image = images.find(({ order }) => order === 0);
  const formattedPrice = formatNumber(FormatPrice(price));

  const handleAccept = async () => {
    setIsLoading(true);
    await api
      .acceptOffers([id])
      .then(() => toast.info('Oferta zaakceptowana'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    onChange();
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await api
      .deleteOffer([id])
      .then(() => toast.info('Oferta usunięta'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    onChange();
    setIsLoading(false);
  };

  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  return (
    <S.Wrapper isFirst={isFirst}>
      {image ? (
        <S.Image src={image.href} alt={`${title} image`} />
      ) : (
        <S.MockImage src={Logo} alt={`no image provided`} />
      )}
      <S.BodyWrapper>
        <S.DataWrapper>
          <S.Title to={`/offer/${id}`}>{title}</S.Title>
          <S.DateWrapper>
            <S.DataRow>od {dateFrom}</S.DataRow>
            <S.DataRow>do {dateTo}</S.DataRow>
          </S.DateWrapper>
          <S.PriceWrapper>
            <S.DataRow>{finalPrice}</S.DataRow>
          </S.PriceWrapper>
          <S.DisplaysWrapper>
            {offererPhone && (
              <S.TextSection>
                <S.MobileIcon />
                <S.GrayDataRow href={`tel:${offererPhone}`}>tel: {offererPhone}</S.GrayDataRow>
              </S.TextSection>
            )}
            <S.TextSection>
              <S.EnvelopeIcon />
              <S.GrayDataRow href={`mailto:${offererEmail}`}>e-mail: {offererEmail}</S.GrayDataRow>
            </S.TextSection>
          </S.DisplaysWrapper>
          <S.DisplaysWrapper>
            <Button
              type="primary"
              onClick={handleAccept}
              loading={isLoading}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: 96,
                height: 26,
                borderRadius: 8,
                fontSize: 14,
                marginBottom: 6,
              }}
            >
              Zaakceptuj
            </Button>
            <Button
              type="default"
              danger
              onClick={handleDelete}
              loading={isLoading}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Poppins',
                fontWeight: 600,
                width: 96,
                height: 26,
                borderRadius: 8,
                fontSize: 14,
              }}
            >
              Usuń
            </Button>
          </S.DisplaysWrapper>
        </S.DataWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default AdminOfferItem;

import * as S from './Quote.styles';

const Quote = () => (
  <S.Wrapper>
    <S.QuoteIcon />
    <S.Text>Malutcy.pl to malutki początek czegoś wielkiego!:)</S.Text>
    {/* <S.HappyUserWrapper>
      <S.HappyUserPhoto
        alt="Happy user"
        src="https://b953706838a8ed8efba0-30e858ac79224cd1c7bb62654f7aaad0.ssl.cf2.rackcdn.com/wp-content/uploads/2015/05/how-to-seduce-a-man.jpg"
      />
      <S.HappyUserData>Oktawian, Poznań</S.HappyUserData>
    </S.HappyUserWrapper> */}
  </S.Wrapper>
);

export default Quote;

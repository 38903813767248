import * as S from './Navigation.styles';

const Navigation = ({
  isCompletedStepOne,
  isCompletedStepTwo,
  isActiveStepOne,
  isActiveStepTwo,
}: {
  isCompletedStepOne: boolean;
  isCompletedStepTwo: boolean;
  isActiveStepOne: boolean;
  isActiveStepTwo: boolean;
}) => (
  <S.Wrapper>
    <S.InternalWrapper>
      <S.RingWrapper isActive={isActiveStepOne} isCompleted={isCompletedStepOne}>
        {isCompletedStepOne ? (
          <S.CheckIcon />
        ) : (
          <S.RingText isActive={isActiveStepOne}>1</S.RingText>
        )}
      </S.RingWrapper>
      <S.Bridge isActive={isActiveStepTwo} />
      <S.RingWrapper isActive={isActiveStepTwo} isCompleted={isCompletedStepTwo}>
        {isCompletedStepTwo ? (
          <S.CheckIcon />
        ) : (
          <S.RingText isActive={isActiveStepTwo}>2</S.RingText>
        )}
      </S.RingWrapper>
    </S.InternalWrapper>
  </S.Wrapper>
);

export default Navigation;

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { IOffer } from '../../../../redux/offer/types';
import OfferItem from '../../OfferItem/OfferItem';
import OfferItemMobile from '../../OfferItemMobile/OfferItemMobile';
// import CustomCheckbox from '../../../Partials/CustomCheckbox/CustomCheckbox';
import * as S from './OffersWrapper.style';

const OffersWrapper = ({
  offers,
  isArchivedSection = false,
  refreshOffers,
}: {
  offers: IOffer[];
  isArchivedSection?: boolean;
  refreshOffers: () => void;
}) => {
  // const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isSideMenu = useMediaQuery('(min-width: 881px)');
  const isTablet = useMediaQuery('(min-width: 750px)');
  const isWideItem = isDesktop || (!isSideMenu && isTablet);

  // const handleSelect = (id: string) => {
  //   if (selectedIds.includes(id)) {
  //     const filtered = selectedIds.filter((currentId) => currentId !== id);
  //     setSelectedIds(filtered);
  //   } else {
  //     setSelectedIds([...selectedIds, id]);
  //   }
  // };
  return (
    <S.Wrapper>
      {offers.map((offer: IOffer, index: number) => {
        const { id } = offer;
        // const isSelected = selectedIds.includes(id);
        return (
          <S.ResultRow key={id}>
            {/* {isDesktop && isArchivedSection ? (
              <CustomCheckbox isSelected={isSelected} handleSelect={() => handleSelect(id)} />
            ) : (
              isDesktop && <S.EmptyDiv />
            )} */}
            {isWideItem && (
              <OfferItem isFirst={index === 1} offer={offer} refreshOffers={refreshOffers} />
            )}
            {!isWideItem && (
              <OfferItemMobile isFirst={index === 1} offer={offer} refreshOffers={refreshOffers} />
            )}
          </S.ResultRow>
        );
      })}
    </S.Wrapper>
  );
};

export default OffersWrapper;

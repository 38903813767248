import { AppState } from '../store';
import { Thread, Threads, USER_ROLE } from './types';

export const authState = (state: AppState) => state.auth;

export const isUserLoggedIn = (state: AppState) => authState(state).isLoggedIn;

export const useIsUserAdmin = (state: AppState) => authState(state).user.role === USER_ROLE.ADMIN;
// (state: AppState) => true;
export const userData = (state: AppState) => authState(state).user;

export const userUserStatus = (state: AppState) => authState(state).user.status;

export const useBalance = (state: AppState) => userData(state).balance / 100;

export const usersEmail = (state: AppState) => authState(state).user.email;

export const resetData = (state: AppState) => {
  const {
    password,
    user: { status, email },
  } = authState(state);
  return { email, password, status };
};

export const loadingData = (state: AppState) => {
  const { isLoading, loadingField } = authState(state);
  return { isLoading, loadingField };
};

export const useLoading = (state: AppState) => authState(state).isLoading;

export const usersId = (state: AppState) => authState(state).user.id;

export const signInError = (state: AppState) => authState(state).signInErrorMessage;

export const changePasswordError = (state: AppState) => authState(state).changePasswordErrorMessage;

export const isPasswordSet = (state: AppState) => userData(state).isPasswordSet;

export const useUserWatchedOffers = (state: AppState) => {
  const { watchedOffers } = userData(state);
  return watchedOffers ? watchedOffers : [];
};

// interface IThreads  {
//   trash: Thread[];
//   active: Thread[];
//   unReadCount: number;
// }

const EMPTY_THREADS: Threads = {
  trash: [],
  active: [],
  // received: [],
  // sent: [],
  unReadCount: 0,
};

export const threads = (state: AppState) => {
  const { threads = EMPTY_THREADS, messagesQuery, messageCategory } = authState(state);
  let searchResults: Thread[] = [];
  if (messagesQuery) {
    const { trash, active } = threads;
    const allThreads = [...active, ...trash];
    searchResults = allThreads.filter(
      ({
        offer: { title },
        sender: { name: senderName, nickname: senderNickname },
        recipient: { name, nickname },
      }: Thread) => {
        const lowerQuery = messagesQuery.toLocaleLowerCase();
        return (
          (title && title.toLocaleLowerCase().includes(lowerQuery)) ||
          (senderName && senderName.toLocaleLowerCase().includes(lowerQuery)) ||
          (senderNickname && senderNickname.toLocaleLowerCase().includes(lowerQuery)) ||
          (name && name.toLocaleLowerCase().includes(lowerQuery)) ||
          (nickname && nickname.toLocaleLowerCase().includes(lowerQuery))
        );
      }
    );
  }
  return { threads, messagesQuery, searchResults, messageCategory };
};

export const threadsCount = (state: AppState) => {
  const { threads = EMPTY_THREADS, messageCategory, messagesQuery } = authState(state);
  const { trash, active, unReadCount } = threads;

  return {
    trash: trash.length,
    // received: received.length,
    // sent: sent.length,
    active,
    threads,
    messageCategory,
    unReadCount,
    messagesQuery,
  };
};

// TODO: remove
export const unReadMessagesCount = (state: AppState) => {
  const { threads = EMPTY_THREADS } = authState(state);
  return threads.unReadCount;
};

export const userUtils = (state: AppState) => {
  const {
    user: { hasAcceptedCookies, hasSeenDashboardWelcome },
  } = authState(state);
  return { hasAcceptedCookies, hasSeenDashboardWelcome };
};

export const threadData = (state: AppState) => {
  const { selectedThread, isLoading } = authState(state);
  return { selectedThread, isLoading };
};

export const useUsersFiles = (state: AppState) => {
  const {
    isLoading,
    loadingField,
    user: { files = [] },
  } = authState(state);
  return { isLoading, loadingField, files };
};

export const useDashboardCount = (state: AppState) => authState(state).dashboard;

export const useTransactions = (state: AppState) =>
  authState(state).user.payments || { current: [], archived: [] };

export const useUsers = (state: AppState) => authState(state).users || { count: 0, rows: [] };

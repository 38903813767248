import axios from 'axios';
import { emptyUser, initialState as authInitialState } from '../redux/auth/authReducer';
// import { initialState as utilsInitialState } from '../redux/utils/utilsReducer';
import {
  ArchiveThread,
  LogLastSeen,
  UserUtilsPayload,
  SubscriptionPayload,
  InvoiceAddressPayload,
  GetGeoDataPayload,
  IContactMessage,
  IUsersPayload,
  IUpdateUsersStatuses,
  SendMessage,
} from '../redux/auth/types';
import { ISearchParams, IWatchSearchParams } from '../redux/offer/types';
import { AppState } from '../redux/store';

const PORT = process.env.REACT_APP_SERVER_PORT ? `:${process.env.REACT_APP_SERVER_PORT}` : '';
const BASE_URL = process.env.REACT_APP_SERVER_IP
  ? `${process.env.REACT_APP_SERVER_IP}${PORT}/api/v1`
  : 'http://localhost:8000/api/v1';

export const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axios.defaults.withCredentials = true;

export const loginLocally = async (params: { email: string; password: string }): Promise<any> => {
  const res = await instance.post(`/auth/login`, { ...params });
  return res.data;
};

export const register = async (params: { email: string; password: string }): Promise<any> => {
  const res = await instance.post(`/auth/register`, { ...params });
  return res.data;
};

export const loginWithFacebook = async (params: any): Promise<any> => {
  const res = await instance.post(`/auth/facebook`, { ...params });
  return res.data;
};

export const loginWithGoogle = async (params: any): Promise<any> => {
  const res = await instance.post(`/auth/google`, { ...params });
  return res.data;
};

export const checkLoggedIn = async () => {
  try {
    const res = await instance.get(`/auth`);
    const preloadedState: AppState = {
      auth: {
        ...authInitialState,
        user: {
          ...emptyUser,
          ...res.data.user,
        },
        isLoggedIn: true,
      },
    };
    return preloadedState;
  } catch (e) {
    // await checkFbLoginState();
    const preloadedState = {
      auth: {
        ...authInitialState,
        user: {
          ...emptyUser,
        },
        isLoggedIn: false,
      },
    };
    return preloadedState;
  }
};

export const logout = async (): Promise<any> => {
  const res = await instance.delete(`/auth`);
  return res.data;
};

export const getCategories = async () => {
  const res = await instance.get(`/category`);
  return res.data;
};

export const getAdminCategories = async () => {
  const res = await instance.get(`admin/categories/all`);
  return res.data;
};

export const getCategoryParameters = async (categoryId: string) => {
  const res = await instance.get(`categories/parameters/${categoryId}`);
  return res.data;
};

export const updateUserContactData = async (formData: FormData): Promise<any> => {
  const res = await instance.post(`/user`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const editUsersSubscriptions = async (payload: SubscriptionPayload): Promise<any> => {
  const res = await instance.post(`/user/subscription`, payload);
  return res.data;
};

export const editInvoiceAddress = async (payload: InvoiceAddressPayload): Promise<any> => {
  const res = await instance.post(`/user/invoice-address`, payload);
  return res.data;
};

export const resetPassword = async (params: { email: string }): Promise<any> => {
  const res = await instance.post(`/user/reset-password`, { ...params });
  return res.data;
};

export const initPasswordChange = async (): Promise<any> => {
  const res = await instance.post(`/user/init-password-change`);
  return res.data;
};

export const changePassword = async (params: {
  password: string;
  newPassword: string;
  newPassword2: string;
  verificationCode: string;
}): Promise<any> => {
  const res = await instance.post(`/user/password`, { ...params });
  return res.data;
};

export const changeResetedPassword = async (params: {
  verificationCode: string;
  password: string;
  repeatedPassword: string;
}): Promise<any> => {
  const res = await instance.post(`/user/reset-password/change`, { ...params });
  return res.data;
};

export const addOffer = async (formData: FormData) => {
  const res = await instance.post(`/offer`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getOffers = async () => {
  const res = await instance.get(`/offers`);
  return res.data;
};

export const getWatchedOffers = async () => {
  const res = await instance.get(`/offers/watched`);
  return res.data;
};

export const getOfferDetails = async (id: string) => {
  const res = await instance.get(`/offer/${id}`);
  return res.data;
};

export const getEditOfferDetails = async (id: string) => {
  const res = await instance.get(`/offer/edit/${id}`);
  return res.data;
};

export const updateOfferViewCounter = async (id: string) => {
  const res = await instance.post(`/offer/viewCount/${id}`);
  return res.data;
};

export const getUserOffers = async () => {
  const res = await instance.get(`/offers/user`);
  return res.data;
};

export const toggleWatchedOffer = async (offerId: string) => {
  const res = await instance.post(`/offer/watched`, { offerId });
  return res.data;
};

export const verifyUser = async (verificationCode: string) => {
  const res = await instance.post(`/user/verify`, { verificationCode });
  return res.data;
};

export const getUserStatus = async () => {
  const res = await instance.get(`/user/status`);
  return res.data;
};

export const searchOffers = async (params: ISearchParams) => {
  const res = await instance.post(`/offers`, { ...params });
  return res.data;
};

export const archiveThread = async (params: ArchiveThread) => {
  const res = await instance.post(`/thread/archive`, params);
  return res.data;
};

export const removeThreads = async (params: { threadIds: string[] }) => {
  const res = await instance.post(`/thread/remove`, params);
  return res.data;
};

export const logLastSeen = async (params: LogLastSeen) => {
  const res = await instance.post(`/thread/last-seen`, params);
  return res.data;
};

export const editUserUtils = async (params: UserUtilsPayload) => {
  const res = await instance.post(`/user/utils`, params);
  return res.data;
};

export const reportOffer = async (offerId: string) => {
  const res = await instance.get(`/offer/report/${offerId}`);
  return res.data;
};

export const getUsers = async (params?: IUsersPayload) => {
  const res = await instance.get(`/admin/users`, { params });
  return res.data;
};

export const updateUsersStatuses = async (params: IUpdateUsersStatuses) => {
  const res = await instance.post(`/admin/users`, params);
  return res.data;
};

export const deleteUsers = async (params: IUpdateUsersStatuses) => {
  const res = await instance.post(`/admin/users/delete`, params);
  return res.data;
};

export const getGeoData = async (params: GetGeoDataPayload): Promise<any> => {
  let url = new URL('https://nominatim.openstreetmap.org/search');
  url.searchParams.append('format', 'json');
  // @ts-ignore
  Object.keys(params).forEach((key: string) => url.searchParams.append(key, params[key]));
  const res = await fetch(url.href);
  return res.json();
};

export const addUsersFile = async (params: FormData): Promise<any> => {
  const res = await instance.post(`/file`, params);
  return res.data;
};

export const getUsersFiles = async (): Promise<any> => {
  const res = await instance.get(`/file`);
  return res.data;
};

export const deleteUsersFile = async (fileId: string): Promise<any> => {
  const res = await instance.delete(`/file`, { params: { fileId } });
  return res.data;
};

export const getDashboardCount = async () => {
  const res = await instance.get(`/user/dashboard-count`);
  return res.data;
};

export const sendContactMessage = async (params: IContactMessage) => {
  const res = await instance.post('/messages/contact', params);
  return res.data;
};

export const terminateOffer = async (offerId: string, closingReason: string) => {
  const res = await instance.get('offer/terminate/new', {
    params: { offerId, closingReason },
  });
  return res.data;
};

export const renewOffer = async (offerId: string) => {
  const res = await instance.get('offer/renew/new', { params: { offerId } });
  return res.data;
};

export const getNotAcceptedOffers = async () => {
  const res = await instance.get('admin');
  return res.data;
};

export const acceptOffers = async (offerIds: string[]) => {
  const res = await instance.post('admin/accept', { offerIds });
  return res.data;
};

export const deleteOffer = async (offerIds: string[]) => {
  const res = await instance.post(`admin/delete`, { offerIds });
  return res.data;
};

export const getReportedOffers = async () => {
  const res = await instance.get('admin/reported');
  return res.data;
};

export const markReportsAsRead = async (offerIds: string[]) => {
  const res = await instance.post('admin/mark-reports', { offerIds });
  return res.data;
};

export const addCategory = async (formData: FormData): Promise<any> => {
  const res = await instance.post(`/admin/categories`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};
export interface NewCategoryParameter {
  categoryId: string;
  label: string;
  type: string;
  options?: string[];
  isRequired: boolean;
  defaultValue?: string;
  isCheckedByDefault?: boolean;
}
export const addCategoryParameter = async (parameter: NewCategoryParameter) => {
  const res = await instance.post(`/admin/categories/parameter`, parameter);
  return res.data;
};

export const selectAccountType = async (isCompany: boolean) => {
  const res = await instance.post('user/account-type', { isCompany });
  return res.data;
};

export const createPayment = async (price: number, currency: string) => {
  const res = await instance.post('/payment', {
    price,
    currency,
  });
  return res.data;
};

export const getTransactions = async () => {
  const res = await instance.get('/payments');
  return res.data;
};

export const archiveOffer = async (payload: { offerId: string; isArchive: boolean }) => {
  const res = await instance.post('offer/archive', payload);
  return res.data;
};

export const findCategory = async (id: string) => {
  const res = await instance.get(`/category/${id}`);
  return res.data;
};

export const getWatchedSearches = async () => {
  const res = await instance.get(`/watched-searches`);
  return res.data;
};

export const saveSearch = async (params: IWatchSearchParams) => {
  const res = await instance.post(`/watched-searches`, { ...params });
  return res.data;
};

export const deleteSavedSearch = async (watchedSearchId: string) => {
  const res = await instance.delete(`/watched-searches/${watchedSearchId}`);
  return res.data;
};

export const watchedSearchRegisterVisit = async (watchedSearchId: string) => {
  const res = await instance.patch(`/watched-searches/${watchedSearchId}`);
  return res.data;
};

export const updateOffer = async (id: string, formData: any) => {
  const res = await instance.post(`/offer/update/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

interface IUpdateCategoryProps {
  id: string;
  label: string;
}
export const updateCategoryName = async (data: IUpdateCategoryProps): Promise<any> => {
  const res = await instance.post(`/admin/category`, data);

  return res.data;
};

export interface IUpdateCategoryParameterProps {
  id: string;
  label: string;
  isRequired: boolean;
  defaultValue: string;
  isCheckedByDefault: boolean;
}
export const updateCategoryParameter = async (
  data: IUpdateCategoryParameterProps
): Promise<any> => {
  const res = await instance.post(`/admin/category/parameter`, data);

  return res.data;
};

export const deleteCategoryParameter = async (id: string): Promise<any> => {
  const res = await instance.delete(`/admin/category/parameter/${id}`);
  return res.data;
};

export interface IUpdateCategoryParameterOptionsProps {
  parameterId: string;
  options: any[];
}

export const updateCategoryParameterOptions = async (
  data: IUpdateCategoryParameterOptionsProps
): Promise<any> => {
  const res = await instance.post(`/admin/category/parameter/options`, data);

  return res.data;
};

export const deleteCategory = async (id: string): Promise<any> => {
  const res = await instance.delete(`/admin/category/${id}`);
  return res.data;
};

export const initDeleteAccount = async (): Promise<any> => {
  const res = await instance.post(`/user/delete-account`);
  return res.data;
};

export const deleteAccount = async (data: { verificationCode: string }): Promise<any> => {
  const res = await instance.post(`/user/delete-account/confirm`, { ...data });
  return res.data;
};

export const getSubcategory = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/subcategory/${id}`);
  return res.data;
};

export const getFilters = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/filters/${id}`);
  return res.data;
};

export const userDeleteOffer = async (id: string) => {
  const res = await instance.delete(`user/offer/${id}`);
  return res.data;
};

export const getAllTags = async () => {
  const res = await instance.get(`admin/tags/all`);
  return res.data;
};

export const addTag = async (params: { label: string }) => {
  const res = await instance.post(`admin/tags`, params);
  return res.data;
};

export const deleteTag = async (params: { id: string }) => {
  const res = await instance.post(`admin/tags/delete`, params);
  return res.data;
};

export const getOfferTags = async (params: { offerId: string }) => {
  const res = await instance.post(`admin/offer-tags/selected`, params);
  return res.data;
};

export const handleOfferTags = async (params: { offerId: string; selectValue: string[] }) => {
  const res = await instance.post(`admin/offer-tags`, params);
  return res.data;
};

export const getIsOfferReportedByUser = async (id: string) => {
  const res = await instance.get(`/offer/reported/${id}`);
  return res.data;
};

export const getIsThreadCreated = async (params: { offerId: string; offererId: string }) => {
  const res = await instance.post(`/offer/check-thread`, params);
  return res.data;
};

export const sendMessage = async (params: SendMessage) => {
  const res = await instance.post('/messages', params);
  return res.data;
};

import MenuItem from '../UserDashboard/MenuItem/MenuItem';
import { useHistory } from 'react-router-dom';
import { ButtonProps, adminButtons } from '../UserDashboard/Menu/Buttons';
import { logout } from '../../redux/auth/actions';
import { useDispatch } from 'react-redux';
import * as S from '../UserDashboard/Menu/Menu.styles';

export default function Menu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <S.Wrapper>
        <S.ButtonsWrapper>
          {adminButtons.map((button: ButtonProps) => {
            const { text, href } = button;
            return <MenuItem key={text} {...button} isActive={pathname === href} count={0} />;
          })}
          <S.LogoutButton onClick={handleLogout}>Wyloguj</S.LogoutButton>
        </S.ButtonsWrapper>
        <div
          style={{
            width: '100%',
            height: '40px',
            position: 'absolute',
            bottom: '-20px',
            backgroundColor: '#11244D',
            maxWidth: '286px',
            left: '0',
          }}
        />
      </S.Wrapper>
    </div>
  );
}

const standardFlex = `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const columnFlex = `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const centeredFlex = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const plainButton = `
  border: none;
  background-color: transparent;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const standardFont12 = `
font-weight: 600;
font-size: 12px;
line-height: 125%;
`;

const standardFont13 = `
font-weight: 600;
font-size: 13px;
letter-spacing: 0.3px;
`;

const standardFont14 = `
font-weight: 600;
font-size: 14px;
letter-spacing: 0.3px;
`;

const standardFont16 = `
font-size: 16px;
line-height: 150%;
font-weight: 400;
`;

const standardFont20 = `
font-weight: 500;
font-size: 20px;
line-height: 150%;
`;

const standardFont32 = `
  font-weight: 500;
  font-size: 32px;
  line-height: 125%;
`;

const styles = {
  standardFlex,
  centeredFlex,
  columnFlex,
  plainButton,
  standardFont12,
  standardFont13,
  standardFont14,
  standardFont16,
  standardFont20,
  standardFont32,
};

export default styles;

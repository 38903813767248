import { memo } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useIsUserAdmin } from '../../redux/auth/selectors';
import * as S from './NavProfile.style';

export interface NavProfileProps {
  avatar: string;
  name: string;
  email: string;
  isLoggedIn: boolean;
  nickname: string;
  hasNotifications: boolean;
}

export default memo(
  ({ name, avatar, email, isLoggedIn, nickname, hasNotifications }: NavProfileProps) => {
    const isAdmin = useSelector(useIsUserAdmin);
    const displayedName = nickname ? nickname : name;
    const displayedAvatar = avatar ? <S.Avatar src={avatar} alt="avatar" /> : <S.MockAvatar />;
    const history = useHistory();
    const redirectURL = isAdmin ? '/admin-dashboard/offers' : '/user-dashboard/offers';
    return (
      <>
        {isLoggedIn ? (
          <>
            <S.Wrapper onClick={() => history.push(redirectURL)}>
              <S.AvatarWrapper>
                {hasNotifications && <S.Status />}
                {displayedAvatar}
              </S.AvatarWrapper>
              <S.UserDataWrapper>
                <S.NameRow>
                  <S.UserName>{displayedName}</S.UserName>
                </S.NameRow>
                <S.UserMail>{email}</S.UserMail>
              </S.UserDataWrapper>
            </S.Wrapper>
          </>
        ) : (
          <S.LinkedWrapper to={'/login'}>
            <S.Person />
            <S.PersonLabel>Moje konto</S.PersonLabel>
          </S.LinkedWrapper>
        )}
      </>
    );
  }
);

import { ICategory } from '../redux/utils/types';

const emptyCategory = { label: '', icon: '', id: 0 };

export default function getCategory(categories?: ICategory[]) {
  if (!categories || categories.length === 0) {
    return emptyCategory;
  }
  return categories.reduce((currentValue: ICategory, acc: ICategory) => {
    const { id } = acc;
    const { id: currentId } = currentValue;
    if (currentId > id) {
      return currentValue;
    }
    return acc;
  }, categories[0]);
}

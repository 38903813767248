import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 734px;
  margin-bottom: 75px;
  width: 100%;
`;

export const SearchHeader = styled.div`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  color: #373f41;
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 24px;
  padding-top: auto;
`;

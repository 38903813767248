import styled from 'styled-components';
import WaveIcon from '../../../assets/Waves/wave-white.svg';

export const ExternalWrapper = styled.div`
  background-color: rgba(17, 36, 77, 1);
  /* margin-top: 24px; */
  @media (min-width: 981px) {
    margin-bottom: 52px;
  }
  overflow: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  max-width: 1152px;
  justify-content: center;
  width: 100%;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 0 0 18px;
  box-sizing: border-box;
  position: relative;
`;

export const Header = styled.h1`
  margin: 0 auto;
  text-align: center;
  text-shadow: 2px 2px 4px #00000090;
  z-index: 1;
  color: white;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 34px;
  line-height: 118%;
  letter-spacing: -0.4px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  z-index: 0;
  filter: saturate(0.8) opacity(0.6);
  right: 0;
  @media (min-width: 600px) {
    filter: saturate(1) opacity(1);
    position: relative;
  }
  height: 156px;
  max-width: 324px;
  width: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: left;
`;

export const WaveContainer = styled.div`
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 2;
`;

export const AbsoluteImage = styled.img``;

export const LeftOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const LeftTwoImageContainer = styled.div`
  position: absolute;
  /* top: 0; */
  top: -70%;
  left: 0;
`;

export const RightOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const RightTwoImageContainer = styled.div`
  position: absolute;
  /* top: 0; */
  top: -70%;
  right: 0;
`;

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  @media (min-width: 768px) {
    right: 25%;
  }
`;

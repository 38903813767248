import { Alert, Button, Modal, Popconfirm, Space, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import ParameterModalComponent, { ICategoryParameterProps } from './ParameterModal';
import { ColumnType, FilterConfirmProps } from 'antd/es/table/interface';
import { AiOutlineSearch } from 'react-icons/ai';
import Highlighter from 'react-highlight-words';
import NewCategoryForm from './NewCategoryForm';
import NewParameterForm from './NewParameterForm';
import { deleteCategory, updateCategoryName } from '../../../../redux/auth/actions';
import * as S from './CategoryTable.styles';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Record {
  id: string;
  label: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  id: string;
  label: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Record;
  record: Record;
  handleSave: (record: Record) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  id,
  label,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${label} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  id: string;
  label: string;
  children?: DataType[];
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface ICategoryTable {
  data: DataType[];
  allCategories: any[];
  allCatsWithChildren: any[];
  fetchData: () => void;
}

type DataIndex = keyof DataType;

const CategoryTable = (props: ICategoryTable) => {
  const { data = [], allCategories = [], allCatsWithChildren = [], fetchData } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewCategoryModalOpen, setIsNewCategoryModalOpen] = useState(false);
  const [isNewParameterModalOpen, setIsNewParameterModalOpen] = useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [currentParameter, setCurrentParameter] = useState<ICategoryParameterProps>();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const [dataToEdit, setDataToEdit] = useState<{ id: string; label: string }>({
    id: '',
    label: '',
  });

  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);

  const openEditCategoryModal = (id: string, label: string) => {
    if (!id) {
      return;
    }
    setDataToEdit({ id, label });
    setIsEditCategoryModalOpen(true);
  };

  const closeEditCategoryModal = () => {
    setDataToEdit({ id: '', label: '' });
    setIsEditCategoryModalOpen(false);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<AiOutlineSearch />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (data) {
      setDataSource(data);
    }
  }, [data]);

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const handleAddNewParam = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    setIsNewParameterModalOpen(true);
  };
  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Kategoria',
      ///@ts-ignore
      dataIndex: 'label',
      key: 'label',
      ...getColumnSearchProps('label'),
    },
    {
      title: 'Parametry',
      dataIndex: 'dynamicOptions',
      key: 'dynamicOptions',
      render: (_, record: any) => (
        <S.ParameterButtonsContainer>
          {record.dynamicOptions?.map((dynamicOption: any, index: any) => (
            <S.ParameterButton
              key={index}
              onClick={() => {
                setCurrentParameter(dynamicOption);
                setIsModalOpen(true);
              }}
            >
              {dynamicOption.label}
            </S.ParameterButton>
          ))}
          <Button onClick={() => handleAddNewParam(record.id)}>Dodaj nowy</Button>
        </S.ParameterButtonsContainer>
      ),
    },
    {
      title: 'Akcje',
      dataIndex: 'actions',
      render: (_, record: any) => {
        const { id, label } = record;
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            {/* <Popconfirm
              title="Na pewno Zapisac?"
              onConfirm={() => handleUpdateCategoryName(id, label)}
            >
              <a style={{ color: 'rgba(75,65,196,1)' }}>Zapisz</a>
            </Popconfirm> */}
            <a
              style={{ color: 'rgba(75,65,196,1)' }}
              onClick={() => openEditCategoryModal(id, label)}
            >
              Edytuj
            </a>
            <Popconfirm title="Na pewno usunąć?" onConfirm={() => handleDeleteCategory(id)}>
              <a style={{ color: '#b70000' }}>Usuń</a>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: 'Typ',
      dataIndex: 'offerType',
      key: 'offerType',
      render: (_, record: any) => {
        const { offerType } = record;
        return offerType === 'ANNOUNCEMENT' ? 'Zajęcia' : 'Bazarek';
      },
      filters: [
        {
          text: 'Zajęcia',
          value: 'ANNOUNCEMENT',
        },
        {
          text: 'Bazarek',
          value: 'BAZAAR',
        },
      ],
      onFilter: (value: any, record: any) => record.offerType.startsWith(value),
      sorter: (a: any, b: any) => a.offerType.localeCompare(b.offerType),
    },
  ];

  // const handleAdd = () => {
  //   const newData: DataType = {
  //     id: '',
  //     label: `test`,
  //   };
  //   setDataSource([...dataSource, newData]);
  //   setCount(count + 1);
  // }; TODO

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  ///TODO dispatch, toast, close modal for both actions
  const handleUpdateCategoryName = async (id: string, label: string) => {
    await updateCategoryName({ id, label });
    fetchData();
  };

  const [error, setError] = useState('');
  const renderErrors = () => {
    return (
      <Space direction="vertical" style={{ width: 'auto', maxWidth: '300px' }}>
        <Alert message={error} type="warning" />
      </Space>
    );
  };

  const handleDeleteCategory = async (id: string) => {
    await deleteCategory(id).then((res) => {
      if (res.status === 401) {
        setError(res.error);
        return;
      }
    });
    fetchData();
  };

  return (
    <S.TableWrapper>
      <S.TopWrapper>
        <Button
          onClick={() => setIsNewCategoryModalOpen(true)}
          type="primary"
          style={{ backgroundColor: 'rgba(75,65,196,1)', height: '40px' }}
        >
          Dodaj kategorie
        </Button>
        {error && <div>{renderErrors()}</div>}
      </S.TopWrapper>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        rowKey={'id'}
        expandable={{
          childrenColumnName: 'categories',
        }}
      />
      <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)} centered footer={null}>
        {currentParameter ? (
          <ParameterModalComponent
            {...currentParameter}
            action={() => {
              setIsModalOpen(false);
              fetchData();
            }}
          />
        ) : null}
      </Modal>
      <Modal
        open={isNewCategoryModalOpen}
        onCancel={() => setIsNewCategoryModalOpen(false)}
        centered
        footer={null}
      >
        <NewCategoryForm
          allCategories={allCategories}
          allCatsWithChildren={allCatsWithChildren}
          closeModal={() => setIsNewCategoryModalOpen(false)}
        />
      </Modal>
      <Modal
        open={isNewParameterModalOpen}
        onCancel={() => setIsNewParameterModalOpen(false)}
        centered
        footer={null}
      >
        <NewParameterForm
          categoryId={selectedCategoryId}
          closeModal={() => {
            setIsNewParameterModalOpen(false);
            fetchData();
          }}
        />
      </Modal>
      <Modal
        open={isEditCategoryModalOpen}
        onCancel={closeEditCategoryModal}
        centered
        onOk={() => {
          handleUpdateCategoryName(dataToEdit?.id, dataToEdit?.label);
          closeEditCategoryModal();
        }}
        okButtonProps={{ disabled: !dataToEdit?.label || !dataToEdit?.id }}
      >
        <Space direction="horizontal">
          <Input
            addonBefore="Nazwa"
            value={dataToEdit?.label}
            onChange={(e: any) => setDataToEdit({ ...dataToEdit, label: e.target.value })}
          />
        </Space>
      </Modal>
    </S.TableWrapper>
  );
};

export default CategoryTable;

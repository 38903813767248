import NavProfile from '../NavProfile/NavProfile';
import { useSelector } from 'react-redux';
import { authState } from '../../redux/auth/selectors';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useState } from 'react';
import SlideComponent from '../SlideComponent/SlideComponent';
import { OFFER_TYPE } from '../../redux/offer/types';
import NavbarBanner from './NavbarBanner';
import * as S from './Navbar.style';

const Navbar = () => {
  const {
    isLoggedIn,
    user: {
      name,
      nickname,
      avatar,
      email,
      notifications: { count },
    },
  } = useSelector(authState);

  const history = useHistory();

  const [isAnnouncementHovered, setIsAnnouncementHovered] = useState(false);
  const [isBazaarHovered, setIsBaazarHovered] = useState(false);
  const [isAnnouncementOpen, setIsAnnouncementOpen] = useState(false);
  const [isBazaarOpen, setIsBazaarOpen] = useState(false);
  const [pickedCategoryType, setPickedCategoryType] = useState('');

  const handleRedirect = () => {
    if (!nickname) {
      history.push('/user-dashboard/settings');
    } else if (email) {
      history.push('/create/step-one');
    } else {
      history.push('/login');
    }
  };
  const isTablet = useMediaQuery('(min-width: 768px)');
  const buttonText = '+ Dodaj ogłoszenie za darmo';

  const unuseSecondOption = () => {
    if (isAnnouncementHovered || isAnnouncementOpen) {
      setIsAnnouncementHovered(false);
      setIsAnnouncementOpen(false);
    } else if (isBazaarHovered || isBazaarOpen) {
      setIsBaazarHovered(false);
      setIsBazaarOpen(false);
    }
  };

  const handleHoverEffect = (type: string) => {
    unuseSecondOption();
    if (type === 'Announcement') {
      setPickedCategoryType('Announcement');
      setIsAnnouncementHovered(true);
      setIsAnnouncementOpen(true);
    } else if (type === 'Bazaar') {
      setPickedCategoryType('Bazaar');
      setIsBaazarHovered(true);
      setIsBazaarOpen(true);
    }
    return;
  };

  const pickedOfferType =
    pickedCategoryType === 'Bazaar' ? OFFER_TYPE.BAZAAR : OFFER_TYPE.ANNOUNCEMENT;
  const open = isAnnouncementOpen || isBazaarOpen;

  const closeSlideAndClearHoverEffect = () => {
    setIsAnnouncementOpen(false);
    setIsAnnouncementHovered(false);
    setIsBazaarOpen(false);
    setIsBaazarHovered(false);
  };

  return (
    <S.ExternalWrapper
      onMouseLeave={() => {
        closeSlideAndClearHoverEffect();
      }}
      isOpen={isAnnouncementOpen || isBazaarOpen}
    >
      <S.Wrapper>
        <S.TopLeftWrapper>
          <S.LogoWrapper to="/">
            <S.BBLogo />
          </S.LogoWrapper>
          {isTablet && (
            <S.DropdownsWrapper>
              <S.DropdownContainer
                onClick={() => history.push('/search?offerType=ANNOUNCEMENT')}
                onMouseOver={() => {
                  handleHoverEffect('Announcement');
                }}
              >
                <S.DropdownName isHovered={isAnnouncementHovered}>Zajęcia</S.DropdownName>
                <S.DropdownArrow isHovered={isAnnouncementHovered} />
              </S.DropdownContainer>
              <S.DropdownContainer
                onClick={() => history.push('/search?offerType=BAZAAR')}
                onMouseOver={() => {
                  handleHoverEffect('Bazaar');
                }}
              >
                <S.DropdownName isHovered={isBazaarHovered}>Bazarek</S.DropdownName>
                <S.DropdownArrow isHovered={isBazaarHovered} />
              </S.DropdownContainer>
            </S.DropdownsWrapper>
          )}
        </S.TopLeftWrapper>
        <S.RightWrapper>
          <NavProfile
            {...{
              avatar,
              name,
              email,
              isLoggedIn,
              nickname,
              hasNotifications: count > 0,
            }}
          />
          <S.AddOfferButton onClick={handleRedirect}>{buttonText}</S.AddOfferButton>
        </S.RightWrapper>
      </S.Wrapper>
      <SlideComponent
        open={open}
        offerType={pickedOfferType}
        onSearch={() => {
          closeSlideAndClearHoverEffect();
        }}
      />
      <NavbarBanner />
    </S.ExternalWrapper>
  );
};

export default Navbar;

import FAQicons from '../../assets/FAQicons/FAQicons';

export interface IArticle {
  title: string;
  body: string;
  categoryId: string;
  id: string;
}

export interface IFaqCategory {
  title: string;
  icon: string;
  id: string;
  articles: IArticle[];
}

const article1 = {
  id: 'Czy-trzeba-rejestrowac',
  title: 'Czy żeby korzystać z portalu Malutcy.pl muszę się zarejestrować?',
  body: `
  Żeby korzystać z portalu Malutcy.pl musisz dodać i aktywować konto w serwisie.
  `,
  categoryId: 'rejestracja',
};

const article2 = {
  id: 'jak-tworzyc-konto',
  title: 'Jak utworzyć konto w serwisie Malutcy.pl?',
  body: `Aby utworzyć konto w serwisie:

  Krok 1. Na stronie głównej portalu wybierz zakładkę Moje konto → Rejestracja.
  
  Krok 2. Podaj swój aktualny adres e-mail, utwórz hasło, a następnie zaakceptuj regulamin serwisu.
  
  Krok 3. Kliknij przycisk Zarejestruj się.
  
  Krok 4. Kliknij link aktywacyjny, który został wysłany na podany przez Ciebie adres e-mail. Jeżeli nie otrzymałeś maila z linkiem aktywacyjnym, skontaktuj się z nami przez formularz kontaktowy.
  
`,
  categoryId: 'rejestracja',
};

const article3 = {
  id: 'droga-do-sukcesurodzaj-konta',
  title: 'Czy podczas tworzenia konta w portalu muszę wybrać rodzaj konta - prywatne czy firmowe?',
  body: `Podczas rejestracji konta w serwisie Malutcy.pl musisz wybrać jak będziesz korzystać z portalu - jako osoba prywatna czy jako przedsiębiorca. Jeśli posiadasz działalność gospodarczą i konto w portalu będziesz wykorzystywać do jej prowadzenia, wtedy należy zarejestrować konto firmowe.

  Na jednym koncie nie można sprzedawać przedmiotów prywatnych i przedmiotów w ramach prowadzenia działalności gospodarczej.
  
`,
  categoryId: 'rejestracja',
};

const article4 = {
  id: 'jak-zalogowac',
  title: 'Jak mogę zalogować się w portalu Malutcy.pl?',
  body: `Na swoje konto możesz zalogować się wykorzystując e-mail, konto Google, konto Facebook. 

  e-mail
  
  Krok 1. Kliknij Moje konto i wybierz zakładkę Zaloguj się.
  Krok 2. Wpisz adres e-mail i hasło, które zostało ustawione podczas zakładania konta. 
  Krok 3. Kliknij przycisk Zaloguj się. 
  
  konto Google
  
  Krok 1. Kliknij Moje konto i wybierz zakładkę Zaloguj się.
  Krok 2. Wybierz przycisk Kontynuuj z Google.
  Krok 3. Wpisz login i hasło, których używasz do logowania na koncie Google.
  Krok 4. Kliknij przycisk Zaloguj się. 
  
  Po zalogowaniu się przez Google, w serwisie Malutcy.pl powstanie konto założone na Twój adres e-mail.
  
  konto Facebook
  
  Krok 1. Kliknij Moje konto i wybierz zakładkę Zaloguj się.
  Krok 2. Wybierz przycisk Kontynuuj z Facebook.
  Krok 3. Wpisz login i hasło, których używasz do logowania na koncie Facebook.
  Krok 4. Kliknij przycisk Zaloguj się. 
  
  Po zalogowaniu się poprzez Facebook, w serwisie Malutcy.pl powstanie konto założone na ten sam adres e-mail, którym logujesz się do Facebooka.
  
`,
  categoryId: 'rejestracja',
};

const article5 = {
  id: 'zapomniane-haslo',
  title: 'Zapomniałem hasła do portalu Malutcy.pl, jak mogę je odzyskać?',
  body: `Aby zresetować hasło:

  Krok 1. Kliknij Moje konto i wybierz zakładkę Zaloguj się.
  Krok 2. Wybierz Zresetuj hasło w dolnej części strony.
  Krok 3. Podaj swój adres e-mail podany podczas rejestracji konta na portalu Malutcy.pl.
  Krok 4. Kliknij przycisk Zresetuj hasło.
  Krok 5. POCZTA
  
`,
  categoryId: 'rejestracja',
};

const article6 = {
  id: 'zmiana-hasla',
  title: 'Znam swoje hasło do portalu Malautcy.pl, ale chę je zmienić, jak mogę to zrobić?',
  body: `Aby zmienić hasło do portalu Malutcy.pl zaloguj się na swoje konto.
  Wybierz Moje konto → Ustawienia → Zmiana hasła.  
`,
  categoryId: 'dane',
};

const article7 = {
  id: 'usuwanie-konta',
  title: 'Jak mogę usunąć konto z portalu Malutcy.pl?',
  body: `Aby usunąć konto w serwisie:

  Krok 1. Zaloguj się na swoje konto w serwisie. 
  Krok 2. Wejdź w zakładkę Ustawienia. Na dole strony dostępny jest przycisk Usuń konto. 
  
`,
  categoryId: 'dane',
};

const article8 = {
  id: 'zmiana-danych',
  title: 'Jak zmienić swoje dane kontaktowe?',
  body: `Tworząc konto na portalu Malutcy.pl musisz uzupełnić swoje dane kontaktowe. W celu ich edytowania wybierz Moje konto → Ustawienia. Po wprowadzeniu zmian kliknij Zapisz zmiany.
`,
  categoryId: 'dane',
};

const article9 = {
  id: 'kto-moze-dodac-ogloszenie',
  title: 'Kto może dodać ogłoszenie w portalu Malutcy.pl?',
  body: ` Dodać ogłoszenie może każdy użytkownik posiadający aktywne konto w serwisie.
`,
  categoryId: 'ogloszenia',
};

const article10 = {
  id: 'czy-ogloszenie-darmowe',
  title: 'Czy dodanie ogłoszenia na portalu Malutcy.pl jest darmowe?',
  body: ` Dodawanie ogłoszeń jest darmowe.
`,
  categoryId: 'ogloszenia',
};

const article11 = {
  id: 'limit-darmowych',
  title: 'Czy jest limit darmowych ogłoszeń?',
  body: `Nie ma limitu darmowych ogłoszeń.
`,
  categoryId: 'ogloszenia',
};

const article12 = {
  id: 'co-mozna-kupic',
  title: 'Co mogę sprzedać lub kupić na Bazarku?',
  body: `Na naszym Bazarku można kupować i sprzedawać nowe i używane przedmioty potrzebne zarówno do wykonywania zajęć dodatkowych, jak również do codziennego funkcjonowania dziecka.
`,
  categoryId: 'ogloszenia',
};

const article13 = {
  id: 'powiadomienia',
  title: 'Czy mogę otrzymywać powiadomienia dotyczące interesujących mnie ogłoszeń?',
  body: 'Wszystkie powiadomienia możesz włączyć w Panelu użytkownika. Poinformujemy Cię drogą mailową, gdy pojawią się ogłoszenia, które pasują do przedmiotów oraz zajęć, które obserwujesz.',
  categoryId: 'ogloszenia',
};

const article14 = {
  id: 'dodawanie-zajec',
  title: 'Jakie zajęcia mogę dodawać na stronie?',
  body: `Chcemy dać użytkownikom szukającym dodatkowych zajęć dla swoich dzieci różne możliwości spędzania wolnego czasu. Na portalu można dodawać m.in. zajęcia sportowe, artystyczne, edukacyjne, taneczne i wiele innych. Wszystkie kategorie wymienione są na stronie po kliknięciu zakładki Zajęcia. Jeżeli uważasz, że Twoje zajęcia nie wpasowują się w żadną z kategorii, możesz zamieścić je w kategorii Inne.
`,
  categoryId: 'ogloszenia',
};

export const categories: IFaqCategory[] = [
  {
    title: 'Rejestracja i logowanie',
    icon: FAQicons.hints,
    id: 'rejestracja',
    articles: [article1, article2, article3, article4, article5],
  },
  {
    title: 'Dane i ustawienia konta',
    icon: FAQicons.payments,
    id: 'dane',
    articles: [article6, article7, article8],
  },
  {
    title: 'Ogłoszenia na portalu',
    icon: FAQicons.personalData,
    id: 'ogloszenia',
    articles: [article9, article10, article11, article12, article13, article14],
  },
  // {
  //   title: 'Bezpieczeństwo',
  //   icon: FAQicons.security,
  //   id: 'bezpieczenstwo',
  //   articles: [],
  // },
  // {
  //   title: 'Zmiany w serwisie',
  //   icon: FAQicons.changes,
  //   id: 'zmainy',
  //   articles: [],
  // },
  // {
  //   title: 'Porady',
  //   icon: FAQicons.advices,
  //   id: 'inne',
  //   articles: [],
  // },
];

export const articles = [
  article1,
  article2,
  article3,
  article4,
  article5,
  article6,
  article7,
  article8,
  article9,
  article10,
  article11,
  article12,
  article13,
  article14,
];

export const popular = [article1, article7, article12];

// const segments = [
//   {
//     title: 'Porady',
//     icon: FAQicons.hints,
//     items: [
//       {
//         text: 'Wyszukiwanie ogłoszeń',
//         href: '#',
//       },
//       {
//         text: 'Jak skontaktować się z ogłoszeniodawcą?',
//         href: '#',
//       },
//       {
//         text: 'Pięć kroków by zostać wzorowym sprzedawcą',
//         href: '#',
//       },
//       {
//         text: 'Zasady bezpiecznej transakcji',
//         href: '#',
//       },
//       {
//         text: 'Problem techniczny - rozwiąż go sam',
//         href: '#',
//       },
//       {
//         text: 'Dodawanie ogłoszeń',
//         href: '#',
//       },
//     ],
//   },
//   {
//     title: 'Płatności',
//     icon: FAQicons.payments,
//     items: [
//       {
//         text: 'Cennik płatnych ogłoszeń',
//         href: '#',
//       },
//       {
//         text: 'Sposoby płatności za ogłoszenie',
//         href: '#',
//       },
//       {
//         text: 'Ogłoszenie Premium',
//         href: '#',
//       },
//       {
//         text: 'Płatność SMS za dodawanie ogłoszeń',
//         href: '#',
//       },
//       {
//         text: 'Zasady zwrotów za płatne ogłoszenia',
//         href: '#',
//       },
//       {
//         text: 'Płatności za promowanie ogłoszeń',
//         href: '#',
//       },
//     ],
//   },
//   {
//     title: 'Ochrona danych osobowych',
//     icon: FAQicons.personalData,
//     items: [
//       {
//         text: 'Co mozna zrobić, aby usunąć, wyłączyć, lub zablokować cookies?',
//         href: '#',
//       },
//       {
//         text: 'Usunięcie danych osobowych',
//         href: '#',
//       },
//       {
//         text: 'Dostęp do danych osobowych',
//         href: '#',
//       },
//       {
//         text: 'Jakie są moje prawa w związku z ochroną danych osobowych?',
//         href: '#',
//       },
//     ],
//   },
//   {
//     title: 'Bezpieczeństwo',
//     icon: FAQicons.security,
//     items: [
//       {
//         text: 'Phishing - podszywanie się pod prawdziew firmy',
//         href: '#',
//       },
//       {
//         text: 'Oszustwo nigeryjskie',
//         href: '#',
//       },
//       {
//         text: 'Wyłudzenie danych',
//         href: '#',
//       },
//       {
//         text: 'Kupujący nie odebrał przesyłki',
//         href: '#',
//       },
//       {
//         text: 'Wyłudzenie pieniędzy',
//         href: '#',
//       },
//       {
//         text: 'Fałszywe wpłaty i potwierdzenia przelewów',
//         href: '#',
//       },
//       {
//         text: 'Fałszywe noclegi i mieszkania',
//         href: '#',
//       },
//     ],
//   },
//   {
//     title: 'Zmiany w serwisie',
//     icon: FAQicons.changes,
//     items: [
//       {
//         text: 'Zasady zamieszczania ogłoszeń',
//         href: '#',
//       },
//       {
//         text: 'Dodawanie ogłoszeń w kategoriach objętych limitem',
//         href: '#',
//       },
//       {
//         text: 'Lista przedmiotów zabronionych',
//         href: '#',
//       },
//     ],
//   },
//   {
//     title: 'Porady',
//     icon: FAQicons.advices,
//     items: [
//       {
//         text: 'Wyszukiwanie ogłoszeń',
//         href: '#',
//       },
//       {
//         text: 'Jak skontaktować się z ogłoszeniodawcą?',
//         href: '#',
//       },
//       {
//         text: 'Pięć kroków by zostać wzorowym sprzedawcą',
//         href: '#',
//       },
//       {
//         text: 'Zasady bezpiecznej transakcji',
//         href: '#',
//       },
//       {
//         text: 'Problem techniczny - rozwiąż go sam',
//         href: '#',
//       },
//       {
//         text: 'Dodawanie ogłoszeń',
//         href: '#',
//       },
//     ],
//   },
// ];

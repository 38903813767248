import FeaturesList from '../FeaturesList/FeaturesList';
import Quote from '../Quote/Quote';
import rightImage from '../../../assets/Login/rightImg.svg';
import person from '../../../assets/Login/person.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './PageWrapper.styles';

const PageWrapper = (props: any) => {
  const isTablet = useMediaQuery('(min-width: 768px)');
  const isDesktop = useMediaQuery('(min-width: 1250px)');
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        {isDesktop && (
          <S.RightImgContainer>
            <S.AbsoluteImg src={rightImage} />
          </S.RightImgContainer>
        )}
        {isTablet && (
          <S.PersonContainer>
            <S.AbsoluteImg src={person} />
          </S.PersonContainer>
        )}
        {props.children}
        <S.DescriptionWrapper>
          <FeaturesList />
          <Quote />
        </S.DescriptionWrapper>
      </S.Wrapper>
      <div
        style={{
          width: '100%',
          height: '40px',
          position: 'absolute',
          right: '0',
          bottom: '-20px',
          backgroundColor: 'rgba(233,245,245,1)',
        }}
      />
    </S.ExternalWrapper>
  );
};

export default PageWrapper;

import { IImage } from '../offer/types';

export interface AuthReducerState {
  changePasswordErrorMessage: string;
  emailForPasswordReset: string;
  // TODO: remove when mailchimp
  password: string;
  isLoading: boolean;
  loadingField: LOADING_FIELD;
  isLoggedIn: boolean;
  isSendingMessage: boolean;
  signInErrorMessage: string;
  messagesQuery: string;
  selectedThread?: Thread;
  threads: Threads;
  user: User;
  users?: { count: number; rows: User[] };
  messageCategory: MESSAGE_CATEGORY;
  dashboard: {
    offersCount: number;
    watchedCount: number;
    messagesCount: number;
  };
}

export enum LOADING_FIELD {
  ANY = 'ANY',
  CONTACT_DATA = 'CONTACT_DATA',
  PASSWORD_CHANGE = 'PASSWORD_CHANGE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  INVOICE_DATA = 'INVOICE_DATA',
  CV_FILE = 'CV_FILE',
  CONTACT_FORM = 'CONTACT_FORM',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
}

export enum USER_STATUS {
  NOT_VERIFIED = 'NOT_VERIFIED',
  DISABLED = 'DISABLED',
  ACTIVE = 'ACTIVE',
  NEW_USER = 'NEW_USER',
}

export enum USER_ROLE {
  USER = 'USER',
  COMPANY = 'COMPANY',
  ADMIN = 'ADMIN',
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  phone: string;
  isOnline: boolean;
  name: string;
  surname: string;
  nickname: string;
  localisation: string;
  isPasswordSet: boolean;
  watchedOffers: string[];
  companyNIP: string;
  companyName: string;
  companyAddress: string;
  companyZipCode: string;
  companyCity: string;
  isSubscribedToTradeAndMarketing: boolean;
  isSubscribedToServicePartners: boolean;
  isSubscribedToOfferStatistics: boolean;
  role: USER_ROLE;
  status: USER_STATUS;
  notifications: {
    count: number;
    rows: any[];
  };
  lat: number;
  lon: number;
  files: IFile[];
  payments?: {
    current: IPayment[];
    archived: IPayment[];
  };
  balance: number;
  hasSeenDashboardWelcome: boolean;
  hasAcceptedCookies: boolean;
}

export interface IPayment {
  id: string;
  userId: string;
  value: number;
  title: string;
  paymentIntentId?: string;
  createdAt: Date;
}

export interface Interlocutor {
  name: string;
  surname: string;
  nickname: string;
  avatar: string;
}

export interface MessageOffer {
  images: string[];
  title: string;
  price: string;
}
export interface Message {
  id: string;
  body: string;
  authorId: string;
  created_at: Date;
  threadId: string;
}

export interface Archived {
  id: string;
  threadId: string;
  userId: string;
}

export interface LastSeen extends Archived {
  lastSeen: Date;
}

export interface ThreadUser {
  id: string;
  nickname: string;
  name: string;
  avatar: string;
}

export type Thread = {
  offer: {
    id: string;
    title: string;
    price: number;
    images: IImage[];
  };
  message: Message;
  messages: Message[];
  lastSeen: LastSeen[];
  archive: Archived[];
  id: string;
  offerId: string;
  senderId: string;
  sender: ThreadUser;
  recipientId: string;
  recipient: ThreadUser;
  isArchived: boolean;
  email: string;
  phone?: string;
  isRead: boolean;
};

export interface Threads {
  unReadCount: number;
  active: Thread[];
  // sent: Thread[];
  // received: Thread[];
  trash: Thread[];
}

export interface SendMessage {
  email: string;
  phone?: string;
  body: string;
  threadId?: string;
  offerId: string;
  recipientId: string;
  senderId: string;
}

export interface ArchiveThread {
  threadId: string;
  isArchive: boolean;
}

export interface LogLastSeen {
  threadId: string;
  lastSeen: Date;
}

export interface UserUtilsPayload {
  hasSeenDashboardWelcome?: boolean;
  hasAcceptedCookies?: boolean;
}

export interface SubscriptionPayload {
  isSubscribedToTradeAndMarketing?: boolean;
  isSubscribedToServicePartners?: boolean;
  isSubscribedToOfferStatistics?: boolean;
}

export interface InvoiceAddressPayload {
  companyNIP: string;
  companyName: string;
  companyAddress: string;
  companyZipCode: string;
  companyCity: string;
}

export enum MESSAGE_CATEGORY {
  // RECEIVED = 'RECEIVED',
  // SENT = 'SENT',
  TRASH = 'TRASH',
  ACTIVE = 'ACTIVE',
}

export interface GetGeoDataPayload {
  street?: string;
  city?: string;
  county?: string;
  state?: string;
  country?: string;
  postalcode?: string;
}

export interface IFile {
  createdAt: Date;
  href: string;
  format: string;
  name: string;
  id: string;
  updatedAt: Date;
  userId: string;
}

export interface IContactMessage {
  name: string;
  email: string;
  body: string;
}

export interface IUsersPayload {
  page?: number;
  limit?: number;
  role?: USER_ROLE;
  status?: USER_STATUS;
}

export interface IUpdateUsersStatuses {
  userIds: string[];
  status?: USER_STATUS;
  role?: USER_ROLE;
}

export interface IDeleteUsers {
  userIds: string[];
}

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 19px;
  /* border-bottom: 1px solid #e5e5e5; */
  width: 100%;
  overflow: auto;
`;

export const BackButton = styled.button`
  font-family: Poppins;
  cursor: pointer;
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.1px;
  margin-right: 16px;
  border-right: 1px solid #e5e5e5;
  color: rgba(130, 130, 130, 1);
  padding: 0 18px 0 0;
  :focus {
    outline: none;
  }
`;

export const BackArrow = styled(IoIosArrowBack)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: rgba(130, 130, 130, 1);
`;

export const ForwardArrow = styled(IoIosArrowForward)`
  width: 20px;
  height: 20px;
  margin: 0 6px;
  color: rgba(130, 130, 130, 1);
`;

export const BackText = styled.div``;

export const Text = styled.button`
  font-family: Poppins;
  height: 20px;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  letter-spacing: 0.1px;
  color: rgba(130, 130, 130, 1);
  white-space: nowrap;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const ShadowText = styled(Text)`
  background-color: ${themes.default.luminescentGray};
  border-radius: 15px;
  padding: 0 12px;
`;

import styled from 'styled-components';
import { FiUser, FiLock, FiBriefcase } from 'react-icons/fi';
import { GoFile } from 'react-icons/go';
import Tag from '../../../assets/tag.svg';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 893px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0px;
  margin-bottom: 32px;
`;

export const HeaderWrapper = styled.div`
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0px;
  padding: 0 18px;
  display: flex;
  align-items: center;
`;

const iconProps = `
margin-right: 18px;
width: 22px;
height: 22px;
color: #2b65ea;
`;

export const UserIcon = styled(FiUser)`
  ${iconProps}
`;

export const DocumentIcon = styled(GoFile)`
  ${iconProps}
`;

export const LockIcon = styled(FiLock)`
  ${iconProps}
`;

export const BriefcaseIcon = styled(FiBriefcase)`
  ${iconProps}
`;

export const LabelIcon = styled.img.attrs({
  src: Tag,
  alt: 'label icon',
})`
  ${iconProps}
`;

export const HeaderText = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: 0.1px;
  color: #373f41;
  font-weight: 400;
`;

export const BodyWrapper = styled.div`
  width: 100%;
  padding: 36px 68px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    padding: 30px 18px;
  }
`;

export const priceValidator = (event: any, setter: (val: string) => void) => {
  const value = event.target.value;
  const regex = /^[0-9]*[.]{0,1}[0-9]{0,2}$/g;
  const isValid = regex.test(value) || value === '';
  if (isValid) {
    setter(value);
  }
};

export const priceValidator2 = (value: any) => {
  const regex = /^[1-9][0-9]*[.]{0,1}[0-9]{0,2}$/g;
  const isValid = regex.test(value) || value === 0.01;
  if (isValid) {
    return value;
  }
};

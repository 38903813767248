import MenuItem from '../MenuItem/MenuItem';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonProps, buttons } from '../Menu/Buttons';
import { useDashboardCount } from '../../../redux/auth/selectors';
import { useRef } from 'react';
import { logout } from '../../../redux/auth/actions';
import { Button } from 'antd';
import * as S from './MobileMenu.styles';

const MobileMenu = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
}) => {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = history;

  const { messagesCount, watchedCount, offersCount } = useSelector(useDashboardCount);

  const count: { [key: string]: number } = {
    Wiadomości: messagesCount,
    Obserwowane: watchedCount,
    Płatności: 0,
    Ustawienia: 0,
    Ogłoszenia: offersCount,
  };

  const handleItemClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRedirect = () => {
    history.push('/create/step-one');
  };

  // useEffect(() => {
  //   if (isMenuOpen) {
  //     useOnClickOutside(ref, handleItemClick);
  //   }
  // }, [isMenuOpen]);

  if (!isMenuOpen) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <S.Wrapper ref={ref}>
      <S.ButtonsWrapper>
        {buttons.map((button: ButtonProps) => {
          const { text, href } = button;
          return (
            <MenuItem
              key={text}
              {...button}
              isActive={pathname === href}
              handleClick={handleItemClick}
              count={count[text]}
            />
          );
        })}
        <Button
          onClick={() => handleRedirect()}
          type="default"
          style={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            maxWidth: 274,
            width: '100%',
            height: 40,
            borderRadius: 12,
            marginBottom: 18,
          }}
        >
          + Dodaj ogłoszenie
        </Button>
        <S.LogoutButton onClick={handleLogout}>Wyloguj</S.LogoutButton>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default MobileMenu;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

interface WrapperProps {
  isInModal: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  max-width: 265px;
  flex-direction: column;
  background-color: ${themes.default.white};
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  box-shadow: ${(props) => (props.isInModal ? 'none' : '1px 1px 12px rgba(0, 0, 0, 0.1)')};
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 111px;
  position: relative;
  overflow: hidden;
`;

// export const StyledMap = styled(Map)<MapProps>`
//   width: 100%;
//   position: absolute;
//   height: 111px;
//   z-index: 0;
// `;

export const Pin = styled.div`
  width: 18px;
  height: 22px;
  transform: scale(2.5);
`;

// export const StyledTooltip = styled(Tooltip)<TooltipProps>`
//   width: 122px;
//   height: 15px;
//   margin-left: 7px;
//   background-color: ${themes.default.lavender};
//   color: ${themes.default.white};
//   font-weight: 500;
//   font-size: 10px;
//   text-align: center;
//   align-self: justify;
//   padding: 3px 0;
//   border: none;
//   font-family: Poppins;
// `;

export const CreatorContainer = styled.div`
  width: 100%;
  min-height: 83px;
  max-height: 200px;
  z-index: 1;
`;

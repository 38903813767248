import styled from 'styled-components';
import Search from '../../assets/search.svg';
import MapPin from '../../assets/mapPin.svg';
import { themes } from '../../assets/CSS/colorThemes';
import { MdClear } from 'react-icons/md';
import { Button, Input } from 'antd';
import { BsSearch } from 'react-icons/bs';

export const Wrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  @media (min-width: 800px) {
    flex-direction: row;
    gap: 0;
  }
`;

export const SelectorsSpan = styled.div`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  flex: 1;
  width: 100%;
  @media (min-width: 450px) {
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    width: auto;
    flex-direction: row;
    height: 50px;
    max-width: 178px;
  }
  .ant-select {
    border-radius: 0 !important;
    height: 38px !important;
    @media (min-width: 1024px) {
      height: 50px !important;
    }
  }
  .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    height: 38px !important;
    @media (min-width: 1024px) {
      height: 50px !important;
    }
  }
  .ant-select-clear {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LocalizationLabel = styled.label`
  position: absolute;
  top: 6px;
  left: 8px;
  @media (min-width: 436px) {
    left: 60px;
  }
  @media (min-width: 580px) {
    left: 75px;
  }
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #bdbdbd;
`;

export const DistanceLabel = styled(LocalizationLabel)`
  /* left: 58px; */
  /* @media (min-width: 450px) { */
  left: 8px;
  /* } */
  @media (min-width: 580px) {
    left: 74px;
  }
  @media (min-width: 1024px) {
    left: 24px;
  }
`;

export const QueryLabel = styled(LocalizationLabel)`
  left: 58px;
  @media (min-width: 580px) {
    left: 75px;
  }
`;

// export const SearchIcon = styled.img.attrs({ alt: 'search', src: Search })`
//   min-width: 30px;
//   height: 30px;
//   opacity: 0.1;
//   margin-right: 18px;
// `;

export const SearchIcon = styled(BsSearch)<{ isFocused?: boolean }>`
  min-width: 30px;
  height: 30px;
  opacity: ${({ isFocused }) => (isFocused ? '1' : '0.1')};
  margin-right: 18px;
  color: ${({ isFocused }) => (isFocused ? 'rgba(75,65,196,1)' : 'rgba(0, 0, 0, 1);')};
`;

export const QueryInput = styled(Input)`
  width: 100%;
  height: 100%;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  border: none;
  border-radius: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LocalizationInput = styled(QueryInput)`
  /* padding-left: 51px; */

  /* @media (min-width: 600px) {
    padding-left: 51px;
  } */
`;

export const QueryInputWrapper = styled.div<{ isFocused?: boolean }>`
  box-sizing: border-box;
  background: #ffffff;
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border: ${({ isFocused }) => (isFocused ? '1px solid rgba(75,65,196,1);' : '1px solid #e5e5e5')};
  .ant-input {
    color: ${({ isFocused }) => (isFocused ? 'rgba(75,65,196,1);' : 'rgba(0, 0, 0, 1)')};
    font-weight: 700;
    ::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-weight: 400;
    }
  }
`;

export const LocationInputWrapper = styled(QueryInputWrapper)`
  flex-grow: 4;
  position: relative;
  @media (min-width: 1024px) {
    min-width: 238px;
  }
`;

export const LocationIcon = styled.img.attrs({ alt: 'location', src: MapPin })`
  /* min-width: 25px;
  height: 25px;
  opacity: 0.3;
  position: absolute;
  left: 14px;
  margin-right: 18px; */

  min-width: 30px;
  height: 30px;
  opacity: 0.1;
  margin-right: 18px;
  /* display: none;
  @media (min-width: 436px) {
    display: block;
  } */
`;

export const DistanceInputWrapper = styled(QueryInputWrapper)`
  display: flex;
  flex: 1;
  padding: 8px;
  @media (min-width: 580px) {
    padding: 8px 24px 8px 72px;
  }
  @media (min-width: 1024px) {
    padding: 8px 24px;
  }
`;

export const SearchButton = styled(QueryInputWrapper)`
  min-width: 175px;
`;

export const ButtonWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  max-width: 207px;
  margin: 0 auto;
`;

export const stylesLocation = {
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    height: '100%',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    padding: state.isFocused ? 'none' : 'none',
    height: '100%',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    outline: state.isFocused ? 'none' : 'none',
    border: state.isFocused ? 'none' : 'none',
    boxShadow: state.isFocused ? 'none' : 'none',
    height: '50px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  placeholder: () => ({
    fontFamily: 'Poppins',
    fontSize: '17px',
    lineHeight: '21px',
    color: '#828282',
    fontWeight: 500,
    letterSpacing: '0.1px',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    outline: state.isFocused ? 'none' : 'none',
    border: state.isFocused ? 'none' : 'none',
    width: '100%',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#828282',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '18px',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '100',
    borderRadius: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
};

export const SubmitButton = `
  min-width: 207px;
  flex-grow: 1;
  font-size: 16px;
  max-width: 100%;
  border-radius: 16px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
  @media (min-width: 800px){
    border-radius: 0 16px 16px 0;
  }
`;

export const Option = styled.option`
  font-family: Poppins;
  font-size: 12px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 4px;
  transition: 250ms;
  box-sizing: border-box;
  padding: 4px 21px;
  width: 100%;
  :hover {
    background-color: ${themes.default.greyBackgroundColor};
  }
  cursor: pointer;
`;

export const ClearIcon = styled(MdClear)<{ isFocused?: boolean }>`
  color: ${({ isFocused }) => (isFocused ? 'rgba(75,65,196,1)' : 'grey')};
  width: 40px;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  border-radius: 16px;
  @media (min-width: 800px) {
    border-radius: 0 16px 16px 0;
  }
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled(Link)`
  width: 100%;
  height: 110px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const Icon = styled.img`
  width: 46px;
  height: 46px;
  margin: auto 28px auto 0;
`;

export const DescriptionWrapper = styled.div`
  margin: auto 0;
`;

export const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #373f41;
  margin-bottom: 4px;
`;

export const BreadCrumb = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 160%;
  color: #bdbdbd;
`;

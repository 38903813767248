import OfferInfoPanel from '../../OfferComponents/OfferInfoPanel/OfferInfoPanel';
import OfferMap from '../../OfferComponents/OfferMap/OfferMap';
import PhotosSlider from '../../OfferComponents/OfferPage/PhotosSlider';
import useMediaQuery from '../../../hooks/useMediaQuery';
import moment from 'moment';
import * as S from './ProductModal.styles';
import * as U from '../../OfferComponents/OfferPage/OfferPage.style';

const ProductModal = (props: any) => {
  const {
    id,
    price,
    title,
    created_at,
    offererName,
    offererPhone,
    images,
    geo_lat,
    geo_lng,
    city,
    isOpen,
    isWatched,
    handleClose,
    subscriptionOptions,
    commitmentForm,
    avatar,
    userId,
    isOnline,
    handleIdSetWatched,
  } = props;

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isMobile = useMediaQuery('(min-width: 556px)');

  moment.locale('pl');
  const date = moment(created_at).fromNow(true);
  const displayImages = images ? images : [];

  const mapData: any = {
    city,
    name: offererName || '',
    coordinates: [geo_lat, geo_lng],
    direction: '',
    avatar,
    userId,
  };

  const offerInfoData: any = {
    id,
    price,
    offererPhone,
    subscriptionOptions,
    commitmentForm,
    isOnline,
  };

  if (!isOpen) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.LeftPanel>
          {!isDesktop && (
            <S.ButtonMobile onClick={handleClose}>
              <S.CloseIcon />
            </S.ButtonMobile>
          )}
          <U.Title>{title}</U.Title>
          <U.SmallInfoRow>
            <U.LocationPin />
            <U.InfoText>{city}</U.InfoText>
            <U.InfoText>{date} temu</U.InfoText>
            <U.InfoText>ID ogloszenia: {id}</U.InfoText>
          </U.SmallInfoRow>
          <PhotosSlider position="bottom" photos={displayImages} isPreview />
        </S.LeftPanel>
        <S.RightPanel>
          {isDesktop && (
            <S.Button onClick={handleClose}>
              <S.CloseIcon />
            </S.Button>
          )}
          <OfferInfoPanel
            isInModal
            isWatched={isWatched}
            props={offerInfoData}
            handleIdSetWatched={handleIdSetWatched}
            handleCloseModal={handleClose}
          />
          <OfferMap {...mapData} direction={isMobile ? 'row' : 'column'} isInModal />
        </S.RightPanel>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default ProductModal;

import { FormEvent, useState } from 'react';
import { Button, Input } from 'antd';
import * as api from '../../../api/common-api';
import * as S from './ContactForm.styles';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const { TextArea } = Input;

  const handleSend = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    await api
      .sendContactMessage({ name, email, body })
      .then(() => {
        toast.success(`Wiadomość wysłana pomyślnie`);
      })
      .catch(() => {
        toast.warn('Wiadomość nie została wysłana');
      });
    setName('');
    setEmail('');
    setBody('');
    setIsLoading(false);
  };

  const isDisabled = !name || !email || !body || isLoading;

  return (
    <S.ExternalWrapper onSubmit={handleSend}>
      <S.Header>Formularz kontaktowy</S.Header>
      <S.Wrapper>
        <S.Row>
          <Input
            required
            placeholder="Imię i Nazwisko"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            style={{ height: 50, marginBottom: 20, width: '100%' }}
          />
          <S.Spacer />
          <Input
            required
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            style={{ height: 50, marginBottom: 20, width: '100%' }}
          />
        </S.Row>
        <TextArea
          required
          placeholder={'Wiadomość'}
          value={body}
          onChange={(e: any) => setBody(e.target.value)}
          style={{ height: 126, resize: 'none', marginBottom: 20 }}
        />
      </S.Wrapper>
      <Button
        onClick={(e: any) => handleSend(e)}
        type="primary"
        loading={isLoading}
        disabled={isDisabled}
        style={{
          fontFamily: 'Poppins',
          fontWeight: 600,
          width: 160,
          height: 50,
          borderRadius: 8,
        }}
      >
        Wyślij
      </Button>
    </S.ExternalWrapper>
  );
};

export default ContactForm;

import { Checkbox, Input } from 'antd';
import { useEffect, useState } from 'react';
import ParameterOptionsTable from './ParameterOptionsTable';
import * as S from './CategoryTable.styles';
import { deleteCategoryParameter, updateCategoryParameter } from '../../../../redux/auth/actions';

export interface ICategoryParameterProps {
  id: string;
  categoryId: string;
  defaultValue: string;
  isCheckedByDefault: boolean;
  isRequired: boolean;
  label: string;
  options: any[];
  type: string;
  action: () => void;
}

const ParameterModalComponent = (props: ICategoryParameterProps) => {
  const {
    label,
    id,
    isRequired,
    defaultValue,
    type,
    isCheckedByDefault,
    options = [],
    action,
  } = props;

  const [parameterLabel, setParameterLabel] = useState(label);
  const [parameterRequired, setParameterRequired] = useState(isRequired);
  const [parameterDefaultValue, setParameterDefaultValue] = useState(defaultValue);
  const [parameterCheckedByDefault, setParameterCheckedByDefault] = useState(isCheckedByDefault);

  useEffect(() => {
    setParameterLabel(label);
    setParameterRequired(isRequired);
    setParameterDefaultValue(defaultValue);
    setParameterCheckedByDefault(isCheckedByDefault);
  }, [id]);

  const typeText = type === 'yesNo' ? 'Tak lub nie' : type === 'select' ? 'Wybór' : 'Tekstowy';
  const isCheckbox = type === 'yesNo';
  const isSelect = type === 'select';

  const handleUpdateCategoryParameter = async () => {
    await updateCategoryParameter({
      id,
      label: parameterLabel,
      isRequired: parameterRequired,
      defaultValue: parameterDefaultValue,
      isCheckedByDefault: parameterCheckedByDefault,
    });
    action();
  };

  const handleDeleteCategoryParameter = async () => {
    await deleteCategoryParameter(id);
    action();
  };

  return (
    <S.DynamicParameterWrapper>
      <Input value={typeText} addonBefore="Typ" readOnly disabled />
      <Input
        value={parameterLabel}
        onChange={(e: any) => setParameterLabel(e.target.value)}
        addonBefore="Nazwa"
      />
      <S.GapContainer>
        Czy wymagany
        <Checkbox
          checked={parameterRequired}
          onChange={() => setParameterRequired(!parameterRequired)}
        />
      </S.GapContainer>
      <Input
        value={parameterDefaultValue}
        onChange={(e: any) => setParameterDefaultValue(e.target.value)}
        addonBefore="Wartość początkowa"
      />
      {isCheckbox && (
        <S.GapContainer>
          Zaznaczony na starcie?
          <Checkbox
            checked={parameterCheckedByDefault}
            onChange={() => setParameterCheckedByDefault(!parameterCheckedByDefault)}
          />
        </S.GapContainer>
      )}
      <S.GapContainer>
        <S.DeleteButton onClick={() => handleDeleteCategoryParameter()}>Usuń</S.DeleteButton>
        <S.ParameterButton onClick={() => handleUpdateCategoryParameter()}>
          Aktualizuj
        </S.ParameterButton>
      </S.GapContainer>
      {isSelect && <ParameterOptionsTable parameterId={id} options={options} onClose={action} />}
    </S.DynamicParameterWrapper>
  );
};

export default ParameterModalComponent;

import styled from 'styled-components';
import { IoMdPerson, IoMdMenu } from 'react-icons/io';
import { MdClose, MdMenu } from 'react-icons/md';
import Logo from '../../assets/malutcyplLogo.svg';
import { themes } from '../../assets/CSS/colorThemes';
import U from '../../assets/CSS/commonStyles';
import { Link } from 'react-router-dom';

export const ExternalWrapper = styled.header`
  width: 100%;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  background-color: ${themes.default.white};
  position: fixed;
  z-index: 21;
  height: 74px;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 1300px;
  margin: 0 auto;
  ${U.standardFlex}
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 16px 18px;
  @media (max-width: 800px) {
    width: 98%;
  }
`;

export const User = styled(MdClose)`
  width: 30px;
  color: ${themes.default.main};
  @media (min-width: 880px) {
    display: none;
  }
`;

export const MenuIcon = styled(IoMdMenu)`
  width: 30px;
  color: ${themes.default.main};
  @media (min-width: 880px) {
    display: none;
  }
`;

export const TopLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
`;

export const LogoWrapper = styled(Link)`
  display: flex;
`;

export const BBLogo = styled.img.attrs({
  alt: 'Malutcy.pl logo',
  src: Logo,
  lang: 'en',
})`
  cursor: pointer;
  min-width: 82px;
`;

export const LogoLabel = styled.label`
  font-family: Poppins;
  ${U.standardFont14}
  margin-left: 24px;
  color: ${themes.default.main};

  @media (max-width: 880px) {
    visibility: hidden;
    display: none;
  }
`;

export const OptionsWrapper = styled.div`
  ${U.standardFlex}
  flex-wrap: nowrap;
`;

export const Person = styled(IoMdPerson)`
  color: ${themes.default.darkerGrey};
  width: 19px;
`;

export const PersonLabel = styled.label`
  margin: 0 30px 0 8px;
  font-family: Mulish;
  ${U.standardFont14}
  color: ${themes.default.darkerGrey};

  @media(max-width: 800px) {
    visibility: hidden;
    width: 24px;
    margin: 0;
  }
`;

interface AddOfferButtonProps {
  isDisplayedOnMobile: boolean;
}

export const AddOfferButton = styled.button<AddOfferButtonProps>`
  width: 249px;
  height: 42px;
  font-family: Poppins;
  ${U.standardFont14}
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  border: none;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :before {
    content: '+ Dodaj ogłoszenie za darmo';
  }

  @media(max-width: 880px) {
    ${(props) => props.isDisplayedOnMobile && 'display: none;'}
    width: 129px;
    :before {
      content: '+ Ogłoszenie';
    }
  }
`;

const UserMenuIconStyles = `
  color: ${themes.default.main};
  min-width: 30px;
  height: 30px;
`;

export const CloseUserMenuIcon = styled(MdClose)`
  ${UserMenuIconStyles}
`;

export const OpenUserMenuIcon = styled(MdMenu)`
  ${UserMenuIconStyles}
`;

export const PlainButton = styled.button`
  ${U.plainButton}
  @media(min-width: 880px) {
    display: none;
  }
`;

export const MobileAddOfferButton = styled.button`
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-family: Poppins;
  /* position: relative; */
  border: none;
  /* display: flex;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  font-family: Poppins;
  font-size: 20px;
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  border: none;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center; */
`;

export const PlusSign = styled.span`
  /* position: absolute; */
  font-size: 16px;
  top: 0;
  /* left: 8px; */
`;

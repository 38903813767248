import { useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import logo from '../../../assets/malutcyLogo.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { IImage } from '../../../redux/offer/types';
import 'react-image-gallery/styles/css/image-gallery.css';
import * as S from './OfferPage.style';

export default function PhotosSlider({
  photos,
  // position = 'right',
  // isWatched = false,
  isBazaar = false,
  isPreview = false,
}: {
  photos: IImage[];
  position?: 'right' | 'top' | 'bottom' | 'left' | undefined;
  isWatched?: boolean;
  isBazaar?: boolean;
  isPreview?: boolean;
}) {
  const [, setSelectedPhoto] = useState<string | undefined>();
  const prepareSliderInput = () =>
    photos
      .sort((a, b) => a.order - b.order)
      .map(({ href }: IImage) => ({ original: href, thumbnail: href }));
  const renderRightNav = (onClick: (props: any) => void, disabled: boolean) => (
    <>
      {/* <S.StarWrapper>{isWatched ? <S.FullStarIcon /> : <S.StarIcon />}</S.StarWrapper> */}
      <S.ArrowWrapper
        className="image-gallery-custom-right-nav"
        onClick={onClick}
        disabled={disabled}
      >
        <S.ArrowRight />
      </S.ArrowWrapper>
    </>
  );

  const renderLeftNav = (onClick: (props: any) => void, disabled: boolean) => (
    <S.ArrowWrapper
      isLeft
      className="image-gallery-custom-right-nav"
      onClick={onClick}
      disabled={disabled}
    >
      <S.ArrowLeft />
    </S.ArrowWrapper>
  );
  const renderItem = (props: ReactImageGalleryItem) => (
    <S.MainPhotoWrapper
      onClick={() => setSelectedPhoto(props.original)}
      isBazaar={isBazaar}
      className="test"
    >
      <S.MainPhoto src={props.original} />
    </S.MainPhotoWrapper>
  );

  const renderThumbInner = (props: ReactImageGalleryItem) => (
    <S.ThumbnailWrapper isBazaar={isBazaar}>
      <S.MainPhoto src={props.thumbnail} />
    </S.ThumbnailWrapper>
  );

  const isTablet = useMediaQuery('(min-width: 800px)');
  const isTabletAndBazaar = isTablet && isBazaar;

  return (
    <S.GalleryWrapper isBazaar={isBazaar}>
      {/* <S.ModalWrapper isOpen={!!selectedPhoto} onClick={() => setSelectedPhoto(undefined)}>
        <S.BigPhoto src={selectedPhoto} alt={'selected photo preview'} />
      </S.ModalWrapper> */}
      {photos.length > 0 ? (
        <ImageGallery
          items={prepareSliderInput()}
          showThumbnails={!isPreview && isTablet}
          // thumbnailPosition={isTablet ? position : 'bottom'}
          // thumbnailPosition={"right"}
          thumbnailPosition={!isTablet ? 'bottom' : isTabletAndBazaar ? 'left' : 'right'}
          showPlayButton={false}
          showBullets={false}
          showIndex={!isTablet}
          showFullscreenButton={true}
          renderRightNav={renderRightNav}
          renderLeftNav={renderLeftNav}
          useBrowserFullscreen
          // showNav={!isTablet}
          showNav
          renderItem={renderItem}
          renderThumbInner={(props: ReactImageGalleryItem) =>
            renderThumbInner({
              ...props,
              thumbnailWidth: isBazaar ? 108 : 400,
              thumbnailHeight: isBazaar ? 80 : 900,
            })
          }
          // slideOnThumbnailOver={true}
        />
      ) : (
        <S.MainPhotoWrapper isBazaar={isBazaar}>
          <S.MockImage src={logo} />
        </S.MainPhotoWrapper>
      )}
    </S.GalleryWrapper>
  );
}

import AutoComplete from 'react-google-autocomplete';
import { useOfferContext } from '../../../context/offer/offerContext';
import { useEffect, useState } from 'react';
import * as S from './YourData.styles';
interface DataBundle {
  name: string;
  email: string;
  localisation: string;
  location: any;
  phone: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
}
interface YourDataProps {
  dataBundle: DataBundle;
  isBazaar: boolean;
}

const YourData = ({ dataBundle, isBazaar }: YourDataProps) => {
  const { tempOffer, setTempOffer } = useOfferContext();

  const [autoCompleteLocation, setAutoCompleteLocation] = useState<{
    name: string;
    lat: number;
    lon: number;
  }>();

  const { name, email, phone, street, streetNumber, zipCode, city, localisation } = dataBundle;

  const numbersError = 'Telefon musi zawierać tylko cyfry w formacie 123456789';

  // const localizationText = isBazaar ? 'Miasto' : 'Miejsce odbywania się zajęć';
  const localizationText = 'Miasto';

  useEffect(() => {
    if (autoCompleteLocation) {
      setTempOffer({
        ...tempOffer,
        city: autoCompleteLocation.name,
        location: autoCompleteLocation,
      });
    }
  }, [autoCompleteLocation]);

  return (
    <S.Wrapper>
      <S.Title>Twoje dane</S.Title>
      <S.InputsWrapper>
        {isBazaar ? (
          <>
            <S.SimpleInfoWrapper>
              <S.StyledLabel>Twoje imię:</S.StyledLabel>
              <S.StyledValue>{name}</S.StyledValue>
            </S.SimpleInfoWrapper>
            <S.SimpleInfoWrapper>
              <S.StyledLabel>Email:</S.StyledLabel>
              <S.StyledValue>{email}</S.StyledValue>
            </S.SimpleInfoWrapper>
            <S.SimpleInfoWrapper>
              <div style={{ width: '345px', marginBottom: '20px' }}>
                <label
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '160%',
                    color: '#444B4D',
                    marginBottom: '5px',
                    display: 'flex',
                  }}
                >
                  {localizationText}
                </label>
                <AutoComplete
                  apiKey={'AIzaSyA2ptNi3RAAGKmWZ9ES21kt153CCVPx_oA'}
                  style={{
                    height: '50px',
                    width: '100%',
                    marginBottom: '6px',
                    background: '#fff',
                    border: '1px solid #E2E8F0',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '15px 21px',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    color: '#444B4D',
                  }}
                  defaultValue={city}
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'pl' },
                  }}
                  onPlaceSelected={(place: any) => {
                    const isDetailed = !!place.formatted_address;
                    if (!isDetailed) {
                      return;
                    }
                    const locationLat = place?.geometry?.location?.lat() || 0;
                    const locationLng = place?.geometry?.location?.lng() || 0;
                    const cityName = place.formatted_address.split(',')[0];
                    setAutoCompleteLocation({ name: cityName, lat: locationLat, lon: locationLng });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </S.SimpleInfoWrapper>
            {phone && (
              <S.SimpleInfoWrapper>
                <S.StyledLabel>Numer:</S.StyledLabel>
                <S.StyledValue>{phone}</S.StyledValue>
              </S.SimpleInfoWrapper>
            )}
            {localisation && (
              <S.SimpleInfoWrapper>
                <S.StyledLabel>Adres:</S.StyledLabel>
                <S.StyledValue>{localisation}</S.StyledValue>
              </S.SimpleInfoWrapper>
            )}
          </>
        ) : (
          <>
            <S.Row>
              <S.InputContainer>
                <S.StyledLabel>
                  Twoje imię <S.RedStar>*</S.RedStar>
                </S.StyledLabel>
                <S.StyledInput
                  placeholder="Twoje imię"
                  value={name}
                  onChange={(e: any) => setTempOffer({ ...tempOffer, offererName: e.target.value })}
                  required
                />
              </S.InputContainer>
              <S.InputContainer>
                <S.StyledLabel>
                  Email <S.RedStar>*</S.RedStar>
                </S.StyledLabel>
                <S.StyledInput
                  placeholder="Email"
                  value={email}
                  onChange={(e: any) =>
                    setTempOffer({ ...tempOffer, offererEmail: e.target.value })
                  }
                  type="email"
                />
              </S.InputContainer>
            </S.Row>
            <S.Row>
              <S.InputContainer>
                <S.StyledLabel>Numer telefonu</S.StyledLabel>
                <S.StyledInput
                  placeholder="Numer telefonu"
                  value={phone}
                  onChange={(e: any) =>
                    setTempOffer({ ...tempOffer, offererPhone: e.target.value })
                  }
                  pattern="^[1-9]\d{8,8}$"
                  type="tel"
                  min={9}
                />
              </S.InputContainer>
            </S.Row>
            <S.Row>
              <S.InputContainer>
                <S.StyledLabel>Ulica</S.StyledLabel>
                <S.StyledInput
                  placeholder="Ulica"
                  value={street}
                  onChange={(e: any) => setTempOffer({ ...tempOffer, street: e.target.value })}
                />
              </S.InputContainer>
              <S.InputContainer>
                <S.StyledLabel>Numer</S.StyledLabel>
                <S.StyledInput
                  placeholder="Numer"
                  value={streetNumber}
                  onChange={(e: any) =>
                    setTempOffer({ ...tempOffer, streetNumber: e.target.value })
                  }
                />
              </S.InputContainer>
            </S.Row>
            <S.Row customWidth="auto">
              <S.InputContainer>
                <S.StyledLabel>Kod pocztowy</S.StyledLabel>
                <S.StyledInput
                  placeholder="Kod pocztowy"
                  value={zipCode}
                  onChange={(e: any) =>
                    setTempOffer({ ...tempOffer, streetNumber: e.target.value })
                  }
                  pattern="\d{2}-\d{3}"
                  title="xx-xxx"
                />
              </S.InputContainer>
              <S.LocalizationContainer>
                <label
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '160%',
                    color: '#444B4D',
                    marginBottom: '5px',
                    display: 'flex',
                  }}
                >
                  {localizationText}
                </label>
                <AutoComplete
                  apiKey={'AIzaSyA2ptNi3RAAGKmWZ9ES21kt153CCVPx_oA'}
                  style={{
                    height: '52px',
                    width: '100%',
                    marginBottom: '6px',
                    background: '#fff',
                    border: '1px solid #d9d9d9',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    padding: '15px 21px',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    color: '#444B4D',
                    maxWidth: '359px',
                  }}
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'pl' },
                  }}
                  defaultValue={city}
                  onPlaceSelected={(place: any) => {
                    const isDetailed = !!place.formatted_address;
                    if (!isDetailed) {
                      return;
                    }
                    const locationLat = place?.geometry?.location?.lat() || 0;
                    const locationLng = place?.geometry?.location?.lng() || 0;

                    const cityName = place.formatted_address.split(',')[0];

                    setAutoCompleteLocation({ name: cityName, lat: locationLat, lon: locationLng });
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                />
              </S.LocalizationContainer>
            </S.Row>
          </>
        )}
      </S.InputsWrapper>
    </S.Wrapper>
  );
};

export default YourData;

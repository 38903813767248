import styled from 'styled-components';
import SVG from '../../../assets/assets-exporter';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const SupportContainer = styled.div`
  position: relative;
  min-width: 90px;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  left: 20px;
  top: -20px;
`;

export const Avatar = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 100%;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 4px;
`;

export const Name = styled.span`
  color: #11244d;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  cursor: pointer;
`;

export const Offers = styled.span`
  color: #4b41c4;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  cursor: pointer;
`;

export const LocationWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

export const LocationIcon = styled.img.attrs({ alt: 'location', src: SVG.mapPin })`
  min-width: 12px;
  height: 12px;
  margin-right: 2px;
`;

export const City = styled.span`
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: -0.9px;
  color: #828282;
`;

export const EmptyAvatar = styled(Avatar).attrs({ src: SVG.offerAvatar })`
  object-fit: none;
`;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TiSocialFacebook, TiSocialInstagram } from 'react-icons/ti';
import { themes } from '../../assets/CSS/colorThemes';
import U from '../../assets/CSS/commonStyles';
import WaveIcon from '../../assets/Waves/wave-yellow.svg';

export const Wrapper = styled.div`
  background-color: #fef9eb;
  width: 100%;
  /* z-index: 2; */
  position: relative;
`;

export const InternalWrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 54px 18px;
  ${U.standardFlex}
  align-items: flex-start;
  @media (max-width: 862px) {
    flex-direction: column;
  }
`;

export const MainHeader = styled.h2`
  font-family: Poppins;
  font-size: 22px;
  color: #373f41;
  font-weight: 600;
  max-width: 460px;
  width: 100%;
  margin: 0 0 24px;
  @media (max-width: 862px) {
    width: 100%;
  }
`;

export const SupHeader = styled.h3`
  font-family: Poppins;
  ${U.standardFont16}
  color: ${themes.default.mediumGrey};
  margin: 0 0 8px;
`;

export const AppsWrapper = styled.div`
  ${U.standardFlex}
  width: 100%;
`;

export const AppIcon = styled.img`
  height: 38px;
  width: auto;
`;

export const MainWrapper = styled.div`
  margin-bottom: 48px;
  flex-grow: 4;
`;

export const SecondaryWrapper = styled.div`
  ${U.standardFlex}
  align-items: flex-start;
  flex-grow: 4;
  @media (max-width: 862px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const SectionHeader = styled.h5`
  font-family: Poppins;
  ${U.standardFont12}
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(75, 65, 196, 1);
  margin-bottom: 16px;
  margin-top: 8px;
`;

export const StyledLink = styled(Link)`
  font-family: Poppins;
  ${U.standardFont16}
  line-height: 200%;
  color: rgba(17, 36, 77, 1);
  text-decoration: none;
  display: block;
  margin-right: 4px;
  cursor: pointer;
`;
/* position: relative;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0px;
    left: 0;
    background-color: #0033a0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  } */

export const ColumnWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 50px;
`;

export const SocialWrapper = styled.div`
  display: flex;
  margin-top: 8px;
`;

const IconBaseStyles = `
  color: ${themes.default.white};
  background-color: ${themes.default.black};
  border-radius: 100%;
  padding: 4px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
`;

interface AnchorProps {
  hasMargin?: boolean;
}

export const Anchor = styled.a<AnchorProps>`
  ${(props) => props.hasMargin && 'margin-right: 12px;'}
  border-radius: 100%;
`;

export const FacebookIcon = styled(TiSocialFacebook)`
  ${IconBaseStyles}
`;

export const InstagramIcon = styled(TiSocialInstagram)`
  ${IconBaseStyles}
`;

export const CopyRights = styled.p`
  font-family: Nunito;
  ${U.standardFont16}
  color: ${themes.default.mediumGrey};
  margin: 0;
  width: 100%;
`;

export const WaveContainer = styled.div`
  top: -20px;
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  /* z-index: 2; */
`;

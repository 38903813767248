import { useEffect, useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { IOffer } from '../../../redux/offer/types';
import OfferThumbnail from '../OfferThumbnail/OfferThumbnail';
import * as S from './OffersWrapper.styles';
import ProductModal from '../../Modals/ProductModal/ProductModal';
import Spinner from '../../Spinner/Spinner';

const OffersWrapper = ({
  backgroundColor,
  header,
  offers,
  redirectURL,
  isBazaar,
  isLoading,
  isDoubleRowed,
}: {
  backgroundColor: string;
  header: string;
  offers: IOffer[];
  redirectURL: string;
  isBazaar?: boolean;
  isLoading?: boolean;
  isDoubleRowed?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<any>();

  const buttonText = isBazaar ? 'Zobacz inne produkty' : 'Zobacz więcej';
  const isTablet = useMediaQuery('(min-width: 800px)');
  const backgroundBazaarMobileColor = !isTablet && isBazaar ? '#E9F5F5' : backgroundColor;

  const [idToSetWatched, setIdToSetWatched] = useState('');

  const handleOpenModal = (id: string) => {
    if (!id) {
      return null;
    }
    setModalProps(offers?.find((x) => x.id === id));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalProps(undefined);
    setIsModalOpen(false);
  };

  const handleIdToSetWatched = (id: string) => {
    if (!id) {
      return null;
    }
    setIdToSetWatched(id);
  };

  const arrayOfPairs: [][] = offers.reduce((acc: any, currentItem: any, index: number) => {
    if (index % 2 === 0) {
      const nextItem = offers[index + 1];
      const pair: any[] = nextItem ? [currentItem, nextItem] : [currentItem];
      acc.push(pair);
    }
    return acc;
  }, []);

  return (
    <S.Wrapper backgroundColor={backgroundBazaarMobileColor} isBazaar={isBazaar || false}>
      <S.InnerWrapper isBazaar={isBazaar}>
        <S.HeaderWrapper isBazaar={isBazaar}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <S.Header>{header}</S.Header>
            <S.StyledLink to={redirectURL}>
              <S.LinkText>{buttonText}</S.LinkText>
              <S.Arrow />
            </S.StyledLink>
          </div>
          {/* {!isBazaar && isTablet && (
            <div style={{ display: 'flex' }}>
              <S.SelectCategoriesWrapper>
                <S.Category
                  isSelected={selectedMainCategory === CATEGORY.POZNAN}
                  onClick={() => setSelectedMainCategory(CATEGORY.POZNAN)}
                >
                  Poznań
                </S.Category>
                <S.Category
                  isSelected={selectedMainCategory === CATEGORY.WARSZAWA}
                  onClick={() => setSelectedMainCategory(CATEGORY.WARSZAWA)}
                >
                  Warszawa
                </S.Category>
                <S.Category
                  isSelected={selectedMainCategory === CATEGORY.KRAKOW}
                  onClick={() => setSelectedMainCategory(CATEGORY.KRAKOW)}
                >
                  Kraków
                </S.Category>
                <S.Category
                  isSelected={selectedMainCategory === CATEGORY.KATOWICE}
                  onClick={() => setSelectedMainCategory(CATEGORY.KATOWICE)}
                >
                  Katowice
                </S.Category>
              </S.SelectCategoriesWrapper>
            </div>
          )}  */}
        </S.HeaderWrapper>
        <S.ItemsWrapper className="color-scroll">
          {isLoading ? (
            <S.SpinnerWrapper>
              <Spinner />
            </S.SpinnerWrapper>
          ) : isDoubleRowed ? (
            arrayOfPairs.map((p) => (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
                {p.map((i: IOffer) => (
                  <div>
                    <OfferThumbnail
                      key={i.id}
                      {...i}
                      handleOpenModal={(id: string) => handleOpenModal(id)}
                      handleSetWatched={idToSetWatched}
                    />
                  </div>
                ))}
              </div>
            ))
          ) : (
            offers.map((offer: IOffer) => (
              <OfferThumbnail
                key={offer.id}
                {...offer}
                handleOpenModal={(id: string) => handleOpenModal(id)}
                handleSetWatched={idToSetWatched}
              />
            ))
          )}
        </S.ItemsWrapper>
        <S.StyledLinkMobile to={redirectURL}>
          <S.LinkText>{buttonText}</S.LinkText>
          <S.Arrow />
        </S.StyledLinkMobile>
      </S.InnerWrapper>
      {isModalOpen && (
        <ProductModal
          {...modalProps}
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
        />
      )}
    </S.Wrapper>
  );
};

export default OffersWrapper;

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 66px 0 14px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 28px 22px;
  }
  margin-bottom: 110px;
`;

export const InnerWrapper = styled.div`
  max-width: 1184px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 44px;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.h4`
  margin: 0;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  @media (min-width: 1024px) {
    align-items: normal;
    flex-direction: row;
    gap: 0;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 472px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  padding-bottom: 32px;
  width: 156px;
  height: 162px;
`;

export const StyledImage = styled.img``;

export const SectionHeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 18px;
  flex: 1;
`;

export const SectionHeader = styled.h4`
  margin: 0;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
`;

export const SectionDescriptionWrapper = styled.div`
  display: flex;
  padding-bottom: 18px;
`;

export const SectionDescription = styled.span`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(17, 36, 77, 1);
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 8px 24px;
  background-color: rgba(75, 65, 196, 1);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

import { ICategory } from '../../../redux/utils/types';
import Subcategory from '../Subcategory/Subcategory';
import * as S from './SubcategoryPanel.style';

export default function SubcategoryPanel({
  subCategories,
  title,
  handleSelect,
  selected,
  handleSelectAll,
  onClose,
}: {
  subCategories: ICategory[];
  title: string;
  handleSelect: (id: string) => void;
  selected: string[];
  handleSelectAll: () => void;
  onClose: () => void;
}) {
  if (!subCategories || subCategories.length === 0) {
    return null;
  }
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.InfoWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <S.CategoryLabel>{title}</S.CategoryLabel>
              <S.AllAuctions onClick={handleSelectAll}>Wszystkie ogłoszenia</S.AllAuctions>
              <S.Arrow />
            </div>
            <div
              style={{
                position: 'absolute',
                top: '0',
                right: '-50px',
              }}
            >
              <button
                style={{
                  border: 'none',
                  background: 'none',
                  cursor: 'pointer',
                }}
                onClick={onClose}
              >
                <S.CloseIcon />
              </button>
            </div>
          </div>
        </S.InfoWrapper>
        <S.SubCategoriesWrapper>
          {subCategories.map((subcategory: ICategory) => {
            const { id } = subcategory;
            return (
              <Subcategory
                onClick={() => handleSelect(id)}
                key={id}
                category={subcategory}
                isSelected={selected.includes(id)}
              />
            );
          })}
        </S.SubCategoriesWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
}

import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
padding-bottom: 40px;
`

export const TitleWrapper = styled.div`
display: flex;
`

export const Title = styled.h2`
margin: 0;
font-family: Poppins;
font-size: 26px;
font-weight: 600;
line-height: 35px;
letter-spacing: 0px;
text-align: left;
color: rgba(17, 36, 77, 1);
`

export const BenefitWrapper = styled.div`
display: flex;
`

export const TickWrapper = styled.div`
padding-right: 8px;
`

export const Tick = styled.img``

export const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
`
export const BenefitTitleWrapper = styled.div`
display: flex;
padding-bottom: 8px;
`

export const BenefitTitle = styled.h3`
margin: 0;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 26px;
letter-spacing: 0px;
color: rgba(17, 36, 77, 1);
`

export const BenefitDescriptionWrapper = styled.div`
display: flex;
`

export const BenefitDescription = styled.span`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0px;
text-align: left;
color: rgba(17, 36, 77, 1);
`

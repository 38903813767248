import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../Partials/Breadcrumb/Breadcrumb';
import OfferInfoPanel from '../OfferInfoPanel/OfferInfoPanel';
import OfferMap from '../OfferMap/OfferMap';
import OffersWrapper from '../../LandingPage/OffersWrapper/OffersWrapper';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PhotosSlider from './PhotosSlider';
import moment from 'moment';
import 'moment/locale/pl';
import ContactForm from '../ContactForm/ContactForm';
import { useIsUserAdmin, userData } from '../../../redux/auth/selectors';
// import { setPageToNavigateAfterLogin } from '../../../redux/utils/actions';
import logo from '../../../assets/malutcyLogo.svg';
import getCategory from '../../../utils/getCategory';
import Details from '../Details/Details';
import Benefits from '../Benefits/Benefits';
import Faqs from '../Faq/Faq';
import { COMMITMENT_FORM, IOffer, OFFER_TYPE, emptyOffer } from '../../../redux/offer/types';
import formatNumber from '../../../utils/formatNumber';
import bazaarLeft from '../../../assets/OfferPage/bazaar-left.svg';
import bazaarRight from '../../../assets/OfferPage/bazaar-right.svg';
import announcementLeft from '../../../assets/OfferPage/announcement-left.svg';
import announcementRight from '../../../assets/OfferPage/announcement-right.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { FormatPrice } from '../../../utils/formatPrice';
import Spinner from '../../Spinner/Spinner';
import TagSelect from './TagSelect/TagSelect';
import * as api from '../../../api/common-api';
import * as S from './OfferPage.style';
import { toast } from 'react-toastify';

const OfferPage = () => {
  const params: any = useParams();
  const history: any = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  // const dispatch = useDispatch();

  // const {
  //   location: { pathname },
  // } = history;

  // useEffect(() => {
  //   dispatch(setPageToNavigateAfterLogin(pathname));
  // }, [pathname]);

  const { id: userId } = useSelector(userData);
  const isAdmin = useSelector(useIsUserAdmin); ///;)) soon

  const { id } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [offer, setOffer] = useState<IOffer>(emptyOffer);
  const [sameCategoryOffers, setSameCategoryOffers] = useState<any[]>([]);

  const [bazaarOffers, setBazaarOffers] = useState<IOffer[]>([]);
  const [isReportDisabled, setIsReportDisabled] = useState<boolean>(true);

  const { id: categoryId } = getCategory(
    sameCategoryOffers.length > 0 ? sameCategoryOffers[0].categories : []
  );

  const fetchIsOfferReported = async () => {
    const isReported = await api.getIsOfferReportedByUser(id);
    setIsReportDisabled(isReported);
  };

  const getOfferDetails = async () => {
    try {
      setIsLoading(true);
      const { details, sameCategoryOffers } = await api.getOfferDetails(id);
      setOffer(details);
      setSameCategoryOffers(sameCategoryOffers);
      fetchIsOfferReported();
      const { bazaarResults } = await api.getOffers();
      setBazaarOffers(bazaarResults);
      setIsLoading(false);
    } catch (error) {
      toast.warn('Nie odnaleziono oferty');
      setIsLoading(false);
      history.push('/');
    }
  };

  useEffect(() => {
    if (!id) {
      return history.push('/');
    }
    getOfferDetails();
  }, [pathname]);

  const handleUpdateOfferView = async (id: string) => {
    await api.updateOfferViewCounter(id);
  };

  useEffect(() => {
    if (id) {
      handleUpdateOfferView(id);
    }
  }, [id]);

  const [idToSetWatched, setIdToSetWatched] = useState('');

  const handleIdToSetWatched = (id: string) => {
    if (!id) {
      return null;
    }
    setIdToSetWatched(id);
  };

  const {
    title,
    offererLocalization,
    categories,
    created_at,
    description,
    offererName,
    offererPhone,
    images = [],
    user,
    id: offerId,
    offerType,
    faqs = [],
    benefits = [],
    isBrandNew,
    ageFrom,
    ageTo,
    price,
    zipCode,
    street,
    city,
    streetNumber,
    commitmentForm,
    subscriptionOptions = [],
    dynamicOptions,
    geo_lat,
    geo_lng,
    isWatched,
  } = offer;

  const offerInfoData: any = {
    id,
    price,
    offererPhone,
    subscriptionOptions,
    commitmentForm,
    isOnline: user?.isOnline || false,
  };

  const offererId = user.id || null;

  const isBazaar = offerType === OFFER_TYPE.BAZAAR;
  moment.locale('pl');
  const date = moment(created_at).fromNow(true);
  const mapData: any = {
    city,
    name: user ? user.nickname || user.name : offererName,
    coordinates: [geo_lat, geo_lng],
    direction: '',
    avatar: user.avatar,
    userId: user.id,
  };
  const reportText = isReportDisabled
    ? 'Naruszenie zostało przez ciebie zgłoszone'
    : 'Zgłoś naruszenie';
  const { href } = images.find(({ order }: any) => order === 0) || { href: logo };

  const handleReport = async () => {
    if (!isReportDisabled) {
      await api.reportOffer(offerId);
      await fetchIsOfferReported();
    }
  };

  const offerTypeText = offerType === OFFER_TYPE.BAZAAR ? 'Prywatnie' : 'Ogłoszenie';
  const advertiser = user ? user.nickname || user.name : offererName;
  const itemStateText = isBrandNew ? 'Nowe' : 'Używane';
  const forWhomText = () => {
    if (ageFrom === 0 && ageTo) {
      return `do ${ageTo} lat`;
    }
    if (ageFrom !== 0 && ageTo) {
      return `${ageFrom}-${ageTo} lat`;
    }
    if (ageFrom && !ageTo) {
      return `${ageFrom}+`;
    }
    if (!ageFrom && !ageTo) {
      return '';
    }
  };

  const addresText = () => {
    if (street && streetNumber && zipCode && city) {
      return `${street} ${streetNumber}, ${zipCode} ${city}`;
    } else if (!street && !streetNumber && zipCode && city) {
      return `${zipCode} ${city}`;
    } else {
      return '';
    }
  };

  const newPrice = FormatPrice(price);
  const formattedPrice = newPrice === 0 ? 'Za darmo' : `${formatNumber(newPrice)} zł`;

  const subscriptionPriceText =
    subscriptionOptions &&
    subscriptionOptions.length > 0 &&
    subscriptionOptions.map((i: any) => {
      const formattedOption =
        i.subscriptionOptions === 'WEEKLY'
          ? 'Tydzień'
          : i.subscriptionOptions === 'MONTHLY'
          ? 'Miesiąc'
          : 'Rok';
      return (
        <span key={i.id}>
          {FormatPrice(i.price)} zł / {formattedOption}
        </span>
      );
    });

  const priceText = () => {
    if (price !== undefined && offerType === OFFER_TYPE.BAZAAR) {
      return formattedPrice;
    } else if (offerType === OFFER_TYPE.ANNOUNCEMENT) {
      if (commitmentForm === COMMITMENT_FORM.SUBSCRIPTION && price !== undefined && price !== 0) {
        return (
          <>
            {formattedPrice}
            {subscriptionPriceText}
          </>
        );
      }
      if (commitmentForm === COMMITMENT_FORM.ONE_TIME_PAYMENT) {
        return formattedPrice;
      } else if (commitmentForm === COMMITMENT_FORM.SUBSCRIPTION) {
        return subscriptionPriceText;
      }
    }
  };
  const currentUrl = window.location.href;
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  if (isLoading) {
    return (
      <S.SpinnerWrapper>
        <Spinner />
      </S.SpinnerWrapper>
    );
  }
  if (isBazaar) {
    return (
      <>
        {/* <MetaTags> */}
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={href} />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content={'website'} />
          <meta property="og:site_name" content={'Malutcy.pl'} />
        </Helmet>
        {/* </MetaTags> */}
        <S.Wrapper>
          <S.AbsoluteTopWrapper>
            <S.SmallWhiteWaveContainer />
          </S.AbsoluteTopWrapper>
          {isDesktop && (
            <S.BazaarTopLeft>
              <S.AbsoluteImage src={bazaarLeft} />
            </S.BazaarTopLeft>
          )}
          <S.BazaarTopRight>
            <S.AbsoluteImage src={bazaarRight} />
          </S.BazaarTopRight>
          <S.OfferWrapper isBazaar>
            <S.ContentRow>
              <S.LeftPanel>
                <Breadcrumb categories={categories} />
                <S.Title>{title}</S.Title>
                <S.SmallInfoRow>
                  <S.LocationPin />
                  <S.InfoText>{offererLocalization}</S.InfoText>
                  <S.InfoText>{date} temu</S.InfoText>
                  <S.InfoText>ID ogloszenia: {id}</S.InfoText>
                </S.SmallInfoRow>
                <PhotosSlider photos={images} isBazaar={isBazaar} />
                <S.MobileRightPanel isLong={true} isPadding={false}>
                  <OfferInfoPanel
                    props={offerInfoData}
                    isWatched={isWatched}
                    handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
                  />
                </S.MobileRightPanel>
                <Details
                  offerType={offerTypeText}
                  advertiser={advertiser}
                  price={priceText() || ''}
                  address={addresText()}
                  isBazaar
                  forWhom={forWhomText()}
                  state={itemStateText}
                  dynamicOptions={dynamicOptions || []}
                  id={offererId}
                />
                <S.DescriptionContainer>
                  <S.DescriptionLabel>Opis</S.DescriptionLabel>
                  <S.Description
                    dangerouslySetInnerHTML={{
                      __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                    }}
                  />
                </S.DescriptionContainer>
                {userId && user.id !== userId && <ContactForm offererId={offererId} />}
                <S.MobileRightPanel isLong={false} isPadding={false}>
                  {isAdmin && <TagSelect offerId={offerId} />}
                  <OfferMap {...mapData} />
                  <S.ReportButton
                    isCentered
                    onClick={handleReport}
                    isReported={isReportDisabled}
                    disabled={isReportDisabled}
                  >
                    <S.InfoIcon />
                    {reportText}
                  </S.ReportButton>
                </S.MobileRightPanel>
              </S.LeftPanel>
              <S.RightPanel>
                <OfferInfoPanel
                  props={offerInfoData}
                  isWatched={isWatched}
                  handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
                />
                {isAdmin && <TagSelect offerId={offerId} />}
                <OfferMap {...mapData} />
                <S.ReportButton
                  onClick={handleReport}
                  disabled={isReportDisabled}
                  isReported={isReportDisabled}
                >
                  <S.InfoIcon />
                  {reportText}
                </S.ReportButton>
              </S.RightPanel>
            </S.ContentRow>
          </S.OfferWrapper>
          {sameCategoryOffers.length > 0 && (
            <OffersWrapper
              offers={sameCategoryOffers}
              backgroundColor={'#fff'}
              header={'Pozostałe ogłoszenia z tej kategorii'}
              redirectURL={`/search?categoryId=${categoryId}`}
            />
          )}
        </S.Wrapper>
      </>
    );
  }
  return (
    <>
      {/* <MetaTags> */}
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={href} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content={'website'} />
        {/* </MetaTags> */}
      </Helmet>
      <S.Wrapper>
        <S.TopWrapper isBazaar={isBazaar}>
          {isDesktop && (
            <S.AnnouncementTopLeft>
              <S.AbsoluteImage src={announcementLeft} />
            </S.AnnouncementTopLeft>
          )}
          <S.AnnouncementTopRight>
            <S.AbsoluteImage src={announcementRight} />
          </S.AnnouncementTopRight>
          <S.OfferWrapper isBazaar={false}>
            <Breadcrumb categories={categories} />
            <S.GeneralInfoWrapper>
              <S.Title>{title}</S.Title>
              <S.SmallInfoRow>
                <S.LocationPin />
                <S.LoctionText>{offererLocalization}</S.LoctionText>
                <S.InfoText>{date} temu</S.InfoText>
                <S.InfoText>ID ogloszenia: {id}</S.InfoText>
              </S.SmallInfoRow>
              <PhotosSlider photos={images} isBazaar={isBazaar} />
            </S.GeneralInfoWrapper>
          </S.OfferWrapper>
        </S.TopWrapper>
        <S.WaveContainer />
        <S.BottomWrapper>
          <S.DetailsWrapper>
            <S.MobileRightPanel isLong={true} isPadding={false}>
              <OfferInfoPanel
                props={offerInfoData}
                isWatched={isWatched}
                handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
              />
            </S.MobileRightPanel>
            <Details
              offerType={offerTypeText}
              advertiser={advertiser}
              price={priceText() || ''}
              address={addresText()}
              forWhom={forWhomText()}
              dynamicOptions={dynamicOptions || []}
              id={offererId}
            />
            <S.DescriptionContainer>
              <S.DescriptionLabel>Opis</S.DescriptionLabel>
              <S.Description
                dangerouslySetInnerHTML={{
                  __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                }}
              />
            </S.DescriptionContainer>
            {!isBazaar && (
              <>
                {benefits && benefits.length > 0 && <Benefits benefits={benefits || []} />}
                {faqs && faqs.length > 0 && <Faqs faqs={faqs || []} />}
              </>
            )}
            {userId && user.id !== userId && <ContactForm offererId={offererId} />}
            <S.MobileRightPanel isLong={false} isPadding={false}>
              {isAdmin && <TagSelect offerId={offerId} />}
              <OfferMap {...mapData} />
              <S.ReportButton
                isCentered
                onClick={handleReport}
                isReported={isReportDisabled}
                disabled={isReportDisabled}
              >
                <S.InfoIcon />
                {reportText}
              </S.ReportButton>
            </S.MobileRightPanel>
          </S.DetailsWrapper>
          {isDesktop && (
            <S.RightDetailsWrapper>
              <S.RightPanel>
                <OfferInfoPanel
                  props={offerInfoData}
                  isWatched={isWatched}
                  handleIdSetWatched={(id: string) => handleIdToSetWatched(id)}
                />
                {isAdmin && <TagSelect offerId={offerId} />}
                <OfferMap {...mapData} />
                <S.ReportButton
                  onClick={handleReport}
                  disabled={isReportDisabled}
                  isReported={isReportDisabled}
                >
                  <S.InfoIcon />
                  {reportText}
                </S.ReportButton>
              </S.RightPanel>
            </S.RightDetailsWrapper>
          )}
        </S.BottomWrapper>
        {!isBazaar && (
          <OffersWrapper
            offers={bazaarOffers}
            backgroundColor={'#fff'}
            header={'Sprawdź nasz bazarek'}
            redirectURL={`/search?categoryId=${categoryId}`}
            isBazaar
          />
        )}
        {sameCategoryOffers.length > 0 && (
          <OffersWrapper
            offers={sameCategoryOffers}
            backgroundColor={'#fff'}
            header={'Pozostałe ogłoszenia w tej kategorii'}
            redirectURL={`/search?categoryId=${categoryId}`}
          />
        )}
      </S.Wrapper>
    </>
  );
};

export default OfferPage;

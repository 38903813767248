import styled from 'styled-components';

export const Wrapper = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  background-color: transparent;
  margin-right: 24px;
  cursor: pointer;
  min-width: max-content;
  flex-direction: column;
  border: none;
  border-bottom: 3px solid
    ${(props: { isActive: boolean }) => (props.isActive ? '#4B41C4pfo' : 'transparent')};
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const Text = styled.span<{ isActive: boolean }>`
  font-family: Poppins;
  font-weight: ${(props: { isActive: boolean }) => (props.isActive ? '600' : '400')};
  font-size: 16px;
  line-height: 137%;
  color: #373f41;
  height: 100%;
`;

export const Counter = styled(Text)<{ isPresent: boolean }>`
  opacity: ${({ isPresent }) => (isPresent ? 1 : 0)};
  min-width: 23px;
  min-height: 21px;
  @media (min-width: 600px) {
    display: ${({ isPresent }) => (isPresent ? 'inline' : 'none')};
    margin: 0;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  overflow-x: auto;
`;

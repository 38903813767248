import styled from 'styled-components';
import { IoMdCheckmark } from 'react-icons/io';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  max-width: 382px;
  margin-bottom: 57px;
`;

export const Header = styled.h6`
  font-family: Poppins;
  font-weight: bold;
  font-size: 14px;
  line-height: 171%;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 7px;
`;

export const Row = styled.div`
  display: flex;
`;

export const CheckIcon = styled(IoMdCheckmark)`
  height: 30px;
  width: 30px;
  min-width: 30px;
  color: ${themes.default.positive};
  margin-right: 14px;
`;

export const Text = styled.div`
  font-family: Nunito;
  font-size: 14px;
  line-height: 214%;
  color: ${themes.default.graphemeGrey};
`;

import styled from 'styled-components';
import { themes } from '../../../../assets/CSS/colorThemes';

interface WrapperProps {
  marginBottom?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  max-width: 1040px;
  width: 100%;
  margin-bottom: ${(props: WrapperProps) => (props.marginBottom ? props.marginBottom : '40px')};
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-bottom: ${(props: WrapperProps) => (props.marginBottom ? props.marginBottom : '112px')};
    min-height: 20px;
  }
`;

export const CategoriesWrapper = styled.div<{ isSearch: boolean }>`
  display: flex;
  flex-wrap: ${({ isSearch }) => (isSearch ? 'no-wrap' : 'wrap')};
  justify-content: space-evenly;
  margin: 0 auto;
  width: 100%;
  padding-top: ${({ isSearch }) => (isSearch ? '128px' : '0')};
  overflow-x: ${({ isSearch }) => (isSearch ? 'scroll' : 'none')};
  @media (min-width: 1024px) {
    justify-content: space-around;
    overflow-x: auto;
    flex-wrap: wrap;
    gap: 5%;
  }
  @media (min-width: 1600px) {
    gap: 2%;
  }
`;

export const Label = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 200%;
  letter-spacing: -0.4px;
  color: ${themes.default.darkGraphemeGrey};
  margin-bottom: 24px;
  padding-top: 80px;
`;

export const CreatorLabel = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
`;

export const Red = styled.span`
  color: red;
`;

export const SelectCategoriesWrapper = styled.div`
  display: flex;
  gap: 32px;
  border-bottom: 3px solid rgba(229, 229, 229, 1);
  width: auto;
  padding-bottom: 8px;
  margin-bottom: 24px;
  transition: 200ms;
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;

export const Category = styled.span<{ isSelected: boolean }>`
  font-family: Poppins;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  font-size: 20px;
  color: rgba(17, 36, 77, 1);
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.7')};
  cursor: pointer;
  position: relative;
  transition: 200ms;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -11px;
    background: ${({ isSelected }) =>
      isSelected ? 'rgba(75, 65, 196, 1)' : 'rgba(229, 229, 229, 1)'};
    height: 3px;
    left: 0;
  }
`;

import { useHistory } from 'react-router-dom';
import * as S from './ChatMessage.styles';

const ChatMessage = ({
  id,
  isHost,
  date,
  message,
  avatar,
  name,
}: {
  id: string;
  isHost: boolean;
  date: string;
  message: string;
  avatar?: string;
  name: string;
}) => {
  const history = useHistory();
  const userProfileLink = `/user/${id}`;

  const handleProfileRedirect = () => {
    if (!isHost && id) {
      history.push(userProfileLink);
    }
  };

  return (
    <S.Wrapper isHost={isHost}>
      {avatar ? (
        <S.Avatar
          src={avatar}
          alt={isHost ? 'your avatar' : `${name}s avatar`}
          isHost={isHost}
          onClick={handleProfileRedirect}
        />
      ) : (
        <S.Person isHost={isHost} onClick={handleProfileRedirect} />
      )}
      <S.MessageWrapper isHost={isHost}>
        <S.Date>{date}</S.Date>
        <S.Message isHost={isHost}>{message}</S.Message>
      </S.MessageWrapper>
    </S.Wrapper>
  );
};

export default ChatMessage;

import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowWrapper = styled.div`
  padding-bottom: 20px;
`;

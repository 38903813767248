import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Assets from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
`;

export const Description = styled.div`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 16px;
  padding-top: 30px;
  display: flex;
  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

export const FAQLink = styled(Link)`
  font-family: Poppins;
  font-size: 15px;
  line-height: 160%;
  font-weight: 500;
  text-decoration: none;
  margin: 14px 0;
  color: ${themes.default.main};
  padding-bottom: 20px;
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  @media (min-width: 575px) {
    flex-direction: row;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 544px;
  align-items: flex-start;
  margin: 0 auto;
  gap: 26px;
  padding-bottom: 66px;
  @media (max-width: 800px) {
    height: auto;
    flex-direction: column;
  }
`;

export const DataSegmentWrapper = styled.div`
  width: 240px;
  display: flex;
  order: 1;
`;

export const PhoneWrapper = styled(DataSegmentWrapper)`
  order: 1;
`;

export const AddressWrapper = styled(DataSegmentWrapper)`
  order: 3;
`;

export const EmailWrapper = styled(DataSegmentWrapper)`
  order: 2;
`;

export const PhoneIcon = styled.img.attrs({ src: Assets.phone, alt: 'phone icon' })`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const MailIcon = styled.img.attrs({ src: Assets.mailGreyIcon, alt: 'mail icon' })`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const MapPinIcon = styled.img.attrs({ src: Assets.greyMapPin, alt: 'map pin icon' })`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const DataTextWrapper = styled.div``;

export const DataText = styled.div`
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
`;

export const LinkedDataText = styled.a`
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  text-decoration: none;
  cursor: pointer;
  :visited {
    color: ${themes.default.graphemeGrey};
  }
  color: ${themes.default.graphemeGrey};
`;

export const CustomB = styled.span`
  font-weight: 600;
`;

export const GreyOut = styled.span`
  color: ${themes.default.bdGrey};
`;

export const FormLink = styled.a`
  display: block;
  font-family: Poppins;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.main};
`;

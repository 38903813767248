import styled from 'styled-components';
import WaveIcon from '../../../assets/Waves/wave-white.svg';

export const ExternalWrapper = styled.div`
  background-color: #e9f5f5;
  @media (min-width: 981px) {
    margin-bottom: 52px;
  }
  overflow: hidden;
  position: relative;
`;

export const Wrapper = styled.div`
  max-width: 1152px;
  justify-content: flex-start;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 0 0 18px;
  box-sizing: border-box;
  position: relative;
`;

export const Header = styled.h1`
  z-index: 1;
  color: rgba(17, 36, 77, 1);
  font-family: Poppins;
  font-weight: 600;
  margin: 0 0 16px 0;
  word-break: break-word;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  z-index: 0;
  filter: saturate(0.8) opacity(0.6);
  right: 0;
  @media (min-width: 600px) {
    filter: saturate(1) opacity(1);
    position: relative;
  }
  height: 156px;
  max-width: 324px;
  width: 100%;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: left;
`;

export const WaveContainer = styled.div`
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 2;
`;

export const AbsoluteImage = styled.img``;

export const LeftImageContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const RightImageContainer = styled.div`
  position: absolute;
  top: -50%;
  right: 0;
  @media (min-width: 768px) {
    top: -36%;
  }
`;

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  @media (min-width: 768px) {
    right: 25%;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  border-radius: 50%;
  min-width: 120px;
  max-width: 120px;
  max-height: 120px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-right: 16px;
  min-height: 120px;
  background-color: white;
  @media (min-width: 768px) {
    max-width: 160px;
    max-height: 160px;
    min-height: 160px;
  }
`;

export const Avatar = styled.img`
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;
export const Location = styled.div`
  color: #11244d;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const LocationContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const LocationIcon = styled.img``;

export const LastLogin = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  color: #828282;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    justify-content: center;
    min-width: 120px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0px;
  }
`;

export const OnlineDot = styled.div<{ isOnline: boolean }>`
  height: 12px;
  width: 12px;
  background-color: ${({ isOnline }) => (isOnline ? 'rgb(111, 207, 151)' : 'red')};
  border-radius: 100%;
  margin-right: 8px;
  margin-bottom: 3px;
`;

export const OnlineStatus = styled.div<{ isOnline: boolean }>`
  color: ${({ isOnline }) => (isOnline ? 'rgb(111, 207, 151)' : 'red')};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
`;

export const SuspendedAccount = styled.div`
  color: #f35431;
  font-family: Poppins;
  font-size: 16px;
`;

import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import * as S from './CategoryTable.styles';
import { updateCategoryParameterOptions } from '../../../../redux/auth/actions';

interface IParameterOption {
  id: string;
  label: string;
  order: number;
  isDeleted?: boolean;
  isNew?: boolean;
  isChanged?: boolean;
}

export interface IParameterOptionTableProps {
  parameterId: string;
  options: IParameterOption[];
  onClose: () => void;
}

const ParameterOptionsTable = (props: IParameterOptionTableProps) => {
  const { parameterId, options = [], onClose } = props;

  const [parameterOptions, setParameterOptions] = useState<IParameterOption[]>(options);
  const [deletedOptions, setDeletedOptions] = useState<any[]>([]);

  useEffect(() => {
    setParameterOptions(options);
  }, [parameterId, options]);

  const handleSelectLabelValue = (e: any, id: string) => {
    e.preventDefault();
    const parameters = [...parameterOptions];
    parameters.map((i: any) => {
      if (i.id === id) {
        i.label = e.target.value;
        i.isChanged = true;
      }
    });
    setParameterOptions(parameters);
  };

  const handleSelectOrderValue = (e: any, id: string) => {
    e.preventDefault();
    const parameters = [...parameterOptions];
    parameters.map((i: any) => {
      if (i.id === id) {
        i.order = e.target.value;
        i.isChanged = true;
      }
    });
    setParameterOptions(parameters);
  };

  const handleDeleteOption = (e: any, id: string) => {
    e.preventDefault();
    const handleDeleteState = () => {
      options.map((p) => {
        if (p.id === id) {
          p.isDeleted = !p.isDeleted;
          return;
        } else {
          return p;
        }
      });
      setParameterOptions(options);
    };
    if (deletedOptions.includes(id)) {
      setDeletedOptions([...deletedOptions.filter((x) => x !== id)]);
      handleDeleteState();
    } else {
      setDeletedOptions([...deletedOptions, id]);
      handleDeleteState();
    }
  };

  const handleAddNewOption = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      label: '',
      order: 0,
      parameterId,
      isNew: true,
    };
    const updatedRecords = [...parameterOptions, newRecord];
    setParameterOptions(updatedRecords);
  };

  const handleUpdateCategoryParameterOptions = async () => {
    await updateCategoryParameterOptions({
      parameterId,
      options: parameterOptions,
    });
    onClose();
  };
  return (
    <S.ParameterOptionsWrapper>
      <S.OptionsTitleContainer>
        <S.OptionsTitle>Opcje</S.OptionsTitle>
        <S.ParameterButton onClick={(e: any) => handleAddNewOption(e)}>Dodaj</S.ParameterButton>
      </S.OptionsTitleContainer>
      {parameterOptions.map((p: IParameterOption, index: number) => {
        const { id, label, order } = p;
        return (
          <S.GapContainer key={p.id}>
            <div>{index + 1}.</div>
            <Input
              value={label}
              onChange={(e: any) => handleSelectLabelValue(e, id)}
              disabled={deletedOptions.includes(id)}
            />
            <Input
              value={order}
              type="number"
              onChange={(e: any) => handleSelectOrderValue(e, id)}
              disabled={deletedOptions.includes(id)}
              style={{ maxWidth: 60 }}
            />
            <S.DeleteButton onClick={(e: any) => handleDeleteOption(e, id)}>Usuń</S.DeleteButton>
          </S.GapContainer>
        );
      })}
      <div>
        <S.ParameterButton onClick={() => handleUpdateCategoryParameterOptions()}>
          Zapisz zmiany
        </S.ParameterButton>
      </div>
    </S.ParameterOptionsWrapper>
  );
};

export default ParameterOptionsTable;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ExternalWrapper = styled.div`
  max-width: 1148px;
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  max-width: 1148px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SideWrapper = styled.aside`
  padding-top: 54px;
  margin-left: 12px;
  width: 346px;
  min-width: 346px;
`;

export const PopularHeader = styled.h3`
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #373f41;
  margin: 0 0 24px;
`;

export const PopularItem = styled(Link)`
  font-family: Poppins;
  font-size: 16px;
  color: #373f41;
  margin: 0 0 16px;
  display: block;
  text-decoration: none;
`;

import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import styled from 'styled-components';
import SVG from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';
import CSS from '../../../assets/CSS/commonStyles';

export const Wrapper = styled.article`
  width: 100%;
  max-width: 251px;
  min-width: 251px;
  height: 310px;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  background-color: ${themes.default.white};
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 8px;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 2px rgba(75, 65, 196, 1);
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  max-width: 254px;
  min-height: 140px;
  height: 140px;
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

interface PreviewProps {
  isOpen: boolean;
}

export const PreviewWrapper = styled.div<PreviewProps>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 300ms;
  display: flex;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  background-color: rgb(74, 82, 83, 0.7);
  z-index: 2;
`;

export const PreviewButton = styled.button`
  ${CSS.plainButton}
  ${CSS.standardFont12}
  color: ${themes.default.graphemeGrey};
  background-color: ${themes.default.white};
  height: 40px;
  width: 150px;
  margin: auto;
  border-radius: 8px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: center;
  z-index: 0;
`;

export const MockImage = styled(Image)`
  object-fit: contain;
`;

export const DataWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const LocalizationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LocationIcon = styled.img.attrs({ alt: 'location', src: SVG.mapPin })`
  min-width: 12px;
  height: 12px;
  /* opacity: 0.6; */
  margin-right: 2px;
`;

export const City = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: -0.9px;
  color: #828282;
`;

export const Title = styled.h6`
  margin: 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
  color: rgba(17, 36, 77, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 20px;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const Price = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 123%;
  letter-spacing: 0.1px;
  color: rgba(75, 65, 196, 1);
`;

export const Date = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const StarWrapper = styled.button`
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 100%;
  right: 8px;
  top: 8px;
  background: ${themes.default.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border: none;
  padding: 4px;
  :focus {
    outline: none;
  }
`;

export const StyledIcon = styled.img`
  min-width: 18px;
  width: 18px;
  height: 14px;
`;
const starCSS = `
  width: 22px;
  height: 22px;
`;

export const StarIcon = styled(AiOutlineStar)`
  ${starCSS}
  color: ${themes.default.graphemeGrey};
`;

export const FullStarIcon = styled(AiFillStar)`
  ${starCSS}
  color: ${themes.default.star};
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
  min-height: 20px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  max-height: 64px;
  padding-bottom: 14px;
  flex: 1;
`;

export const Description = styled.div`
  margin: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: rgba(17, 36, 77, 1);
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 64px;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const PriceAndDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

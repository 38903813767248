import styled from 'styled-components';
import SVG from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';
import CSS from '../../../assets/CSS/commonStyles';
import { IoIosArrowUp, IoIosArrowDown, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import WaveIcon from '../../../assets/Waves/wave-yellow.svg';
import WaveWhite from '../../../assets/Waves/wave-white.svg';

export const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column; */
  padding-bottom: 174px;
  position: relative;
  width: 100%;
`;

export const WaveContainer = styled.div`
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 2;
  transform: rotate(180deg);
  margin-bottom: 34px;
`;

export const TopWrapper = styled.div<{ isBazaar: boolean }>`
  width: 100%;
  background-color: ${({ isBazaar }) => (isBazaar ? '#E9F5F5' : 'rgba(254, 249, 235, 1)')};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const OfferWrapper = styled.div<{ isBazaar: boolean }>`
  max-width: 1108px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ isBazaar }) => (isBazaar ? '68px;' : '0px')};
  font-family: Poppins;
  position: relative;
  padding: 16px 20px;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const GeneralInfoWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const BottomWrapper = styled.div`
  display: flex;
  max-width: 1108px;
  width: 100%;
  margin: 0 auto 32px;
  gap: 52px;
`;

export const RightDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 288px;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: sticky;
  top: 80px;
  align-items: center;
  width: 100%;
  max-width: 288px;
  margin-top: 35px;
  margin-bottom: 67px;
  @media (max-width: 1130px) {
    display: none;
  }
`;

export const Title = styled.div`
  min-height: 40px;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 5px;
  margin-top: 19px;
  color: ${themes.default.graphemeGrey};
`;

export const SmallInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 9px;
`;

export const LocationPin = styled.img.attrs({ src: SVG.greyMapPin, alt: 'pin' })`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;

export const InfoText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: rgba(130, 130, 130, 1);
  margin-right: 28px;
  margin-bottom: 10px;
`;

export const LoctionText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: rgba(17, 36, 77, 1);
  margin-right: 28px;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${themes.default.fontColorBlack};
  overflow: auto;
  border: none;
  resize: none;
  box-sizing: border-box;
  /* height: 320px; */
  background-color: white;
  font-family: Poppins;
  width: 100%;
  margin-bottom: 29px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface MobileRightPanelProps {
  isLong: boolean;
  isPadding: boolean;
}

export const MobileRightPanel = styled.div<MobileRightPanelProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  height: ${(props) => (props.isLong ? `350px` : 'auto')};
  padding-bottom: ${(props) => (props.isPadding ? `56px;` : '0;')};
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
  @media (min-width: 1131px) {
    display: none;
  }
  @media (max-width: 1130px) {
    max-width: 100%;
    width: 100%;
  }
`;

interface ArrowProps {
  isLeft?: boolean;
}

export const ArrowWrapper = styled.button<ArrowProps>`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${themes.default.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  position: absolute;
  z-index: 4;
  top: 50%;
  ${(props) => (props.isLeft ? 'left: 8px' : 'right: 8px')};
  :focus {
    outline: none;
  }
`;

export const ArrowUp = styled(IoIosArrowUp)`
  width: 30px;
  height: 30px;
`;

export const ArrowDown = styled(IoIosArrowDown)`
  width: 30px;
  height: 30px;
`;

export const ArrowLeft = styled(IoIosArrowBack)`
  width: 30px;
  height: 30px;
`;

export const ArrowRight = styled(IoIosArrowForward)`
  width: 30px;
  height: 30px;
`;

const starCSS = `
width: 30px;
height: 30px;
`;

export const StarIcon = styled(AiOutlineStar)`
  ${starCSS}
  color: ${themes.default.graphemeGrey};
`;

export const FullStarIcon = styled(AiFillStar)`
  ${starCSS}
  color: ${themes.default.star};
`;

export const StarWrapper = styled(ArrowWrapper)`
  top: 8px;
  left: 8px;
`;

export const GalleryWrapper = styled.div<{ isBazaar: boolean }>`
  /* margin: 0 auto; */
  max-width: 846px;
  width: 100%;
  z-index: 3;
  .fullscreen {
    width: 100% !important;
  }
  .fullscreen .test {
    max-width: 100% !important;
    height: 100% !important;
    max-height: none;
  }
  .image-gallery-thumbnails {
    max-width: ${({ isBazaar }) => (isBazaar ? '128px' : '348px')} !important;
    width: ${({ isBazaar }) => (isBazaar ? '108px' : '348px')} !important;
    /* height: ${({ isBazaar }) => (isBazaar ? 'auto' : 'auto')} !important; */
  }
  .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
    gap: 22px !important;
  }
  .image-gallery-thumbnail {
    max-width: ${({ isBazaar }) => (isBazaar ? '128px' : '348px')} !important;
    width: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    .active {
      max-width: ${({ isBazaar }) => (isBazaar ? '128px' : '348px')} !important;
      width: 100% !important;
      border-radius: 8px !important;
    }
  }
  .image-gallery-thumbnails-wrapper.left {
    margin-right: 25px;
  }
  .image-gallery-slide-wrapper.left {
    width: calc(100% - 130px);
  }
  .image-gallery-content.fullscreen  {
  .image-gallery-slide img {
    /* width: auto; */
    max-height: 100vh;
    height: 100vh;
    object-fit: contain;
  }
  .image-gallery-image {
    text-align: center;
  } ///mobile todo
}
`;

export const MockImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const MainPhotoWrapper = styled.div<{ isBazaar: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  max-width: ${({ isBazaar }) => (isBazaar ? '600px' : '736px')};
  max-height: ${({ isBazaar }) => (isBazaar ? '' : '176px')};
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media (min-width: 1109px) {
    height: ${({ isBazaar }) => (isBazaar ? '444px' : '396px')};
    max-height: none;
  }
`;

export const ThumbnailWrapper = styled.div<{ isBazaar: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: ${({ isBazaar }) => (isBazaar ? '128px' : '348px')};
  height: ${({ isBazaar }) => (isBazaar ? '80px' : '186px')};
  border-radius: 8px;
`;

export const MainPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

interface ReportProps {
  isCentered?: boolean;
  isReported?: boolean;
}

export const ReportButton = styled.button<ReportProps>`
  ${CSS.plainButton}
  ${CSS.standardFont12}
  color: ${themes.default.bdGrey};
  margin: 16px 0 0 auto;
  display: flex;
  align-items: center;
  ${(props) => props.isCentered && 'margin: 16px auto auto;'}
  ${(props) => props.isReported && 'cursor: default'}
`;

export const InfoIcon = styled(IoMdInformationCircleOutline)`
  color: ${themes.default.bdGrey};
  margin-right: 4px;
  width: 20px;
  height: 20px;
`;

export const ModalWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #373f41dd;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  padding: 32px 16px;
  box-sizing: border-box;
  transform: ${({ isOpen }) => (isOpen ? 'scale(1)' : 'scale(0)')};
  transition: transform 250ms;
  @media (min-width: 600px) {
    padding: 32px;
  }
`;

export const BigPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionLabel = styled.h4`
  margin: 0;
  padding-bottom: 24px;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(17, 36, 77, 1);
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 736px;
  width: 100%;
  padding: 16px 20px;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const ContentRow = styled.div`
  max-width: 1110px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 1130px) {
    max-width: 900px;
  }
  @media (max-width: 1130px) {
    flex-direction: column;
  }
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 825px;
  width: 100%;
  margin-bottom: 68px;
  @media (max-width: 1130px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const AbsoluteTopWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 586px;
  background-color: #e9f5f5;
  display: flex;
  align-items: flex-end;
`;

export const SmallWhiteWaveContainer = styled.div`
  width: 100%;
  height: 20px;
  background-image: url(${WaveWhite});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  /* z-index: 2; */
  /* transform: rotate(180deg); */
`;

export const BazaarTopLeft = styled.div`
  position: absolute;
  top: 200px;
`;

export const BazaarTopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const AnnouncementTopLeft = styled.div`
  position: absolute;
  top: 200px;
`;

export const AnnouncementTopRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const AbsoluteImage = styled.img``;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 100vh;
`;

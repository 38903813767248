import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { GoQuote } from 'react-icons/go';

export const Wrapper = styled.div``;

export const QuoteIcon = styled(GoQuote)`
  color: ${themes.default.lavender};
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  max-width: 280px;
  font-family: Poppins;
  font-style: italic;
  font-size: 14px;
  line-height: 200%;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 24px;
`;

export const HappyUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HappyUserPhoto = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 100%;
  margin-right: 8px;
`;

export const HappyUserData = styled.div`
  font-family: Poppins;
  font-size: 14px;
  line-height: 214%;
  color: ${themes.default.graphemeGrey};
`;

import styled from 'styled-components';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { FiTrash2 } from 'react-icons/fi';

export const Wrapper = styled.div`
  display: flex;
  background-color: #e9f5f5;
  justify-content: center;
  padding: 20px;
  position: relative;
  z-index: 2;
  @media (min-width: 768px) {
    padding: 16px 0 58px;
    width: 100%;
  }
`;

export const InternalWrapper = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 12px;
  max-width: 730px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 12px;
  padding: 20px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 56px 95px;
    gap: 24px;
  }
`;

export const HeaderContainer = styled.div`
  margin-bottom: 8px;
`;

export const StyledHeader = styled.h2`
  margin: 0;
  color: #11244d;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.4px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 50px;
  font-family: Poppins;
  font-size: 12px;
  background: white;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  padding: 15px 21px;
  border-radius: 4px;
  color: black;
  :focus {
    outline: none;
  }
  ${(props) => props.readOnly && `background: #FAFAFA`};
  ${(props) => props.disabled && `background: #FAFAFA`};
  ::placeholder {
    color: hsl(0, 0%, 80%);
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  font-family: Poppins;
  font-size: 12px;
  background: white;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  padding: 15px 21px;
  border-radius: 4px;
  color: black;
  :focus {
    outline: none;
  }
  ${(props) => props.readOnly && `background: #FAFAFA`};
  ::placeholder {
    color: hsl(0, 0%, 80%);
  }
`;

export const StyledLabel = styled.span`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  color: #11244d;
`;

export const ButtonContainer = styled.div``;

export const SaveButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  padding: 12px 24px;
  background-color: rgba(75, 65, 196, 1);
  font-family: Poppins;
  border: none;
  color: white;
  font-weight: 600;
  ${(props) => props.disabled && `opacity: 0.6; cursor: auto;`};
  &:hover&:not([disabled]) {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const DoubleInputContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1024px) {
    gap: 32px;
    justify-content: flex-start;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  height: 50px;
  align-items: flex-end;
`;

export const RowContainer = styled.div<{ isDeleted: boolean }>`
  opacity: ${({ isDeleted }) => (isDeleted ? '0.6' : '1')};
`;

export const ParamsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 35px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DynamicOptionsWrapper = styled.div`
  display: flex;
  width: 168px;
  align-items: center;
`;

export const DynamicOptionlabel = styled.span<{ isSelect: boolean }>`
  font-family: Poppins;
  font-size: 15px;
  color: #11244d;
  font-weight: 600;
  overflow-wrap: anywhere;
  margin-bottom: ${({ isSelect }) => (isSelect ? '12px' : '0')};
`;

export const DynamicInputContainer = styled.div`
  display: flex;
  margin-right: 14px;
  flex: 1;
`;

export const DynamicTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
`;

export const DynamicTextContainer = styled.div`
  margin-bottom: 4px;
`;

export const StyledCheckboxInput = styled.input`
  margin-right: 16px;
  min-width: 28px;
  width: 28px;
  height: 28px;
  .checked {
    background-color: red !important;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const CancelButton = styled(SaveButton)`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  color: #f35431;
  border: 2px solid #f35431;
  font-weight: 700;
  padding: 10px 24px;
  &:hover&:not([disabled]) {
    box-shadow: 0 0 4px #f35431;
  }
`;

export const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

interface PhotoContainerProps {
  isSelected: boolean;
}

export const PhotoContainer = styled.div<PhotoContainerProps>`
  display: flex;
  height: 48px;
  max-width: 82px;
  width: 100%;
  cursor: pointer;
  position: relative;
  opacity: ${({ isSelected }) => (isSelected ? '0.5' : '1')};
  border: 1px dashed #e5e5e5;
`;

export const StyledPhoto = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
`;

export const Counter = styled.div`
  width: 100%;
  color: #828282;
  text-align: right;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
`;

export const ForFreeLabel = styled.label`
  color: #11244d;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ParamsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const CheckIcon = styled(AiOutlineCheck)`
  width: 16px;
  height: 16px;
`;

export const CloseIcon = styled(IoMdClose)`
  width: 16px;
  height: 16px;
`;

export const TrashContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(74, 82, 83, 0.7);
  align-items: center;
  justify-content: center;
`;

export const TrashIcon = styled(FiTrash2)`
  width: 24px;
  height: 24px;
  color: white;
`;

export const StarsContainer1 = styled.div`
  position: absolute;
  right: 0;
  z-index: -1;
  top: 80px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StarsContainer2 = styled.div`
  position: absolute;
  left: -114px;
  z-index: -1;
  top: 234px;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

import { IOffer, OFFER_STATUS } from '../redux/offer/types';

export const mapWatchedCountForOffers = (offers: IOffer[]) => {
  const updatedLatest = offers.map((offer: IOffer) => {
    const { watched } = offer;
    const watchedCount = watched ? watched.length : 0;
    return {
      ...offer,
      watchedCount: watchedCount,
    };
  });
  return updatedLatest;
};

export const mapUserOffers = (offers: IOffer[]) => {
  const archived = offers.filter((offer: IOffer) => offer.status === OFFER_STATUS.ARCHIVED);
  const active = offers.filter(
    (offer: IOffer) => offer.status === OFFER_STATUS.ACTIVE && offer.isAdminAccepted
  );
  const awaiting = offers.filter(
    (offer: IOffer) =>
      !offer.isAdminAccepted &&
      offer.status !== OFFER_STATUS.TERMINATED &&
      offer.status !== OFFER_STATUS.ARCHIVED
  );
  const terminated = offers.filter((offer: IOffer) => offer.status === OFFER_STATUS.TERMINATED);
  return {
    archived,
    active,
    awaiting,
    terminated,
  };
};

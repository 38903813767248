import useMediaQuery from '../../../hooks/useMediaQuery';
import imgLeft1 from '../../../assets/SearchSection/left1.svg';
import imgLeft2 from '../../../assets/SearchSection/left2.svg';
import imgRight1 from '../../../assets/SearchSection/right1.svg';
import imgRight2 from '../../../assets/SearchSection/right2.svg';
import stars from '../../../assets/Contact/Stars.svg';
import * as S from './Header.styles';

const Header = ({ title, image }: { title: string; image?: string }) => {
  const isTablet = useMediaQuery('(min-width: 768px)');
  return (
    <S.ExternalWrapper>
      <>
        <S.LeftOneImageContainer>
          <S.AbsoluteImage src={imgLeft1} />
        </S.LeftOneImageContainer>
        <S.LeftTwoImageContainer>
          <S.AbsoluteImage src={imgLeft2} />
        </S.LeftTwoImageContainer>
      </>
      <S.Wrapper>
        <S.StarsContainer>
          <S.AbsoluteImage src={stars} />
        </S.StarsContainer>
        <S.Header>{title}</S.Header>
      </S.Wrapper>
      <S.WaveContainer />
      {isTablet && (
        <>
          <S.RightOneImageContainer>
            <S.AbsoluteImage src={imgRight1} />
          </S.RightOneImageContainer>
          <S.RightTwoImageContainer>
            <S.AbsoluteImage src={imgRight2} />
          </S.RightTwoImageContainer>
        </>
      )}
    </S.ExternalWrapper>
  );
};

export default Header;

import * as S from './FAQContact.styles';

const FAQContact = () => (
  <S.Wrapper>
    <S.Message>Jesli nie znalazłeś(aś) odpowiedzi na pytanie to:</S.Message>
    <S.ContactLink to="/contact">Skontaktuj się z nami</S.ContactLink>
  </S.Wrapper>
);

export default FAQContact;

import styled from 'styled-components';
import Star from '../../../assets/star.svg';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import WaveIcon from '../../../assets/Waves/wave-yellow.svg';

export const ExternalWrapper = styled.div`
  background-color: #fafafa;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Wrapper = styled.main`
  padding: 118px 19px 58px;
  max-width: 1112px;
  margin: 0 auto;
  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #11244d;
  position: relative;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    height: 230px;
  }
`;

export const PurpleContainer = styled.div`
  height: 21px;
  position: absolute;
  background-color: #11244d;
  bottom: -21px;
  width: 100%;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #fef9eb;
  position: relative;
  justify-content: center;
`;

export const SearchWrapper = styled.section.attrs({ role: 'search section' })`
  max-width: 920px;
  padding: 24px;
  border-radius: 32px;
  background-color: white;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  z-index: 4;
  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const WatchTagWrapper = styled.button`
  display: flex;
  align-self: flex-end;
  align-items: center;
  border: none;
  background-color: transparent;
  margin-left: auto;
  :focus {
    outline: none;
  }
  @media (min-width: 1024px) {
  }
`;

export const WatchTag = styled.div`
  font-size: 13px;
  color: #828282;
  letter-spacing: 0.1px;
  font-weight: 400;
`;

export const StarIcon = styled.img.attrs({ alt: 'star', src: Star })`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  opacity: 0.7;
`;

export const ResultsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 19px;
`;

const ResultsHeaderFontProps = `
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
  color: #BDBDBD;
`;

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  ${ResultsHeaderFontProps}
  @media (max-width: 800px) {
    margin-bottom: 24px;
  }
`;

export const BreadCrumbHead = styled.button`
  ${ResultsHeaderFontProps}
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const BreadCrumbSeparator = styled(IoIosArrowForward)`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  color: #bdbdbd;
`;

export const BreadCrumbItem = styled.button`
  background: #f2f2f2;
  font-family: Poppins;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #bdbdbd;
  border-radius: 15px;
  padding: 1px 16px;
  border: none;
  cursor: pointer;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 28px;
  margin-bottom: 16px;
`;

export const ResultsCount = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
  color: #bdbdbd;
  margin-bottom: 29px;
`;

export const MockOffer = styled.div`
  width: 100%;
  max-width: 251px;
  height: 170px;
`;

export const AbsoluteImage = styled.img``;

export const LeftOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const LeftTwoImageContainer = styled.div`
  position: absolute;
  /* top: 0; */
  top: -70%;
  left: 0;
`;

export const RightOneImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const RightTwoImageContainer = styled.div`
  position: absolute;
  /* top: 0; */
  top: -70%;
  right: 0;
`;

export const WaveContainer = styled.div`
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  position: relative;
  z-index: 2;
`;

export const SearchHeaderWrapper = styled.div`
  padding: 32px 0;
  z-index: 10;
  @media (min-width: 1024px) {
    padding: 40px 0;
  }
`;

export const SearchHeader = styled.div`
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  color: white;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  z-index: 11;
  @media (min-width: 768px) {
    position: absolute;
    bottom: -100px;
  }
`;

export const CategorySearchButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  position: relative;
  justify-content: center;
`;

export const CategoryButton = styled.button<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? 'white' : 'rgba(221, 223, 228, 1)')};
  color: rgba(17, 36, 77, 1);
  border-radius: 16px 16px 0 0;
  max-width: 124px;
  width: 100%;
  border: none;
  font-family: Poppins;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  padding: 8px 32px;
  cursor: pointer;
  transition: 300ms;
`;

export const QueryText = styled.span`
  color: rgba(242, 183, 5, 1);
`;

export const QueryTextContainer = styled.span``;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubcategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
`;

export const SubcategoryContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background-color: ${({ isSelected }) => (isSelected ? 'rgba(75, 65, 196, 1)' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : 'rgba(75, 65, 196, 1)')};
  border: ${({ isSelected }) =>
    isSelected ? '1px solid transparent' : '1px solid rgba(75, 65, 196, 1)'};
  cursor: pointer;
  padding: 4px 6px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const BackToSubcategory = styled(SubcategoryContainer)`
  border: none;
  color: rgba(75, 65, 196, 1);
  font-weight: 700;
  background-color: transparent;
  &:hover {
    box-shadow: none;
  }
`;

export const SingleInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
`;

export const LabelContainer = styled.div`
  margin-bottom: 8px;
`;

export const StyledLabel = styled.label``;

export const SearchButtonContainer = styled.div`
  margin-bottom: 16px;
`;

export const SearchButton = styled.button`
  max-width: 100px;
  width: 100%;
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  background-color: rgba(75, 65, 196, 1);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const ArrowBack = styled(IoIosArrowBack)``;

import * as A from './actionTypes';
import {
  AuthReducerState,
  User,
  // Thread,
  MESSAGE_CATEGORY,
  LOADING_FIELD,
  USER_ROLE,
  USER_STATUS,
} from './types';

export const emptyUser: User = {
  id: '',
  email: '',
  phone: '',
  isOnline: false,
  name: '',
  avatar: '',
  surname: '',
  nickname: '',
  isPasswordSet: false,
  localisation: '',
  watchedOffers: [],
  isSubscribedToTradeAndMarketing: false,
  isSubscribedToServicePartners: false,
  isSubscribedToOfferStatistics: false,
  notifications: {
    count: 0,
    rows: [],
  },
  companyNIP: '',
  companyName: '',
  companyAddress: '',
  companyZipCode: '',
  companyCity: '',
  lat: 0,
  lon: 0,
  files: [],
  role: USER_ROLE.USER,
  status: USER_STATUS.ACTIVE,
  payments: {
    current: [],
    archived: [],
  },
  balance: 0,
  hasSeenDashboardWelcome: false,
  hasAcceptedCookies: false,
};

export const initialState: AuthReducerState = {
  user: emptyUser,
  isLoading: false,
  isLoggedIn: false,
  loadingField: LOADING_FIELD.ANY,
  signInErrorMessage: '',
  emailForPasswordReset: '',
  // TODO: remove when mailchimp
  password: '',
  messageCategory: MESSAGE_CATEGORY.ACTIVE,
  changePasswordErrorMessage: '',
  messagesQuery: '',
  threads: {
    unReadCount: 0,
    active: [],
    // sent: [],
    // received: [],
    trash: [],
  },
  isSendingMessage: false,
  dashboard: {
    offersCount: 0,
    watchedCount: 0,
    messagesCount: 0,
  },
};

const AuthReducer = (state = initialState, action: any): AuthReducerState => {
  switch (action.type) {
    case A.SET_SIGN_IN_ERROR_MESSAGE: {
      return {
        ...state,
        signInErrorMessage: action.data,
      };
    }
    case A.SET_VERIFIED_USER: {
      return {
        ...state,
        user: { ...emptyUser, isOnline: true, ...action.user },
        isLoading: false,
        isLoggedIn: true,
      };
    }
    case A.LOGIN_USER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.LOGIN_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        user: { ...emptyUser, ...action.data.user },
      };
    }
    case A.LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case A.SET_USER_DATA: {
      return {
        ...state,
        user: { ...emptyUser, ...action.data },
        isLoading: false,
      };
    }
    case A.USER_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        loadingField: LOADING_FIELD.ANY,
        user: { ...state.user, ...action.data },
      };
    }
    case A.REGISTER_USER_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.REGISTER_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.RESET_PASSWORD_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        emailForPasswordReset: action.email,
        // TODO: remove when mailchimp
        password: action.password,
        isLoading: false,
      };
    }
    case A.SET_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        changePasswordErrorMessage: action.message,
      };
    }
    case A.SET_LOADING_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.SET_LOADING_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.ADD_NEW_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.ADD_NEW_CATEGORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.ADD_NEW_CATEGORY_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.REMOVE_THREAD_SUCCESS: {
      const { threadIds }: { threadIds: string[] } = action.data;
      const {
        threads: { trash, ...rest },
      } = state;
      const filteredTrash = trash.filter(({ id }) => !threadIds.includes(id));
      return {
        ...state,
        threads: {
          ...rest,
          trash: filteredTrash,
        },
      };
    }
    case A.USER_UTILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case A.USER_UTILS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case A.USER_UTILS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    }
    case A.SET_MESSAGES_CATEGORY: {
      return {
        ...state,
        messageCategory: action.data,
      };
    }
    case A.SET_MESSAGES_QUERY: {
      return {
        ...state,
        messagesQuery: action.query,
      };
    }
    case A.SET_IS_LOADING: {
      return {
        ...state,
        ...action.data,
      };
    }
    case A.ADD_USER_FILE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          files: [action.data, ...state.user.files],
        },
        isLoading: false,
      };
    }
    case A.GET_USERS_FILES_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          files: action.data,
        },
      };
    }
    case A.DELETE_USERS_FILE_SUCCESS: {
      const files = state.user.files.filter(({ id }) => id !== action.data);
      return {
        ...state,
        user: {
          ...state.user,
          files: files,
        },
      };
    }
    case A.GET_DASHBOARD_COUNT_SUCCESS: {
      return {
        ...state,
        dashboard: action.data,
      };
    }
    case A.ARCHIVE_THREAD_SUCCESS: {
      const { threadId, isArchive } = action.data;
      const {
        threads: { trash, unReadCount },
        // user: { id },
      } = state;
      if (!isArchive) {
        const filteredArchived = trash.filter(({ id }) => id !== threadId);
        return {
          ...state,
          threads: {
            ...state.threads,
            trash: filteredArchived,
            // active: state.threads
            // sent: updatedSent,
            // received: updatedReceived,
          },
        };
      }
      return {
        ...state,
        threads: {
          active: [],
          trash: [],
          // trash: filteredOut ? [...trash, filteredOut] : trash,
          // sent: newSent,
          // received: newReceived,
          unReadCount,
        },
      };
    }
    case A.SELECT_ACCOUNT_TYPE: {
      return {
        ...state,
        user: {
          ...state.user,
          role: action.data.role,
        },
        isLoading: false,
      };
    }
    case A.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          payments: action.data,
        },
        isLoading: false,
      };
    }
    case A.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.data,
      };
    }
    case A.UPDATE_USERS_SUCCESS: {
      const { rows, count } = state.users || { rows: [], count: 0 };
      const { userIds, status, role } = action.data;
      const updatedUsers = rows.map((user) => {
        const { id, status: oldStatus, role: oldRole } = user;
        const newStatus = status ? status : oldStatus;
        const newRole = role ? role : oldRole;
        if (userIds.includes(id)) {
          return { ...user, role: newRole, status: newStatus };
        }
        return user;
      });
      return {
        ...state,
        users: {
          count,
          rows: updatedUsers,
        },
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;

import styled from 'styled-components';

export const Wrapper = styled.article<{ isFirst: boolean }>`
  width: 100%;
  min-height: 98px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e5e5e5;
  ${({ isFirst }) => isFirst && `border-top:  none;`}
  padding: 13px 22px;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserMail = styled.p`
  flex-basis: 120px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: #373f41;
  padding: 0 4px 4px;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

export const UserName = styled(UserMail)`
  font-weight: 500;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 130px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

import { memo, useEffect } from 'react';
import NavProfile from '../NavProfile/NavProfile';
import { useSelector } from 'react-redux';
import { authState } from '../../redux/auth/selectors';
import { useLocation } from 'react-router';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useHistory } from 'react-router-dom';
import * as S from './NavbarMobile.style';
import NavbarBanner from '../Navbar/NavbarBanner';

const NavbarMobile = ({
  isMenuOpen,
  setIsMenuOpened,
}: {
  isMenuOpen: boolean;
  setIsMenuOpened: (value: boolean) => void;
}) => {
  const {
    isLoggedIn,
    user: {
      name,
      nickname,
      avatar,
      email,
      notifications: { count },
    },
  } = useSelector(authState);
  const isDesktop = useMediaQuery('(min-width: 881px)');
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const handleToggleUserMenu = () => {
    setIsMenuOpened(!isMenuOpen);
  };
  const isMenuButton = pathname.includes('user-dashboard') || pathname.includes('admin-dashboard');

  useEffect(() => {
    if (isMenuOpen) {
      setIsMenuOpened(!isMenuOpen);
    }
  }, [isDesktop]);

  const handleRedirect = () => {
    if (!nickname) {
      history.push('/user-dashboard/settings');
    } else if (email) {
      history.push('/create/step-one');
    } else {
      history.push('/login');
    }
  };

  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        {isMenuButton && (
          <S.PlainButton onClick={handleToggleUserMenu}>
            {isMenuOpen ? <S.CloseUserMenuIcon /> : <S.OpenUserMenuIcon />}
          </S.PlainButton>
        )}
        <S.TopLeftWrapper>
          <S.LogoWrapper to="/">
            <S.BBLogo />
          </S.LogoWrapper>
          {/* <S.LogoLabel>Sprzedawaj bez opłat</S.LogoLabel> */}
        </S.TopLeftWrapper>
        <NavProfile
          {...{
            avatar,
            name,
            email,
            isLoggedIn,
            nickname,
            hasNotifications: count > 0,
          }}
        />
        <S.MobileAddOfferButton onClick={handleRedirect}>
          <S.PlusSign>+</S.PlusSign>
        </S.MobileAddOfferButton>
      </S.Wrapper>
      <NavbarBanner />
    </S.ExternalWrapper>
  );
};

export default memo(NavbarMobile);

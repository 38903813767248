import AppStoreIcon from '../../assets/AppStore.svg';
import GoogleStoreIcon from '../../assets/GooglePlay.svg';
import * as S from './Footers.style';

export default () => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div>
        <S.WaveContainer />
        <div
          style={{
            backgroundColor: '#fef9eb',
            height: '20px',
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        />
        <S.Wrapper>
          <S.InternalWrapper>
            <S.MainWrapper>
              {/* <S.SupHeader>Darmowa aplikacja na Twój telefon</S.SupHeader> */}
              <S.MainHeader>Zajęcia dla dzieci blisko Ciebie</S.MainHeader>
              {/* <S.AppsWrapper>
                <S.Anchor hasMargin href="https://www.apple.com/app-store/">
                  <S.AppIcon src={AppStoreIcon} alt="Apple Store" />
                </S.Anchor>
                <S.Anchor href="https://play.google.com/store">
                  <S.AppIcon src={GoogleStoreIcon} alt="Google Store" />
                </S.Anchor>
              </S.AppsWrapper> */}
            </S.MainWrapper>
            <S.SecondaryWrapper>
              <S.ColumnWrapper>
                <S.SectionHeader>Firma</S.SectionHeader>
                <S.StyledLink to="/help">Jak działają Malutcy.pl</S.StyledLink>
                <S.StyledLink to="/privacy-policy">Polityka prywatności</S.StyledLink>
              </S.ColumnWrapper>
              <S.ColumnWrapper>
                <S.SectionHeader>Dla Ciebie</S.SectionHeader>
                <S.StyledLink to="/help">Jak działają Malutcy.pl</S.StyledLink>
                <S.StyledLink to="/regulations">Regulamin</S.StyledLink>
                <S.StyledLink to="/privacy-policy">Polityka prywatności</S.StyledLink>
              </S.ColumnWrapper>
              <S.ColumnWrapper>
                <S.SectionHeader>Pomoc</S.SectionHeader>
                <S.StyledLink to="/faq">FAQ</S.StyledLink>
                <S.StyledLink to="/contact">Kontakt</S.StyledLink>
                <S.SocialWrapper>
                  <S.Anchor hasMargin href="https://www.facebook.com">
                    <S.FacebookIcon />
                  </S.Anchor>
                  <S.Anchor href="https://instagram.com/">
                    <S.InstagramIcon />
                  </S.Anchor>
                </S.SocialWrapper>
              </S.ColumnWrapper>
            </S.SecondaryWrapper>
            <S.CopyRights>2024 © Malutcy.pl, Wszystkie prawa zastrzeżone.</S.CopyRights>
          </S.InternalWrapper>
        </S.Wrapper>
      </div>
    </div>
  );
};

import Navigation from './Navigation/Navigation';
import * as S from './PageWrapper.styles';

const PageWrapper = ({
  children,
  isCompletedStepOne,
  isCompletedStepTwo,
  isActiveStepOne,
  isActiveStepTwo,
  onSubmit,
}: any) => (
  <S.Wrapper>
    <Navigation
      isCompletedStepOne={isCompletedStepOne}
      isCompletedStepTwo={isCompletedStepTwo}
      isActiveStepOne={isActiveStepOne}
      isActiveStepTwo={isActiveStepTwo}
    />
    <S.BodyWrapper onSubmit={onSubmit}>{children}</S.BodyWrapper>
    {/* <S.StarsContainer2>
      <S.StyledImage src={Stars2} />
    </S.StarsContainer2> */}
  </S.Wrapper>
);

export default PageWrapper;

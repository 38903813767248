import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { addCategoryParameter } from '../../../../redux/auth/actions';
import { Button, Checkbox, Input, Select } from 'antd';
import * as S from './CategoryTable.styles';

interface INewParamProps {
  categoryId: string;
  closeModal: () => void;
}

const paramOptions: any[] = [
  {
    label: 'Tak/Nie',
    value: 'yesNo',
  },
  {
    label: 'Tekst',
    value: 'text',
  },
  {
    label: 'Wybór z listy',
    value: 'select',
  },
];

const NewParameterForm = (props: INewParamProps) => {
  const { categoryId, closeModal } = props;
  const dispatch = useDispatch();

  const [paramLabel, setParamLabel] = useState('');
  const [selectedParamType, setSelectedParamType] = useState(undefined);
  const [newParamOptions, setNewParamOptions] = useState('');
  const [isRequiredParameter, setIsRequiredParameter] = useState(false);
  const [parameterDefaultValue, setParameterDefaultValue] = useState('');
  const [isCheckedByDefault, setIsCheckedByDefault] = useState(false);

  const selectedTypeVal =
    paramOptions.find(({ value }) => value === selectedParamType) || undefined;

  const handleSaveParameter = () => {
    dispatch(
      addCategoryParameter({
        label: paramLabel,
        categoryId,
        type: selectedTypeVal.value,
        options: newParamOptions.split(','),
        isRequired: isRequiredParameter,
        defaultValue: parameterDefaultValue,
        isCheckedByDefault,
      })
    );
    closeModal();
  };

  useEffect(() => {
    setParamLabel('');
    setSelectedParamType(undefined);
  }, [categoryId]);

  const showSelectDefault = selectedParamType && selectedParamType === 'select';
  const checkboxDefault = selectedParamType && selectedParamType === 'yesNo';

  const disabledButtonWhenSelect = showSelectDefault && !newParamOptions;
  const isButtonDisabled = !paramLabel || !selectedParamType || disabledButtonWhenSelect;

  return (
    <S.DynamicParameterWrapper>
      <Input
        placeholder="Nazwa"
        value={paramLabel}
        onChange={(e) => setParamLabel(e.target.value)}
        addonBefore="Nazwa"
      />
      <Select
        options={paramOptions}
        onChange={(e: any) => setSelectedParamType(e)}
        value={selectedTypeVal || undefined}
        placeholder="Wybierz typ"
      />
      <S.GapContainer>
        Wymagane?
        <Checkbox
          checked={isRequiredParameter}
          onChange={() => setIsRequiredParameter(!isRequiredParameter)}
        />
      </S.GapContainer>
      {selectedParamType === 'select' && (
        <S.GapContainer>
          <Input
            placeholder="Np. m lub 48 "
            onChange={(e) => setNewParamOptions(e.target.value)}
            addonBefore="Wpisz wartości po przecinku:"
          />
        </S.GapContainer>
      )}
      {showSelectDefault && (
        <S.GapContainer>
          <Input
            placeholder="Jedna z opcji"
            value={parameterDefaultValue}
            onChange={(e: any) => setParameterDefaultValue(e.target.value)}
            addonBefore="Wartość początkowa:"
          />
        </S.GapContainer>
      )}
      {checkboxDefault && (
        <S.GapContainer>
          Początkowo zaznaczony?
          <Checkbox
            checked={isCheckedByDefault}
            onChange={() => setIsCheckedByDefault(!isCheckedByDefault)}
          />
        </S.GapContainer>
      )}
      <div>
        <Button onClick={() => handleSaveParameter()} disabled={isButtonDisabled}>
          Dodaj
        </Button>
      </div>
    </S.DynamicParameterWrapper>
  );
};

export default NewParameterForm;

import * as S from './DeleteAccountComponent.styles';

const ModalComponent = ({ cancel, action }: any) => {
  return (
    <S.Wrapper>
      <S.ButtonsWrapper>
        <S.ButtonContainer>
          <S.CancelButton onClick={cancel}>Anuluj</S.CancelButton>
        </S.ButtonContainer>
        <S.ButtonContainer>
          <S.AcceptButton onClick={action}>Tak</S.AcceptButton>
        </S.ButtonContainer>
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export default ModalComponent;

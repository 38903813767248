import styled from 'styled-components';
import { AiOutlineStar, AiOutlineEye } from 'react-icons/ai';
import CheckIco from '../../../assets/check.svg';
import EditIco from '../../../assets/edit.svg';
import MobileIco from '../../../assets/mobile.svg';
import MailIco from '../../../assets/mailGreyIcon.svg';
import { Link } from 'react-router-dom';

export const Wrapper = styled.article<{ isFirst: boolean }>`
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 14px 8px;
  ${({ isFirst }) => isFirst && `border-top: none;`}
  @media (min-width: 400px) {
    padding: 14px 22px;
  }
`;

export const MobileBody = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataWrapper = styled.div`
  margin-bottom: 8px;
`;

export const Image = styled.img`
  width: 105px;
  height: 58px;
  margin-right: 23px;
  object-fit: cover;
  margin-bottom: 17px;
`;

export const Title = styled(Link)`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: #373f41;
  flex-grow: 2;
  margin-bottom: 10px;
  transition: 250ms;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const DateWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 10px;
`;

export const PriceWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: 14px;
`;

export const DisplaysWrapper = styled.div`
  flex-grow: 1;
`;

export const DataRow = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #373f41;
`;

export const GrayDataRow = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const Label = styled.label``;

export const Value = styled.span`
  word-break: break-word;
  white-space: pre-wrap;
  @media (min-width: 400px) {
    word-break: normal;
  }
`;

export const MobileIcon = styled.img.attrs({ src: MobileIco, alt: 'mobile phone icon' })`
  width: 16px;
  min-width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: left;
  margin-right: 5px;
  margin-left: -1px;
  color: #828282;
`;

export const EnvelopeIcon = styled.img.attrs({ src: MailIco, alt: 'mail icon' })`
  width: 14px;
  height: 14px;
  min-width: 14px;
  object-fit: contain;
  margin-right: 4px;
  margin-top: 1px;
  color: #828282;
`;

export const WatchersWrapper = styled.div`
  width: 100%;
  min-height: 26px;
  box-sizing: border-box;
  background-color: #fafafa;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 8px;
  @media (min-width: 400px) {
    padding: 0 20px;
  }
`;

export const StarIcon = styled(AiOutlineStar)`
  width: 14px;
  height: 14px;
  color: #373f41;
  margin-right: 8px;
`;

export const WatchersCounter = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const Section = styled.div`
  display: flex;
  padding-right: 8px;
`;

export const MarginSection = styled(Section)`
  margin: 0 0 8px;
  @media (min-width: 400px) {
    margin: 0;
  }
`;

export const PlainButton = styled.button`
  display: flex;
  background: none;
  border: none;
  :focus {
    outline: none;
  }
`;

export const TerminateButton = styled(PlainButton)`
  margin: 0 0 0 10px;
`;

export const ButtonText = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  text-align: right;
  letter-spacing: 0.1px;
  color: #2b65ea;
`;

export const DeleteButtonText = styled(ButtonText)`
  color: #b70000;
`;
export const EditIcon = styled.img.attrs({ src: EditIco, alt: 'edit icon' })`
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin: auto 4px auto 0;
  color: #2b65ea;
`;

export const FinishIcon = styled.img.attrs({ src: CheckIco, alt: 'check icon' })`
  width: 11px;
  min-width: 11px;
  height: 11px;
  margin: auto 2px auto 0;
  color: #2b65ea;
`;

export const EyeIcon = styled(AiOutlineEye)`
  width: 15px;
  min-width: 15px;
  height: 15px;
  color: #373f41;
  margin-right: 8px;
`;

export const DisplayedContainer = styled.div`
  display: flex;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

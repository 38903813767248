import styled from 'styled-components';

export const ExternalWrapper = styled.div`
  width: 100%;
  padding: 0 0 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const Wrapper = styled.div`
  max-width: 1116px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
`;

export const MainWrapper = styled.div`
  max-width: 1114px;
  padding: 0 18px;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 980px) {
    margin: 0 auto;
  }
`;

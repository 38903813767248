import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { resetData } from '../../../redux/auth/selectors';
import confirmation from '../../../assets/Login/confirmation.svg';
import { USER_STATUS } from '../../../redux/auth/types';
import leftImage from '../../../assets/Login/leftImg.svg';
import rightImage from '../../../assets/Login/rightImg.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './Confirmation.styles';

const Confirmation = () => {
  const { email, password, status } = useSelector(resetData);
  const isVerified = status === USER_STATUS.ACTIVE;
  const history = useHistory();
  const params: any = useParams();
  const { verificationCode } = params;

  useEffect(() => {
    if (isVerified && !verificationCode && !password) {
      history.push('/');
    }
  }, [isVerified, verificationCode]);

  const position = email ? email.search('@') : 0;
  const domain = email && email.slice(position + 1);
  const isDesktop = useMediaQuery('(min-width: 1250px)');
  const isTablet = useMediaQuery('(min-width: 768px)');

  return (
    <S.ExternalWrapper>
      {isTablet && (
        <S.LeftImgContainer>
          <S.AbsoluteImg src={leftImage} />
        </S.LeftImgContainer>
      )}
      <S.Wrapper>
        <S.Header>Już prawie gotowe</S.Header>
        <S.BodyWrapper>
          <S.Information>
            Aby dokończyć rejestrację, przejdź do poczty i&nbsp;potwierdź swój email
            {/* <b> {email}</b>
            <br /> */}
            {/* TODO: remove when mailchimp
            {password && <b>Hasło: {password}</b>} */}
          </S.Information>
          {/* <S.ButtonLink>
            <S.MailIcon /> Przejdź do poczty email
          </S.ButtonLink> */}
        </S.BodyWrapper>
        <S.BottomMessage>
          Wróć na <S.BlueLink to={'/'}>stronę główną</S.BlueLink>
          {/* lub przejdź do
          <S.BlueLink to={'/user-dashboard/settings'}> ustawień konta</S.BlueLink> */}
        </S.BottomMessage>
      </S.Wrapper>
      {isDesktop && (
        <S.RightImagesContainer>
          <S.BackgroundImage alt={'background-image'} src={confirmation} />
          <S.RightImage src={rightImage} />
        </S.RightImagesContainer>
      )}
    </S.ExternalWrapper>
  );
};

export default Confirmation;

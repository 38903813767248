import { useEffect, useState } from 'react';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { priceValidator } from '../../../utils/PriceValidator';
import * as S from './PriceInput.styles';

interface PriceInputProps {
  isFree?: boolean;
  value: any;
  setPrice: (e: any) => void;
  setGiveaway: (e: any) => void;
}

const PriceInput = ({ setPrice, setGiveaway, value, isFree = false }: PriceInputProps) => {
  const [isPriceRequired, setIsPriceRequired] = useState(true);

  const handleSetFree = () => {
    setGiveaway(!isFree);
    setPrice(0);
  };

  useEffect(() => {
    if (isFree) {
      setIsPriceRequired(false);
    } else {
      setIsPriceRequired(true);
    }
  }, [isFree]);

  return (
    <S.Wrapper>
      <S.InputWrapper>
        <S.PriceInput
          disabled={isFree}
          required={isPriceRequired}
          type="text"
          value={value}
          onChange={(e) => priceValidator(e, setPrice)}
          placeholder="Podaj cenę"
          maxLength={9}
        />
        <S.Currency>zł</S.Currency>
      </S.InputWrapper>
      <S.ButtonWrapper>
        <CustomCheckbox required={!isPriceRequired} value={isFree} setValue={handleSetFree} />
        <S.Label>Za darmo</S.Label>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default PriceInput;

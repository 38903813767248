import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  background-color: rgb(74, 82, 83, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

export const InnerWrapper = styled.div`
  position: relative;
  max-width: 800px;
  border-radius: 16px;
  width: 100%;
  max-height: 775px;
  margin: 18px;
  background-color: ${themes.default.white};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 16px;
  @media (min-width: 634px) {
    padding: 48px 56px;
  }
  overflow: auto;
`;

export const Header = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: -0.4px;
  color: ${themes.default.black};
  width: 100%;
  text-align: center;
  margin: 0 0 8px;
  z-index: 1;
`;

export const Description = styled.p`
  font-family: Poppins;
  font-size: 16px;
  line-height: 25px;
  color: ${themes.default.black};
  width: 100%;
  text-align: center;
  margin: 0 0 32px;
  z-index: 1;
`;

export const Form = styled.form`
  margin: 0 auto 32px;
  z-index: 1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 8px;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-size: 16px;
  color: ${themes.default.black};
`;

export const Background = styled.img`
  position: absolute;
  right: 24px;
  bottom: 24px;
  opacity: 0.5;
  z-index: 0;
`;

import { instance } from "./common-api";

export const getThreads = async () => {
  const res = await instance.get('/threads');
  return res.data;
};

export const getThreadById = async (id: string) => {
  const res = await instance.get(`/thread/${id}`);
  return res.data;
};

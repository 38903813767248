import { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './InputWithCounter.styles';

interface InputWithCounterProps {
  type: string;
  displayCounter?: boolean;
  handleInputChange: (e: any) => void;
  value: any;
  required?: boolean;
}

const InputWithCounter = ({
  type,
  displayCounter = true,
  handleInputChange,
  value,
  required = false,
}: InputWithCounterProps) => {
  const isDesktop = useMediaQuery('(min-width: 670px)');

  const getLimit = () => {
    if (type === 'title') {
      return 50;
    } else if (type === 'description') {
      return 5000;
    } else {
      return 0;
    }
  };

  const InputSetter = (e: any) => {
    const value = e.target.value;
    const limit = getLimit();
    if (value.length <= limit) {
      handleInputChange(value);
    }
  };

  const [isQuestionMarkInfoShown, setIsQuestionMarkInfoShown] = useState(false);
  const [isMobileTitleInfoShown] = useState(false);

  return (
    <S.Wrapper>
      {type === 'title' && (
        <S.LabelWrapper>
          <S.Label>
            Tytuł <S.Red>*</S.Red>
          </S.Label>
          <S.QuestionMarkWrapper>
            {isQuestionMarkInfoShown && isDesktop && (
              <S.QuestionMarkInfoContainer>
                <S.QuestionMarkText>
                  Dodaj tytuł ogłoszenia, opisz sprzedawany przedmiot słowami kluczowymi , tak jak
                  gdybyś Ty szukał takiego przedmiotu w serwisie. Pamiętaj, że dobrze dopasowany
                  tytuł do ogłoszenia zwiększa szansę szybszego znalezienia przedmiotu przez
                  potencjalnego nabywcę.
                </S.QuestionMarkText>
              </S.QuestionMarkInfoContainer>
            )}
            {isDesktop && (
              <S.QuestionMarkIcon
                onMouseEnter={() => setIsQuestionMarkInfoShown(true)}
                onMouseLeave={() => setIsQuestionMarkInfoShown(false)}
              />
            )}
          </S.QuestionMarkWrapper>
        </S.LabelWrapper>
      )}
      {type === 'description' && (
        <S.LabelWrapper>
          <S.Label>
            Opis <S.Red>*</S.Red>
          </S.Label>
        </S.LabelWrapper>
      )}
      {type === 'title' && (
        <>
          <S.StyledInput
            required={required}
            type="text"
            value={value}
            onChange={InputSetter}
            placeholder="Wpisz tytuł ogłoszenia"
            // onFocus={() => setIsMobileTitleInfoShown(true)}
            // onBlur={() => setIsMobileTitleInfoShown(false)}
          />
        </>
      )}
      {type === 'description' && (
        <S.StyledTextarea
          required={required}
          value={value}
          onChange={InputSetter}
          placeholder="Opisz przedmiot lub zajęcia"
        />
      )}
      <S.InfoAndCounterContainer>
        {displayCounter && <S.Counter>Pozostało {getLimit() - value.length} znaków</S.Counter>}
        <S.MobileInfoContainer>
          {/* {isMobileTitleInfoShown && !isDesktop && (
            <S.MobileInfoSpan>
              Dobrze dopasowany tytuł zwiększa szansę znalezienia przedmiotu przez nabywcę.
            </S.MobileInfoSpan>
          )} */}
          {!isMobileTitleInfoShown && !isDesktop && <S.EmptyDiv />}
        </S.MobileInfoContainer>
      </S.InfoAndCounterContainer>
    </S.Wrapper>
  );
};

export default InputWithCounter;

export const getTreeDataPath = (treeData: any[], value: any) => {
  const valueMap: any = {};

  const loops = (list: any[], parent?: any) => {
    return (list || []).map(({ categories, id }) => {
      const node: any = (valueMap[id] = {
        parent,
        id,
      });
      node.children = loops(categories, node);
      return node;
    });
  };

  loops(treeData);

  const getPath = (id: any) => {
    const path = [];
    let current = valueMap[id];
    while (current) {
      path.unshift(current.id);
      current = current.parent;
    }
    return path;
  };

  const path = getPath(value);

  return path;
};

import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.button`
  display: flex;
  border: none;
  padding: 0;
  background-color: transparent;
  :focus {
    outline: none;
  }
  width: 300px;
  min-height: 30px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const Arrow = styled(IoIosArrowForward)`
  min-width: 16px;
  height: 16px;
  margin-right: 14px;
  color: ${themes.default.main};
`;

export const Label = styled.p<{ isSelected: boolean }>`
  color: ${themes.default.fontColorBlack};
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0.1px;
  white-space: nowrap;
  margin: 0;
  font-weight: ${({ isSelected }) => (isSelected ? 600 : 500)};
  transition: 300ms;
  border-bottom: 1px solid ${({ isSelected }) => (isSelected ? themes.default.main : 'transparent')};
  padding: 4px;
  &:hover {
    color: rgba(75, 65, 196, 1);
  }
`;

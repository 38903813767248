import { memo, useState, useEffect, FormEvent } from 'react';
import SubmitButton from './SubmitButton/SubmitButton';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordError, useLoading } from '../../redux/auth/selectors';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import leftImage from '../../assets/Login/leftImg.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useHistory, useParams } from 'react-router-dom';
import { changeResetedPassword, setChangePasswordErrorMessage } from '../../redux/auth/actions';
import { Input } from 'antd';
import * as S from './RegisterPage.styles';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  const { verificationCode } = params;

  useEffect(() => {
    if (!verificationCode) {
      history.push('/');
    }
  }, [verificationCode]);

  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const isTablet = useMediaQuery('(min-width: 768px)');
  const isLoading = useSelector(useLoading);

  const handlePasswordChange = (event: FormEvent) => {
    event.preventDefault();
    dispatch(changeResetedPassword({ verificationCode, password, repeatedPassword }));
  };

  const errorMessage = useSelector(changePasswordError);

  useEffect(() => {
    if (errorMessage) {
      dispatch(setChangePasswordErrorMessage(''));
    }
  }, [password]);

  return (
    <S.ExternalWrapper>
      {isTablet && (
        <S.LeftImgContainer>
          <S.AbsoluteImg src={leftImage} />
        </S.LeftImgContainer>
      )}
      <S.Wrapper onSubmit={(e: any) => handlePasswordChange(e)}>
        <S.Header>Resetowanie hasła</S.Header>
        <Input.Password
          min={8}
          required
          type="password"
          placeholder="hasło"
          value={password}
          iconRender={(isVisible) =>
            isVisible ? (
              <EyeTwoTone rev={undefined} twoToneColor="rgba(75,65,196,1)" />
            ) : (
              <EyeInvisibleOutlined rev={undefined} />
            )
          }
          onChange={(e: any) => setPassword(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <Input.Password
          min={8}
          required
          type="password"
          placeholder="powtórz hasło"
          value={repeatedPassword}
          iconRender={(isVisible) =>
            isVisible ? (
              <EyeTwoTone rev={undefined} twoToneColor="rgba(75,65,196,1)" />
            ) : (
              <EyeInvisibleOutlined rev={undefined} />
            )
          }
          onChange={(e: any) => setRepeatedPassword(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        <SubmitButton type="submit" text={'Zresetuj hasło'} isLoading={isLoading} />
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(ResetPasswordPage);

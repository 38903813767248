import * as S from './Option.styles';

export default function Option({
  isActive,
  text,
  count = 0,
  onClick,
  icon,
}: {
  isActive: boolean;
  text: string;
  count?: number;
  onClick?: () => void;
  icon?: JSX.Element;
}) {
  return (
    <S.Wrapper onClick={onClick} isActive={isActive}>
      {/* {isMobileIcon ? icon : <S.Text isActive={isActive}>{text}</S.Text>} */}
      <S.TextWrapper>
        <S.Text isActive={isActive}>{text}</S.Text>
        <S.Counter isPresent={count > 0} isActive={isActive}>{`(${count})`}</S.Counter>
      </S.TextWrapper>
    </S.Wrapper>
  );
}

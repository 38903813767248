import { useParams } from 'react-router';
import * as S from '../FacebookShare/FacebookShare.styles';

export default () => {
  const { id }: { id?: string } = useParams();
  const currentUrl = window.location.href;

  const openWindow = () => {
    if (window !== null && id) {
      window.open(
        `https://api.whatsapp.com/send/?phone&text=${encodeURIComponent(`${currentUrl}`)}`,
        '',
        'width=600,height=400'
      );
    }
  };

  return (
    <S.WhatsAppButton onClick={openWindow}>
      <S.Whatsapp />
    </S.WhatsAppButton>
  );
};

import { memo, useState, useEffect, FormEvent } from 'react';
import FederatedButtons from './FederatedButtons/FederatedButtons';
import SubmitButton from './SubmitButton/SubmitButton';
import Header from './Header/Header';
import PageWrapper from './PageWrapper/PageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { loginLocally, setSignInErrorMessage } from '../../redux/auth/actions';
import { signInError, useLoading } from '../../redux/auth/selectors';
import leftImage from '../../assets/Login/leftImg.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import * as S from './RegisterPage.styles';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    dispatch(loginLocally({ email, password }));
  };

  const errorMessage = useSelector(signInError);

  useEffect(() => {
    if (errorMessage) {
      dispatch(setSignInErrorMessage(''));
    }
  }, [password, email]);

  const isTablet = useMediaQuery('(min-width: 768px)');
  const isLoading = useSelector(useLoading);

  return (
    <PageWrapper>
      {isTablet && (
        <S.LeftImgContainer>
          <S.AbsoluteImg src={leftImage} />
        </S.LeftImgContainer>
      )}
      <S.Wrapper onSubmit={handleLogin}>
        <S.OptionsWrapper>
          <Header link={'/login'} text={'Zaloguj się'} isSelected />
          <Header link={'/register'} text={'Rejestracja'} />
        </S.OptionsWrapper>
        <FederatedButtons />
        <Input
          required
          type="email"
          placeholder="login"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <Input.Password
          min={8}
          required
          type="password"
          placeholder="hasło"
          value={password}
          iconRender={(isVisible) =>
            isVisible ? (
              <EyeTwoTone rev={undefined} twoToneColor="rgba(75,65,196,1)" />
            ) : (
              <EyeInvisibleOutlined rev={undefined} />
            )
          }
          onChange={(e: any) => setPassword(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        <SubmitButton type="submit" text={'Zaloguj się'} isLoading={isLoading} />
        <S.Disclaimer>
          Nie masz swojego konta? <S.Blue to={'/register'}>Zarejestruj się</S.Blue>
        </S.Disclaimer>
        {/* TODO: Designs required */}
        <S.Disclaimer2>
          Zapomniałeś hasła? <S.Blue to={'/reset-password'}>Zresetuj je</S.Blue>
        </S.Disclaimer2>
      </S.Wrapper>
    </PageWrapper>
  );
};

export default memo(LoginPage);

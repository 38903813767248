import { useDispatch, useSelector } from 'react-redux';
import { editUserUtils } from '../../../redux/auth/actions';
import { userUtils } from '../../../redux/auth/selectors';
import imgLeft1 from '../../../assets/SearchSection/left1.svg';
import imgLeft2 from '../../../assets/SearchSection/left2.svg';
import * as S from './Disclaimer.styles';

export default function Disclaimer() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(editUserUtils({ hasSeenDashboardWelcome: true }));
  const { hasSeenDashboardWelcome } = useSelector(userUtils);

  if (hasSeenDashboardWelcome) {
    return null;
  }

  return (
    <S.Wrapper>
      <>
        <S.LeftOneImageContainer>
          <S.AbsoluteImage src={imgLeft1} />
        </S.LeftOneImageContainer>
        <S.LeftTwoImageContainer>
          <S.AbsoluteImage src={imgLeft2} />
        </S.LeftTwoImageContainer>
      </>
      <S.TextWrapper>
        <S.Header>Witaj w swoim panelu</S.Header>
        {/* {isDesktop && <S.CustomText>Tutaj mozna umieścić tekst dla uzytkownika</S.CustomText>} */}
      </S.TextWrapper>
      <S.RightWrapper>
        <S.Art />
      </S.RightWrapper>
      <S.PlainButton onClick={handleClose}>
        <S.CloseIcon />
      </S.PlainButton>
    </S.Wrapper>
  );
}

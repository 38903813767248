import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  position: relative;
  max-width: 920px;
  background: ${themes.default.white};
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 960px) {
    margin: 0 20px;
  }
`;

export const BodyWrapper = styled.form`
  padding: 56px 95px;
  @media (max-width: 800px) {
    padding: 41px 15px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const StarsContainer2 = styled.div`
  position: absolute;
  left: -114px;
  top: 234px;
  z-index: -1;
`;

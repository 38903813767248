import styled from 'styled-components';
import { IoMdPerson } from 'react-icons/io';
import { themes } from '../../assets/CSS/colorThemes';
import U from '../../assets/CSS/commonStyles';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

export const LinkedWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

export const Person = styled(IoMdPerson)`
  color: rgba(17, 36, 77, 1);
  width: 30px;
  height: 30px;
  /* @media (max-width: 800px) {
    margin-right: 24px;
  } */
`;

export const PersonLabel = styled.label`
  margin: 0 30px 0 8px;
  font-family: Poppins;
  ${U.standardFont14}
  color: rgba(17, 36, 77, 1);
  cursor: inherit;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const AvatarWrapper = styled.div`
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  position: relative;
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  align-self: center;
  object-fit: cover;
  cursor: pointer;
  z-index: 1;
`;

export const MockAvatar = styled(IoMdPerson)`
  width: 36px;
  height: 36px;
  color: rgba(17, 36, 77, 1);
  align-self: center;
  cursor: pointer;
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  border: 3px solid ${themes.default.white};
  border-radius: 100%;
  background-color: ${themes.default.positive};
  position: absolute;
  margin-left: 4px;
`;

export const UserDataWrapper = styled.div`
  margin-right: 14px;
  cursor: pointer;
  @media (max-width: 880px) {
    display: none;
  }
`;

export const UserName = styled.div`
  font-family: Poppins;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #373f41;
`;

export const NameRow = styled.div`
  ${U.standardFlex}
`;

export const UserMail = styled.div`
  font-family: Poppins;
  ${U.standardFont12}
  color: #545454;
`;

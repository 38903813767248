import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import App, { history } from './App';
import { checkLoggedIn } from './api/common-api';
import './index.css';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
require('dotenv').config();

const rootEl = document.getElementById('root');

const renderApp = (preloadedState: any) => {
  const store = configureStore(preloadedState);
  render(
    //@ts-ignore
    <Provider store={store}>
      <React.Suspense fallback={<div />}>
        <Router history={history}>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Platforma sprzedażowa" />
            <meta property="og:site_name" content={'Malutcy.pl'} />
          </Helmet>
          <App />
        </Router>
      </React.Suspense>
    </Provider>,
    rootEl
  );
};

(async () => renderApp(await checkLoggedIn()))();

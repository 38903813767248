import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import { themes } from '../../../assets/CSS/colorThemes';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div<{ backgroundColor: string; isBazaar: boolean }>`
  background-color: ${(props) => props.backgroundColor};
  /* padding: 28px 22px; */
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 36px 20px;
  @media (min-width: 800px) {
    /* padding: 66px 0 14px; */
    padding: 0px 0 14px;
  }
`;

export const InnerWrapper = styled.div<{ isBazaar: any }>`
  max-width: ${({ isBazaar }) => (isBazaar ? '1128px' : '1184px')};
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: ${({ isBazaar }) => (isBazaar ? '#E9F5F5' : 'white')};
  border-radius: 16px;
  @media (min-width: 768px) {
    padding: ${({ isBazaar }) => (isBazaar ? '32px 28px 0' : '32px 10px 0')};
  }
`;

export const HeaderWrapper = styled.div<{ isBazaar: any }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* padding: 0 18px 32px; */
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: ${({ isBazaar }) => (isBazaar ? '0 0 32px' : '0 18px 32px')};
  }
`;

export const Header = styled.h4`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: -0.4px;
  width: 100%;
  font-size: 26px;
  /* max-width: 280px; */
  color: ${themes.default.reddishGrey};
  @media (min-width: 768px) {
    font-size: 26px;
    max-width: 500px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const StyledLinkMobile = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  @media (min-width: 800px) {
    display: none;
  }
`;

export const LinkText = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${themes.default.main};
`;

export const Arrow = styled(IoIosArrowForward)`
  color: ${themes.default.main};
  width: 18px;
  height: 18px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 24px;
  gap: 30px;
  padding: 4px 4px 16px;
`;

export const SelectCategoriesWrapper = styled.div`
  display: flex;
  gap: 32px;
  border-bottom: 3px solid rgba(229, 229, 229, 1);
  /* width: aut1o; */
  padding-bottom: 8px;
  margin-bottom: 24px;
  transition: 100ms;
`;

export const Category = styled.span<{ isSelected: boolean }>`
  font-family: Poppins;
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  font-size: 16px;
  color: rgba(17, 36, 77, 1);
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.7')};
  cursor: pointer;
  position: relative;
  transition: 100ms;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -11px;
    background: ${({ isSelected }) =>
      isSelected ? 'rgba(75, 65, 196, 1)' : 'rgba(229, 229, 229, 1)'};
    height: 3px;
    left: 0;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
`;

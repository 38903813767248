import { useHistory } from 'react-router-dom';
import FAQContact from '../FAQContact/FAQContact';
import FAQPageWrapper from '../FAQPageWrapper/FAQPageWrapper';
import FAQSearchPage from '../FAQSearchPage/FAQSearchPage';
import SegmentsWrapper from '../SegmentsWrapper/SegmentsWrapper';

export default function FAQPage() {
  const history = useHistory();
  const {
    location: { search },
  } = history;

  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('query');

  if (query) {
    return <FAQSearchPage />;
  }

  return (
    <FAQPageWrapper>
      <div>
        <SegmentsWrapper />
        <FAQContact />
      </div>
    </FAQPageWrapper>
  );
}

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { BsPlusLg } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Input } from 'antd';

const titleProps = `
margin: 0;
padding: 0;
font-family: Poppins;
font-weight: 600;
font-size: 26px;
line-height: 154%;
letter-spacing: -0.4px;
color: ${themes.default.graphemeGrey};
;`;

export const ExternalWrapper = styled.div`
  position: relative;
  padding: 16px 0 58px;
  width: 100%;
  background-color: #e9f5f5;
`;

export const Title = styled.h1`
  ${titleProps}
  margin-bottom: 49px;
`;

export const SecondaryTitle = styled.h2`
  ${titleProps}
  margin-bottom: 40px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 25px 0 0 auto;
  width: 201px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const SeparatorWrapper = styled.div`
  margin-top: 7px;
  padding-bottom: 36px;
  border-bottom: 1px solid ${themes.default.greyBackgroundColor};
  margin-bottom: 35px;
  @media (max-width: 800px) {
    padding-bottom: 34px;
    margin-bottom: 41px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ant-select-selector {
    height: 50px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }
  .ant-select-selection-item {
    line-height: 50px !important;
  }
`;

export const RowLabel = styled.label`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #373f41;
  margin-bottom: 5px;
  display: flex;
`;

export const RowInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  font-family: Poppins;
  font-size: 12px;
  background: ${themes.default.white};
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  padding: 15px 21px;
  border-radius: 4px;
  color: ${themes.default.fontColorBlack};
  :focus {
    outline: none;
  }
  ${(props) => props.readOnly && `background-color: ${themes.default.bdGrey};`}
  ::placeholder {
    color: ${themes.default.bdGrey};
  }
`;

export const SecondaryTitleWithButton = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const AddButton = styled(BsPlusLg)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-bottom: 40px;
  color: rgba(75, 65, 196, 1);
`;

export const RowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
`;

export const DeleteButton = styled.button`
  border: 1px solid rgba(75, 65, 196, 1);
  padding: 2px 6px;
  background-color: white;
  color: rgba(75, 65, 196, 1);
  cursor: pointer;
  font-family: Poppins;
  max-height: 50px;
  border-radius: 8px;
  height: 100%;
  margin-top: 42px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
  @media (min-width: 768px) {
    margin-top: auto;
    min-height: 50px;
  }
`;

export const ParamsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 35px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DynamicOptionsWrapper = styled.div`
  display: flex;
  width: 168px;
  align-items: center;
`;

export const DynamicOptionlabel = styled.span<{ isSelect: boolean }>`
  font-family: Poppins;
  font-size: 15px;
  color: #373f41;
  font-weight: 600;
  overflow-wrap: anywhere;
  margin-bottom: ${({ isSelect }) => (isSelect ? '12px' : '0')};
`;

export const DynamicInputContainer = styled.div`
  display: flex;
  margin-right: 14px;
  flex: 1;
`;

export const DynamicTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
`;

export const DynamicTextContainer = styled.div`
  margin-bottom: 4px;
`;

export const StyledCheckboxInput = styled.input`
  margin-right: 16px;
  min-width: 28px;
  width: 28px;
  height: 28px;
  .checked {
    background-color: red !important;
  }
`;

export const CreatorLabel = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
  padding: 0;
  margin-bottom: 6px;
`;

export const Red = styled.span`
  color: red;
`;

export const CascaderContainer = styled.div`
  margin-bottom: 27px;
  .ant-select-selector {
    display: flex !important;
    height: 50px !important;
    padding: 15px 21px !important;
    align-items: center !important;
    .ant-select-selection-search {
      display: flex !important;
      align-items: center !important;
    }
  }
`;

export const BottomContainer = styled.div``;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-bottom: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #373f41;
`;

export const PriceInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StarsContainer1 = styled.div`
  position: absolute;
  right: 0;
  top: 80px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ClearIcon = styled(AiFillCloseCircle)`
  width: 30px;
  height: 30px;
`;

export const AntInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 274px;
`;

export const AntStyledLabel = styled.label`
  color: #11244d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledInput = styled(Input)`
  padding: 14px 20px;
`;

export const AntSelectContainer = styled(AntInputContainer)`
  max-width: none;
  width: 100%;
  margin-bottom: 26px;
  font-family: Poppins;
  .ant-select-selector {
    display: flex !important;
    height: 50px !important;
    padding: 15px 21px !important;
    align-items: center !important;
    .ant-select-selection-search {
      display: flex !important;
      align-items: center !important;
    }
  }
`;

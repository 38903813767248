import advices from './advices.svg';
import hints from './hints.svg';
import changes from './changes.svg';
import payments from './payments.svg';
import personalData from './personalData.svg';
import security from './security.svg';
import heart from './heart.svg';

const styles = {
  advices,
  hints,
  changes,
  payments,
  personalData,
  security,
  heart,
};

export default styles;

import { useState } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import { Input } from 'antd';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../redux/auth/actions';
import { useLoading } from '../../../redux/auth/selectors';
import * as S from '../RegisterPage.styles';

const PasswordReminderPage = () => {
  const [login, setLogin] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => login && dispatch(resetPassword(login));
  const isLoading = useSelector(useLoading);
  return (
    <PageWrapper>
      <S.Wrapper>
        <S.Header>Zapomniałeś hasła?</S.Header>
        <S.Description>
          Podaj swój adres email na który wyślemy kod weryfikacyjny. Po wpisaniu kodu będzie możliwe
          ustawienie nowego hasła.
        </S.Description>
        <Input
          required
          type="email"
          placeholder="login"
          value={login}
          onChange={(e: any) => setLogin(e.target.value)}
          style={{ height: 50, marginBottom: 20, width: '100%' }}
        />
        <SubmitButton onClick={handleSubmit} text="Zresetuj hasło" isLoading={isLoading} />
      </S.Wrapper>
    </PageWrapper>
  );
};

export default PasswordReminderPage;

import { MdClose } from 'react-icons/md';
import { RiExchangeLine } from 'react-icons/ri';
import styled from 'styled-components';
import Assets from '../../../assets/assets-exporter';
import { themes } from '../../../assets/CSS/colorThemes';
import { FiTrash2 } from 'react-icons/fi';

export const Wrapper = styled.div<{ isEdit: boolean }>`
  border-bottom: 1px solid ${themes.default.greyBackgroundColor};
  box-sizing: border-box;
  padding: ${({ isEdit }) => (isEdit ? '0 0 16px' : '0 15px 104px')};
  margin-bottom: ${({ isEdit }) => (isEdit ? '0' : '34px')};
  max-width: 760px;
`;

export const Title = styled.h3`
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 154%;
  letter-spacing: -0.4px;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 22px;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 672px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const MainPhoto = styled.button`
  position: relative;
  overflow: hidden;
  height: 182px;
  width: 326px;
  background-color: transparent;
  cursor: pointer;
  border: 1px dashed ${themes.default.greyBackgroundColor};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 9px 18px 0;
  @media (max-width: 891px) {
    margin: 0 auto 18px;
    width: calc(100vw - 30px);
    height: calc((100vw - 30px) * 0.55);
  }
`;

export const MiniPhoto = styled.button`
  height: 48px;
  min-width: 82px;
  cursor: pointer;
  background-color: transparent;
  border: 1px dashed ${themes.default.greyBackgroundColor};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px 18px;
`;

export const Thumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

export const MiniPhotoWrapper = styled.div`
  height: 48px;
  min-width: 82px;
  width: 82px;
  /* padding: 2px; */
  background-color: transparent;
  border: 1px dashed ${themes.default.greyBackgroundColor};
  box-sizing: border-box;
  position: relative;
  margin: 0 2px 18px;
  overflow: hidden;
`;

export const RemoveWrapper = styled.div<{ hover: boolean; isExistingPhoto?: boolean }>`
  height: 100%;
  width: ${({ isExistingPhoto }) => (isExistingPhoto ? '100%' : '49%')};
  z-index: 1;
  opacity: ${({ hover }) => (hover ? 0.8 : 0)};
  transition: 250ms;
  background-color: ${themes.default.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ChangeWrapper = styled(RemoveWrapper)`
  left: 0;
  width: 49%;
  /* background-color: transparent; */
`;

export const RemoveIcon = styled(MdClose)`
  width: 28px;
  height: 28px;
  color: ${themes.default.negative};
`;

export const ChangeIcon = styled(RiExchangeLine)`
  width: 28px;
  height: 28px;
  color: ${themes.default.positive};
`;

export const MockPhoto = styled.div`
  flex-grow: 1;
  height: 48px;
  @media (min-width: 892px) {
    display: none;
  }
`;

export const MiniaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 201px;
  width: 336px;
  @media (max-width: 891px) {
    width: 100%;
    height: auto;
  }
`;

export const Instructions = styled.div<{ isHighlighted: boolean }>`
  font-family: Nunito;
  font-size: 12px;
  line-height: 142%;
  color: ${themes.default.bdGrey};
  transition-duration: 200ms;
  transition-delay: 100ms;
  transition-property: color;
  ${(props) =>
    props.isHighlighted &&
    `
    color: ${themes.default.negative};
  `}
`;

export const CameraIcon = styled.img.attrs({ src: Assets.cameraCreate, alt: 'add a photo' })`
  height: 22px;
  width: 18px;
`;

export const Label = styled.div`
  margin-left: 10px;
  font-family: Nunito;
  font-size: 11px;
  line-height: 464%;
  color: ${themes.default.bdGrey};
`;

export const SimpleButton = styled.div`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  :focus {
    outline: none;
  }
`;

export const Photo = styled.img`
  width: 100%;
  height: 100%;
`;

export const Input = styled.input`
  opacity: 0;
`;

export const TrashContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(74, 82, 83, 0.7);
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TrashIcon = styled(FiTrash2)`
  width: 24px;
  height: 24px;
  color: white;
`;

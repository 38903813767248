import { useEffect, useState } from 'react';
import CustomCheckbox from '../../Partials/CustomCheckbox/CustomCheckbox';
import ImageOne from '../../../assets/NewSection/image-one.svg';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { USER_STATUS } from '../../../redux/auth/types';
import * as S from './NewUserModal.styles';
import * as api from '../../../api/common-api';

const NewUserModal = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserStatus = async () => {
    const res = await api.getUserStatus();
    if (res === USER_STATUS.NEW_USER) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    getUserStatus();
  }, [location]);

  const selectAccountType = async (isCompany: boolean) => {
    setIsLoading(true);
    await api.selectAccountType(isCompany).catch(() => {
      toast.warn('Błąd przy wyborze typu konta');
    });
    toast.success('Wybrany typ konta został zapisany');
    setIsOpen(false);
    setIsLoading(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.Header>Witaj wśród szczęśliwych użytkowników Malutcy.pl</S.Header>
        <S.Description>
          Nim jednak rzucisz się w wir zakupowego szaleństwa wybierz typ konta, z którego chcesz
          korzystać i uzupełnij woje dane w ustawnieniach.
        </S.Description>
        <S.Form>
          {!isLoading && (
            <>
              <S.Row>
                <CustomCheckbox handleSelect={() => selectAccountType(false)} />
                <S.Label>Konto prywante</S.Label>
              </S.Row>
              <S.Row>
                <CustomCheckbox handleSelect={() => selectAccountType(true)} />
                <S.Label>Konto firmowe</S.Label>
              </S.Row>
            </>
          )}
        </S.Form>
        <S.Background src={ImageOne} alt={'background'} />
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default NewUserModal;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { isUserLoggedIn } from '../redux/auth/selectors';

export interface IProtectedProps extends RouteComponentProps {
  path?: string;
  component?: any;
  exact?: boolean;
}

function ProtectedRoute({ path, component: Component, exact = true }: IProtectedProps) {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const history = useHistory();

  const {
    location: { pathname },
  } = history;

  useEffect(() => {
    if (!isLoggedIn && pathname === path) {
      history.push('/login');
    }
  }, [isLoggedIn, history, pathname, path]);

  return (
    <Route
      exact={exact}
      sensitive={false}
      path={path}
      render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}

export default withRouter(ProtectedRoute);

import Assets from '../../../assets/assets-exporter';

export interface ButtonProps {
  icon: any;
  text: string;
  href: string;
}

export const buttons: ButtonProps[] = [
  {
    icon: Assets.offersOutlined,
    text: 'Ogłoszenia',
    href: '/user-dashboard/offers',
  },
  {
    icon: Assets.commentOutline,
    text: 'Obserwowane',
    href: '/user-dashboard/observed',
  },
  {
    icon: Assets.commentOutline,
    text: 'Wiadomości',
    href: '/user-dashboard/messages',
  },
  // {
  //   icon: Assets.heartOutline,
  //   text: 'Płatności',
  //   href: '/user-dashboard/payments',
  // },
  {
    icon: Assets.settingsOutlined,
    text: 'Ustawienia',
    href: '/user-dashboard/settings',
  },
];

export const adminButtons: ButtonProps[] = [
  {
    icon: Assets.offersOutlined,
    text: 'Niezaakceptowane',
    href: '/admin-dashboard/offers',
  },
  {
    icon: Assets.commentOutline,
    text: 'Zgłoszone',
    href: '/admin-dashboard/reports',
  },
  {
    icon: Assets.commentOutline,
    text: 'Użytkownicy',
    href: '/admin-dashboard/users',
  },
  {
    icon: Assets.settingsOutlined,
    text: 'System',
    href: '/admin-dashboard/system',
  },
  {
    icon: Assets.hashOutlined,
    text: 'Tagi',
    href: '/admin-dashboard/tags',
  },
];

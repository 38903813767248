import * as S from './CustomCheckbox.styles';

export default function CustomCheck({
  isSelected = false,
  handleSelect,
  isRed = false,
}: {
  isSelected?: boolean;
  handleSelect: () => void;
  isRed?: boolean;
}) {
  return (
    <S.CheckBox isRed={isRed} aria-label={'checkbox'} selected={isSelected} onClick={handleSelect}>
      {isSelected && (isRed ? <S.RemoveIcon /> : <S.CheckIcon />)}
    </S.CheckBox>
  );
}

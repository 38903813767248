import { useEffect, useState } from 'react';
import { OFFER_TYPE } from '../../../../redux/offer/types';
import { ICategory } from '../../../../redux/utils/types';
import { useHistory, useLocation } from 'react-router-dom';
import CategoryButton from '../../CategoryButton/CategoryButton';
import SubcategoryPanel from '../../SubcategoryPanel/SubcategoryPanel';
import * as S from './CategoriesContainer.style';
import * as api from '../../../../api/common-api';

interface CategoriesContainerProps {
  type?: string;
  marginBottom?: string;
  setCategoryIds?: (val: string[]) => void;
  selected?: string[];
  required?: boolean;
  isSearch?: boolean;
  selectedType?: string;
  onChangeType?: any;
  showCategories?: boolean;
  selectedCategories?: any[];
}

const CategoriesContainer = ({
  type,
  marginBottom,
  setCategoryIds,
  selected = [],
  required = false,
  isSearch = false,
  selectedType,
  onChangeType,
  showCategories = true,
  selectedCategories = [],
}: CategoriesContainerProps) => {
  const history = useHistory();
  const location = useLocation();
  const isSearchPage = location.pathname.includes('search');

  const [categories, setCategories] = useState<ICategory[]>([]);

  const fetchCategories = async () => {
    const fetchedCategories = (await api.getCategories()) || [];
    setCategories(fetchedCategories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const areCategoriesAvailable = categories && categories.length > 0;
  const bazaarCategories =
    areCategoriesAvailable &&
    categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.BAZAAR);
  const announementsCategories =
    categories && categories.filter((c: ICategory) => c.offerType === OFFER_TYPE.ANNOUNCEMENT);

  const isRequired = required ? !selected : false;
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);

  const usedCategoriesType =
    selectedType === OFFER_TYPE.BAZAAR ? bazaarCategories : announementsCategories;
  const usedCategoriesWhenCreator =
    selectedType && selectedType === OFFER_TYPE.BAZAAR ? bazaarCategories : announementsCategories;
  const properCategoriesType = type === 'creator' ? usedCategoriesWhenCreator : usedCategoriesType;

  const handleCategorySelect = (isMainButtonActive: boolean, id: string) => {
    if (isMainButtonActive && setCategoryIds) {
      setCategoryIds([]);
      return;
    }
    if (!isSearchPage) {
      return history.push(`/search?offerType=${selectedType}&categoryId=${id}`);
    }
    const pickedCategory =
      properCategoriesType &&
      properCategoriesType.length > 0 &&
      properCategoriesType.find((x) => x.id === id)?.categories;
    if (pickedCategory && pickedCategory.length < 1 && setCategoryIds) {
      return setCategoryIds([id]);
    }
    if (selectedCategory.includes(id)) {
      return setSelectedCategory([]);
    }
    setSelectedCategory([id]);
  };

  const isIdInCategoryTree = (id: string, categories: ICategory[]): boolean => {
    if (!id) {
      return false;
    }
    for (const category of categories) {
      if (category.id === id) {
        return true;
      }
      if (category.categories && category.categories.length > 0) {
        if (isIdInCategoryTree(id, category.categories)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSelectSubCategory = (id: string, subId: string) => {
    const isCategoryAlreadySelected = selectedCategories
      ? selectedCategories.includes(subId) || selectedCategories.includes(id)
      : false;

    if (isCategoryAlreadySelected && setCategoryIds) {
      setCategoryIds([]);
      setSelectedCategory([]);
      return;
    }
    if (setCategoryIds) {
      setCategoryIds([id, subId]);
      setSelectedCategory([]);
    }
  };

  return (
    <S.Wrapper marginBottom={marginBottom}>
      {type === 'creator' ? (
        <S.CreatorLabel>
          Wybierz kategorię <S.Red>*</S.Red>
          <input required={isRequired} type="checkbox" style={{ width: 0, height: 0 }} />
        </S.CreatorLabel>
      ) : isSearch ? (
        <></>
      ) : showCategories ? (
        <>
          <S.SelectCategoriesWrapper>
            <S.Category
              isSelected={selectedType === OFFER_TYPE.ANNOUNCEMENT}
              onClick={() => onChangeType(OFFER_TYPE.ANNOUNCEMENT)}
            >
              Zajęcia
            </S.Category>
            <S.Category
              isSelected={selectedType === OFFER_TYPE.BAZAAR}
              onClick={() => onChangeType(OFFER_TYPE.BAZAAR)}
            >
              Bazarek
            </S.Category>
          </S.SelectCategoriesWrapper>
        </>
      ) : (
        <></>
      )}
      <S.CategoriesWrapper isSearch={isSearch}>
        {showCategories &&
          properCategoriesType &&
          properCategoriesType.length > 0 &&
          properCategoriesType.map((category: ICategory) => {
            const { id, label, categories = [] } = category;
            const isActive = selectedCategory.includes(id);
            const isMainButtonActive =
              selectedCategories &&
              (selectedCategories.includes(id) ||
                isIdInCategoryTree(selectedCategories[0] || '', categories));

            return (
              <div key={id}>
                <CategoryButton
                  {...category}
                  isActive={isMainButtonActive}
                  onClick={() => handleCategorySelect(isMainButtonActive, id)}
                />
                <div>
                  {isActive && (
                    <SubcategoryPanel
                      handleSelectAll={() => {
                        if (setCategoryIds) {
                          setCategoryIds([id]);
                          setSelectedCategory([]);
                        }
                      }}
                      selected={selected}
                      handleSelect={(subId: string) => {
                        handleSelectSubCategory(id, subId);
                      }}
                      title={label}
                      subCategories={categories}
                      onClose={() => setSelectedCategory([])}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </S.CategoriesWrapper>
    </S.Wrapper>
  );
};

export default CategoriesContainer;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.form`
  flex-grow: 1;
  background: ${themes.default.white};
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 38px;

  @media (max-width: 800px) {
    padding: 38px 18px;
  }
`;

export const TopText = styled.div`
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px;
  line-height: 190%;
  letter-spacing: -0.4px;
  margin-bottom: 24px;
  color: ${themes.default.fontColorBlack};
`;

export const InputsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  @media (min-width: 740px) {
    flex-direction: row;
  }
`;

export const Input = styled.input`
  padding: 15px 21px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: ${themes.default.fontColorBlack};
  &::placeholder {
    color: rgba(130, 130, 130, 1);
  }
  flex-grow: 1;
  height: 50px;
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 18px;
  @media (min-width: 740px) {
    margin: 0 8px 18px;
  }
  @media (max-width: 800px) {
    max-width: 100%;
  }
  :focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  padding: 12px 20px;
  resize: none;
  height: 96px;
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  color: ${themes.default.fontColorBlack};
  :placeholder {
    color: ${themes.default.bdGrey};
  }
  font-size: 14px;
  font-family: Poppins;
  border-radius: 4px;
  margin: 0 0 18px;
  flex-grow: 1;
  :focus {
    outline: none;
  }
  width: 100%;
`;

export const Button = styled.button<{ disabled: boolean }>`
  width: 204px;
  height: 53px;
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  box-sizing: border-box;
  border: 1px solid ${themes.default.main};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
  @media (max-width: 800px) {
    max-width: 100%;
    width: 100%;
  }
  &:hover&:not([disabled]) {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${themes.default.lightGraphemeGrey};
    border: 1px solid ${themes.default.lightGraphemeGrey};
  }
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (min-width: 740px) {
    padding: 0 8px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  border-radius: 8px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
  border-radius: 0 8px 8px 0;
`;

export const Counter = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 164%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;
  color: ${themes.default.fontColorBlack};
  align-self: flex-end;
`;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, updateUsersStatuses } from '../../../redux/auth/actions';
import { useUsers } from '../../../redux/auth/selectors';
import CustomCheckbox from '../../Partials/CustomCheckbox/CustomCheckbox';
import useMediaQuery from '../../../hooks/useMediaQuery';
import UserRow from './UserRow';
import { USER_STATUS } from '../../../redux/auth/types';
import * as S from '../../UserDashboard/OffersPage/OffersPage.styles';
import { deleteUsers } from '../../../api/common-api';

export default function UsersPage() {
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { rows } = useSelector(useUsers);

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 750px)');

  const handleSelect = (id: string) => {
    if (selectedIds.includes(id)) {
      const filtered = selectedIds.filter((currentId) => currentId !== id);
      setSelectedIds(filtered);
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const userIds = rows.map(({ id }) => id);

  const areSelectedAll = selectedIds.length === rows.length;
  const handleSelectAll = () => {
    if (areSelectedAll) {
      return setSelectedIds([]);
    }
    setSelectedIds(userIds);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleDisableUsers = () =>
    dispatch(updateUsersStatuses({ userIds: selectedIds, status: USER_STATUS.DISABLED }));

  const handleDeleteUsers = async () => {
    await deleteUsers({ userIds: selectedIds });
  };

  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.Header>Raporty ogłoszeń</S.Header>
        <S.RemoveRow>
          {isTablet ? (
            <S.SelectAll onClick={handleSelectAll}>
              {areSelectedAll ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'}
            </S.SelectAll>
          ) : (
            <CustomCheckbox isSelected={areSelectedAll} handleSelect={handleSelectAll} />
          )}
          <S.AcceptAll onClick={handleDisableUsers} disabled={selectedIds.length === 0}>
            {isTablet ? 'Zablokuj użytkowników' : <S.Disable />}
          </S.AcceptAll>
          <S.AcceptAll onClick={handleDeleteUsers} disabled={selectedIds.length === 0}>
            {isTablet ? 'Usuń użytkowników' : <S.Disable />}
          </S.AcceptAll>
          {/* <S.RemoveButton disabled={true}>{isTablet ? 'Usuń zaznaczone' : <S.Trash />}</S.RemoveButton> */}
        </S.RemoveRow>

        {isDesktop && (
          <S.TableUsersHeaderRow>
            <S.TableUsersHeader>Email</S.TableUsersHeader>
            <S.TableUsersHeader>Nickname</S.TableUsersHeader>
            <S.TableUsersHeader>Name</S.TableUsersHeader>
            <S.UsersActionsHeader>Akcje</S.UsersActionsHeader>
          </S.TableUsersHeaderRow>
        )}
        {rows.map((user, index) => {
          const { id } = user;
          const isSelected = selectedIds.includes(id);
          return (
            <S.ResultRow key={id}>
              <CustomCheckbox isSelected={isSelected} handleSelect={() => handleSelect(id)} />
              <UserRow user={user} isFirst={index !== 0} />
            </S.ResultRow>
          );
        })}
      </S.BodyWrapper>
    </S.Wrapper>
  );
}

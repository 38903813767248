import { useEffect, useState } from 'react';
import Disclaimer from '../Disclaimer/Disclaimer';
import Option from '../Option/Option';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { OFFER_TYPE } from '../../../redux/offer/types';
import OfferTypeTable from './OfferTypeTable';
import * as S from './OffersPage.styles';
import * as api from '../../../api/common-api';
import { mapUserOffers } from '../../../utils/common';

enum CATEGORY {
  ACTIVE = 'ACTIVE',
  AWAITING = 'AWAITING',
  TERMINATED = 'TERMINATED',
  ARCHIVED = 'ARCHIVED',
}

const TITLE = {
  [CATEGORY.ACTIVE]: 'aktywne',
  [CATEGORY.AWAITING]: 'oczekujące',
  [CATEGORY.TERMINATED]: 'zakończone',
  [CATEGORY.ARCHIVED]: 'archiwalne',
};

const OffersPage = () => {
  const [userOffers, setUserOffers] = useState<any>({
    active: [],
    awaiting: [],
    terminated: [],
    archived: [],
  });
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY.ACTIVE);
  const { active, awaiting, terminated, archived } = userOffers;
  const activeCount = active.length;
  const awaitingCount = awaiting.length;
  const terminatedCount = terminated.length;
  const archivedCount = archived.length;

  const offers = {
    [CATEGORY.ACTIVE]: active,
    [CATEGORY.AWAITING]: awaiting,
    [CATEGORY.TERMINATED]: terminated,
    [CATEGORY.ARCHIVED]: archived,
  };

  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const isSideMenu = useMediaQuery('(min-width: 881px)');
  const isTablet = useMediaQuery('(min-width: 750px)');
  const isMobile = useMediaQuery('(min-width: 600px)');
  const isTableHeader = isDesktop || (!isSideMenu && isTablet);

  useEffect(() => {
    const fetchData = async () => {
      const offers = await api.getUserOffers();
      const res = mapUserOffers(offers);
      setUserOffers(res);
    };
    fetchData();
  }, []);

  const announcementsOffers = offers[selectedCategory].filter(
    (x: any) => x.offerType === OFFER_TYPE.ANNOUNCEMENT
  );
  const bazaarOffers = offers[selectedCategory].filter(
    (x: any) => x.offerType === OFFER_TYPE.BAZAAR
  );

  const refreshOffers = async () => {
    const offers = await api.getUserOffers();
    const res = mapUserOffers(offers);
    setUserOffers(res);
  }

  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <Disclaimer />
        <S.Header>Ogłoszenia</S.Header>
        {!isMobile && <S.SubHeader>{TITLE[selectedCategory]}</S.SubHeader>}
        <S.OptionsWrapper className="color-scroll">
          <Option
            onClick={() => setSelectedCategory(CATEGORY.ACTIVE)}
            isActive={CATEGORY.ACTIVE === selectedCategory}
            text="Aktywne"
            count={activeCount}
            icon={<S.ActiveIcon />}
          />
          <Option
            onClick={() => setSelectedCategory(CATEGORY.AWAITING)}
            isActive={CATEGORY.AWAITING === selectedCategory}
            text="Oczekujące"
            count={awaitingCount}
            icon={<S.AwaitingIcon />}
          />
          <Option
            onClick={() => setSelectedCategory(CATEGORY.TERMINATED)}
            isActive={CATEGORY.TERMINATED === selectedCategory}
            text="Zakończone"
            count={terminatedCount}
            icon={<S.TerminatedIcon />}
          />
          <Option
            onClick={() => setSelectedCategory(CATEGORY.ARCHIVED)}
            isActive={CATEGORY.ARCHIVED === selectedCategory}
            text="Archiwum"
            count={archivedCount}
            icon={<S.ArchivedIcon />}
          />
        </S.OptionsWrapper>
        {isTableHeader && (
          <S.TableHeaderRow>
            <S.TableTitle>Tytuł</S.TableTitle>
            <S.TableDate>Data</S.TableDate>
            <S.TablePrice>Cena</S.TablePrice>
            <S.TableDisplays>Wyświetlanie</S.TableDisplays>
            {selectedCategory === CATEGORY.TERMINATED && (
              <S.TablePromotion>Promowanie</S.TablePromotion>
            )}
          </S.TableHeaderRow>
        )}
        <OfferTypeTable
          header="Zajęcia"
          offers={announcementsOffers}
          isArchivedSection={selectedCategory === CATEGORY.ARCHIVED}
          refreshOffers={refreshOffers}
        />
        <OfferTypeTable
          header="Bazarek"
          offers={bazaarOffers}
          isArchivedSection={selectedCategory === CATEGORY.ARCHIVED}
          refreshOffers={refreshOffers}
        />
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default OffersPage;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import {
  GiTrafficLightsGreen,
  GiTrafficLightsOrange,
  GiTrafficCone,
  GiTrafficLightsRed,
} from 'react-icons/gi';
import { MdCheck, MdSyncDisabled } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { VscDiscard } from 'react-icons/vsc';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.extraLightGrey};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.bdGrey};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 1229px;
  justify-content: space-between;
`;

export const BodyWrapper = styled.div`
  max-width: 893px;
  width: 100%;
`;

export const TableHeaderRow = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0 22px 12px 150px;
  @media (min-width: 1024px) {
    margin: 0 22px 12px 204px;
  }
`;

export const TableUsersHeaderRow = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0 22px 12px 82px;
`;

export const TableTitle = styled.div`
  font-family: Poppins;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #bdbdbd;
  flex-grow: 2;
  flex-basis: 100px;
`;

export const TableDate = styled(TableTitle)`
  flex-basis: 50px;
  flex-grow: 1;
`;

export const TablePrice = styled(TableDate)``;

export const TableDisplays = styled(TableDate)`
  max-width: 120px;
  min-width: 120px;
`;

export const TablePromotion = styled(TableDate)`
  max-width: 97px;
  min-width: 97px;
  text-align: center;
`;

export const TableAdminFields = styled(TableDate)`
  max-width: 120px;
  min-width: 120px;
  box-sizing: border-box;
  padding: 0 20px 0 0;
  text-align: center;
`;

export const TableUsersHeader = styled(TableDate)`
  flex-basis: 100px;
  flex-grow: 1;
  padding: 0 4px;
  box-sizing: border-box;
`;

export const UsersActionsHeader = styled(TableUsersHeader)`
  padding-left: 14px;
`;

export const ResultsWrapper = styled.div`
  width: 100%;
  margin-bottom: 28px;
`;

export const ResultRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  padding: 0;
  margin: 0 0 8px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #110f11;
`;

export const SubHeader = styled.h2`
  padding: 0;
  margin: 0 0 28px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  color: #110f11;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
  padding-bottom: 20px;
  overflow-x: scroll;
`;

export const commonIcon = `
  color: #000;
  width: 24px;
  height: 24px;
`;

export const ActiveIcon = styled(GiTrafficLightsGreen)`
  ${commonIcon}
`;

export const AwaitingIcon = styled(GiTrafficLightsOrange)`
  ${commonIcon}
`;

export const TerminatedIcon = styled(GiTrafficLightsRed)`
  ${commonIcon}
`;

export const ArchivedIcon = styled(GiTrafficCone)`
  ${commonIcon}
`;

// admin

export const RemoveRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transition: 250ms;
  margin: 0 0 16px;
  overflow: hidden;
  height: 34px;
`;

export const SelectAll = styled.button`
  background-color: ${themes.default.main};
  border: none;
  :disabled {
    background-color: #bdbdbd;
    cursor: default;
  }
  transition: 250ms;
  color: ${themes.default.white};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  margin: 0 8px 0 0;
  padding: 8px;
  width: 34px;
  padding: 8px;
  @media (min-width: 750px) {
    width: auto;
    padding: 8px 16px;
  }
`;

export const AcceptAll = styled(SelectAll)`
  margin: 0 24px 0 auto;
  @media (min-width: 750px) {
    margin: 0 8px 0 0;
  }
`;

export const RemoveButton = styled(SelectAll)`
  background-color: ${themes.default.negative};
  margin: 0;
`;

export const Check = styled(MdCheck)`
  height: 18px;
  width: 18px;
  color: white;
`;

export const Discard = styled(VscDiscard)`
  height: 18px;
  width: 18px;
  color: white;
`;

export const Disable = styled(MdSyncDisabled)`
  height: 18px;
  width: 18px;
  color: white;
`;

export const Trash = styled(IoMdTrash)`
  height: 18px;
  width: 18px;
  color: white;
`;

export const OfferTypeNameContainer = styled.div``;

export const OfferTypeName = styled.h3`
  margin: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 18px;
  line-height: 39px;
  color: #110f11;
`;

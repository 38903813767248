import ContactPageWrapper from '../ContactPageWrapper/ContactPageWrapper';
import RegulationsImage from '../../../assets/RegulationsImage.svg';
import * as S from './RegulationsPage.styles';

const RegulationsPage = () => (
  <ContactPageWrapper image={RegulationsImage} title="Regulamin">
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>Regulamin serwisu malutcy.pl</S.Header>
      </S.HeaderWrapper>
      <S.Paragraph>
        <strong>I. Postanowienia ogólne</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Każdy korzystający z serwisu jest zobowiązany do zapoznania się z treścią regulaminu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Regulamin określa warunki korzystania z serwisu, w tym zasady publikacji ogłoszeń,
            płatności i postępowania reklamacyjnego.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>W ramach serwisu można:</S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                przeglądać zawartość serwisu, a w szczególności publikowanych w serwisie ogłoszeń,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                założyć konto i korzystać z dostępnych w jego ramach funkcjonalności (publikacji
                ogłoszeń, obserwowania ogłoszeń, wymiany wiadomości).
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>Na użytek regulaminu:</S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                „Operator serwisu” oznacza podmiot prowadzący i obsługujący serwis malutcy.pl i
                świadczący usługi zgodnie z niniejszym regulaminem, tj. Malutcy.pl Spółka z
                ograniczoną odpowiedzialnością z siedzibą w Gnieźnie (62-200) przy ul. Kruszwickiej
                6, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod numerem
                0001067137, NIP 7842538212, z którą kontakt jest możliwy pod adresem{' '}
                <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                „Użytkownik” oznacza korzystającą z serwisu, w ramach założonego konta, osobę
                fizyczną, prawną lub jednostkę organizacyjną nieposiadającą osobowości prawnej,
                której ustawa przyznaje zdolność prawną, przy czym wszelkich czynności w ramach
                serwisu w imieniu osób prawnych i jednostek organizacyjnych dokonuje umocowana przez
                nie osoba fizyczna. Operator serwisu ma prawo żądać dokumentu potwierdzającego to
                umocowanie.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                „Użytkownik biznesowy” oznacza użytkownika korzystającego z serwisu, w ramach
                założonego konta, w związku z prowadzoną działalnością gospodarczą, handlową,
                rzemieślniczą lub zawodową.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                „Konsument” oznacza użytkownika będącego osobą fizyczną, dokonującego w serwisie
                czynności niezwiązanych bezpośrednio z jego działalnością gospodarczą lub zawodową.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Wymagania techniczne, które są niezbędne do korzystania z serwisu są następujące:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                Aktywne połączenie z Internetem, które umożliwia dwustronną komunikację przez
                protokół HTTPS.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Prawidłowo zainstalowana i skonfigurowana, aktualna przeglądarka internetowa
                obsługująca standard HTML5 oraz technologię kaskadowych arkuszy stylów (CSS3), na
                przykład Google Chrome, Mozilla Firefox, Opera, Microsoft Edge, lub Internet
                Explorer.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Włączona obsługa JavaScript oraz plików cookies (zwykle domyślnie włączona w
                przeglądarce).
              </S.Paragraph>
            </li>
          </ol>
        </li>
      </ol>
      <S.Paragraph>
        <strong>II.Usługa konta</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Konto daje użytkownikowi możliwość korzystania z następujących usług lub
            funkcjonalności:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>publikacji i przeglądania własnych ogłoszeń,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>obserwowania ogłoszeń innych użytkowników,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>wymiany wiadomości z innymi użytkownikami serwisu,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>zamawiania i konfigurowania usługi powiadamiania.</S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            W celu korzystania z usługi konta i dalszych funkcjonalności należy dokonać rejestracji
            konta i spełnić pozostałe wymagania, o których mowa poniżej.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik może posiadać tylko jedno konto w serwisie. Reguła ta podlega wyłączeniu w
            przypadku, gdy:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                użytkownik posiada jedno konto do celów prywatnych i inne konta, które wykorzystuje
                w zakresie prowadzonej działalności gospodarczej, o ile zamieszczane poprzez te
                konta ogłoszenia nie powtarzają się ze względu na lokalizację,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                konieczne jest założenie nowego konta ze względu na brak możliwości odzyskania
                dostępu do założonego już konta.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>Rejestracja konta następuje poprzez:</S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                wypełnienie formularza danymi uwierzytelniania lub uwierzytelnienie za pośrednictwem
                zewnętrznego usługodawcy, jeśli w danym momencie taka usługa jest dostępna,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                złożenie oświadczenia o zapoznaniu się z regulaminem oraz akceptacji jego
                postanowień
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                zatwierdzenie powyższych czynności poprzez użycie przycisku „zarejestruj się”
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Aktywację konta poprzez kliknięcie w link aktywacyjny wysłany na podany w formularzu
                adres e-mail.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                aktywację konta poprzez kliknięcie w link aktywacyjny wysłany na podany w formularzu
                adres e-mail w czasie wynikającym ze względów bezpieczeństwa, o którym użytkownik
                zostanie poinformowany w trakcie rejestracji.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Zawarcie umowy o usługę konta następuje z chwilą użycia linku aktywacyjnego. Umowa
            zostaje zawarta na czas nieokreślony.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Po rejestracji użytkownik otrzymuje na podany adres e-mail aktualny regulamin serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>Użytkownik zobowiązuje się do:</S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                zachowania danych dostępu do konta w tajemnicy i do ich ochrony przed dostępem przez
                osoby trzecie, a także w razie nabrania podejrzeń powiadomienia operatora serwisu o
                dostępie do konta ze strony niepowołanych osób trzecich,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                podawania prawdziwych i aktualnych danych, do podania których jest uprawniony lub
                umocowany,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                korzystania z konta w zgodzie z deklaracją o tym, czy korzysta z konta jako
                użytkownik prywatny, czy użytkownik biznesowy.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Przed dodaniem pierwszego ogłoszenia użytkownik ma obowiązek złożyć deklarację, czy
            korzysta z konta jako użytkownik prywatny, czy użytkownik biznesowy. Deklaracja obejmuje
            całą związaną z korzystaniem z konta aktywność użytkownika.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Przed dodaniem pierwszego ogłoszenia użytkownik ma obowiązek uzupełnić w ustawieniach
            konta wymagane dane identyfikacyjne. Zakres wymaganych danych jest szerszy dla
            użytkownika biznesowego.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Dane identyfikacyjne i kontaktowe użytkownika biznesowego są publikowane w serwisie, co
            ma na celu poinformowanie kupujących o tym, czy przysługuje im ochrona konsumencka.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu zastrzega sobie prawo do zwrócenia się do użytkownika biznesowego o
            przekazanie dodatkowych danych lub poddanie się dodatkowym czynnościom weryfikacyjnym, w
            tym przedstawienie stosownych dowodów świadczących o prawdziwości lub aktualności
            podanych przez użytkownika danych. Może to dotyczyć zarówno informacji o prowadzonej
            działalności gospodarczej, tożsamości użytkownika, jak i informacji podawanych w
            ogłoszeniach użytkownika, a także wyjątków przewidzianych w pkt. 3 powyżej. Weryfikacja
            może mieć charakter zautomatyzowany. Odmowa podania dodatkowych danych lub poddania się
            dodatkowym czynnościom weryfikacyjnym może skutkować zablokowaniem konta przez operatora
            z odpowiednim zastosowaniem zasad przewidzianych w punkcie VII pt. „Działania niezgodne
            z regulaminem” a związanych m.in. ze składaniem sprzeciwu
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik może rozwiązać umowę usługi konta z zachowaniem poniższych zasad:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                Użytkownik może rozwiązać umowę bez żadnej przyczyny poprzez:
              </S.Paragraph>
              <ol>
                <li>
                  <S.Paragraph>
                    skorzystanie z opcji usunięcia konta w ustawieniach konta,
                  </S.Paragraph>
                </li>
                <li>
                  <S.Paragraph>
                    złożenie oświadczenia o rozwiązaniu umowy za pomocą formularza kontaktowego,
                  </S.Paragraph>
                </li>
                <li>
                  <S.Paragraph>
                    złożenie oświadczenia o rozwiązaniu pisemnie na adres operatora serwisu podany w
                    punkcie I.4.a.
                  </S.Paragraph>
                </li>
              </ol>
            </li>
            <li>
              <S.Paragraph>
                Rozwiązanie umowy konta będzie skuteczne w chwili, gdy informacja o rozwiązaniu
                umowy dotrze do operatora serwisu w wyniku dokonania jednej z powyższych czynności.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Rozwiązanie umowy uprawnia operatora serwisu do usunięcia konta użytkownika, a wraz
                z nim wszelkich przekazanych lub wygenerowanych w trakcie korzystania z usługi konta
                informacji.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                W przypadkach rozwiązania umowy inaczej niż poprzez skorzystanie z opcji usunięcia
                konta, która jest dostępna w ustawieniach konta, operator serwisu może zwrócić się o
                przekazanie dodatkowych danych lub poddanie się dodatkowym czynnościom
                weryfikacyjnym, które będą niezbędne do ustalenia jakiego konta dotyczy rozwiązywana
                umowa. Nieprzekazanie danych lub niepoddanie się dodatkowym czynnościom może
                skutkować zachowaniem konta i związanych z nim informacji.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                W przypadku gdy operator serwisu nie dokona jeszcze usunięcia konta, a użytkownik
                ponownie zaloguje się na to konto, następuje ponowne zawarcie umowy o usługę konta.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Wraz z usunięciem konta wygasają inne umowy zawarte przy użyciu konta (dotyczy
                udostępnianych w ramach konta funkcjonalności, a w szczególności publikacji
                ogłoszeń).
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Powyższe zasady nie naruszają uprawnień użytkownika do odstąpienia lub wypowiedzenia
                umowy, na innych zasadach, które zostały przewidziane przepisami prawa lub
                niniejszym regulaminem.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu może rozwiązać umowę usługi konta, która została zawarta z
            użytkownikiem, z zachowaniem poniższych zasad:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>Operator serwisu może rozwiązać umowę usługi konta, gdy:</S.Paragraph>
              <ol>
                <li>
                  <S.Paragraph>
                    pomimo wezwania użytkownika do zaprzestania określonych działań lub zaniechań
                    naruszających postanowienia regulaminu lub przepisy prawa powszechnie
                    obowiązującego, użytkownik kontynuuje wskazane w wezwaniu działania,
                  </S.Paragraph>
                </li>
                <li>
                  <S.Paragraph>
                    użytkownik nie zalogował się na koncie przez co najmniej 24 miesiące, pomimo
                    wysłania przez operatora serwisu na przypisany do danego konta adres e-mail
                    wiadomości z instrukcją o tym jak zapobiec rozwiązaniu umowy i usunięciu konta
                  </S.Paragraph>
                </li>
              </ol>
            </li>
            <li>
              <S.Paragraph>
                Rozwiązanie umowy uprawnia operatora serwisu do usunięcia konta użytkownika, a wraz
                z nim wszelkich przekazanych lub wygenerowanych w trakcie korzystania z usługi konta
                informacji.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Wraz z usunięciem konta wygasają inne umowy zawarte przy użyciu konta (dotyczy
                udostępnianych w ramach konta funkcjonalności, a w szczególności publikacji
                ogłoszeń).
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Rozwiązanie umowy i usunięcie konta z powodu wskazanego w punkcie (a) (ii) powyżej,
                nie ogranicza uprawnienia użytkownika do zawarcia nowej umowy o usługę kotna, z tym
                że operator serwisu nie gwarantuje dostępności dotychczas używanych danych
                uwierzytelniających i identyfikacyjnych.
              </S.Paragraph>
            </li>
          </ol>
        </li>
      </ol>
      <S.Paragraph>
        <strong>III. Publikacja ogłoszeń</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            W ramach usługi konta operator serwisu udostępnia użytkownikowi usługę publikacji
            ogłoszeń.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Publikacja ogłoszenia następuje poprzez wypełnienie formularza ogłoszenia i
            zatwierdzenie przez użytkownika oraz akceptację moderatora, po spełnieniu innych,
            ewentualnych wymagań wynikających z regulaminu (np. co do uiszczenia opłaty).
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Opublikowane ogłoszenie jest dostępne dla wszystkich korzystających z serwisu. Wraz z
            ogłoszeniem korzystający z serwisu otrzymują również dostęp:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>do numeru telefonu użytkownika,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                informacji o tym, czy użytkownik jest zalogowany w serwisie oraz
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                wizytówki użytkownika wskazującej lokalizację użytkownika (podaną przez niego w
                ustawieniach konta) oraz zawierającej nazwę użytkownika i odnośnik do wszystkich
                ogłoszeń tego użytkownika,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                a ponadto zalogowani użytkownicy mają możliwość wysłania do ogłoszeniodawcy
                wiadomości.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Korzystający z serwisu otrzymują wraz z ogłoszeniem także informację o:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                tym, czy ogłoszeniodawca jest przedsiębiorcą, czy nie — zgodnie z deklaracją danego
                użytkownika, którą złożył w chwili zakładania konta;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                stosowaniu bądź niestosowaniu przepisów dotyczących konsumentów do umowy, która może
                być zawarta;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                podziale obowiązków związanych z umową, która może być zawarta przez konsumenta,
                przy czym jeśli nie pojawia się inna informacja, jest tak, że:
              </S.Paragraph>
              <ol>
                <li>
                  <S.Paragraph>
                    operator odpowiada za udostępnienie usług internetowych, o których mowa w
                    niniejszym regulaminie (prowadzenie konta, zamieszczanie ogłoszeń) i nie
                    odpowiada za sprzedaż oferowanych towarów i usług
                  </S.Paragraph>
                </li>
                <li>
                  <S.Paragraph>
                    ogłoszeniodawcy są odpowiedzialni za sprzedaż i dostarczenie dokładnie tego
                    przedmiotu lub zrealizowanie dokładnie tej usługi, które zostały opisane w
                    ogłoszeniu. W przypadku, gdy ogłoszeniodawca jest przedsiębiorcą obejmuje to
                    zapewnienie zwrotów, odpowiedniej informacji i innych praw konsumenta.
                  </S.Paragraph>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Ogłoszenie musi spełniać wymogi niniejszego regulaminu, mieścić się w granicach prawa,
            być zgodne z prawdą oraz jednoznaczne i zrozumiałe.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik ma swobodę w redagowaniu treści ogłoszenia, jednak z uwzględnieniem
            następujących wymogów:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                Jedno ogłoszenie może dotyczyć jednego przedmiotu lub kompletu (kilka przedmiotów
                stanowi całość).
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>Ogłoszenie musi być zredagowane w języku polskim.</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie nie może zawierać słów powszechnie uznanych za wulgarne lub obraźliwe.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie musi spełniać podane w formularzu wymogi techniczne (np. co do liczby
                znaków, przypisania do kategorii, podania wymaganych informacji).
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie musi zawierać jasny, dokładny, kompletny i niewprowadzający w błąd opis
                przedmiotu ogłoszenia (szczególnie co do cech przedmiotu i warunków transakcji). W
                tym przypadku poprzez opis należy rozumieć wszystkie podane parametry i wymienione
                korzyści, a także tytuł i zdjęcia.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie nie może zawierać dodatkowych danych kontaktowych (dane kontaktowe można
                podawać jedynie poprzez pola formularza lub konta użytkownika).
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie nie może zawierać reklamy lub treści promocyjnych i ogłoszeniowych, które
                nie dotyczą bezpośrednio przedmiotu ogłoszenia, a np. użytkownika, innej jego
                oferty, prowadzonej przez niego działalności gospodarczej lub zawodowej itp.
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                Ogłoszenie nie może zawierać adresów stron internetowych oraz innych elementów
                prowadzących do serwisów świadczących takie same lub podobne usługi jak operator
                serwisu w zakresie publikacji ogłoszeń.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Zabronione jest przekazywanie informacji związanych z ogłoszeniem z pominięciem serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu zastrzega sobie prawo do zmiany kategorii ogłoszenia, a jeśli zmiana ta
            wiązałaby się z opłatą, do usunięcia ogłoszenia.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>Przedmiotem ogłoszenia nie może być: </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                rzecz lub usługa, które w danym czasie są już objęte innym ogłoszeniem w serwisie,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                rzecz, która nie jest w posiadaniu użytkownika lub usługa, której użytkownik nie
                może świadczyć,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                rzecz, która nie znajduje się na terytorium Polski lub usługa, która nie jest
                wykonywana na terytorium Polski,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>rzecz lub usługa nieprzeznaczona dla dzieci,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                rzecz lub usługa znajdująca się na liście zakazanych przedmiotów ogłoszenia,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                rzecz którą nie można obracać ze względu na obowiązujące przepisy prawa lub usługa,
                której nie można świadczyć ze względu na obowiązujące przepisy prawa,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                rzecz pochodząca z kradzieży lub co do której toczy się spór, lub która jest objęta
                postępowaniem egzekucyjnym lub upadłościowym.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik zobowiązuje się publikować ogłoszenia w związku ze złożoną deklaracją o tym,
            czy korzysta z konta jako użytkownik prywatny, czy użytkownik biznesowy. Użytkownicy
            prywatni mogą publikować wyłącznie ogłoszenia niezwiązane z działalnością gospodarczą, a
            użytkownicy biznesowi wyłącznie ogłoszenia związane z ich działalnością gospodarczą lub
            zawodową. Informacja, czy dany Użytkownik jest użytkownikiem biznesowym, czy korzysta z
            serwisu prywatnie, jest publikowana w serwisie zgodnie z deklaracją danego użytkownika.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Publikacja ogłoszenia następuje niezwłocznie po zaksięgowaniu ewentualnie wymaganej
            opłaty.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Ogłoszenia domyślnie są plasowane zgodnie z kolejnością publikacji, aby korzystający
            mogli skorzystać z najświeższych i przypuszczalnie najbardziej dostępnych ofert.
            Korzystający z serwisu mogą otrzymać opcję pozwalającą na sortowanie ogłoszeń według
            innych kryteriów (np. według ceny).
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Publikacja ogłoszenia rozpoczyna dostęp do ogłoszenia dla korzystających z serwisu.
            Dostęp trwa przez czas, który został wybrany przez użytkownika w ramach dostępnych
            opcji, w momencie uzupełniania formularza ogłoszenia, jednak nie dłużej niż przez 3
            miesiące od publikacji lub zmiany ogłoszenia lub do wystąpienia jednej z następujących
            okoliczności:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                użytkownik dokonał zmiany ogłoszenia w sposób tak istotny, że dotyczy ono już innego
                przedmiotu,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>użytkownik usunął ogłoszenie,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                ogłoszenie zostało usunięte lub zablokowane przez operatora serwisu.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            W czasie, gdy ogłoszenie jest udostępnione w serwisie, użytkownik może modyfikować
            niektóre parametry tego ogłoszenia lub je usunąć.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Po zakończeniu udostępniania ogłoszenia zostaje ono zarchiwizowane w odpowiedniej
            zakładce konta użytkownika, gdzie jest dostępne przez okres 6 miesięcy albo do czasu
            usunięcia przez użytkownika. Użytkownik powinien we własnym zakresie archiwizować treść
            ogłoszenia, a także informacje o zawartych transakcjach.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Publikacja ogłoszenia jest bezpłatna w ramach limitu ogłoszeń. Limity mogą być różne dla
            różnych kategorii ogłoszeń, a także dla poszczególnych rodzajów użytkowników. Operator
            serwisu podaje obowiązujące limity w załączniku nr 1. Publikacja ogłoszeń ponad
            przyznany limit jest płatna.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik może w każdej chwil usunąć ogłoszenie i tym samym wypowiedzieć umowę o
            świadczenie usługi publikacji tego ogłoszenia.
          </S.Paragraph>
        </li>
      </ol>

      <S.Paragraph>
        <strong>IV. Obserwowanie ogłoszeń innych użytkowników</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            W ramach usługi konta operator serwisu udostępnia użytkownikowi usługę obserwowania
            ogłoszeń innych użytkowników serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Usługa pozwala na oznaczanie ogłoszeń do obserwacji (jako ulubionych) i przeglądanie ich
            w innym widoku, w ramach udostępnionych w serwisie funkcjonalności.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Przeglądanie oznaczonych ogłoszeń jest możliwe tak długo, jak długo ogłoszenia te są
            publikowane.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Usługa ta związana jest z kontem i rezygnacja z niej następuje poprzez rezygnację z
            dostępu do konta i jego usunięcie.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>V. Wymiana wiadomości z innymi użytkownikami serwisu</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            W ramach usługi konta operator serwisu udostępnia użytkownikowi usługę wymiany
            wiadomości z innymi użytkownikami serwisu wewnątrz serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Usługa pozwala na wysyłanie wiadomości do innych użytkowników serwisu
            (ogłoszeniodawców), odpowiadanie na ich wiadomości, także przeglądanie i usuwanie
            wiadomości w ramach udostępnionych w serwisie funkcjonalności
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Korespondencja jest dostępna dla użytkownika przez czas 12 miesięcy.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik zgadza się na to, że konwersacje nie są prywatne, co oznacza, że operator
            serwisu ma możliwość uzyskania dostępu do wiadomości użytkownika i analizowania ich w
            celu zwiększenia bezpieczeństwa użytkowników serwisu, zapobiegania nadużyciom i
            ulepszania serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Usługa ta związana jest z kontem i rezygnacja z niej następuje poprzez rezygnację z
            dostępu do konta i jego usunięcie.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>VI. Pozostałe warunki korzystania z usług </strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Użytkownik może publikować wyłącznie materiały, do których przysługują mu prawa
            pozwalające na takie korzystanie z tych materiałów (własność majątkowych praw autorskich
            lub odpowiednia licencja).
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Własność treści publikowanych w serwisie przez użytkowników (w tym własność
            intelektualna) pozostaje nienaruszona w związku z tą publikacją. Z chwilą opublikowania
            przez użytkownika należących do niego materiałów , udziela on operatorowi licencji na
            korzystanie z tych materiałów zgodnie z charakterem świadczonych usług, bez ograniczeń
            terytorialnych (na całym świecie) i nieodpłatnie. O czasie trwania licencji decyduje
            użytkownik odpowiednio zarządzając treścią, z tym, że zdjęcie lub usunięcie ogłoszenia
            nie oznacza zakończenia korzystania z materiałów przez operatora, którzy może je
            archiwizować w okresie wypowiedzenia umowy, czyli przez 6 miesięcy.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator nie udziela dostępu do danych, które gromadzi w związku ze świadczeniem usług i
            działaniem serwisu.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Wszelkie ograniczenia świadczenia usług na rzecz użytkowników biznesowych obejmują
            blokadę lub usuniecie danej treści oraz zawieszenie lub zablokowanie konta. Podstawy i
            zasady nakładania tych ograniczeń zostały opisane w części VII pt. „Działania niezgodne
            z regulaminem”.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator odpowiada za zgodność świadczenia z umową (niniejszym regulaminem).
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>VII. Działania niezgodne z regulaminem</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Użytkownik jest uprawniony do korzystania z serwisu zgodnie z jego przeznaczeniem, w
            granicach prawa i dobrych obyczajów społeczeństwa informacyjnego, a także z
            poszanowaniem praw i wolności innych osób. Użytkownik zobowiązuje się w szczególności do
            tego, że:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                nie będzie korzystał z serwisu niezgodnie z jego przeznaczeniem lub wbrew
                regulaminowi;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie podejmował działań, które mogłyby zakłócić prawidłowe funkcjonowanie
                serwisu, w tym do tego, że nie będzie ingerował w zawartość serwisu, swojego konta
                lub kont innych użytkowników, lub w jakiekolwiek inne elementy informatyczne
                serwisu, z pominięciem funkcjonalności, które zostały mu udostępnione;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie podejmował działań, które mogłyby zakłócić prawidłowe funkcjonowanie
                serwisu poprzez ograniczenie jego dostępności;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie podejmował działań bezprawnych, w tym przesyłał lub publikował z
                wykorzystaniem funkcjonalności serwisu treści naruszających przepisy prawa, w tym
                treści naruszających dobra osobiste, a w szczególności treści zawierających lub
                rozpowszechniających pornografię dziecięcą, treści terrorystycznych lub
                naruszających cudze prawa własności intelektualnej, a także treści o charakterze
                dyskryminującym lub rasistowskim;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie wprowadzał operatora serwisu lub stron trzecich w błąd np. poprzez
                podawanie nieprawdziwych informacji lub zatajenie istotnych informacji, w
                szczególności co do stanu, cech i elementów oferowanych usług i rzeczy;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie prowadził z wykorzystaniem funkcjonalności serwisu sprzedaży (lub
                rozporządzał rzeczą w podobny sposób lub z podobnym skutkiem) rzeczy pochodzących z
                kradzieży lub co do których toczy się spór sądowy lub administracyjny albo też
                rzeczy objętych postępowaniem egzekucyjnym lub wchodzących w skład masy
                upadłościowej lub sanacyjnej;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                nie będzie prowadził z wykorzystaniem funkcjonalności serwisu sprzedaży (lub
                rozporządzał rzeczą w podobny sposób lub z podobnym skutkiem) rzeczy którymi obrót
                wyłączony jest obowiązującymi przepisami prawa.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik zobowiązany jest przestrzegać powyższego zobowiązania określonego w ust. 1
            przy każdej czynności korzystania z serwisu, w stosunku do każdej funkcjonalności, w tym
            w szczególności w przypadku publikowania ogłoszeń i przesyłania wiadomości do innych
            użytkowników.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Publikowanie lub przesyłanie treści w serwisie odbywa się w sposób zautomatyzowany, a
            operator serwisu nie weryfikuje uprzednio wszystkich tych treści.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu uprawniony jest do edytowania lub usunięcia treści, które są niezgodne
            z regulaminem.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Każda osoba, która korzysta z serwisu ma możliwość zgłoszenia operatorowi serwisu
            treści, które są niezgodne z regulaminem, w tym bezprawne, korzystając z formularza
            kontaktowego. Taka osoba powinna w miarę możliwości podać dane umożliwiające operatorowi
            serwisu dokonanie weryfikacji nadużycia, w tym w szczególności: ID Ogłoszenia,
            wyjaśnienie powodów, dla których uznaje daną treść za niezgodną z regulaminem oraz wedle
            swojego wyboru, danych kontaktowych.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            W razie uzyskania wiarygodnej wiadomości o tym, że ktoś dokonał publikacji treści, która
            jest niezgodna z regulaminem, operator serwisu może zablokować daną treść, a także
            związane z nią usługi i podjąć właściwe czynności wyjaśniające, dążąc do ustalenia
            okoliczności sprawy, działając proporcjonalnie i rozsądnie, w tym przykładowo poprzez
            kontakt z podmiotem zgłaszającym nadużycie, dostawcą danej treści, właściwym organem lub
            zewnętrznym doradcą.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu, z zachowaniem zasad proporcjonalności i poszanowania wolności obrotu,
            może zablokować lub usunąć ogłoszenie lub inną treść, a także zawiesić lub zablokować
            konto (lub konta różnych użytkowników, którzy działają w porozumieniu), jeśli nabierze
            podejrzeń, że za pośrednictwem tej treści lub konta:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                mogło lub może dojść do działań zagrażających bezpieczeństwu operatora serwisu lub
                stron trzecich, jak również w przypadku, w którym ogłoszenie negatywnie wpływa na
                dobre imię operatora serwisu lub szkodzi mu w inny sposób;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                mogło lub może dojść do poważnego naruszenia prawa z wykorzystaniem serwisu;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                mogło lub może dojść do poważnego lub powtarzającego się naruszenia postanowień
                regulaminu.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Zawieszenie konta oznacza czasowe wstrzymania jego funkcjonalności — użytkownik, którego
            konto zostało zawieszone, traci możliwość aktywnego korzystania z serwisu. Taki
            użytkownik może jednak przeglądać serwis, historię wiadomości i opublikowanych przez
            siebie ogłoszeń.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Zablokowanie konta oznacza utratę przez użytkownika możliwości zalogowania się do konta,
            ale nie oznacza zwolnienia adresu poczty elektronicznej, który był wykorzystany do
            założenia konta.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            W razie dokonania blokady lub usunięcia danej treści, a także zawieszenia lub
            zablokowania konta, najpóźniej z chwilą gdy zostanie podjęte dane działanie, operator
            serwisu:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                poinformuje o tym dostawcę danej treści lub użytkownika konta w formie wiadomości
                poczty elektronicznej,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>wskaże treść lub konto wobec których podjął działania</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                przedstawi uzasadnienie, w którym wskaże powody i regulaminowe podstawy swojej
                decyzji, a także treść powiadomień otrzymanych od stron trzecich, które doprowadziły
                do podjęcia danej decyzji,
              </S.Paragraph>
            </li>
          </ol>
          <S.Paragraph>
            chyba, że czego innego wymagają względy bezpieczeństwa życia lub zdrowia osób lub
            względy bezpieczeństwa państwa.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik biznesowy może zakwestionować decyzję o blokadzie danej treści, jak również
            blokadzie lub zawieszeniu konta, poprzez złożenie sprzeciwu w ciągu 14 dni od otrzymania
            tej decyzji. W tej sytuacji stosuje się odpowiednio postanowienia dotyczące reklamacji.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            W przypadku, gdy operator serwisu uzna sprzeciw klienta biznesowego, lub gdy niezgodność
            z regulaminem ustanie w niebudzący wątpliwości sposób:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                operator serwisu przywróci daną treść, jeśli użytkownik tak zadecyduje;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                jeśli usługa była odpłatna, operator serwisu dokona zwrotu opłaty.{' '}
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            W przypadku, gdy operator serwisu uzna sprzeciw klienta biznesowego za bezzasadny lub
            użytkownik biznesowy nie wniesie sprzeciwu w przewidzianym regulaminem terminie:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                operator serwisu podtrzyma decyzję o blokadzie lub zawieszeniu;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                jeśli usługa była odpłatna, operator serwisu dokona zwrotu opłaty.{' '}
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Wniesienie lub niewniesienie sprzeciwu pozostaje bez wpływu na prawo użytkownika do
            wniesienia reklamacji zgodnie z odpowiednimi postanowieniami regulaminu.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>VIII. Reklamacje</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            W sprawie świadczonych usług z operatorem serwisu można kontaktować się w formie:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>pisemnej na adres: ul. Kruszwicka 6, 62-200 Gniezno;</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                elektronicznej za pośrednictwem dostępnego w serwisie formularza kontaktowego lub na
                adres <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                telefonicznej pod numerem: 731 607 060 (w godzinach 8:00-16:00).
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu dokłada wszelkich starań, aby świadczone usługi były najwyższej
            jakości. Użytkownik ma jednak prawo do nieodpłatnego złożenia reklamacji dotyczącej
            niewykonania bądź nienależytego wykonania usług świadczonych na podstawie regulaminu.{' '}
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Reklamację można złożyć za pomocą formularza kontaktowego, na podany wyżej adres poczty
            elektronicznej lub pisemnie na podany wyżej adres.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Reklamacja powinna zawierać imię, nazwisko, przypisany do konta adres poczty
            elektronicznej, ID ogłoszenia, opis żądania reklamacyjnego i uzasadnienie zawierające
            istotne okoliczności takie jak data, miejsce i przebieg zdarzenia będącego przyczyną
            reklamacji. Użytkownik może też podać zwykły adres do korespondencji.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Jeżeli podane informacje nie pozwolą na rozpatrzenie reklamacji, operator zwróci się do
            użytkownika z prośbą o uzupełnienie reklamacji o wskazane informacje lub wyjaśnienia
            dotyczące wskazanych wątpliwości.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Reklamacje pochodzące od konsumentów będą rozpatrywane w ciągu 14 dni, a pozostałe w
            ciągu 30 dni od daty ich otrzymania.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Odpowiedź w sprawie reklamacji zostanie wysłana na przypisany do konta adres poczty
            elektronicznej, chyba, że użytkownik zażąda w treści reklamacji przesłania odpowiedzi
            pocztą na adres korespondencyjny, który poda w treści reklamacji.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>IX. Skarga i mediacja</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Użytkownik biznesowy może złożyć skargę w następujących przypadkach:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                rzekomego niewywiązywania się przez operatora serwisu ze zobowiązań wynikających z
                regulaminu lub obowiązków wynikających z Rozporządzenia Parlamentu Europejskiego i
                Rady (UE) 2019/1150 z dnia 20 czerwca 2019 r. w sprawie propagowania sprawiedliwości
                i przejrzystości dla użytkowników biznesowych korzystających z usług pośrednictwa
                internetowego;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                funkcjonowania serwisu, które wpływa na możliwość korzystania z oferowanych w nim
                usług;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                podejmowanych przez operatora serwisu środków, które wpływają na sytuację
                użytkownika, w tym blokowanie konta lub opublikowanej przez użytkownika treści.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Do składania i rozpatrywania skarg znajdują odpowiednie zastosowanie postanowienia
            dotyczące reklamacji.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Użytkownik biznesowy może zawnioskować o skorzystanie z mediacji w celu rozwiązania
            sporów z operatorem serwisu w związku ze świadczonymi usługami, w tym sporów, które mogą
            być przedmiotem skargi. Użytkownik biznesowy może zaproponować osobę mediatora.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Korzystanie z mediacji jest dobrowolne. Każda ze stron ma prawo odmowy udziału w
            mediacji lub przerwania mediacji.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        <strong>X. Pozasądowe rozstrzyganie sporów</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Konsument może skorzystać z pozasądowych sposobów rozpatrywania sporów konsumenckich,
            zgodnie z procedurą Internetowego Rozstrzygania Sporów opracowaną przez Komisję
            Europejską, która jest dostępna na stronie internetowej:{' '}
            <S.Link
              href={'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=PL'}
            >
              https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=PL
            </S.Link>
            .
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Konsument ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i
            dochodzenia roszczeń. Szczegółowe informacje dotyczące możliwości skorzystania przez
            konsumenta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz
            zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych
            powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których
            zadań statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów Inspekcji
            Handlowej oraz pod następującymi adresami stron internetowych Urzędu Ochrony Konkurencji
            i Konsumentów:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                <S.Link href={'http://www.uokik.gov.pl/spory_konsumenckie.php'}>
                  http://www.uokik.gov.pl/spory_konsumenckie.php
                </S.Link>
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                <S.Link href={'http://polubowne.uokik.gov.pl/rejestr,5,pl.html'}>
                  http://polubowne.uokik.gov.pl/rejestr,5,pl.html
                </S.Link>
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                w Urzędzie Ochrony Konkurencji i Konsumentów działa punkt kontaktowy, którego dane
                można znaleźć na stronie{' '}
                <S.Link href={'https://uokik.gov.pl/aktualnosci.php'}>UOKiK</S.Link>
              </S.Paragraph>
            </li>
          </ol>
        </li>
      </ol>
      <S.Paragraph>
        <strong>XI. Postanowienia końcowe</strong>
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Operator serwisu może zmienić regulamin. Zmiana regulaminu może nastąpić w szczególności
            z następujących przyczyn:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>potrzeba poprawy bezpieczeństwa,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>potrzeba usprawnienia działania serwisu,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                konieczność przeciwdziałania nadużyciom, które mają związek korzystaniem z serwisu,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>wprowadzenie nowej usługi,</S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                zmiana okoliczności, która ma wpływ na możliwość świadczenia usług przez operatora
                serwisu, w tym zmiana powszechnie obowiązujących przepisów prawa.
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Jeśli zmiany będą wiązały się z dodatkowymi opłatami, zmianą warunków świadczenia lub
            likwidacją danej usługi odpłatnej, będą dobrowolne i użytkownik będzie miał możliwość
            korzystania z wcześniej (przed wejściem w życie zmian) wykupionych usług odpłatnych na
            zasadach sprzed zmiany lub wypowiedzenia umowy w przypadku pozostałych usług. Tak samo
            jeśli zmiany będą wiązały się z istotnym pogorszeniem dostępu do dowolnej usługi.
            Ponadto konsument może wypowiedzieć umowę usługi w ciągu 30 dni od dnia poinformowania
            go o tej zmianie.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu poinformuje o zmianie na stronie serwisu oraz drogą elektroniczną,
            przekazując informację na trwałym nośniku informacji.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Zmiany wchodzą w życie w terminie wskazanym w ogłoszeniu, nie krótszym niż 15 dni od
            poinformowania użytkownika o zmianie regulaminu, chyba że przepisy prawa stanowią
            inaczej.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu może wprowadzić zmianę regulaminu bez zachowania terminu 15 dni, o
            którym mowa w postanowieniu powyżej, w tym ze skutkiem natychmiastowym, w przypadku,
            gdy:{' '}
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                podlega obowiązkowi prawnemu lub regulacyjnemu, a zmiana regulaminu z zachowaniem
                wskazanego terminu jest niemożliwa;
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                musi przeciwdziałać zagrożeniu związanemu z ochroną usług pośrednictwa internetowego
                i użytkowników przed oszustwami, niechcianą korespondencją elektroniczną, złośliwym
                oprogramowaniem, naruszeniem ochrony danych osobowych itp.{' '}
              </S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Operator serwisu wydłuży termin wejścia w życie zmiany regulaminu, jeśli jest to
            konieczne, aby umożliwić użytkownikom biznesowym wprowadzenie dostosowań technicznych
            lub handlowych w celu dostosowania się do zmian.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            O ile bezwzględnie obowiązujące przepisy prawa nie stanowią inaczej, prawem właściwym
            dla umów pomiędzy użytkownikiem a operatorem jest prawo polskie. Wybór prawa polskiego
            nie pozbawia konsumenta ochrony przyznanej mu na podstawie przepisów, których nie można
            wyłączyć w drodze umowy, na mocy prawa jakie byłoby właściwe w braku wyboru prawa.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>Załączniki stanowią integralną część regulaminu.</S.Paragraph>
        </li>
      </ol>
    </S.Wrapper>
  </ContactPageWrapper>
);

export default RegulationsPage;

import { FormEvent, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserWatchedOffers, userData } from '../../../redux/auth/selectors';
import { IOffer } from '../../../redux/offer/types';
import formatNumber from '../../../utils/formatNumber';
import logo from '../../../assets/malutcyLogo.svg';
import moment from 'moment';
import getCategory from '../../../utils/getCategory';
import { FormatPrice } from '../../../utils/formatPrice';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import HeartIconFull from '../../../assets/heartIconFull.svg';
import HeartIcon from '../../../assets/heartIcon.svg';
import { toast } from 'react-toastify';
import * as S from './OfferRow.styles';
import * as api from '../../../api/common-api';
import { useHistory } from 'react-router-dom';

const OfferRow = ({
  categories,
  title,
  price,
  created_at,
  city,
  images,
  id,
  commitmentForm,
  subscriptionOptions = [],
  offererEmail,
  isWatched,
}: IOffer) => {
  const history = useHistory();
  const { email } = useSelector(userData);
  const isOwnOffer = offererEmail === email;
  const mainPhoto = images && images.find(({ order }) => order === 0);
  const photoHref = mainPhoto ? mainPhoto.href : logo;
  const newPrice = FormatPrice(price);
  const formattedPrice = formatNumber(newPrice);

  const [isLoading, setIsLoading] = useState(false);
  const [isOfferWatched, setIsOfferWatched] = useState(isWatched || false);

  moment.locale('pl');
  const date = moment(created_at).fromNow(true);

  const handleObserve = async (e: FormEvent) => {
    e.preventDefault();
    if (!id || isLoading) {
      return null;
    }
    setIsLoading(true);
    await api
      .toggleWatchedOffer(id)
      .then(({ isAdded }) => {
        toast.success(`Oferta ${isAdded ? 'dodana do' : 'usunięta z'} obserwowanych`);
        setIsOfferWatched(isAdded ? true : false);
      })
      .catch((e) => {
        toast.info('Zaloguj się aby zaobserwować ofertę');
        setIsLoading(false);
        history.push('/login');
      });
    setIsLoading(false);
  };

  const { label } = getCategory(categories);

  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  return (
    <S.Wrapper role={'article'} to={`/offer/${id}`}>
      <S.Image alt={title} src={photoHref} />
      <S.DataWrapper>
        <S.Row>
          <S.Category>{label}</S.Category>
        </S.Row>
        <S.Title>{title}</S.Title>
        <S.Row>
          <S.LocalizationWrapper>
            <S.LocationIcon />
            <S.City>{city}</S.City>
          </S.LocalizationWrapper>
          <S.Separator />
          <S.Date>{date}</S.Date>
        </S.Row>
        <S.Row>
          <S.Price>{finalPrice}</S.Price>
        </S.Row>
      </S.DataWrapper>
      {!isOwnOffer && (
        <S.StarWrapper onClick={handleObserve}>
          {isOfferWatched ? <S.StyledIcon src={HeartIconFull} /> : <S.StyledIcon src={HeartIcon} />}
          <S.StarLabel>{isOfferWatched ? 'Nie obserwuj' : 'Obserwuj'}</S.StarLabel>
        </S.StarWrapper>
      )}
    </S.Wrapper>
  );
};

export default memo(OfferRow);

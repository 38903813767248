import { IOffer } from '../../../redux/offer/types';
import formatNumber from '../../../utils/formatNumber';
import Logo from '../../../assets/malutcyLogo.svg';
import moment from 'moment';
import { FormatPrice } from '../../../utils/formatPrice';
import { GetFinalPrice } from '../../../utils/GetFinalPrice';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import * as S from '../../UserDashboard/OfferItemMobile/OfferItemMobile.style';
import * as api from '../../../api/common-api';

const OfferItemMobile = ({
  offer,
  isFirst,
  onChange,
}: {
  offer: IOffer;
  isFirst: boolean;
  onChange: () => Promise<void>;
}) => {
  const {
    title,
    created_at,
    price,
    offererPhone,
    offererEmail,
    expiresAt,
    id,
    subscriptionOptions = [],
    commitmentForm,
  } = offer;
  const [isLoading, setIsLoading] = useState(false);
  const dateFrom = moment(created_at).format('DD.MM');
  const dateTo = moment(expiresAt).format('DD.MM');
  const newPrice = FormatPrice(price);
  const formattedPrice = formatNumber(newPrice);
  const image = offer.images.find(({ order }) => order === 0);

  const handleAccept = async () => {
    setIsLoading(true);
    await api
      .acceptOffers([id])
      .then(() => toast.info('Oferta zaakceptowana'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    onChange();
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await api
      .deleteOffer([id])
      .then(() => toast.info('Oferta usunięta'))
      .catch(() => {
        toast.warn('Wystąpił błąd');
      });
    onChange();
    setIsLoading(false);
  };

  const finalPrice = GetFinalPrice({ subscriptionOptions, formattedPrice, commitmentForm });

  return (
    <S.Wrapper isFirst={isFirst}>
      <S.MobileBody>
        <S.MobileColumn>
          {image ? (
            <S.Image src={image.href} alt={`${title} image`} />
          ) : (
            <S.Image src={Logo} alt={`${title} image`} />
          )}
          <Button
            type="primary"
            onClick={handleAccept}
            loading={isLoading}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: 96,
              height: 26,
              borderRadius: 8,
              fontSize: 14,
              marginBottom: 6,
            }}
          >
            Zaakceptuj
          </Button>
          <Button
            type="default"
            danger
            onClick={handleDelete}
            loading={isLoading}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Poppins',
              fontWeight: 600,
              width: 96,
              height: 26,
              borderRadius: 8,
              fontSize: 14,
            }}
          >
            Usuń
          </Button>
        </S.MobileColumn>
        <S.DataWrapper>
          <S.Title to={`/offer/${id}`}>{title}</S.Title>
          <S.DateWrapper>
            <S.DataRow>od {dateFrom}</S.DataRow>
            <S.DataRow>do {dateTo}</S.DataRow>
          </S.DateWrapper>
          <S.PriceWrapper>
            <S.DataRow>{finalPrice}</S.DataRow>
          </S.PriceWrapper>
          <S.DisplaysWrapper>
            <S.Section>
              <S.MobileIcon />
              <S.GrayDataRow>
                <S.Label>tel:</S.Label> <S.Value>{offererPhone}</S.Value>
              </S.GrayDataRow>
            </S.Section>
            <S.Section>
              <S.EnvelopeIcon />
              <S.GrayDataRow>
                <S.Label>e-mail:</S.Label> <S.Value>{offererEmail}</S.Value>
              </S.GrayDataRow>
            </S.Section>
          </S.DisplaysWrapper>
        </S.DataWrapper>
      </S.MobileBody>
    </S.Wrapper>
  );
};

export default OfferItemMobile;

import SearchFields from '../SearchFields/SearchFields';
import imgLeft1 from '../../assets/SearchSection/left1.svg';
import imgLeft2 from '../../assets/SearchSection/left2.svg';
import leftPerson from '../../assets/SearchSection/leftPerson.svg';
import imgRight1 from '../../assets/SearchSection/right1.svg';
import imgRight2 from '../../assets/SearchSection/right2.svg';
import rightPerson from '../../assets/SearchSection/rightPerson.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { OFFER_TYPE } from '../../redux/offer/types';
import * as S from './SearchSection.styles';

interface ISearchSectionProps {
  selectedType?: string;
  onChangeType: any;
}

const SearchSection = (props: ISearchSectionProps) => {
  const { selectedType, onChangeType } = props;

  const isDesktop = useMediaQuery('(min-width: 1250px)');
  const isTablet = useMediaQuery('(min-width: 768px)');
  const isBelowTablet = useMediaQuery('(max-width: 768px)');

  return (
    <S.Wrapper>
      {isTablet && (
        <>
          <S.LeftOneImageContainer>
            <S.AbsoluteImage src={imgLeft1} />
          </S.LeftOneImageContainer>
          <S.LeftTwoImageContainer>
            <S.AbsoluteImage src={imgLeft2} />
          </S.LeftTwoImageContainer>
        </>
      )}
      <S.LeftPersonContainer isFixed={isBelowTablet}>
        <S.AbsoluteImage src={leftPerson} isSmall={!isDesktop} />
      </S.LeftPersonContainer>
      <S.InnerWrapper>
        {/* {isDesktop && (
          <S.BackgroundContainer>
            <S.Background src={background} />
          </S.BackgroundContainer>
        )} */}
        <S.HeaderWrapper>
          <S.Header>
            Platforma stworzona z myślą o Tobie i Twoim dziecku!
            {/* Ponad{' '}
            <S.UnderlinedText> 2 000 zajęć</S.UnderlinedText> w ponad{' '}
            <S.UnderlinedText>30 miastach</S.UnderlinedText> */}
          </S.Header>
          <S.SubHeader>Darmowy serwis ogłoszeń lokalnych i ogólnopolskich</S.SubHeader>
        </S.HeaderWrapper>
        <S.WrapperContainer>
          <S.CategorySearchButtonsContainer>
            <S.CategoryButton
              isSelected={selectedType === OFFER_TYPE.ANNOUNCEMENT}
              onClick={() => onChangeType(OFFER_TYPE.ANNOUNCEMENT)}
            >
              Zajęcia
            </S.CategoryButton>
            <S.CategoryButton
              isSelected={selectedType === OFFER_TYPE.BAZAAR}
              onClick={() => onChangeType(OFFER_TYPE.BAZAAR)}
            >
              Bazarek
            </S.CategoryButton>
          </S.CategorySearchButtonsContainer>
          <S.SearchWrapper>
            <SearchFields onSetSelectedOfferType={selectedType} />
            <div
              style={{
                fontSize: '12px',
                textAlign: 'center',
                marginTop: '10px',
              }}
            >
              Brakuje na liście Twojego miasta? Dodaj ogłoszenie i pomóż rozwijać społeczność
              Malutcy.pl
            </div>
          </S.SearchWrapper>
        </S.WrapperContainer>

        {/* <S.CountersWrapper>
        <S.CounterWrapper>
          <S.LeftCounter>+ 1000</S.LeftCounter>
          <S.LeftCounterDescription>Aktywnych ogłoszeń</S.LeftCounterDescription>
        </S.CounterWrapper>
        <S.Spacer />
        <S.CounterWrapper>
          <S.Counter>+ 2000</S.Counter>
          <S.CounterDescription>Zakończonych sprzedażą</S.CounterDescription>
        </S.CounterWrapper>
      </S.CountersWrapper> */}
      </S.InnerWrapper>
      <>
        <S.RightOneImageContainer>
          <S.AbsoluteImage src={imgRight1} />
        </S.RightOneImageContainer>
        <S.RightTwoImageContainer>
          <S.AbsoluteImage src={imgRight2} />
        </S.RightTwoImageContainer>
      </>
      <S.RightPersonContainer isFixed={isBelowTablet}>
        <S.AbsoluteImage src={rightPerson} isSmall={!isDesktop} />
      </S.RightPersonContainer>
      {/* )} */}
    </S.Wrapper>
  );
};

export default SearchSection;

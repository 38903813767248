import ContactData from '../ContactData/ContactData';
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../../../redux/auth/selectors';
import Modal from '../../Modals/Modal/Modal';
import { useState } from 'react';
import DeleteAccountComponent from '../../DeleteAccountComponent/DeleteAccountComponent';
import SegmentWrapper from '../SegmentWrapper/SegmentWrapper';
import SubmitButton from '../../LoginPage/SubmitButton/SubmitButton';
import { initDeleteAccount, initPasswordChange } from '../../../redux/auth/actions';
import * as S from './SettingsPage.styles';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const { nickname } = useSelector(userData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoadingChangePassowrd, setIsLoadingPassowrdChange] = useState(false);

  const handleInitPassowordChange = () => {
    setIsLoadingPassowrdChange(true);
    dispatch(initPasswordChange());
    setIsLoadingPassowrdChange(false);
  };

  const handleInitDeleteAccount = () => {
    dispatch(initDeleteAccount());
    setIsModalOpen(false);
  };

  return (
    <S.Wrapper>
      <S.Header>Ustawienia</S.Header>
      {!nickname && (
        <S.WarningWrapper>
          <S.WarningContainer>
            <S.StyledIcon />
            <S.WarningText>Aby dodać ogłoszenie uzupełnij wymagane pola w profilu</S.WarningText>
          </S.WarningContainer>
        </S.WarningWrapper>
      )}
      <ContactData />
      <SegmentWrapper title="Zmiana hasła" icon="password">
        <S.ChangePasswordButtonContainer>
          <SubmitButton
            type="button"
            text={'Zmien hasło'}
            isLoading={isLoadingChangePassowrd}
            onClick={() => handleInitPassowordChange()}
          />
        </S.ChangePasswordButtonContainer>
      </SegmentWrapper>
      <S.DeleteAccountButtonWrapper>
        <S.DeleteAccoutButton onClick={() => setIsModalOpen(!isModalOpen)}>
          Usuń konto
        </S.DeleteAccoutButton>
      </S.DeleteAccountButtonWrapper>
      {isModalOpen && (
        <Modal
          child={
            <DeleteAccountComponent
              cancel={() => setIsModalOpen(false)}
              action={() => handleInitDeleteAccount()}
            />
          }
          onClick={() => setIsModalOpen(false)}
          isCloseShown={false}
          title="Czy na pewno chcesz usunąć konto?"
        />
      )}
    </S.Wrapper>
  );
};

export default SettingsPage;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  max-width: 400px;
  flex-grow: 1;
  min-height: 300px;
  margin-bottom: 28px;
  min-width: 280px;
  @media (min-width: 500px) {
    min-width: 324px;
  }
`;

export const Header = styled.h2`
  margin: 0 0 4px 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 20px;
  color: ${themes.default.reddishGrey};
`;

export const Icon = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 4px;
`;

interface CustomLinkProps {
  isActive?: boolean;
}

export const CustomLink = styled(Link)<CustomLinkProps>`
  display: block;
  font-family: Poppins;
  font-weight: ${(props) => (props.isActive ? '600' : '400')};
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.reddishGrey};
  text-decoration: none;
  margin-bottom: 4px;
  :visited {
    color: ${themes.default.reddishGrey};
  }
`;

import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { loginWithGoogle } from '../../../redux/auth/actions';

const ContinueWithGoogleButton = ({ action, width }: { action: string; width: number }) => {
  const dispatch = useDispatch();

  const responseGoogle = async (response: any) => {
    const userObject: any = jwt_decode(response.credential);
    localStorage.setItem('user', JSON.stringify(userObject));
    await dispatch(loginWithGoogle({ id_token: response.credential }));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <GoogleLogin
        onSuccess={responseGoogle}
        onError={() => {
          console.log('Login Failed');
        }}
        width={width}
      />
    </div>
  );
};

export default ContinueWithGoogleButton;

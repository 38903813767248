import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { ICategory } from '../../../redux/utils/types';
import * as S from './Breadcrumb.styles';

export default function Breadcrumb({ categories }: { categories: ICategory[] }) {
  const history = useHistory();
  const sorted = categories; //.sort(({ id: aId }, { id: bId }) => aId - bId);
  const goToCategory = (id: string) => history.push(`/search?categoryId=${id}`);
  return (
    <S.Wrapper>
      <S.BackButton onClick={() => history.goBack()}>
        <S.BackArrow />
        <S.BackText>Wróć</S.BackText>
      </S.BackButton>
      <S.Text onClick={() => history.push('/search')}>Strana główna</S.Text>
      <S.ForwardArrow />
      {sorted.map(({ id, label }, index: number) => {
        if (index === categories.length - 1) {
          return (
            <Fragment key={id}>
              <S.Text onClick={() => goToCategory(id)}>{label}</S.Text>
            </Fragment>
          );
        }
        return (
          <Fragment key={id}>
            <S.ShadowText onClick={() => goToCategory(id)}>{label}</S.ShadowText>
            <S.ForwardArrow />
          </Fragment>
        );
      })}
    </S.Wrapper>
  );
}

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { Input } from 'antd';

export const Wrapper = styled.div`
  max-width: 730px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${themes.default.greyBackgroundColor};
  margin-bottom: 47px;
`;

export const Row = styled.div<{ customWidth?: string }>`
  display: flex;
  width: ${({ customWidth }) => (customWidth ? customWidth : '100%')};
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Title = styled.h3`
  padding: 0;
  margin: 0;
  font-family: Poppins;
  font-weight: 600;
  font-size: 26px;
  line-height: 154%;
  letter-spacing: -0.4px;
  color: ${themes.default.graphemeGrey};
  margin-bottom: 46px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 32px;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: #373f41;
  @media (min-width: 340px) {
    white-space: nowrap;
  }
`;

export const SimpleInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledLabel = styled.span`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  color: ${themes.default.graphemeGrey};
  display: flex;
`;

export const StyledValue = styled.span`
  font-family: Poppins;
`;

export const LocalizationContainer = styled.div`
  max-width: 308px;
  width: 100%;
  @media (min-width: 800px) {
    max-width: 345px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  max-width: 344px;
`;

export const StyledLabel2 = styled.label`
  color: #11244d;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const RedStar = styled.span`
  color: red;
`;

export const StyledInput = styled(Input)`
  padding: 14px 20px;
`;

import FAQSearch from '../FAQSearch/FAQSearch';
import * as S from './FAQPageWrapper.styles';

const FAQPageWrapper = (props: any) => (
  <>
    <FAQSearch />
    <S.BodyWrapper>{props.children}</S.BodyWrapper>
  </>
);

export default FAQPageWrapper;

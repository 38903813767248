import styled from 'styled-components';
import CSS from '../../../assets/CSS/commonStyles';
import SVG from '../../../assets/assets-exporter';
import { ImWhatsapp } from 'react-icons/im';

export const Button = styled.button`
  ${CSS.plainButton}/* margin: 0 4px 0 8px; */
`;

export const ShareDiv = styled.div`
  margin: 0 4px 0 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;
export const WhatsAppButton = styled.button`
  ${CSS.plainButton}/* margin: 0 4px 0 0; */
  /* padding-left: 2px; */
`;

export const FBIcon = styled.img.attrs({ src: SVG.fbGreyIcon, alt: 'facebook' })`
  height: 24px;
  width: 24px;
`;

export const MailIcon = styled.img.attrs({ src: SVG.mailGreyIcon, alt: 'mail' })`
  height: 28px;
  width: 28px;
`;

export const Whatsapp = styled(ImWhatsapp)`
  height: 22px;
  width: 22px;
  color: #bdbdbd;
`;

import styled from 'styled-components';
import { AiOutlineStar, AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import CheckIco from '../../../assets/check.svg';
import EditIco from '../../../assets/edit.svg';
import MobileIco from '../../../assets/mobile.svg';
import MailIco from '../../../assets/mailGreyIcon.svg';

export const Wrapper = styled.article<{ isFirst: boolean }>`
  width: 100%;
  min-height: 98px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e5e5e5;
  ${({ isFirst }) => isFirst && `border-top:  none;`}
  display: flex;
  padding: 13px 22px;
`;

export const BodyWrapper = styled.div`
  width: 100%;
`;

export const MobileBody = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Image = styled.img`
  width: 105px;
  min-width: 105px;
  height: 70px;
  margin-right: 23px;
  object-fit: cover;
`;

export const MockImage = styled(Image)`
  object-fit: contain;
`;

export const Title = styled(Link)`
  margin: 0;
  padding: 0 8px 0 0;
  box-sizing: border-box;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.1px;
  color: #373f41;
  flex-grow: 2;
  flex-basis: 100px;
  transition: 250ms;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const DateWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 50px;
`;

export const PriceWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 50px;
`;

export const DisplaysWrapper = styled.div`
  width: 120px;
  padding: 0 20px 0 0;
  box-sizing: border-box;
`;

export const DataRow = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #373f41;
`;

export const GrayDataRow = styled.a`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :visited {
    color: #828282;
  }
`;

export const MobileIcon = styled.img.attrs({ src: MobileIco, alt: 'mobile phone icon' })`
  width: 16px;
  min-width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: left;
  margin-right: 5px;
  margin-left: -1px;
  color: #828282;
`;

export const EnvelopeIcon = styled.img.attrs({ src: MailIco, alt: 'mail icon' })`
  width: 14px;
  height: 14px;
  min-width: 14px;
  object-fit: contain;
  margin-right: 4px;
  color: #828282;
`;

export const WatchersWrapper = styled.div`
  min-width: 296px;
  width: 100%;
  height: 26px;
  box-sizing: border-box;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
`;

export const StarIcon = styled(AiOutlineStar)`
  width: 15px;
  min-width: 15px;
  height: 15px;
  color: #373f41;
  margin-right: 8px;
`;

export const WatchersCounter = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  letter-spacing: 0.1px;
  color: #828282;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const TextSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

export const PlainButton = styled.button`
  display: flex;
  background: none;
  border: none;
  margin: 0 0 0 4px;
  :focus {
    outline: none;
  }
`;

export const ButtonText = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 145%;
  text-align: right;
  letter-spacing: 0.1px;
  color: rgba(75, 65, 196, 1);
`;

export const DeleteButtonText = styled(ButtonText)`
  color: #b70000;
`;

export const EditIcon = styled.img.attrs({ src: EditIco, alt: 'edit icon' })`
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin: auto 4px auto 0;
  color: rgba(75, 65, 196, 1);
`;

export const FinishIcon = styled.img.attrs({ src: CheckIco, alt: 'check icon' })`
  width: 11px;
  min-width: 11px;
  height: 11px;
  margin: auto 2px auto 0;
  color: rgba(75, 65, 196, 1);
`;

export const DisplayedContainer = styled.div`
  display: flex;
`;
export const EyeIcon = styled(AiOutlineEye)`
  width: 15px;
  min-width: 15px;
  height: 15px;
  color: #373f41;
  margin-right: 8px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

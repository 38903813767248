import { combineReducers, createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import AuthReducer from './auth/authReducer';

import { AuthReducerState } from './auth/types';

const rootReducer = combineReducers({
  auth: AuthReducer,
});

export interface AppState {
  auth: AuthReducerState;
}

const Store = (preloadedState: AppState) =>
  process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware))
    : createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware, logger));

export default Store;

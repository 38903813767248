import { Select } from 'antd';
import * as S from './Filter.styles';
import { useEffect, useState } from 'react';

interface IFilter {
  label: string;
  options: any[];
  filterData: any;
  setFilterData: any;
  onResetFilter: () => void;
}

const Filter = (props: IFilter) => {
  const { label, options, filterData, setFilterData } = props;

  const selectOptions = options.map((o) => ({
    label: o.label,
    value: o.id,
  }));

  const [value, setValue] = useState<any>([]);

  const handleChange = (e: any) => {
    setValue(e);
    const filterIncluded = filterData.find((x: any) => x.label === label);
    if (!filterIncluded) {
      setFilterData((p: any) => [...p, { label, options: e }]);
    } else {
      setFilterData((p: any) => [
        ...p.map((s: any) => (s.label === label ? { ...s, options: e } : s)),
      ]);
    }
    return;
  };

  const resetData = () =>
    setFilterData(filterData.filter((p: any) => !value.some((v: any) => v === p.options)));

  return (
    <S.Wrapper>
      <S.LabelContainer>
        <S.StyledLabel>{label}</S.StyledLabel>
      </S.LabelContainer>
      <Select
        allowClear
        showSearch
        options={selectOptions}
        popupMatchSelectWidth={false}
        placeholder="Wybierz"
        onChange={(e: any) => {
          handleChange(e);
        }}
        value={value}
        mode="multiple"
        style={{ width: '100%' }}
        onClear={() => resetData()}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
    </S.Wrapper>
  );
};

export default Filter;

import { FiUser } from 'react-icons/fi';
import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

export const Wrapper = styled.div<{ isHost: boolean }>`
  display: flex;
  max-width: 695px;
  margin: ${(props) => (props.isHost ? '0 0 16px auto' : '0 auto 16px 0')};
  flex-direction: ${(props) => (props.isHost ? 'row-reverse' : 'row')};
`;

export const Avatar = styled.img<{ isHost: boolean }>`
  min-width: 26px;
  height: 26px;
  border-radius: 100%;
  margin: ${({ isHost }) => (isHost ? `auto 0 auto 26px` : `auto 26px auto 0`)};
  object-fit: cover;
  object-position: center;
  width: 26px;
  cursor: ${(props) => (props.isHost ? 'default' : 'pointer')};
`;

export const Person = styled(FiUser)<{ isHost: boolean }>`
  min-width: 26px;
  height: 26px;
  border-radius: 100%;
  margin-right: ${(props) => (props.isHost ? '0' : '27px')};
  margin-left: ${(props) => (props.isHost ? '27px' : '0')};
  margin-top: 32px;
  object-fit: cover;
  object-position: center;
  width: 26px;
  color: ${themes.default.white};
  background-color: ${themes.default.main};
  padding: 4px;
  box-sizing: border-box;
  cursor: ${(props) => (props.isHost ? 'default' : 'pointer')};
`;

export const MessageWrapper = styled.div<{ isHost: boolean }>`
  flex-grow: 1;
  margin: ${(props) => (props.isHost ? '0 0 16px auto' : '0')};
`;

export const Date = styled.div`
  font-family: Poppins;
  font-size: 13px;
  line-height: 154%;
  text-align: right;
  color: #bdbdbd;
  margin-left: auto;
`;

export const Message = styled.div<{ isHost: boolean }>`
  background: #fafafa;
  border-radius: 10px;
  padding: 16px;
  @media (min-width: 801px) {
    padding: 14px;
  }
  width: 100%;
  max-width: 645px
  height: auto;
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
  color: #2c2c2c;
  box-sizing: border-box;
  background-color: ${(props) => (props.isHost ? '#dfe8fc' : '#fafafa')};
`;

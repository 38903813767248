import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';

interface StyleProps {
  isActive: boolean;
}

export const Wrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  transition: background 200ms;
  border-radius: 16px;
  background-color: transparent;
  width: 120px;
  height: 120px;
  @media (min-width: 1600px) {
    width: 150px;
    height: 150px;
  }
`;

export const Icon = styled.img<{ isActive: boolean }>`
  width: 48px;
  height: 48px;
  filter: ${({ isActive }) =>
    isActive
      ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(201deg) brightness(101%) contrast(101%)'
      : 'invert(23%) sepia(92%) saturate(2281%) hue-rotate(238deg) brightness(80%) contrast(90%)'};
  @media (min-width: 1600px) {
    width: 54px;
    height: 54px;
  }
`;

export const Label = styled.label<StyleProps>`
  display: flex;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: ${(props: StyleProps) => (props.isActive ? themes.default.white : themes.default.main)};
  @media (min-width: 1600px) {
    font-size: 16px;
  }
`;

export const InnerContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  transition: background 250ms;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  @media (min-width: 1600px) {
    width: 150px;
    height: 150px;
  }
  gap: 8px;
  background-color: ${({ isActive }) => (isActive ? themes.default.main : 'transparent')};
`;

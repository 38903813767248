import SearchResult from '../SearchResult/SearchResult';
import { IFaqCategory } from '../faqData';
import * as S from './SearchResultsWrapper.styles';

const SearchResultsWrapper = ({
  query,
  results,
  resultsCount,
}: {
  query: string;
  resultsCount: number;
  results: IFaqCategory[];
}) => (
  <S.Wrapper>
    <S.SearchHeader>
      Wyniki dla szukanego hasła <b>„{query}”</b>: {resultsCount}
    </S.SearchHeader>
    {results.map(({ articles, title, icon }) => (
      <>
        {articles.map(({ id: articleId, title: articleTitle }) => (
          <SearchResult
            link={`/faq/${articleId}`}
            key={articleId}
            title={articleTitle}
            category={`Malutcy.pl \\ ${title} \\ ${articleTitle}`}
            icon={icon}
          />
        ))}
      </>
    ))}
  </S.Wrapper>
);

export default SearchResultsWrapper;

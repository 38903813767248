import styled from 'styled-components';
import WaveIcon from '../../assets/Waves/wave-yellow.svg';

export const Wrapper = styled.div``;

export const Disclaimer = styled.div`
  font-family: Poppins;
  font-size: 13px;
  line-height: 169%;
  width: 100%;
  max-width: 1114px;
  text-align: left;
  color: #828282;
  margin: auto;
  padding: 75px 18px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 45px auto;
  }
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(254, 249, 235, 1);
`;

export const WaveContainer = styled.div<{ isRotated: boolean }>`
  width: 100%;
  height: 20px;
  background-image: url(${WaveIcon});
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center center;
  transform: ${({ isRotated }) => (isRotated ? 'rotate(180deg)' : 'none')};
`;

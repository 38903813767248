import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 400px; */
  gap: 12px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  max-width: 300px;
`;

export const StyledHeader = styled.h3`
  margin: 0 0 8px 0;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
`;

export const NewCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLabel = styled.label``;

export const StyledInput = styled.input`
  padding: 5px 11px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
`;

export const StyledFileInput = styled.input``;

export const AddButton = styled.button`
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background-color: rgba(75, 65, 196, 1);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
  &:hover {
    box-shadow: 0 0 4px rgba(75, 65, 196, 1);
  }
`;

export const TableContainer = styled.div`
  width: 100%;
`;

import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCategoryParameters, getEditOfferDetails } from '../../../api/common-api';
import { OFFER_TYPE, emptyOffer } from '../../../redux/offer/types';
import CustomCheckbox from '../../AddOffer/CustomCheckbox/CustomCheckbox';
import {
  IBenefit,
  IFaq,
  IInterval,
  selectSubscriptionOptions,
} from '../../AddOffer/PageOne/PageOne';
import IntervalRow from '../../AddOffer/PageOne/IntervalRow';
import { v4 as uuidv4 } from 'uuid';
import FaqRow from '../../AddOffer/PageOne/FaqRow';
import BenefitRow from '../../AddOffer/PageOne/BenefitRow';
import { FormatPrice } from '../../../utils/formatPrice';
import Spinner from '../../Spinner/Spinner';
import PhotosUpload from '../../AddOffer/PhotosUpload/PhotosUpload';
import Stars1 from '../../../assets/OfferPage/stars-1.svg';
import Stars2 from '../../../assets/OfferPage/stars-2.svg';
import { toast } from 'react-toastify';
import * as api from '../../../api/common-api';
import * as S from './OfferEdit.styles';

interface IExtendedInterval extends IInterval {
  isNew?: boolean;
  isChanged?: boolean;
  isDeleted?: boolean;
}

interface IExtendedFaq extends IFaq {
  isNew?: boolean;
  isChanged?: boolean;
  isDeleted?: boolean;
}

interface IExtendedBenefit extends IBenefit {
  isNew?: boolean;
  isChanged?: boolean;
  isDeleted?: boolean;
}

const OfferEdit = () => {
  const history = useHistory();
  const params: any = useParams();
  const [data, setData] = useState(emptyOffer);
  const [isLoading, setIsLoading] = useState(false);
  const [availableParameters, setAvailableParameters] = useState<any[]>([]);

  const { id } = params;

  const getData = async () => {
    const res = await getEditOfferDetails(id);
    if (res.error) {
      toast.warning(res.message);
      return history.push('/');
    }
    const { details } = res;
    const formattedPrice = details.price / 100;
    const formattedSubscriptionOptions = details?.subscriptionOptions.map((i: any) => ({
      ...i,
      price: FormatPrice(i.price),
    }));
    setData({
      ...details,
      price: formattedPrice,
      subscriptionOptions: formattedSubscriptionOptions,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    if (!id) {
      return;
    }
    getData();
    setIsLoading(false);
  }, [id]);

  const {
    offerType,
    title,
    categories,
    description,
    price,
    ageFrom,
    ageTo,
    giveaway,
    subscriptionOptions = [],
    faqs = [],
    benefits = [],
    dynamicOptions = [],
    images = [],
  } = data;

  const isAnnouncement = offerType === OFFER_TYPE.ANNOUNCEMENT;
  const offerTypeLabel = isAnnouncement ? 'Zajęcia' : 'Bazarek';
  const categoryLabel = (categories.length > 0 && categories[categories.length - 1].label) || '';

  const handleUpdate = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    const filesMap = photos.map(({ order, file }) => ({ order, fileName: file.name })); /// TODO: CHECK IF REALLY NEEDED
    const data = {
      id,
      title,
      description,
      price: giveaway ? 0 : price * 100,
      giveaway: giveaway || price === 0,
      ageFrom,
      ageTo,
      subscriptionOptions,
      faqs,
      benefits,
      dynamicOptions,
      images,
      filesMap,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify({ ...data }));
    photos.forEach(({ file }: any) => {
      formData.append(file.webkitRelativePath, file);
    });
    await api.updateOffer(id, formData).catch(() => {
      toast.warn('Aktualizacja się nie powiodła');
    });
    toast.success('Zaktualizowano!');
    setIsLoading(false);
    history.push('/user-dashboard/offers');
  };
  const pickedOptions = subscriptionOptions.map((i: any) => i.subscriptionOptions || '');

  const subOptions = selectSubscriptionOptions.filter((s: any) =>
    pickedOptions ? !pickedOptions.includes(s.value) : selectSubscriptionOptions
  );

  const handleAddNewInterval = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      isNew: true,
      commitmentForm: {
        label: '',
        value: '',
      },
      price: 0,
    };
    const updatedRecords = [...subscriptionOptions, newRecord];
    setData({ ...data, subscriptionOptions: updatedRecords });
  };

  const canAddNewInterval = subscriptionOptions && subscriptionOptions.length <= 2;
  const [deletedIntervalRows, setDeletedIntervalRows] = useState<any[]>([]);
  const [deletedFaqsRows, setDeletedFaqsRows] = useState<any[]>([]);
  const [deletedBenefitsRows, setDeletedBenefitsRows] = useState<any[]>([]);
  const [selectedPhotos, setSelectedPhotos] = useState<any[]>([]);
  // const [hoveredPhoto, setHoveredPhoto] = useState<string>('');
  const [photos, setPhotos] = useState<{ order: number; file: any }[]>([]);

  const handleDeleteIntervalRow = (id: string) => {
    if (deletedIntervalRows.includes(id)) {
      setDeletedIntervalRows([...deletedIntervalRows.filter((x) => x !== id)]);
    } else {
      setDeletedIntervalRows([...deletedIntervalRows, id]);
    }
  };

  const renderIntervals = () =>
    subscriptionOptions.map((x: any, index: number) => (
      <S.RowContainer isDeleted={deletedIntervalRows.includes(x.id)}>
        <IntervalRow
          id={x.id}
          commitmentForm={
            selectSubscriptionOptions.find((s) => s.value === x.subscriptionOptions) || {
              value: '',
              label: '',
            }
          }
          price={x.price}
          readOnly={x.isDeleted}
          key={index}
          options={subOptions}
          index={index}
          onDelete={() =>
            subscriptionOptions.map((interval: IExtendedInterval) => {
              if (x.id === interval.id) {
                if (interval.isNew) {
                  const filteredRecords = subscriptionOptions.filter((a) => a.id !== interval.id);
                  setData({ ...data, subscriptionOptions: filteredRecords });
                } else if (interval.isDeleted) {
                  interval.isDeleted = false;
                  handleDeleteIntervalRow(x.id);
                } else {
                  interval.isDeleted = true;
                  handleDeleteIntervalRow(x.id);
                }
              }
            })
          }
          onOptionSelected={(option, itemId) => {
            const subOptions = [...subscriptionOptions];
            subOptions.map((interval: IExtendedInterval) => {
              if (interval.id === itemId) {
                if (interval.isNew) {
                  interval.commitmentForm = option;
                } else {
                  interval.commitmentForm = option;
                  interval.isChanged = true;
                }
              }
            });
            setData({ ...data, subscriptionOptions: subOptions });
          }}
          onPriceUpdate={(price) => {
            const subOptions = [...subscriptionOptions];
            subOptions.map((interval: any) => {
              if (interval.id === x.id) {
                if (interval.isNew) {
                  interval.price = price;
                } else {
                  interval.price = price;
                  interval.isChanged = true;
                }
              }
            });
            setData({ ...data, subscriptionOptions: subOptions });
          }}
        />
      </S.RowContainer>
    ));

  const handleAddNewFaq = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      question: '',
      answer: '',
      isNew: true,
    };
    const updatedRecords = [...faqs, newRecord];
    setData({ ...data, faqs: updatedRecords });
  };

  const handleDeleteFaqRow = (id: string) => {
    if (deletedFaqsRows.includes(id)) {
      setDeletedFaqsRows([...deletedFaqsRows.filter((x) => x !== id)]);
    } else {
      setDeletedFaqsRows([...deletedFaqsRows, id]);
    }
  };

  const handleFaqData = (name: string, value: any, rowId: string) =>
    setData({
      ...data,
      faqs: [...faqs].map((f: any) => {
        if (f.id === rowId) {
          f[name] = value;
          f.isChanged = true;
        }
        return f;
      }),
    });

  const renderFaqs = () =>
    faqs.map((f: any, index: number) => (
      <S.RowContainer isDeleted={deletedFaqsRows.includes(f.id)}>
        <FaqRow
          key={index}
          {...f}
          index={index}
          readOnly={f.isDeleted}
          onDelete={() =>
            faqs.map((faq: IExtendedFaq) => {
              if (f.id === faq.id) {
                if (faq.isNew) {
                  const filteredRecords = faqs.filter((a) => a.id !== faq.id);
                  setData({ ...data, faqs: filteredRecords });
                } else if (faq.isDeleted) {
                  faq.isDeleted = false;
                  handleDeleteFaqRow(f.id);
                } else {
                  faq.isDeleted = true;
                  handleDeleteFaqRow(f.id);
                }
              }
            })
          }
          onChange={(e: any) => handleFaqData(e.target.name, e.target.value, f.id)}
        />
      </S.RowContainer>
    ));

  const handleAddNewBenefit = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      title: '',
      description: '',
      isNew: true,
    };
    const updatedRecords = [...benefits, newRecord];
    setData({ ...data, benefits: updatedRecords });
  };

  const handleDeleteBenefitRow = (id: string) => {
    if (deletedBenefitsRows.includes(id)) {
      setDeletedBenefitsRows([...deletedBenefitsRows.filter((x) => x !== id)]);
    } else {
      setDeletedBenefitsRows([...deletedBenefitsRows, id]);
    }
  };

  const handleBenefitData = (name: string, value: any, rowId: string) =>
    setData({
      ...data,
      benefits: [...benefits].map((f: any) => {
        if (f.id === rowId) {
          f[name] = value;
          f.isChanged = true;
        }
        return f;
      }),
    });

  const renderBenefits = () =>
    benefits.map((f: any, index: number) => (
      <S.RowContainer isDeleted={deletedBenefitsRows.includes(f.id)}>
        <BenefitRow
          key={index}
          {...f}
          index={index}
          readOnly={f.isDeleted}
          onDelete={() =>
            benefits.map((benefit: IExtendedBenefit) => {
              if (f.id === benefit.id) {
                if (benefit.isNew) {
                  const filteredRecords = benefits.filter((a) => a.id !== benefit.id);
                  setData({ ...data, benefits: filteredRecords });
                } else if (benefit.isDeleted) {
                  benefit.isDeleted = false;
                  handleDeleteBenefitRow(f.id);
                } else {
                  benefit.isDeleted = true;
                  handleDeleteBenefitRow(f.id);
                }
              }
            })
          }
          onChange={(e: any) => handleBenefitData(e.target.name, e.target.value, f.id)}
        />
      </S.RowContainer>
    ));

  useEffect(() => {
    const getParams = async () => {
      if (categories.length < 1) {
        return;
      }
      const childCategoryId = categories[categories.length - 1].id;
      const params = await getCategoryParameters(childCategoryId);
      const defaultParamsValues: any[] = [];

      params.map(({ id, type, label, defaultValue, isCheckedByDefault, options, value }: any) => {
        const properDefaultValue =
          type === 'yesNo' && isCheckedByDefault ? isCheckedByDefault : defaultValue;

        const selectDefault = options.find((el: any) =>
          dynamicOptions.some((s) => type === 'select' && s.label === label && s.value === el.label)
        );

        const boolDefault = dynamicOptions.find((s) => type === 'yesNo' && s.label === label);

        const dynamicOption = dynamicOptions.find((el: any) =>
          options.some((s: any) => (type === 'yesNo' ? el.label === label : s.label === el.value))
        );

        const selectDefaultValue = selectDefault?.id || '';
        const dynamicOptionId = dynamicOption?.id || '';
        const boolDefaultValue = boolDefault?.value === 'false' ? false : true;

        defaultParamsValues.push({
          id,
          type,
          value:
            type === 'yesNo'
              ? boolDefaultValue
              : type === 'select'
              ? selectDefaultValue
              : properDefaultValue,
          label,
          dynamicOptionId: dynamicOptionId,
        });
      });
      setData({ ...data, dynamicOptions: defaultParamsValues });
      setAvailableParameters(params);
    };
    getParams();
  }, [categories]);

  const RenderParams = () => {
    return availableParameters.map((param, index) => {
      const getDynamicOptionValue = () =>
        dynamicOptions.find((x: any) => x.id === param.id)?.value || '';
      if (param.type === 'yesNo') {
        return (
          <S.DynamicOptionsWrapper key={index}>
            <S.StyledCheckboxInput
              type="checkbox"
              onClick={() => handleToggleCheckbox(param.id, param.label)}
              checked={getDynamicOptionValue()}
            />
            <S.DynamicOptionlabel isSelect={false}>{param.label}</S.DynamicOptionlabel>
          </S.DynamicOptionsWrapper>
        );
      } else if (param.type === 'text') {
        return (
          <S.DynamicTextWrapper key={index}>
            <S.DynamicOptionlabel isSelect>{param.label}</S.DynamicOptionlabel>
            <S.DynamicTextContainer>
              <input
                style={{
                  display: 'flex',
                  border: '1px solid rgba(226, 232, 240, 1)',
                  borderRadius: '4px',
                  padding: '14px 20px',
                  flex: '1',
                }}
                defaultChecked={false}
                key={param.id}
                required={param.isRequired}
                value={getDynamicOptionValue() || ''}
                onChange={(e: any) =>
                  handleDynamicSelectOptionsData({
                    e,
                    id: param.id,
                    type: param.type,
                    value: e.target.value,
                    label: param.label,
                  })
                }
              />
            </S.DynamicTextContainer>
          </S.DynamicTextWrapper>
        );
      } else if (param.type === 'select') {
        const valueToSearch = getDynamicOptionValue();
        const getDefaultSelectValue = () =>
          param.options.find((x: any) => x.id === valueToSearch)?.id || '';

        const optionValue = getDefaultSelectValue();
        return (
          <S.DynamicTextWrapper key={index}>
            <S.DynamicOptionlabel isSelect>{param.label}</S.DynamicOptionlabel>
            <S.DynamicInputContainer>
              <select
                style={{
                  display: 'flex',
                  border: '1px solid rgba(226, 232, 240, 1)',
                  borderRadius: '4px',
                  padding: '14px 20px',
                  flex: '1',
                }}
                key={param.id}
                required={param.isRequired}
                onChange={(e: any) =>
                  handleDynamicSelectOptionsData({
                    e,
                    id: param.id,
                    type: param.type,
                    value: e.target.value,
                    label: param.label,
                  })
                }
              >
                {!optionValue && <option selected>Wybierz</option>}
                {param.options.map((option: any) => (
                  <option key={option.id} value={option.id} selected={option.id === optionValue}>
                    {option.label}
                  </option>
                ))}
              </select>
            </S.DynamicInputContainer>
          </S.DynamicTextWrapper>
        );
      } else {
        return null;
      }
    });
  };

  const handleToggleCheckbox = (id: string, label: string) => {
    const options = [...dynamicOptions];
    let alreadyIncludedOption = false;
    options.map((i: any) => {
      if (i.id === id) {
        i.value = !i.value;
        i.isChanged = true;
        alreadyIncludedOption = true;
        return;
      }
    });
    if (!alreadyIncludedOption) {
      options.push({
        id,
        type: 'yesNo',
        value: dynamicOptions.find((x: any) => x.id === id).value,
        label,
        isNew: true,
      });
    }
    setData({ ...data, dynamicOptions: options });
  };

  const handleDynamicSelectOptionsData = (props: any) => {
    const { e } = props;
    e.preventDefault();
    handleChangeSelectOrText(props);
  };

  const handleChangeSelectOrText = (props: any) => {
    const { e, id, type, value, label } = props;
    e.preventDefault();
    const options = [...dynamicOptions];
    let alreadyIncludedOption = false;
    options.map((i: any) => {
      if (i.id === id && i.value) {
        i.value = e.target.value;
        i.isChanged = true;
        alreadyIncludedOption = true;
        return;
      } else if (i.id === id) {
        i.value = e.target.value;
        i.isNew = true;
        alreadyIncludedOption = true;
        return;
      }
    });
    if (!alreadyIncludedOption) {
      options.push({
        id,
        type,
        value,
        label,
      });
    }
    setData({ ...data, dynamicOptions: options });
  };

  const handleCancelEdit = () => history.push('/user-dashboard/offers');

  const handleSelectPhoto = (id: string) => {
    const handleDeleteState = () => {
      images.map((i) => {
        if (i.id === id) {
          i.isDeleted = !i.isDeleted;
          return;
        } else {
          return i;
        }
      });
      setData({
        ...data,
        images,
      });
    };
    if (selectedPhotos.includes(id)) {
      setSelectedPhotos([...selectedPhotos.filter((x) => x !== id)]);
      handleDeleteState();
    } else {
      setSelectedPhotos([...selectedPhotos, id]);
      handleDeleteState();
    }
  };

  return (
    <S.Wrapper>
      <S.StarsContainer1>
        <S.StyledImage src={Stars1} />
      </S.StarsContainer1>
      <S.InternalWrapper onSubmit={(e: any) => handleUpdate(e)}>
        <S.StarsContainer2>
          <S.StyledImage src={Stars2} />
        </S.StarsContainer2>
        <S.HeaderContainer>
          <S.StyledHeader>Edytuj ogłoszenie</S.StyledHeader>
        </S.HeaderContainer>
        <S.InputContainer>
          <S.StyledLabel>Typ oferty</S.StyledLabel>
          <S.StyledInput readOnly value={offerTypeLabel} />
        </S.InputContainer>
        <S.InputContainer>
          <S.StyledLabel>Tytuł</S.StyledLabel>
          <S.StyledInput
            value={title}
            onChange={(e: any) => setData({ ...data, title: e.target.value })}
          />
        </S.InputContainer>
        <S.InputContainer>
          <S.StyledLabel>Kategoria</S.StyledLabel>
          <S.StyledInput readOnly value={categoryLabel} />
        </S.InputContainer>
        <S.InputContainer>
          <S.StyledLabel>Opis</S.StyledLabel>
          <S.StyledTextArea
            rows={5}
            value={description}
            onChange={(e: any) => setData({ ...data, description: e.target.value })}
            maxLength={5000}
          />
          <S.Counter>Pozostało {5000 - description.length} znaków</S.Counter>
        </S.InputContainer>
        <S.DoubleInputContainer>
          <S.InputContainer>
            <S.StyledLabel>Cena</S.StyledLabel>
            <S.PriceContainer>
              <S.StyledInput
                type="number"
                value={price}
                onChange={(e: any) => setData({ ...data, price: Number(e.target.value) })}
                max={999999}
                required
                disabled={giveaway}
                min={0}
                step="0.01"
                placeholder="Podaj cene"
                style={{ minWidth: 150 }}
              />
              <span>zł</span>
            </S.PriceContainer>
          </S.InputContainer>
          <S.InputContainer style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
            <S.CheckboxContainer>
              <CustomCheckbox
                value={giveaway}
                setValue={() => setData({ ...data, giveaway: !giveaway })}
                margin={8}
              />
            </S.CheckboxContainer>
            <S.ForFreeLabel>Za darmo</S.ForFreeLabel>
          </S.InputContainer>
        </S.DoubleInputContainer>
        {isAnnouncement && (
          <S.InputContainer>
            <S.ButtonContainer>
              <S.SaveButton
                disabled={!canAddNewInterval}
                onClick={(e: any) => handleAddNewInterval(e)}
              >
                + Karnet
              </S.SaveButton>
            </S.ButtonContainer>
            <S.BenefitsContainer>{renderIntervals()}</S.BenefitsContainer>
          </S.InputContainer>
        )}
        {isAnnouncement && (
          <S.DoubleInputContainer>
            <S.InputContainer>
              <S.StyledLabel>Wiek od</S.StyledLabel>
              <S.StyledInput
                value={ageFrom}
                onChange={(e: any) => setData({ ...data, ageFrom: e.target.value })}
                type="number"
                min={0}
                max={100}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.StyledLabel>Wiek do</S.StyledLabel>
              <S.StyledInput
                value={ageTo}
                onChange={(e: any) => setData({ ...data, ageTo: e.target.value })}
                type="number"
                min={0}
                max={100}
              />
            </S.InputContainer>
          </S.DoubleInputContainer>
        )}
        {isAnnouncement && (
          <>
            <S.InputContainer>
              <S.ButtonContainer>
                <S.SaveButton onClick={(e: any) => handleAddNewFaq(e)}>+ Faq</S.SaveButton>
              </S.ButtonContainer>
              <S.BenefitsContainer>{renderFaqs()}</S.BenefitsContainer>
            </S.InputContainer>
            <S.InputContainer>
              <S.ButtonContainer>
                <S.SaveButton onClick={(e: any) => handleAddNewBenefit(e)}>+ Benefit</S.SaveButton>
              </S.ButtonContainer>
              <S.BenefitsContainer>{renderBenefits()}</S.BenefitsContainer>
            </S.InputContainer>
          </>
        )}

        <S.InputContainer>
          <S.StyledLabel>Zdjęcia</S.StyledLabel>
        </S.InputContainer>
        {/* {images.length > 0 && (
          <S.PhotosWrapper>
            {images.map((i: any, index: number) => (
              <S.PhotoContainer
                key={index}
                isSelected={selectedPhotos.includes(i.id)}
                onClick={() => handleSelectPhoto(i.id)}
                onMouseEnter={() => (hoveredPhoto === i.id ? null : setHoveredPhoto(i.id))}
                onMouseLeave={() => setHoveredPhoto('')}
              >
                <S.StyledPhoto src={i.href} />
                {(hoveredPhoto === i.id || selectedPhotos.includes(i.id)) && (
                  <S.TrashContainer>
                    <S.TrashIcon />
                  </S.TrashContainer>
                )}
              </S.PhotoContainer>
            ))}
          </S.PhotosWrapper>
        )} */}
        <PhotosUpload
          handleSelectPhoto={handleSelectPhoto}
          photos={photos}
          setPhotos={setPhotos}
          isEdit
          existingPhotoCount={images.length || 0}
          existingPhotos={images}
          selectedPhotos={selectedPhotos}
        />
        <S.ParamsContainer>{RenderParams()}</S.ParamsContainer>
        {isLoading ? (
          <Spinner />
        ) : (
          <S.ButtonsWrapper>
            <S.ButtonContainer>
              <S.SaveButton>
                <S.CheckIcon />
                Zapisz zmiany
              </S.SaveButton>
            </S.ButtonContainer>
            <S.ButtonContainer>
              <S.CancelButton type="button" onClick={() => handleCancelEdit()}>
                <S.CloseIcon />
                Anuluj
              </S.CancelButton>
            </S.ButtonContainer>
          </S.ButtonsWrapper>
        )}
      </S.InternalWrapper>
    </S.Wrapper>
  );
};

export default OfferEdit;

import styled from 'styled-components';
import { themes } from '../../../assets/CSS/colorThemes';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export const Wrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  max-width: 730px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
`;

export const Label = styled.label`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: ${themes.default.graphemeGrey};
  margin-right: 4px;
`;

export const Red = styled.span`
  color: red;
`;

export const QuestionMarkIcon = styled(AiOutlineQuestionCircle)`
  width: 20px;
  height: 20px;
  color: ${themes.default.positive};
  cursor: pointer;
`;

export const StyledInput = styled.input`
  height: 50px;
  width: 100%;
  margin-bottom: 6px;
  background: ${themes.default.white};
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 21px;
  font-family: Poppins;
  font-size: 14px;
  color: ${themes.default.fontColorBlack};
  :focus {
    outline: none;
  }
  margin-bottom: 6px;
  ::placeholder {
    color: ${themes.default.bdGrey};
  }
`;

export const StyledTextarea = styled.textarea`
  border: 1px solid ${themes.default.lightGraphemeGrey};
  box-sizing: border-box;
  border-radius: 4px;
  height: 126px;
  padding: 13px 21px;
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  color: ${themes.default.fontColorBlack};
  overflow: auto;
  resize: none;
  :focus {
    overflow: none;
  }
  margin-bottom: 9px;
  ::placeholder {
    color: ${themes.default.bdGrey};
  }
`;

export const Counter = styled.div`
  font-family: Poppins;
  font-size: 11px;
  line-height: 164%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1px;
  color: ${themes.default.fontColorBlack};
  align-self: flex-end;
`;

export const QuestionMarkInfoContainer = styled.div`
  display: flex;
  position: absolute;
  background-color: #2b65ea;
  padding: 8px;
  border-radius: 6px;
  z-index: 10;
  top: -50px;
  left: 30px;
`;

export const QuestionMarkText = styled.span`
  font-family: Poppins;
  font-size: 10px;
  min-width: 400px;
  color: white;
  font-weight: 600;
`;

export const QuestionMarkWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const InfoAndCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileInfoSpan = styled.span`
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: white;
  background-color: #2b65ea;
  border-radius: 8px;
  padding: 4px;
  max-width: 370px;
`;

export const EmptyDiv = styled.div`
  height: 31px;
`;

export const MobileInfoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

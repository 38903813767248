import { useEffect } from 'react';
import * as S from './Modal.styles';

interface IModal {
  child: JSX.Element;
  title?: string;
  isCentered?: boolean;
  isCloseShown?: boolean;
  onClick?: () => void;
  isResearchModal?: boolean;
}
const Modal = ({
  child,
  isCentered = true,
  onClick,
  title,
  isCloseShown = true,
  isResearchModal = false,
}: IModal) => {
  //   const actionHandler = isCloseShown ? onClick : undefined;

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27 && onClick) {
        onClick();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  return (
    <S.Wrapper isCentered={isCentered} onClick={onClick}>
      <S.BodyWrapper onClick={(e: any) => e.stopPropagation()}>
        <S.InnerWrapper isResearchModal={isResearchModal}>
          {isCloseShown && <S.CloseWrapper onClick={onClick}>{/* <S.Close /> */}x</S.CloseWrapper>}
          <S.TitleWrapper>
            <S.Title>{title}</S.Title>
          </S.TitleWrapper>
          {child}
        </S.InnerWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};
export default Modal;

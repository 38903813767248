import { TailSpin } from 'react-loader-spinner';

export interface ISpinner {
  height?: number;
  width?: number;
  color?: string;
}

const Spinner = ({ height = 48, width = 48, color = 'rgba(75,65,196,1)' }: ISpinner) => {
  return <TailSpin color={color} height={height} width={width} />;
};

export default Spinner;

import styled from 'styled-components';
import { FaFileDownload } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useState } from 'react';
import calendar from './kalendarz.pdf';
import { useLocation } from 'react-router-dom';

export const Banner = styled.div`
  width: 100%;
  background-color: #4b41c4;
  display: flex;
`;

export const BannerText = styled.span`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-right: 10px;
  font-family: Poppins;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const BannerButton = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 6px;
  cursor: pointer;
  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export const CloseButton = styled(MdClose)`
  color: #fff;
  font-weight: 700;
  cursor: pointer;
`;

export const Anchor = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  padding: 6px;
  margin: 0 auto;
  text-decoration: none;
  @media (min-width: 768px) {
    padding: 20px;
    font-size: 20px;
  }
`;

const NavbarBanner = () => {
  const location = useLocation();

  const [isBannerClosed, setIsBannerClosed] = useState(!!localStorage.getItem('banner'));

  const close = () => {
    localStorage.setItem('banner', 'true');
    setIsBannerClosed(true);
  };

  const handleClose = () => {
    close();
  };

  const handleDownload = () => {
    window.gtag('event', 'download_calendar', {
      page_path: location.pathname + location.search,
    });
    close();
  };

  if (isBannerClosed) {
    return null;
  }

  return (
    <Banner>
      <Anchor
        href="/kalendarz.pdf"
        download="Malutcy.pl - Kalendarz 2024.pdf"
        onClick={handleDownload}
      >
        <BannerText>Pobierz kalendarz na rok 2024!</BannerText>
        <FaFileDownload />
      </Anchor>
      <BannerButton onClick={handleClose}>
        <CloseButton />
      </BannerButton>
    </Banner>
  );
};

export default NavbarBanner;

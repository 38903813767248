import phone from './phone.svg';
import mailGreyIcon from './mailGreyIcon.svg';
import greyMapPin from './greyMapPin.svg';
// import faqSearch from './faqSearch.svg';
// import HeaderRegulationsArt from './HeaderRegulationsArt.svg';
// import HeaderPersonArt from './HeaderPersonArt.svg';
import userDashboardArt from './userDashboardArt.svg';
import settingsOutlined from './settingsOutlined.svg';
import heartOutline from './heartOutline.svg';
import commentOutline from './commentOutline.svg';
import offersOutlined from './offersOutlined.svg';
import cameraCreate from './camera-create.svg';
import star from './star.svg';
import mapPin from './mapPin.svg';
import greenEllipse from './greenEllipse.svg';
import offerAvatar from './offerAvatar.svg';
import fbGreyIcon from './fbGreyIcon.svg';
import purplePin from './purplePin.svg';
import map from './map.svg';
import smallStar from './star_small.svg';
import smallStarBlue from './star_small_blue.svg';
import bigStar from './star_big.svg';
import greenElipse from './greenElipse.svg';
import message from './message.svg';
import hashOutlined from './hashOutlined.svg';

export default {
  phone,
  mailGreyIcon,
  greyMapPin,
  // faqSearch,
  // HeaderRegulationsArt,
  // HeaderPersonArt,
  userDashboardArt,
  settingsOutlined,
  heartOutline,
  commentOutline,
  offersOutlined,
  cameraCreate,
  star,
  mapPin,
  greenEllipse,
  offerAvatar,
  fbGreyIcon,
  purplePin,
  map,
  smallStar,
  bigStar,
  greenElipse,
  smallStarBlue,
  message,
  hashOutlined,
};

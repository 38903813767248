import styled from 'styled-components';
import U from '../../../assets/CSS/commonStyles';

export const ExternalWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 18px;
  box-sizing: border-box;
  background-color: rgba(233, 245, 245, 1);
  position: relative;
  /* bottom: -20px; */
  /* z-index: -2; */
`;

export const Wrapper = styled.div`
  ${U.standardFlex}
  align-items: flex-start;
  padding-top: 138px;
  padding-bottom: 78px;
  max-width: 1480px;
  width: 100%;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 564px);
`;

export const DescriptionWrapper = styled.aside`
  box-sizing: border-box;
  max-width: 350px;
  width: 100%;
  @media (min-width: 768px) {
    margin-left: 57px;
  }
`;

export const AbsoluteImg = styled.img``;

export const RightImgContainer = styled.div`
  position: absolute;
  right: 0;
  top: 23%;
`;

export const PersonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -108px;
  z-index: 2;
`;

import { useState } from 'react';
import plus from '../../../assets/plus.svg';
import minus from '../../../assets/minus.svg';
import * as S from './Faq.styles';

export interface IFaq {
  question: string;
  answer: string;
}

interface IFaqs {
  faqs: IFaq[];
}

const Faqs = ({ faqs = [] }: IFaqs) => {
  const [isCollapsible] = useState(false);
  const [expandedElements, setExpandedElements] = useState<any[]>([]);

  const triggerStyle = {
    cursor: 'pointer',
    width: '100%',
  };

  const triggerProps = {
    triggerStyle,
    transitionTime: 300,
    easing: 'ease-in',
    width: '100%',
  };

  const addElementToExpandedList = (key: number) => {
    setExpandedElements([...expandedElements, key]);
  };

  const removeElementFromExpandedList = (key: number) => {
    setExpandedElements([...expandedElements.filter((e) => e !== key)]);
  };

  const isArrowRotated = (index: number) => expandedElements.find((x) => x === index);

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>Faq</S.Title>
      </S.TitleWrapper>
      <S.FaqsWrapper>
        {faqs.map((f: IFaq, index: number) => (
          <S.FaqWrapper key={index}>
            <S.StyledCollapsible
              open={isCollapsible}
              onTriggerOpening={() => addElementToExpandedList(index + 1)}
              onTriggerClosing={() => removeElementFromExpandedList(index + 1)}
              {...triggerProps}
              trigger={
                <S.QuestionContainer>
                  <S.Question>{f.question}</S.Question>
                  <S.Icon src={isArrowRotated(index + 1) ? minus : plus} />
                </S.QuestionContainer>
              }
            >
              {f.answer}
            </S.StyledCollapsible>
          </S.FaqWrapper>
        ))}
      </S.FaqsWrapper>
    </S.Wrapper>
  );
};

export default Faqs;

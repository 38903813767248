import { IFaq } from './PageOne';
import * as S from './PageOne.styles';

interface IFaqRow extends IFaq {
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
  index: number;
  readOnly?: boolean;
}

const FaqRow = (props: IFaqRow) => {
  const { id, answer, question, onDelete, index, onChange, readOnly = false } = props;

  return (
    <S.RowContainer>
      <S.InputContainer>
        <S.RowLabel>Pytanie</S.RowLabel>
        <S.RowInput
          name="question"
          value={question}
          defaultValue={question}
          onChange={(e: any) => onChange(e, id)}
          type="text"
          required
          readOnly={readOnly}
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.RowLabel>Odpowiedź</S.RowLabel>
        <S.RowInput
          name="answer"
          value={answer}
          defaultValue={answer}
          onChange={(e: any) => onChange(e, id)}
          type="text"
          required
          readOnly={readOnly}
        />
      </S.InputContainer>
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.RowContainer>
  );
};

export default FaqRow;

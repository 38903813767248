import styled from 'styled-components';
import { IoMdCheckmark } from 'react-icons/io';
import { themes } from '../../../../assets/CSS/colorThemes';

export const Wrapper = styled.div`
  width: 100%;
  height: 94px;
  background: ${themes.default.lightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InternalWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-width: 332px;
  padding: 0 16px;
  box-sizing: border-box;
`;

interface Props {
  isActive: boolean;
  isCompleted?: boolean;
}

export const RingWrapper = styled.div<Props>`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 3px solid
    ${(props: Props) =>
      props.isActive || props.isCompleted
        ? themes.default.lavender
        : themes.default.greyBackgroundColor};
  background-color: ${(props: Props) =>
    props.isCompleted ? themes.default.lavender : themes.default.lightGrey};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RingText = styled.div<Props>`
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  line-height: 200%;
  text-align: center;
  color: ${(props: Props) =>
    props.isActive ? themes.default.lavender : themes.default.greyBackgroundColor};
`;

export const CheckIcon = styled(IoMdCheckmark)`
  background-color: ${themes.default.lavender};
  color: ${themes.default.greyBackgroundColor};
  width: 30px;
  height: 30px;
  border-radius: 100%;
`;

export const Bridge = styled.hr<Props>`
  min-width: 50px;
  flex-grow: 1;
  border: 3px solid
    ${(props: Props) =>
      props.isActive ? themes.default.lavender : themes.default.greyBackgroundColor};
  border-bottom: 0px;
`;

import { IOffer } from '../../../redux/offer/types';
import OffersWrapper from './OffersWrapper/OffersWrapper';
import * as S from './OffersPage.styles';

interface IOfferTypeTable {
  offers: IOffer[];
  header: string;
  isArchivedSection: boolean;
  refreshOffers: () => void;
}

const OfferTypeTable = (props: IOfferTypeTable) => {
  const { offers = [], header = '', isArchivedSection = false, refreshOffers } = props;
  if (offers.length === 0) {
    return null;
  }

  return (
    <>
      <S.OfferTypeNameContainer>
        <S.OfferTypeName>{header}</S.OfferTypeName>
      </S.OfferTypeNameContainer>
      <OffersWrapper
        refreshOffers={refreshOffers}
        offers={offers}
        isArchivedSection={isArchivedSection}
      />
    </>
  );
};

export default OfferTypeTable;

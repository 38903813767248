import { useEffect, useState } from 'react';
import WatchedOfferItem from '../WatchedOfferItem/WatchedOfferItem';
import Option from '../Option/Option';
import { IOffer } from '../../../redux/offer/types';
import Disclaimer from '../Disclaimer/Disclaimer';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './WatchedOffersPage.styles';
import * as api from '../../../api/common-api';
import { toast } from 'react-toastify';

const WatchedOffersPage = () => {
  const [isOffer, setIsOffer] = useState(true);
  const [userWatchedOffers, setUserWatchedOffers] = useState<any[]>([]);

  const getUserWatchedOffers = async () => {
    try {
      const res = await api.getWatchedOffers();
      setUserWatchedOffers(res);
    } catch (error) {
      console.log({ error });
    }
  };

  const watchedOffersCount = userWatchedOffers.length;

  const isDesktop = useMediaQuery('(min-width: 979px)');
  const isMobile = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    getUserWatchedOffers();
  }, []);

  const handleRemoveFromWatched = async (id: string) => {
    if (!id) {
      return null;
    }
    await api
      .toggleWatchedOffer(id)
      .then(() => {
        setUserWatchedOffers(userWatchedOffers.filter((x) => x.id !== id));
        toast.success('Oferta usunięta z obserwowanych');
      })
      .catch((e) => {
        console.log({ e });
        toast.warn('Wystąpił błąd');
      });
  };

  const renderResults = () => {
    if (isOffer) {
      return (
        <S.ResultsWrapper>
          {userWatchedOffers &&
            userWatchedOffers.length > 0 &&
            userWatchedOffers.map((offer: IOffer, index: number) => (
              <WatchedOfferItem
                key={offer.id}
                offer={offer}
                isLast={index + 1 === watchedOffersCount}
                handleRemove={(id) => handleRemoveFromWatched(id)}
              />
            ))}
        </S.ResultsWrapper>
      );
    }
    // return (
    //   <S.ResultsWrapper>
    //     {usersWatchedSearches &&
    //       usersWatchedSearches.length > 0 &&
    //       usersWatchedSearches.map((search, index: number) => (
    //         <WatchedSearchItem
    //           key={search.id}
    //           watchedSearch={search}
    //           isLast={index + 1 === watchedSearchesCount}
    //         />
    //       ))}
    //   </S.ResultsWrapper>
    // );
  };

  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <Disclaimer />
        <S.Header>Obserwowane</S.Header>
        {/* {!isMobile && <S.SubHeader>{isOffer ? 'oferty' : 'wyszukiwania'}</S.SubHeader>} */}
        {!isMobile && <S.SubHeader>Oferty</S.SubHeader>}
        <S.OptionsWrapper>
          <Option
            onClick={() => setIsOffer(true)}
            isActive={isOffer}
            text="Ogłoszenia"
            count={watchedOffersCount}
            icon={<S.OffersIcon />}
          />
          {/* <Option
            onClick={() => setIsOffer(false)}
            isActive={!isOffer}
            text="Wyszukiwania"
            count={newResultsCount}
            icon={<S.SearchIcon />}
          /> */}
        </S.OptionsWrapper>
        {isDesktop && isOffer && (
          <S.TableHeaderRow>
            <S.TableTitle>Tytuł</S.TableTitle>
            <S.TableDate>Czas trwania</S.TableDate>
            <S.TablePrice>Cena</S.TablePrice>
          </S.TableHeaderRow>
        )}
        {renderResults()}
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default WatchedOffersPage;

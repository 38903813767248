import { useEffect, useState } from 'react';
import { getAllTags } from '../../../api/common-api';
import { Modal, Button, Input, List } from 'antd';
import { toast } from 'react-toastify';
import * as api from '../../../api/common-api';
import * as S from './TagsPage.style';

const TagsPage = () => {
  const [allTags, setAllTags] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newTagLabel, setNewTagLabel] = useState('');
  const [isListLoading, setIsListLoading] = useState(false);
  const isAddingDisabled = newTagLabel.length <= 0;

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewTagLabel('');
  };

  const fetchTags = async () => {
    setIsListLoading(true);
    const tags = await getAllTags();
    setAllTags(tags);
    setIsListLoading(false);
  };

  const handleAddTag = async () => {
    await api.addTag({ label: newTagLabel });
    toast.success(`Dodano nowy tag "${newTagLabel}".`);
    await fetchTags();
    handleCloseModal();
  };

  const handleDeleteTag = async (id: string, label: string) => {
    await api.deleteTag({ id });
    toast.success(`Usunięto tag "${label}".`);
    await fetchTags();
  };

  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <Button onClick={() => setIsModalOpen(true)}>Dodaj nowy tag</Button>
        <Modal
          title="Podaj nazwę"
          open={isModalOpen}
          onCancel={() => handleCloseModal()}
          okText="Dodaj"
          cancelText="Anuluj"
          onOk={() => handleAddTag()}
          okButtonProps={{ disabled: isAddingDisabled }}
        >
          <Input value={newTagLabel} onChange={(e: any) => setNewTagLabel(e.target.value)} />
        </Modal>
        <List
          loading={isListLoading}
          itemLayout="horizontal"
          dataSource={allTags}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button key="list-delete" onClick={() => handleDeleteTag(item.id, item.label)}>
                  Usuń
                </Button>,
              ]}
            >
              <List.Item.Meta title={item.label} />
            </List.Item>
          )}
        />
      </S.Container>
    </S.Wrapper>
  );
};

export default TagsPage;

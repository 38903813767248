import { useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordError, loadingData } from '../../../redux/auth/selectors';
import { changePassword, setChangePasswordErrorMessage } from '../../../redux/auth/actions';
import { LOADING_FIELD } from '../../../redux/auth/types';
import delay from 'lodash/delay';
import SubmitButton from '../../LoginPage/SubmitButton/SubmitButton';
import { useHistory, useParams } from 'react-router-dom';
import * as S from './ChangePassword.styles';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  const { verificationCode } = params;

  useEffect(() => {
    if (!verificationCode) {
      history.push('/');
    }
  }, [verificationCode]);

  const { isLoading, loadingField } = useSelector(loadingData);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const handlePasswordChange = (event: FormEvent) => {
    event.preventDefault();
    dispatch(
      changePassword({ password: oldPassword, newPassword, newPassword2, verificationCode })
    );
  };

  const errorMessage = useSelector(changePasswordError);

  const clearErrorMessage = () => dispatch(setChangePasswordErrorMessage(''));
  useEffect(() => {
    if (errorMessage) {
      delay(clearErrorMessage, 8000);
    }
  }, [errorMessage]);

  const displayMessage = () => {
    if (!errorMessage) {
      return null;
    }
    return (
      <S.Message isWarning onClick={clearErrorMessage}>
        {errorMessage}
      </S.Message>
    );
  };
  const isSubmitting = loadingField === LOADING_FIELD.PASSWORD_CHANGE && isLoading;

  return (
    <S.ExternalWrapper>
      <S.FormWrapper id="password-form" onSubmit={handlePasswordChange}>
        <S.InputContainer>
          <S.StyledLabel>
            Obecne hasło <S.RedStar>*</S.RedStar>
          </S.StyledLabel>
          <S.StyledInput
            placeholder="Obecne hasło"
            value={oldPassword}
            onChange={(e: any) => setOldPassword(e)}
            min={8}
            type="password"
            required
          />
        </S.InputContainer>
        <S.InputContainer>
          <S.StyledLabel>
            Nowe hasło <S.RedStar>*</S.RedStar>
          </S.StyledLabel>
          <S.StyledInput
            placeholder="Nowe hasło"
            value={newPassword}
            onChange={(e: any) => setNewPassword(e)}
            min={8}
            type="password"
            required
          />
        </S.InputContainer>
        <S.InputContainer>
          <S.StyledLabel>
            Powtórz nowe hasło <S.RedStar>*</S.RedStar>
          </S.StyledLabel>
          <S.StyledInput
            placeholder="Powtórz nowe hasło"
            value={newPassword2}
            onChange={(e: any) => setNewPassword2(e)}
            min={8}
            type="password"
            required
          />
        </S.InputContainer>
        <div>
          <SubmitButton type="submit" text={'Zmien hasło'} isLoading={isSubmitting} />
          {displayMessage()}
        </div>
      </S.FormWrapper>
    </S.ExternalWrapper>
  );
};

export default ChangePassword;

import confirmation from '../../../assets/Login/confirmation.svg';
import leftImage from '../../../assets/Login/leftImg.svg';
import rightImage from '../../../assets/Login/rightImg.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import * as S from './Confirmation.styles';

const ResetPasswordConfirmation = () => {
  const isDesktop = useMediaQuery('(min-width: 1250px)');
  const isTablet = useMediaQuery('(min-width: 768px)');

  return (
    <S.ExternalWrapper>
      {isTablet && (
        <S.LeftImgContainer>
          <S.AbsoluteImg src={leftImage} />
        </S.LeftImgContainer>
      )}
      <S.Wrapper>
        <S.Header>Już prawie gotowe</S.Header>
        <S.BodyWrapper>
          <S.Information>
            Aby dokończyć resetowanie hasła, przejdź do poczty i&nbsp;potwierdź swój email
          </S.Information>
        </S.BodyWrapper>
      </S.Wrapper>
      {isDesktop && (
        <S.RightImagesContainer>
          <S.BackgroundImage alt={'background-image'} src={confirmation} />
          <S.RightImage src={rightImage} />
        </S.RightImagesContainer>
      )}
    </S.ExternalWrapper>
  );
};

export default ResetPasswordConfirmation;

import { MouseEvent, useState } from 'react';
import * as S from './PhotosUpload.styles';

export default function Miniature({
  onClick,
  remove,
  photo: { file, name },
  src,
  isSelected = false,
}: {
  onClick: (e: MouseEvent) => void;
  remove: (e: MouseEvent) => void;
  photo: { file: any; name: string };
  src?: string;
  isSelected?: boolean;
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <S.MiniPhotoWrapper
      onMouseEnter={() => !isSelected && setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <S.RemoveWrapper onClick={remove} hover={isHover} isExistingPhoto={!!src}>
        <S.RemoveIcon />
      </S.RemoveWrapper>
      {!src && (
        <S.ChangeWrapper onClick={onClick} hover={isHover}>
          <S.ChangeIcon />
        </S.ChangeWrapper>
      )}
      {!src ? (
        <S.Thumbnail src={URL.createObjectURL(file)} alt={name} />
      ) : (
        <>
          <S.Thumbnail src={src} alt={''} />
          {isSelected && (
            <S.TrashContainer onClick={remove}>
              <S.TrashIcon />
            </S.TrashContainer>
          )}
        </>
      )}
    </S.MiniPhotoWrapper>
  );
}

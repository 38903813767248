import ContactPageWrapper from '../ContactPageWrapper/ContactPageWrapper';
import RegulationsImage from '../../../assets/RegulationsImage.svg';
import * as S from './TermsPage.styles';

const TermsPage = () => (
  <ContactPageWrapper image={RegulationsImage} title="Polityka prywatności">
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.H1>Polityka prywatności</S.H1>
      </S.HeaderWrapper>
      <S.H2>Informacja o plikach cookies</S.H2>
      <S.Paragraph>
        Zgodnie z informacją przedstawioną w chwili twojego wejścia na stronę, za twoją zgodą,
        stosujemy technologię cookies. Cookies to pliki tekstowe, które są przesyłane do twojego
        urządzenia (przeglądarki) i tam przechowywane.{' '}
      </S.Paragraph>
      <S.Paragraph>
        Korzystamy z cookies, jeśli wyraziłeś na to zgodę. Zgoda jest zawsze dobrowolna, ale może
        być warunkiem korzystania ze strony, jej części lub jakiejś udostępnionej na stronie usługi.
        Możesz odwołać zgodę na cookies w każdym czasie.
      </S.Paragraph>
      <S.Paragraph>
        Korzystanie z cookies może wiązać się z przetwarzaniem twoich danych osobowych (informacji
        powiązanych z twoim wejściem na stronę). Dane o tobie kojarzymy również łącząc na podstawie
        przydzielonych ci identyfikatorów (np. „użytkownik345”), które zostają zapisane w pamięci
        twojego urządzenia na czas, gdy opuszczasz naszą stronę. Zasady przetwarzania twoich danych
        wyjaśniamy poniżej w sekcji „Korzystasz z naszej strony internetowej (malutcy.pl), w tym
        dokonujesz ustawień cookies (ciasteczek)”.
      </S.Paragraph>
      <S.Paragraph>
        Cookies są przechowywyane w twojej przeglądarce. Popularne przeglądarki pozwalają częściowo
        zarządzać cookies w ustawieniach. Twórcy przeglądarek informują o tym jak dokonać ustawień
        na swoich stronach internetowych, a także w panelach pomocy w przeglądarkach. Poniżej dajemy
        ci możliwość ustawienia cookies również po naszej stronie (dzięki tym ustawieniom wiemy, że
        jakichś cookies nie mamy ci wysyłać).{' '}
      </S.Paragraph>
      <S.Paragraph>
        Cookies mogą należeć do nas lub naszych partnerów. Cookies partnerów pomagają w świadczeniu
        przez nich usług i mogą być wykorzystywane do przetwarzania przez nich twoich danych
        osobowych. Opis poszczególnych cookies przedstawiamy poniżej.
      </S.Paragraph>
      <S.H3>Ustawienia i opis cookies</S.H3>
      <S.H5>Prawidłowe działanie strony</S.H5>
      <S.Paragraph>
        W cookies przechowujemy dane niezbędne do prawidłowego działania strony i jej głównych
        usług, w tym utrzymania twojej sesji. Działania tych cookies nie można wyłączyć. Oznacza to,
        że korzystanie z naszej strony jest równoznaczne ze zgodą na stosowanie tych cookies. Jeśli
        nie chcesz byśmy tak przetwarzali twoje dane, to musisz zrezygnować z korzystania z naszej
        strony.
      </S.Paragraph>
      <S.Paragraph>Korzystamy z narzędzi następujących zewnętrznych usługodawców:</S.Paragraph>
      <S.H5>Zbieranie danych statystycznych</S.H5>
      <S.Paragraph>
        Cookies wspierają zbieranie statystyk dotyczących tego jak korzystasz z naszej strony.
        Cookies pozwalają powiązać ze sobą różne twoje wejścia na naszą stronę i związane z tymi
        wejściami zdarzenia. Jeśli cofniesz zgodę na cookies, nie będziemy stosować tego rodzaju
        identyfikatorów, jednak nadal możemy zbierać dane statystyczne dotyczące poszczególnych
        twoich wejść na stronę z osobna. W celach statystycznych zapisujemy takie informacje jak:
        długość przebywania na stronie, ilość odwiedzonych stron, twoje IP, …
      </S.Paragraph>
      <S.Paragraph>
        Do zbierania statystyk korzystamy z narzędzi zewnętrznych usługodawców:
      </S.Paragraph>
      <S.H5>Tracking, marketing bezpośredni</S.H5>
      <S.Paragraph>
        Cookies pozwalają na tworzenie twojego profilu i wyświetlanie ci dostosowanych do ciebie
        reklam. Cookies zewnętrznych usługodawców pozwalają na zbieranie w tym celu większej ilości
        informacji o tym jako korzystasz z różnych stron internetowych i jakich treści poszukujesz.
        Włączenie tego rodzaju cookies na naszej stronie oznacza, ze ci usługodawcy będą zbierać
        również informacje o wykorzystaniu naszej strony i mogą je udostępniać swoim partnerom.
      </S.Paragraph>
      <S.Paragraph>
        Do działań marketingowych korzystamy z narzędzi zewnętrznych usługodawców:
      </S.Paragraph>
      <S.H2>Informacja o przetwarzaniu danych osobowych na stronie malutcy.pl</S.H2>
      <S.H3>Informacje ogólne</S.H3>
      <S.H4>Administrator danych osobowych</S.H4>
      <S.Paragraph>
        Administratorem danych osobowych jest Malutcy.pl Sp. z o. o. z siedzibą w 62-200 Gniezno,
        ul. Kruszwicka 6. Jeżeli podane tu informacje nie są wystarczające, zachęcamy do kontaktu.
        Dołożymy wszelkich starań, by wyjaśnić każdą sprawę. Nie wyznaczyliśmy inspektora ochrony
        danych — pytania i wnioski możesz kierować do nas bezpośrednio pod adresem{' '}
        <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link> lub ul. Kruszwicka 6,
        62-200 Gniezno.
      </S.Paragraph>{' '}
      <S.H4>Przepisy, które regulują ochronę danych osobowych</S.H4>
      <S.Paragraph>
        Najważniejsze przepisy, które regulują przetwarzanie twoich danych osobowych zawarte są w
        Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
        sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
        swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie
        o ochronie danych). Przywołując to rozporządzenie, używamy skrótu „RODO”.
      </S.Paragraph>
      <S.H4>Twoje prawa</S.H4>
      <S.Paragraph>Zgodnie z RODO masz prawo:</S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>żądać dostępu do swoich danych osobowych (otrzymać ich kopię), </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>sprostować twoje dane (poprawić błędne dane), </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            usunąć dane (zgodnie z informacjami zamieszczonymi w tym dokumencie i na warunkach
            określonych w przepisach prawa),{' '}
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            ograniczyć przetwarzanie twoich danych (na warunkach określonych w RODO),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            sprzeciwić się wobec przetwarzania twoich danych (jednak tylko w zakresie w jakim
            przetwarzane są na podstawie tak zwanego prawnie uzasadnionego interesu).
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        Masz również prawo do wniesienia skargi do organu nadzorczego: Prezesa Urzędu Ochrony Danych
        Osobowych (ul. Stawki 2, 00-193 Warszawa).{' '}
      </S.Paragraph>
      <S.H3>Informacje o konkretnych sytuacjach</S.H3>
      <S.H4>Piszesz do nas mejl lub inaczej kontaktujesz się z nami </S.H4>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Przetwarzamy twoje dane osobowe by udzielić ci odpowiedzi i załatwić twoją sprawę zawsze
        kiedy piszesz do nas mejl, wiadomość za pośrednictwem dowolnej naszej strony internetowej
        (np. zgłaszasz ogłoszenie poprzez formularz), innej platformy internetowej (np. Messenger),
        dzwonisz, wysyłasz sms itp.
      </S.Paragraph>
      <S.Paragraph>
        Podstawą takiego przetwarzania jest nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. (f)
        RODO), który polega na komunikowaniu się z tobą najwygodniejszą dla nas i dla ciebie drogą,
        a następnie skutecznym załatwianiu łączących nas spraw. Nasz interes obejmuje również
        zarchiwizowanie sprawy, byśmy mogli do jej rozstrzygnięcia, a także zachowanie danych aby
        dochodzić roszczeń lub bronić się przed roszczeniami.
      </S.Paragraph>
      <S.H5>Inne cele</S.H5>
      <S.Paragraph>
        Może zdarzyć się, że załatwiana przez nas sprawa będzie polegała na tym, że przygotujemy dla
        ciebie informację o wybranych produktach lub usługach, która będzie miała charakter
        handlowy. W takim przypadku będziemy przetwarzać twoje dane na zasadach opisanych w części
        „Otrzymujesz od nas newsletter lub inne wiadomości mejlowe, które zawierają informację
        handlową”.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami twoich danych mogą być: dostawcy naszych usług informatycznych (hosting),
        podmioty wspierające działanie naszych systemów informatycznych, nasi kontrahenci i
        podwykonawcy (jeśli załatwienie sprawy będzie wymagało ich zaangażowania), dostawcy usług
        telekomunikacyjnych, pocztowych i kurierskich, a także podmioty wspierające naszą
        działalność (np. prawnicy).
      </S.Paragraph>
      <S.Paragraph>
        Część dostawców usług informatycznych, z których korzystamy (Messenger), funkcjonuje w
        państwach spoza Europejskiego Obszaru Gospodarczego. Możemy przekazywać tam dane. Stosujemy
        przy tym mechanizmy zapewniające wymaganą ochronę, w szczególności poprzez stosowanie
        zatwierdzonych przez Komisję Europejską standardowych klauzul umownych. Możesz uzyskać od
        nas informację o takich klauzulach. W tym celu zwróć się do nas na adres:{' '}
        <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>.
      </S.Paragraph>
      <S.H5>Usuwanie danych, sprzeciw wobec przetwarzania</S.H5>
      <S.Paragraph>
        Twoje dane usuwamy, gdy załatwimy twoją sprawę. Możemy zachować je jednak na dłużej, jeśli
        uznamy, że jest to w naszym interesie, bo spodziewamy się, że będziemy chcieli wrócić do tej
        sprawy (archiwizacja), albo dlatego, że przewidujemy potrzebę dochodzenia roszczeń lub
        obrony przed roszczeniami. W przypadku archiwizacji czas przechowywania danych będzie
        zależał od charakteru sprawy. Potrzebę przetwarzania danych weryfikujemy średnio co 5 lat, a
        na dłużej niż 10 lat zachowujemy tylko istotne dane. W przypadku dochodzenia roszczeń lub
        obrony przed roszczeniami nie będzie dłuższy niż termin przedawnienia danego roszczenia.
        Opisujemy szczegółowo archiwizację i dochodzenie roszczeń w odrębnej sekcji poniżej.
      </S.Paragraph>
      <S.Paragraph>
        Przypominamy, że wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu
        zawsze możesz wnieść sprzeciw. Jeśli ustalimy, że twój interes przeważa nad naszym,
        natychmiast zaprzestaniemy takiego przetwarzania.
      </S.Paragraph>
      <S.H4>
        Korzystasz z naszej strony internetowej (malutcy.pl), w tym dokonujesz ustawień cookies
        (ciasteczek)
      </S.H4>
      <S.H5>Przetwarzanie danych</S.H5>
      <S.Paragraph>
        Kiedy korzystasz z naszej strony zbieramy twoje dane osobowe w ten sposób, że:
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            gromadzimy tak zwane logi (czyli informacje o zdarzeniach na stronie, w tym informację o
            twoim numerze IP),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            wiążemy ze sobą i z tobą różne informacje przy użyciu tak zwanych cookies (ciasteczek),
            zgodnie z opisem zamieszczonym w „Informacji o plikach cookies” powyżej.{' '}
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>Zbieramy przede wszystkim informacje:</S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            charakteryzujące cię pod względem demograficznym, społecznym itp. (przedział wiekowy,
            płeć, skąd do nas trafiasz, w związku z jakimi zainteresowaniami itp.),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            o tym jak korzystasz z naszej strony (np. jakich treści poszukujesz, opcje które
            wybrałeś (wybrałaś), najbardziej angażujące cię elementy strony, co nie działa tak, jak
            powinno itp.),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            oraz o twoim urządzeniu (np. jaki to rodzaj urządzenia, jaki ma system operacyjny,
            ustawioną rozdzielczość, dostępną pamięć itp.).
          </S.Paragraph>
        </li>
      </ol>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Uruchomienie cookies lub gromadzenie logów często wiąże się z przetwarzaniem twoich danych.
        Odbywa się ono na podstawie prawnie uzasadnionego interesu — naszego lub naszych partnerów
        (art. 6 ust. 1 lit. (f) RODO), który w zależności od rodzaju cookies polega na tym, że:
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            w celu świadczenia żądanych przez ciebie usług chcemy dostarczać ci poprawnie działającą
            stronę internetową i dostępne na niej usługi (niezbędne cookies),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            w celu zapewnienia ci komfortu użytkowania strony i dodatkowych funkcjonalności, chcemy
            dostarczać ci stronę internetową i dostępne na niej usługi w sposób, który jest zgodny z
            twoimi preferencjami (cookies preferencji, funkcjonalne),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            zbieramy dane statystyczne w celu ich analizy i rozwijania naszych usług (cookies
            statystyczne, wydajnościowe),
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            opieramy nasze działania marketingowe na danych (marketing bezpośredni, tracking) w celu
            poprawy ich skuteczności i np. przedstawieniu ci dobranej do twojego profilu reklamy
            (cookies marketingowe).
          </S.Paragraph>
        </li>
      </ol>
      <S.H5>Sprzeciw wobec przetwarzania</S.H5>
      <S.Paragraph>
        Przypominamy, że wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu
        możesz wnieść sprzeciw. Jeśli ustalimy, że twój interes przeważa nad naszym, natychmiast
        zaprzestaniemy takiego przetwarzania.{' '}
      </S.Paragraph>
      <S.Paragraph>
        Wszystkie cookies grupujemy w ramach czterech wymienionych wyżej celów. Skuteczny sprzeciw
        wobec wybranego celu przetwarzania danych oznacza, że wyłączamy wszystkie tego rodzaju
        cookies.
      </S.Paragraph>
      <S.Paragraph>
        Przewaga twojego interesu nie jest oczywista w przypadku niezbędnych cookies oraz logów,
        które zapewniają prawidłowe działanie strony. Jest tak ze względu na cele, którym służą i
        dlatego taki sprzeciw wymaga każdorazowej,{' '}
      </S.Paragraph>
      <S.Paragraph>
        weryfikacji. Jeśli nie chcesz byśmy tak przetwarzali twoje dane, musisz zrezygnować z
        korzystania z naszej strony. W pozostałych przypadkach sprzeciw honorujemy automatycznie i
        można go wyrazić w sekcji ustawień cookies.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami danych są podmioty świadczące nam usługi pomiaru statystyk i marketingowe oraz
        dostarczające nam narzędzia, na których opierają się niektóre funkcjonalności naszej strony.
        Część dostawców usług informatycznych, z których korzystamy funkcjonuje w państwach spoza
        Europejskiego Obszaru Gospodarczego. Możemy przekazywać tam dane. Stosujemy przy tym
        mechanizmy zapewniające wymaganą ochronę, w szczególności poprzez stosowanie zatwierdzonych
        przez Komisję Europejską standardowych klauzul umownych. Możesz uzyskać od nas informację o
        takich klauzulach. W tym celu zwróć się do nas na adres:{' '}
        <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>. Aktualna lista
        odbiorców podana jest w „Informacji o plikach cookies” powyżej.
      </S.Paragraph>
      <S.H5>Usuwanie danych</S.H5>
      <S.Paragraph>
        Cookies usuwane są automatycznie przez przeglądarkę, zgodnie z tym jak zostały
        zaprogramowane. Możesz też sam usunąć cookies, korzystając z opcji udostępnionych przez
        twoją przeglądarkę. Dane zebrane poprzez cookies możemy przechowywać dłużej niż same pliki.
        Potrzebę przetwarzania danych weryfikujemy średnio co 5 lat, a na dłużej niż 10 lat
        zachowujemy tylko istotne dane.
      </S.Paragraph>
      <S.H4>Korzystasz z naszej strony na Facebooku</S.H4>
      <S.H5>Przetwarzanie danych</S.H5>
      <S.Paragraph>
        Prowadzimy swoją stronę na Facebooku. Na tym portalu jesteśmy administratorem twoich danych
        osobowych w takim zakresie jaki odpowiada udostępnionym nam funkcjonalnościom portalu —
        możemy zarządzać treścią naszej strony (moderować komentarze, umieszczać na stronie treści,
        które ciebie dotyczą, publikować odnośniki oznaczające twoje konto na danym portalu itp.)
        oraz bezpośrednio się z tobą komunikować (np. przez Messenger).
      </S.Paragraph>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Twoje dane przetwarzamy na podstawie naszego prawnie uzasadnionego interesu (art. 6 ust. 1
        lit. (f) RODO), który polega na informowaniu o swojej działalności i prowadzeniu skutecznej
        komunikacji z klientami w mediach społecznościowych. Elementem tego jest wyświetlanie i
        publikowanie związanych z tobą treści. Jeżeli nasze działania ci nie odpowiadają, możesz
        wyrazić sprzeciw kontaktując się z nami w dowolnej formie. Jeśli ustalimy, że twój interes
        przeważa nad naszym, natychmiast zaprzestaniemy takiego przetwarzania.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami twoich danych będą inni użytkownicy naszej strony oraz administrator danego
        portalu (np. Meta, do której należy Facebook). Mogą nimi być także nasi dostawcy innych
        usług informatycznych i podmioty, które wspierają nas w prowadzeniu naszej strony.
      </S.Paragraph>
      <S.Paragraph>
        Administratorzy portalów funkcjonują w państwach spoza Europejskiego Obszaru Gospodarczego.
        Możemy przekazywać tam dane. Stosujemy przy tym mechanizmy zapewniające wymaganą ochronę, w
        szczególności poprzez stosowanie zatwierdzonych przez Komisję Europejską standardowych
        klauzul umownych. Możesz uzyskać od nas informację o takich klauzulach. W tym celu zwróć się
        do nas na adres: <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>.
      </S.Paragraph>
      <S.Paragraph>
        Pamiętaj, że portale społecznościowe mogą zbierać znacznie więcej informacji o tobie niż my.
        Dotyczy to również informacji zbieranych przy użyciu cookies. Nad tym przetwarzaniem nie
        mamy kontroli i zależy ono jedynie od zasad określonych przez administratorów tych portali,
        którzy w tym zakresie są odrębnymi od nas administratorami danych osobowych. Zapoznaj się z
        ich regulaminami i politykami prywatności, by wiedzieć więcej na ten temat:
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Informacje dotyczące Facebooka dostępne są{' '}
            <S.Link href={'https://www.facebook.com/legal/terms'}>tutaj</S.Link>
          </S.Paragraph>
        </li>
      </ol>
      <S.H5>Usuwanie danych</S.H5>
      <S.Paragraph>
        W przypadku treści umieszczonych w portalach społecznościowych nie podejmujemy
        systematycznego ich usuwania, a czynimy to jedynie w przypadku, gdy nie jesteśmy już
        zainteresowani publikacją tych treści i ewentualnym przetwarzaniem twoich danych osobowych.
        Pamiętaj, że w wymienionych portalach często możesz samodzielnie zarządzać treściami, które
        ciebie dotyczą. Możesz też w tej sprawie zwrócić się do nas.
      </S.Paragraph>
      <S.H4>
        Otrzymujesz od nas newsletter lub inne wiadomości mejlowe, które zawierają informację
        handlową
      </S.H4>
      <S.H5>Zamawianie informacji handlowej, zgoda na przesyłanie informacji handlowej</S.H5>
      <S.Paragraph>
        Przesyłamy ci newsletter lub inne wiadomości mejlowe, w których informujemy cię o naszych
        produktach lub usługach, jak również produktach lub usługach naszych partnerów. Wiadomości
        te przesyłamy za twoją zgodą na przesyłanie informacji handlowej (w tym treści
        marketingowych). Zgody tej mogłeś (mogłaś) udzielić:
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>dołączając do naszego newslettera,</S.Paragraph>
        </li>
        <li>
          <S.Paragraph>zamawiając powiadomienia o ofertach.</S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        Na każdy kanał zbieramy odrębną zgodę, co oznacza, że na przykład zamawiając powiadomienia o
        ofertach nie zamawiasz newslettera.
      </S.Paragraph>
      <S.Paragraph>
        Zgoda ta jest dobrowolna, ale jest też niezbędna, by zamówić nasz newesletter lub informacje
        handlowe. W każdej chwili możesz wycofać swoją zgodę, przy czym nie ma to wpływu na
        działania, które miały miejsce do momentu jej odwołania.
      </S.Paragraph>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Przesyłanie informacji handlowej (w tym komunikatów marketingowych) stanowi nasz prawnie
        uzasadniony interes lub prawnie uzasadniony interes naszych partnerów. Na tej podstawie
        przetwarzamy twoje dane osobowe (art. 6 ust. 1 lit. (f) RODO). Przypominamy, że masz prawo
        wyrażenia sprzeciwu wobec takiego przetwarzania twoich danych osobowych. Jeśli ustalimy, że
        twój interes przeważa nad naszym, natychmiast zaprzestaniemy takiego przetwarzania. Jako
        sprzeciw traktujemy również wycofanie zgody na samo przesyłanie informacji handlowej.
      </S.Paragraph>
      <S.H5>Inne cele</S.H5>
      <S.Paragraph>
        Jeśli przesłana informacja handlowa zawiera konkretną ofertę, to oznacza, że podejmujemy już
        działania przed zawarciem umowy. Powstaje wtedy nowy cel przetwarzania twoich danych i
        dzieje się to na nowej podstawie. Więcej o tym przeczytasz w części „Zawierasz z nami
        umowę”.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami twoich danych mogą być 1) podmioty, które wspierają przesyłanie informacji
        handlowej (dostawcy usług informatycznych takich jak hosting, poczta elektroniczna, czy
        usługi służące do mejlingu) oraz 2) inne podmioty, które utrzymują i serwisują nasze systemy
        informatyczne. Odbiorcą danych mogą być również 3) podmioty, które świadczą usługi pocztowe
        lub kurierskie, a także 4) podmioty, które świadczą usługi wspierające naszą działalność
        (np. księgowe, audytorskie, usługi prawne itp.).
      </S.Paragraph>
      <S.H5>Usuwanie danych</S.H5>
      <S.Paragraph>
        Twoje dane będziemy przetwarzać do czasu, gdy skutecznie się temu sprzeciwisz lub do chwili
        cofnięcia przez ciebie zgody na przesyłanie informacji handlowej. Zaprzestaniemy też
        przetwarzania danych, gdy sami zakończymy świadczenie usługi newslettera lub innej usługi
        polegającej na przesyłaniu informacji handlowej.
      </S.Paragraph>
      <S.H4>Zawierasz z nami umowę, w tym korzystasz z usług dostępnych na stronie malutcy.pl</S.H4>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Nasze kontakty mogą prowadzić do tego, że zawrzesz z nami umowę. Zawarcie umowy ma miejsce
        również wtedy, gdy korzystasz z dowolnej oferowanej na naszej stronie usługi, zgodnie z
        regulaminem.
      </S.Paragraph>
      <S.Paragraph>
        Umowa oznacza szereg czynności, w których przetwarzamy twoje dane na różnych podstawach:
      </S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Umowa oraz wcześniejsze podjęcie działań przed zawarciem umowy stanowi podstawę
            przetwarzania twoich danych jako strony umowy (art. 6 ust. 1 lit. (b) RODO). Podanie
            danych jest dobrowolne, ale jest warunkiem zawarcia umowy.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Wykonywanie umowy wiąże się ponadto z obowiązkami prawnymi o charakterze rachunkowym i
            podatkowym. Dane włączone do wytworzonej w tym celu dokumentacji będą przetwarzane na
            podstawie regulujących te obowiązki przepisów prawa (co przewiduje art. 6 ust. 1 lit.
            (c) RODO).
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Niektóre osoby mogą występować w umowie w innej roli niż jedna ze stron umowy, bo na
            przykład dla jednej ze stron są pracownikami. Dane takich osób przetwarzamy na podstawie
            naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. (f) RODO), który polega na
            korzystaniu z kompetencji takich osób przy wykonaniu umowy.
          </S.Paragraph>
        </li>
      </ol>
      <S.H5>Odbiorcy danych </S.H5>
      <S.Paragraph>
        Odbiorcami danych mogą być: 1) podmioty, które utrzymują i serwisują nasze systemy
        informatyczne, 2) podmioty, które świadczą usługi pocztowe lub kurierskie, 3) podmioty,
        które świadczą usługi wspierające naszą działalność (np. księgowe, audytorskie, usługi
        prawne itp.).
      </S.Paragraph>
      <S.H5>Usuwanie danych</S.H5>
      <S.Paragraph>Usuwanie danych podlega następującym regułom:</S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Dane przetwarzane są do chwili rozwiązania umowy, jednak z uwzględnieniem:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                opisanego niżej wyjątku, gdy przetwarzamy dane by dochodzić roszczeń lub się przed
                nimi bronić, a także
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>
                wyjątku w odniesieniu do dokumentacji rachunkowo – podatkowej, który zakłada, że
                zawarte w niej dane przechowywyane są przez sześć lat od sporządzenia ostatniego
                dokumentu, który dotyczy danej umowy, do końca roku kalendarzowego,
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>opisanego niżej wyjątku dotyczącego archiwizacji.</S.Paragraph>
            </li>
          </ol>
        </li>
        <li>
          <S.Paragraph>
            Przetwarzanie danych może ulec wydłużeniu w sytuacji, w której twoje dane osobowe są
            niezbędne do tego by dochodzić roszczeń lub się przed nimi bronić. Twoje dane będą
            przetwarzane co najmniej do terminu przedawnienia ewentualnych roszczeń lub zakończenia
            wszystkich postępowań sądowych lub egzekucyjnych. Opisujemy szczegółowo dochodzenie
            roszczeń w odrębnej sekcji poniżej.
          </S.Paragraph>
        </li>
        <li>
          <S.Paragraph>
            Dane możemy przechowywać w celu zarchiwizowania informacji mającej dla nas uzasadnione,
            faktycznie lub potencjalnie istotne znaczenie. Opisujemy szczegółowo archiwizację w
            odrębnej sekcji poniżej.
          </S.Paragraph>
        </li>
      </ol>
      <S.Paragraph>
        Przypominamy, że wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu
        zawsze możesz wnieść sprzeciw. Jeśli ustalimy, że twój interes przeważa nad naszym,
        natychmiast zaprzestaniemy takiego przetwarzania.
      </S.Paragraph>
      <S.H4>
        Przetwarzamy twoje dane w ogłoszeniach, które nie zostały opublikowane przez ciebie
      </S.H4>
      <S.Paragraph>
        Ogłoszenie opublikowane przez osobę trzecią może zawierać treści, które ciebie dotyczą (np.
        twój wizerunek, twoje dane kontaktowe itp.). Zgodnie z regulaminem naszych usług użytkownicy
        publikujący ogłoszenie oświadczają nam, że mogą publikować przekazane nam materiały i dane.
        Jeżeli nie mają do tego prawa, naruszają regulamin, a materiał powinien zostać usunięty.
      </S.Paragraph>
      <S.H5>Żądanie usunięcia naruszenia</S.H5>
      <S.Paragraph>
        Jeśli zażądasz usunięcia naruszenia polegającego na przetwarzaniu twoich danych w ogłoszeniu
        osoby trzeciej, dokonamy weryfikacji tego ogłoszenia. Jeżeli łamie ono nasz regulamin,
        niezwłocznie usuniemy opublikowane w tym ogłoszeniu twoje dane osobowe. Zgłoszenia możesz
        dokonać na adres: <S.Link href={'mailto:kontakt@malutcy.pl'}>kontakt@malutcy.pl</S.Link>.
      </S.Paragraph>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Twoje dane zawarte w ogłoszeniu osoby trzeciej publikujemy na podstawie twojej zgody (art. 6
        ust. 1 lit. (a) RODO), której udzieliłeś (udzieliłaś) naszym partnerom lub umowy (art. 6
        ust. 1 lit. (b) RODO), którą z nimi zawarłeś (zawarłaś). Udzielenie zgody i zawarcie umowy
        są zawsze dobrowolne. Zgodę możesz wycofać w każdej chwili, jednak bez wpływu na
        przetwarzanie, które miało miejsce do chwili wycofania zgody.
      </S.Paragraph>
      <S.Paragraph>
        W sytuacji, w której dokonujesz zgłoszenia naruszenia, my przetwarzamy twoje dane na
        postawie naszego prawnie uzasadnionego interesu (art. 6 ust. 1 lit. (f) RODO), który polega
        na zwalczaniu działań, które są niezgodne z prawem lub naszym regulaminem. Przypominamy, że
        masz prawo wyrażenia sprzeciwu wobec takiego przetwarzania twoich danych osobowych. Jeśli
        ustalimy, że twój interes przeważa nad naszym, natychmiast zaprzestaniemy takiego
        przetwarzania.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami danych mogą być: 1) podmioty, które utrzymują i serwisują nasze systemy
        informatyczne, 2) odbiorcy opublikowanych ogłoszeń, 3) podmioty, które świadczą usługi
        pocztowe lub kurierskie, 4) podmioty, które świadczą usługi wspierające naszą działalność
        (np. kancelarie prawne).
      </S.Paragraph>
      <S.H5> Usuwanie danych, sprzeciw wobec przetwarzania danych</S.H5>
      <S.Paragraph>Twoje dane będą przetwarzane:</S.Paragraph>
      <ol>
        <li>
          <S.Paragraph>
            Do czasu obowiązywania zgody na przetwarzanie danych lub umowy, która je reguluje,
            jednakże z uwzględnieniem:
          </S.Paragraph>
          <ol>
            <li>
              <S.Paragraph>
                opisanego niżej wyjątku, gdy przetwarzamy dane by dochodzić roszczeń lub się przed
                nimi bronić, a także
              </S.Paragraph>
            </li>
            <li>
              <S.Paragraph>opisanego niżej wyjątku dotyczącego archiwizacji.</S.Paragraph>
            </li>
          </ol>
        </li>
      </ol>
      <S.H4>Dochodzimy wobec ciebie roszczeń lub bronimy się przed twoimi roszczeniami</S.H4>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Dochodzenie roszczeń lub obrona przed roszczeniami stanowi nasz prawnie uzasadniony interes
        (art. 6 ust. 1 lit. (f) RODO). Takie przetwarzanie twoich danych będzie miało miejsce
        wyłącznie w przypadku uprawdopodobnienia roszczeń lub ustalenia przez nas istotnego ryzyka
        ich wystąpienia.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami danych mogą być: 1) podmioty, które utrzymują i serwisują nasze systemy
        informatyczne, 2) podmioty, które świadczą usługi pocztowe lub kurierskie, 3) podmioty,
        które świadczą usługi wspierające naszą działalność (np. kancelarie prawne).{' '}
      </S.Paragraph>
      <S.H5>Usuwanie danych, sprzeciw wobec przetwarzania danych</S.H5>
      <S.Paragraph>
        Twoje dane będą przetwarzane co najmniej do terminu przedawnienia ewentualnych roszczeń lub
        zakończenia wszystkich postępowań sądowych lub egzekucyjnych.
      </S.Paragraph>
      <S.Paragraph>
        Przypominamy, że wobec przetwarzania danych na podstawie prawnie uzasadnionego interesu
        zawsze możesz wnieść sprzeciw. Jeśli ustalimy, że twój interes przeważa nad naszym,
        natychmiast zaprzestaniemy takiego przetwarzania.
      </S.Paragraph>
      <S.H4>Archiwizujemy twoje dane</S.H4>
      <S.H5>Cel i podstawa przetwarzania danych</S.H5>
      <S.Paragraph>
        Dane możemy przechowywać w celu zarchiwizowania informacji mającej dla nas uzasadnione,
        faktycznie lub potencjalnie istotne znaczenie. Podstawą takiego przetwarzania danych jest
        nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. (f) RODO) polegający na tym by chronić
        informację, która z dużym prawdopodobieństwem wciąż może służyć osiąganiu innych, prawnie
        uzasadnionych celów.
      </S.Paragraph>
      <S.H5>Odbiorcy danych</S.H5>
      <S.Paragraph>
        Odbiorcami danych mogą być: 1) podmioty, które utrzymują i serwisują nasze systemy
        informatyczne, 2) podmioty, które świadczą usługi pocztowe lub kurierskie, 3) podmioty,
        które świadczą usługi wspierające naszą działalność (np. zewnętrzne archiwum).{' '}
      </S.Paragraph>
      <S.H5>Usuwanie danych, sprzeciw wobec przetwarzania danych</S.H5>
      <S.Paragraph>
        W przypadku archiwizacji czas przechowywania danych będzie zależał od charakteru sprawy.
        Potrzebę przetwarzania danych weryfikujemy średnio co 5 lat, a na dłużej niż 10 lat
        zachowujemy tylko istotne dane.{' '}
      </S.Paragraph>
    </S.Wrapper>
  </ContactPageWrapper>
);

export default TermsPage;

import { useEffect, useState } from 'react';
import Cookie from '../../../src/assets/cookie.svg';
import SmileEmoji from '../../../src/assets/smile_emoji.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { acceptCookie, checkIfCookieIsAccepted } from '../../utils/cookies';
import * as S from './Cookies.styles';

const Cookies = () => {
  const isDesktop = useMediaQuery('(min-width: 880px)');
  const isCookieAccepted = checkIfCookieIsAccepted();

  const [showCookies, setShowCookies] = useState(false);
  const [closedCookies, setClosedCookies] = useState(false);

  const handleAcceptCookie = (e: any) => {
    e.preventDefault();
    acceptCookie();
    setClosedCookies(true);
  };

  useEffect(() => {
    if (!isCookieAccepted) {
      setShowCookies(true);
    }
  }, [isCookieAccepted]);

  if (!showCookies || closedCookies) {
    return null;
  }

  return (
    <>
      <S.Background />
      <S.Wrapper>
        <S.InnerWrapper>
          {isDesktop && (
            <S.CookieWrapper>
              <S.CookieImage src={Cookie} alt="Cookie image" />
            </S.CookieWrapper>
          )}
          <S.BodyWrapper>
            <S.Header>Dbamy o Twoją prywatność</S.Header>
            <S.Text>
              Używamy plików cookie i podobnych technologii, aby personalizować treści, dostosowywać
              reklamy i mierzyć ich skuteczność oraz zapewniać lepszą jakość naszych usług. Klikając
              OK lub włączając opcję w Ustawienia plików cookie, wyrażasz na to zgodę zgodnie z
              naszą{' '}
              <S.Anchor href="https://malutcy.pl/privacy-policy">Polityką plików cookie</S.Anchor>.
              Aby zmienić preferencje lub wycofać zgodę, zaktualizuj swoje Ustawienia plików cookie.
            </S.Text>
          </S.BodyWrapper>
          <S.ButtonsWrapper>
            <S.AcceptButton onClick={(e: any) => handleAcceptCookie(e)} type="button">
              <span>Akceptuję</span>
              <S.Emoji src={SmileEmoji} alt="Smile emoji" />
            </S.AcceptButton>
          </S.ButtonsWrapper>
        </S.InnerWrapper>
      </S.Wrapper>
    </>
  );
};

export default Cookies;
